import EnumAddress from './address.enum';
import EnumCompany from './company.enum';
import EnumIdentity from './identity.enum';

const correlation = Object.freeze({
  VSB_SMB: {
    legalForm: [],
    BUSINESS: {
      COMPANY: [[EnumCompany.description, EnumCompany.KBIS], { isLiveness: false }],
    },
    LEADER: {
      IDENTITY: [EnumIdentity, { isLiveness: true }],
      ADDRESS: [EnumAddress, { isLiveness: false }],
    },
    SHAREHOLDER: {
      IDENTITY: [EnumIdentity, { isLiveness: false }],
    },
  },
  SELF_EMPLOYED_PERSON: {
    legalForm: [1000],
    BUSINESS: {
      COMPANY: [[EnumCompany.description, EnumCompany.KBIS, EnumCompany.SIRENE], { isLiveness: false }],
    },
    LEADER: {
      IDENTITY: [EnumIdentity, { isLiveness: true }],
      ADDRESS: [EnumAddress, { isLiveness: false }],
    },
    SHAREHOLDER: {
      IDENTITY: [EnumIdentity, { isLiveness: false }],
    },
  },
  INDEPENDENT_AND_LIBERAL_PROFESSION: {
    legalForm: [],
    BUSINESS: {
      COMPANY: [[EnumCompany.description, EnumCompany.URSSAF, EnumCompany.INSEE, EnumCompany.OTHER], { isLiveness: false }],
    },
    LEADER: {
      IDENTITY: [EnumIdentity, { isLiveness: true }],
      ADDRESS: [EnumAddress, { isLiveness: false }],
    },
    SHAREHOLDER: {
      IDENTITY: [EnumIdentity, { isLiveness: false }],
    },
  },
  MAJOR_ACCOUNT: {
    legalForm: [],
    BUSINESS: {
      COMPANY: [[EnumCompany.description, EnumCompany.KBIS], { isLiveness: false }],
    },
    LEADER: {
      IDENTITY: [EnumIdentity, { isLiveness: true }],
      ADDRESS: [EnumAddress, { isLiveness: false }],
    },
    SHAREHOLDER: {
      IDENTITY: [EnumIdentity, { isLiveness: false }],
    },
  },
  NATURAL_PERSON: {
    legalForm: [],
    LEADER: {
      IDENTITY: [EnumIdentity, { isLiveness: true }],
    },
  },
});

export default correlation;
