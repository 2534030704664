import SvgSupport from '../../assets/img/icons/support.svg?inline';
import SvgLogOut from '../../assets/img/icons/log-out.svg?inline';
import SvgParameters from '../../assets/img/icons/parameters.svg?inline';
import SvgDocument from '../../assets/img/icons/document.svg?inline';

import auth from '../auth';
import router from '../../router';

const profil = Object.freeze({
  PARAMETRES: {
    label: 'Paramètres',
    icon: SvgParameters,
    name: 'profil',
    action: (currentRoute) => {
      if (currentRoute.path.includes('profil')) return;
      router.push({ name: 'profil' }).catch(() => {});
    },
  },
  SUPPORT: {
    label: 'Support',
    icon: SvgSupport,
    name: 'support',
    action: (currentRoute) => {
      if (currentRoute.path.includes('support')) return;
      router.push({ name: 'support' }).catch(() => {});
    },
  },
  LEGALS: {
    label: 'Informations légales',
    icon: SvgDocument,
    name: 'legals',
    action: () => {
      window.open('/assets/documents/Informations-Legales.pdf', '_blank');
    },
  },
  DECONNEXION: {
    label: 'Déconnexion',
    icon: SvgLogOut,
    action: () => {
      auth.logout();
    },
  },
});

export default profil;
