<template>
  <section class="login">
    <app-two-blocks>
      <template v-slot:main>
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
          <component v-if="step === 1" :is="LoginIdentifiers"
            :email="user.email"
            :password="user.password"
            @onChange="(value) => onChangeUser(value)"
            @click="onClickIdentifiers"
            :isLoading="isLoading"
          ></component>
          <component v-else :is="LoginSecretCode"
            :secretCode="user.secretCode"
            @onChange="(value) => onChangeUser(value)"
            @click="onClickSecretCode"
            :isLoading="isLoading"
          ></component>
        </template>
      </template>
      <template v-slot:animation>
        <app-lottie
          :dataAppearance="step === 1 ? ConnectionAppeareance : PinAppeareance"
          :dataLoop="step === 1 ? ConnectionLoop : PinLoop"
        ></app-lottie>
      </template>
    </app-two-blocks>
  </section>
</template>

<script>
// Api
import ls from 'local-storage';
import ApiCustomer from '../services/api/customer';
import auth from '../services/auth';

// Components
import LoginIdentifiers from '../components/Login/Identifiers.vue';
import LoginSecretCode from '../components/Login/SecretCode.vue';
import AppLottie from '../components/AppLottie.vue';

// Enums
import EnumNotificationType from '../services/enums/notification.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

// Animations
import ConnectionAppeareance from '../assets/animations/individual/connection-appearance.json';
import ConnectionLoop from '../assets/animations/individual/connection-loop.json';
import PinAppeareance from '../assets/animations/individual/pin-appearance.json';
import PinLoop from '../assets/animations/individual/pin-loop.json';

let pendingNotification;

export default {
  name: 'login',
  components: {
    AppLottie,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        secretCode: [],
      },
      preAuthentication: null,
      step: 1,
      isLoading: false,

      // Components
      LoginIdentifiers,
      LoginSecretCode,

      ConnectionAppeareance,
      ConnectionLoop,
      PinAppeareance,
      PinLoop,
    };
  },
  async mounted() {
    this.resetTheme();

    if (auth.isLoggedIn()) {
      await this.saveThemeAndRedirectTo();
    }

    if (pendingNotification) {
      this.$notification.show(pendingNotification);
      pendingNotification = null;
    }
  },
  methods: {
    onChangeUser(value) {
      this.user = {
        ...this.user,
        ...value,
      };
    },
    async onClickIdentifiers() {
      this.isLoading = true;
      try {
        this.preAuthentication = await this.$auth.loginIdentifiers(this.user.email, this.user.password);
        this.step = 2;
      } catch (error) {
        this.$notification.show({
          title: 'La connexion a échouée',
          text: 'Nous vous invitons à vérifier vos identifiants.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    async onClickSecretCode() {
      this.isLoading = true;
      try {
        await this.$auth.loginSecretCode(this.preAuthentication.token, this.user.secretCode);

        await this.saveThemeAndRedirectTo();
      } catch (error) {
        this.$notification.show({
          title: 'La connexion a échouée',
          text: 'Nous vous invitons à entrer de nouveau votre code secret.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    resetTheme() {
      // Réinitialisation du thème
      ls('theme', EnumCustomerType.INDIVIDUAL.toLowerCase());
      this.$theme = EnumCustomerType.INDIVIDUAL.toLowerCase();
    },
    async saveThemeAndRedirectTo() {
      const customer = await ApiCustomer.getCustomer();

      /* Définition du thème en fonction du rôle de l'utilisateur
      * puis sauvegarde dans la variable globale
      * et dans le localstorage
      */
      this.$theme = EnumCustomerType[customer.customer.type].toLowerCase();
      ls('theme', this.$theme);

      this.$notification.show({
        title: 'Bonjour !',
        type: EnumNotificationType.SUCCESS,
      });

      if (!customer.customer.generalConditions
        || !customer.customer.privacyPolicy
        || !customer.customer.treezorContract
        || !customer.customer.kalveenContract) {
        this.$router.push({ name: 'register' });
      } else {
        this.$router.push({ path: '/' }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="sass">
// Surcharge app
#app
  .app-navigation .logo
    width: 25px

.login
  .illustration
    &::after
      background-image: url('../assets/img/individual/background-register.svg')
</style>
