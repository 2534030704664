import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient les informations d'une entreprise
 *
 * @param {string} siret
 * @returns
 */
const getBusinessSearchs = async (siret) => {
  try {
    const response = await axios.get(`${API_URL}/trz/business-searchs`, {
      params: {
        ...(siret && { siret }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient les mandataires d'une entreprise
 * Les deux paramètres sont facultatifs mais au moins un est obligatoire
 *
 * @param {string} externalId optional - cette valeur est renvoyée en réponse de l'appel getBusinessSearchs
 * @param {string} siret optional
 * @returns
 */
const getBusinessInformations = async (siret, externalId) => {
  try {
    const response = await axios.get(`${API_URL}/trz/business-informations`, {
      params: {
        ...(siret && { siret }),
        ...(externalId && { externalId }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getBusinessSearchs = getBusinessSearchs;
api.getBusinessInformations = getBusinessInformations;

export default api;
