<template>
  <div class="profil-security">
    <p>Sécurité</p>
    <p>Contrôlez vos accès à Kalveen</p>

    <div class="email">
      <p>Email validé</p>
      <div class="grid-x align-middle">
        <SvgValid />
        <p v-if="customer">{{ customer.customer.email }}</p>
        <app-spinner v-else :small="true" :color="'--color-success'"></app-spinner>
      </div>
    </div>

    <div class="password">
      <app-button @click="() => isTheFlapOpenPassword = true" :class="{ 'active': isTheFlapOpenPassword }">
        <div class="grid-x align-middle">
          <div class="icon cell shrink">
            <div class="circle">
              <SvgPin />
            </div>
          </div>
          <p class="cell auto">Changer mot de passe</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>

      <EditPassword ref="security-edit-password"
        :isOpen="isTheFlapOpenPassword"
        :isLoading="passwordLoading"
        :errors="passwordErrors"
        @is-open="(value) => isTheFlapOpenPassword = value"
        @click="(data) => setPassword(data)"
      />
    </div>

    <div class="secret-code">
      <app-button @click="() => this.isTheFlapOpenSecretCode = true" :class="{ 'active': isTheFlapOpenSecretCode }">
        <div class="grid-x align-middle">
          <div class="icon cell shrink">
            <div class="circle">
              <SvgLock />
            </div>
          </div>
          <p class="cell auto">Changer code sécurité</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>

      <EditSecretCode ref="security-edit-secretcode"
        :isOpen="this.isTheFlapOpenSecretCode"
        :isLoading="secretCodeLoading"
        :currentStep="secretCodeCurrentStep"
        :errors="secretCodeErrors"
        @is-open="(value) => isTheFlapOpenSecretCode = value"
        @click:step-1="() => getDoubleAuthentication()"
        @click:step-2="(data) => setSecretCode(data)"
      />
    </div>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Components
import EditPassword from './EditPassword.vue';
import EditSecretCode from './EditSecretCode.vue';
import AppButton from '../../../basics/components/AppButton.vue';

// Svg
import SvgPin from '../../../assets/img/icons/pin.svg?inline';
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';
import SvgValid from '../../../assets/img/icons/valid.svg?inline';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'profil-security',
  inject: ['$getCustomer'],
  components: {
    EditPassword,
    EditSecretCode,
    AppButton,

    SvgPin,
    SvgArrowRight,
    SvgLock,
    SvgValid,
  },
  data() {
    return {
      isTheFlapOpenPassword: false,
      isTheFlapOpenSecretCode: false,

      isLoading: false,

      passwordErrors: [],
      passwordLoading: false,

      secretCodeErrors: [],
      secretCodeCurrentStep: 0,
      secretCodeLoading: false,
    };
  },
  watch: {
    isTheFlapOpenPassword(newValue) {
      if (newValue) this.isTheFlapOpenSecretCode = false;
    },
    isTheFlapOpenSecretCode(newValue) {
      if (newValue) this.isTheFlapOpenPassword = false;
    },
  },
  computed: {
    customer: {
      get() {
        return this.$getCustomer();
      },
      set() {
      },
    },
  },
  methods: {
    async setPassword(data) {
      this.resetErrors('password');
      this.passwordLoading = true;
      try {
        await ApiCustomer.setPassword(data.actualPassword, data.password, data.passwordConfirm);
        this.$notification.show({
          title: 'Mot de passe modifié avec succès !',
          type: EnumNotificationType.SUCCESS,
        });
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.passwordErrors.push((error.response.data.message));
          else this.passwordErrors = [...this.passwordErrors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de l\'édition du mot de passe',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.passwordLoading = false;
    },
    async getDoubleAuthentication() {
      this.resetErrors();
      this.secretCodeLoading = true;
      this.secretCodeCurrentStep = 1;
      // Si l'url ne possède pas déjà un token alors on fait l'appel
      if (!this.$route.query.token) {
        try {
          const token = await this.$auth.requestDoubleAuthentication();
          this.$router.replace({ query: { token: token.token } });
        } catch (error) {
          throw error;
        }
      }
      this.secretCodeLoading = false;
    },
    async setSecretCode(data) {
      this.resetErrors();
      this.secretCodeLoading = true;
      try {
        await ApiCustomer.setSecretCode(data.oldSecretCode, data.newSecretCode, this.$route.query.token, data.doubleAuthent);
        this.$notification.show({
          title: 'Code secret modifié avec succès !',
          type: EnumNotificationType.SUCCESS,
        });
        this.resetQuery();
        this.secretCodeCurrentStep = 0;
        this.isTheFlapOpenSecretCode = false;
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.secretCodeErrors.push((error.response.data.message));
          else this.secretCodeErrors = [...this.secretCodeErrors, ...error.response.data.message];
        }
        this.secretCodeCurrentStep = 0;
        this.$notification.show({
          title: 'Echec de l\'édition du code secret',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
        this.resetQuery();
      }
      this.secretCodeLoading = false;
    },
    resetErrors(type) {
      switch (type) {
        case 'password':
          this.passwordErrors = [];
          break;
        default:
          this.secretCodeErrors = [];
          break;
      }
    },
    resetQuery() {
      this.$router.replace({ path: this.$route.path, query: {} });
    },
  },
};
</script>

<style lang="sass">
.profil-security
  > p
    margin: 0
    @include text
    &:first-child
      @include header2
    &:nth-child(2)
      margin-bottom: ($global-padding-horizontal * 2)
      color: var(--color-grey70)
    ~ p
      margin-top: $global-padding-horizontal / 2
  > div
    max-width: 382px
    border-radius: ($global-border-radius * 2)
    background-color: var(--color-cloud)
    ~ div
      margin-top: $global-padding-horizontal
    p
      margin: 0
    > .app-button
      @include header3
      width: 100%
      border-radius: ($global-border-radius * 2)
      padding: $global-padding-horizontal ($global-padding-vertical * 2)
      background-color: var(--color-cloud)
      > div
        width: 100%
      div.arrow
        display: flex
        svg
          path
            stroke: var(--color-grey70)
      p
        text-align: left
      .icon
        margin-right: $global-padding-horizontal
        svg
          margin: 0
          max-width: 25px
          path
            fill: var(--color-black)
            stroke: transparent
        .circle
          display: flex
          height: 38px
          width: 38px
          justify-content: center
          align-items: center
          border-radius: 100%
          background-color: var(--color-70)
      &.active, &:hover
        background-color: var(--color-50)
    &.password .icon svg
      width: 25px // Fix Safari
    &.email
      @include header3
      padding: ($global-padding-vertical * 2)
      p:first-child
        margin-bottom: 4px
      p:last-child
        @include text-bold
        margin-left: ($global-padding-horizontal / 2)
        color: var(--color-success)
      svg
        width: 22px
        path, circle
          stroke: var(--color-success)
</style>
