const yesno = Object.freeze({
  true: {
    name: 'true',
    label: 'Oui',
  },
  false: {
    name: 'false',
    label: 'Non',
  },
});

export default yesno;
