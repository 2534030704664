<template>
  <div class="payouts">
    <app-navigation
      v-if="$route.meta.hasNavigation"
      :type="$route.meta.navigationType"
      :routes="navigation"
    ></app-navigation>
    <router-view></router-view>
  </div>
</template>

<script>
import EnumNavigationPayouts from '../services/enums/navigation/payouts.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'payouts',
  inject: ['$getCustomer'],
  data() {
    return {
      EnumNavigationPayouts,
      EnumCustomerType,
      navigation: [],
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
  },
  mounted() {
    this.navigation = this.cleanNavigation();
  },
  methods: {
    cleanNavigation() {
      let routeValues = Object.values(EnumNavigationPayouts);

      // L'onglet des prélèvements SEPA est disponible uniquement aux B2B
      if (this.customer?.customer?.type !== 'BUSINESS') {
        routeValues = routeValues.filter((v) => v.name !== 'payouts-creditors');
      }

      return routeValues.map((route) => route);
    },
  },
};
</script>

<style lang="sass">
.payouts
  nav.app-navigation.links a
    padding-top: 0
</style>
