<template>
  <transition name="app-flap-slide">
    <div class="app-flap" v-if="isOpen" :class="{ small }" @scroll="onScroll">
      <div class="main">
        <SvgBackgroundBusiness v-if="$theme === EnumCustomerType.BUSINESS.toLowerCase()" class="shape"/>
        <SvgBackgroundIndividual v-else class="shape"/>
        <div class="wrapper grid-x align-middle">
          <SvgCancel class="cancel" @click="$emit('click:is-open', false)"/>
          <div class="cell auto">
            <slot name="app-flap-main"></slot>
          </div>
        </div>
      </div>

      <div class="content">
        <slot name="app-flap-content"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
// Svg
import SvgCancel from '../assets/img/icons/cancel.svg?inline';
import SvgBackgroundBusiness from '../assets/img/business/background-flap.svg?inline';
import SvgBackgroundIndividual from '../assets/img/individual/background-flap.svg?inline';

// Enums
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  components: {
    SvgCancel,
    SvgBackgroundBusiness,
    SvgBackgroundIndividual,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    onScroll: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      EnumCustomerType,
    };
  },
  computed: {
    width() {
      return this.small ? '322px' : '425px';
    },
  },
  watch: {
    isOpen(value) {
      if (value) {
        document.documentElement.style.setProperty('--app-flap-width', this.width);
      }
      this.$isFlapOpen = value;
    },
  },
  mounted() {
    // Ajuste la taille du app-flap dans toute l'application
    if (this.small) {
      document.documentElement.style.setProperty('--app-flap-width', '322px');
    } else {
      document.documentElement.style.setProperty('--app-flap-width', '425px');
    }
  },
  beforeDestroy() {
    this.$emit('click:is-open', false);
    this.$isFlapOpen = false;
  },
};
</script>

<style lang="sass">
// Etend root
\:root
  --app-flap-width: 425px

.app-flap.small
  \:root
    --app-flap-width: 322px

.app-flap
  position: fixed
  top: 0
  right: 0
  max-width: var(--app-flap-width)
  min-height: 100vh
  max-height: 100vh
  background-color: var(--color-cloud)
  border-radius: $global-border-radius * 2 0 0 $global-border-radius * 2
  box-shadow: rgba(84, 84, 84, 0.5)
  z-index: 10000
  overflow-y: auto
  @include scrollbar
  > .main
    position: relative
    display: flex
    align-items: center
    justify-content: center
    @include header2
    .wrapper
      position: absolute
      height: 100%
      width: 100%
      text-align: center
    svg.cancel
      position: absolute
      right: $global-padding-horizontal
      top: $global-padding-horizontal
      width: 16px
      height: 16px
      z-index: 1
      cursor: pointer
      path
        stroke: var(--color-black)
        stroke-width: 2px
  > .content
    padding: ($global-padding-vertical * 5) ($global-padding-horizontal * 2) ($global-padding-horizontal * 2) ($global-padding-horizontal * 2)
    @include text

  &.small
    .main
      overflow-x: hidden
    svg.shape
      min-width: 425px

.app-flap-slide-enter-active
  transition: all ease-in-out 0.150s
.app-flap-slide-leave-active
  transition: all ease-in-out 0.150s
.app-flap-slide-enter, .app-flap-slide-leave-to
  transform: translateX(var(--app-flap-width))
</style>
