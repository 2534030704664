const packageBenefits = Object.freeze({
  CASH_OUT: 'CASH_OUT',
  POST_PAYOUT: 'POST_PAYOUT',
  POST_PAYIN: 'POST_PAYIN',
  NUMBER_OF_VIRTUAL_CARDS: 'NUMBER_OF_VIRTUAL_CARDS',
  NUMBER_OF_PHYSICAL_CARDS: 'NUMBER_OF_PHYSICAL_CARDS',
  NUMBER_OF_CHEQUES: 'NUMBER_OF_CHEQUES',
  NUMBER_OF_WITHDRAWAL: 'NUMBER_OF_WITHDRAWAL',
  WITHDRAWAL_INTRA_EU: 'WITHDRAWAL_INTRA_EU',
  WITHDRAWAL_OUT_EU: 'WITHDRAWAL_OUT_EU',
  NUMBER_OF_SET_PIN: 'NUMBER_OF_SET_PIN',
  TRANSACTION_OUT_EU: 'TRANSACTION_OUT_EU',
  SEPA_REJECT_SDDR: 'SEPA_REJECT_SDDR',
});

export default packageBenefits;
