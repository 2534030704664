<template>
  <div class="offers">
    <p class="title">Voici nos offres !</p>
    <p class="subtitle">Consulter le détail de chacune pour choisir la formule adaptée à votre situation & vos besoins</p>
    <app-radio-custom :values=selection @value="(value) => selected = value" :value="selected"></app-radio-custom>

    <app-offers v-if="selected"
      :packages="data[selected.name]"
      :activeSubscription="{}"
      :isChooseButtonEnabled="false"
      @selectedPackage="(packageId) => {}"
    />
  </div>
</template>

<script>
import AppOffers from '../components/AppOffers.vue';
import ApiPackage from '../services/api/package';

import EnumCustomerType from '../services/enums/customer/type.enum';
import EnumNotificationType from '../services/enums/notification.enum';

export default {
  name: 'offers',
  components: {
    AppOffers,
  },
  data() {
    return {
      data: {
        INDIVIDUAL: [],
        BUSINESS: [],
      },
      selection: [
        {
          name: EnumCustomerType.INDIVIDUAL, label: 'Particulier', active: true, disabled: false,
        },
        {
          name: EnumCustomerType.BUSINESS, label: 'Professionnel', active: false, disabled: false,
        },
      ],
      selected: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getPackages();
    const customerType = this.selection.find((el) => el.name === this.$route.query.type);
    this.selected = customerType || { ...this.selection[0] };
  },
  methods: {
    async getPackages() {
      this.isLoading = true;
      try {
        this.data.INDIVIDUAL = await ApiPackage.getPackages(EnumCustomerType.INDIVIDUAL);
        this.data.BUSINESS = await ApiPackage.getPackages(EnumCustomerType.BUSINESS);
      } catch (error) {
        this.$notification.show({
          title: 'La récupération des informations des offres a échouée.',
          text: 'Nous vous invitons à rafraîchir la page.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.offers
  padding-bottom: ($global-padding-horizontal * 10)
  p
    &.title, &.subtitle
      text-align: center
    &.subtitle
      @include header3
      color: var(--color-grey70)
    &.name
      @include header2
      margin: $global-padding-horizontal 0
    &.price, &.title
      @include header1
      margin: 0
    &.duration
      @include text
      margin-top: -5px
    &.description
      @include text
      margin: ($global-padding-vertical * 2) 0
      color: var(--color-grey90)

  .app-radio-custom
    margin-top: $global-padding-horizontal * 3
    justify-content: center

  a
    @include link
    display: block
    color: var(--color-black)
    margin-top: ($global-padding-vertical * 2)
  .app-offers
    margin: auto

</style>
