<template>
  <div class="app-offers">
    <div class="subscriptions grid-x align-middle">
      <div class="subscription cell auto" v-for="(subscription, index) in packagesComputed" :key="`profil-offer-subscription-${index}`">
        <div class="card">
          <img :src="subscription.image" alt="">
        </div>
        <p class="name">{{ subscription.name }}</p>
        <p class="price">{{ subscription.price / 100 }} €</p>
        <p class="duration">par mois</p>
        <p class="description">{{ subscription.description }}</p>
        <app-button
          v-if="isChooseButtonEnabled"
          @click="() => emitPackageId(subscription.packageId)"
          :disabled="subscription.packageId === activeSubscription.packageId">
          {{ subscription.packageId === activeSubscription.packageId ? 'Mon offre' : 'Choisir' }}
        </app-button>

        <a @click="() => scrollTo(subscription.packageId)">+ de détails</a>
      </div>
    </div>

    <p class="details">Détails de nos offres</p>
    <div class="grid-x table">
      <div class="cell auto empty" ref="empty"></div>
      <div :ref="pack.packageId" class="cell auto offer" v-for="(pack, packageIndex) in packagesComputed" :key="`package-${packageIndex}`">
        <div class="grid-x head">
          <p class="cell small-12 name">{{ pack.name }}</p>
          <p class="cell small-12 price">{{ pack.price | convertCentsToEuros | formatPrice }} / mois</p>
          <div class="cell small-12">
            <app-button size="small"
              v-if="isChooseButtonEnabled"
              @click="() => emitPackageId(pack.packageId)"
              :disabled="pack.packageId === activeSubscription.packageId">
              Choisir
            </app-button>
          </div>
        </div>
        <div v-if="pack.benefits" class="grid-x content">
          <div class="image"><img :src="pack.image" alt=""></div>
          <div class="cell small-12 record" v-for="(prices, pricesIndex) in pack.benefits" :key="`package-${packageIndex}-prices-${pricesIndex}`">
            <template v-if="prices">
              <div v-if="packageIndex === 0" class="labels" :style="{ width: `${emptyWidth}px` }">
                {{ EnumFeeLabels[pricesIndex] }}
                <span class="auto-direct-debit cell small-12">Prélèvement automatique: {{ prices.auto ? 'Oui' : 'Non' }}</span>
              </div>
              <div class="isActive">
                <SvgValid v-if="!!prices.isActive" />
                <SvgDelete v-else />
              </div>

              <div class="fees grid-x" :class="{ disabled: !prices.isActive}">
                <!-- Nombre ou montant -->
                <span v-if="prices.limit.type === EnumBenefitsLimit.NUMBER">{{ prices.limit.value }}</span>
                <span v-else>{{ prices.limit.value | convertCentsToEuros | formatPrice }}</span>

                <span v-if="prices.fees && prices.fees.length > 0">puis</span>
                <span v-for="(fee, feeIndex) in prices.fees" :key="`package-${packageIndex}-prices-${pricesIndex}-fees-${feeIndex}`">
                  <template v-if="EnumFeeType[fee.type] === EnumFeeType.PERCENT ">{{ fee.amount }}%</template>
                  <template v-else>{{ fee.amount | convertCentsToEuros | formatPrice }}</template>
                  <template v-if="feeIndex !== (prices.fees.length - 1)"> et</template>
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnumFeeType from '../services/enums/fees/type.enum';
import EnumFeeLabels from '../services/enums/fees/labels.enum';
import EnumBenefitsLimit from '../services/enums/benefits/limit.enum';

import SvgValid from '../assets/img/icons/valid.svg?inline';
import SvgDelete from '../assets/img/icons/delete.svg?inline';

export default {
  name: 'app-offers',
  components: {
    SvgValid,
    SvgDelete,
  },
  props: {
    packages: {
      type: Array,
      required: true,
    },
    activeSubscription: {
      type: Object,
      required: false,
    },
    isChooseButtonEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      EnumFeeType,
      EnumFeeLabels,
      EnumBenefitsLimit,

      emptyWidth: 0,
    };
  },
  computed: {
    packagesComputed() {
      const tmpPackage = [...this.packages];
      return tmpPackage.sort((a, b) => a.price - b.price);
    },
  },
  updated() {
    this.getWidth();
  },
  created() {
    // Permet de redimenssionner la largeur du texte des labels en fonction de la largeur de la fenêtre du navigateur
    window.addEventListener('resize', this.getWidth);
  },
  mounted() {
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener('resize', this.getWidth);
  },
  methods: {
    getWidth() {
      this.emptyWidth = this.$refs.empty.clientWidth;
    },
    emitPackageId(packageId) {
      this.$emit('selectedPackage', packageId);
    },
    scrollTo(packageId) {
      this.$refs[packageId][0].scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="sass">
// Etend root
\:root
  --table-head-height: 146px

.app-offers
  margin-bottom: ($global-padding-horizontal * 10)
  max-width: 945px
  .subscriptions
    margin: ($global-padding-horizontal * 4) 0 ($global-padding-horizontal * 5) 0
    justify-content: space-between
    .subscription
      max-width: 312px
      padding: ($global-padding-vertical * 4) ($global-padding-vertical * 3)
      height: fit-content
      text-align: center
      border-radius: $global-border-radius * 2
      background-color: var(--color-cloud)
      box-shadow: 0px 4px 20px 0px #EEEEEE
      a
        @include link
        cursor: pointer
      p.name, p.price, p.description, p.duration
        margin: 0
      p.name
        margin-top: $global-padding-horizontal
      p.description
        margin: ($global-padding-vertical * 2) 0
      .app-button
        margin: auto
      .card
        display: flex
        margin: auto
        height: 139px
        align-items: center
        border-radius: $global-border-radius
        img
          width: 100%
          max-height: -webkit-fill-available
          border-radius: $global-border-radius
      ~ .subscription
        margin-left: ($global-padding-vertical * 2)
      &:first-child, &:last-child
        padding: ($global-padding-vertical * 2) ($global-padding-vertical * 3) ($global-padding-vertical * 2) ($global-padding-vertical * 3)
  .details
    @include header2
    text-align: center
  .table
    position: relative
    margin: auto
    background-color: var(--color-cloud)
    > div
      @include text
    .empty
      width: 200px
      border-right: 1px solid var(--color-70)
      &::before
        content: ''
        display: block
        height: var(--table-head-height)
        background-color: var(--color-30)
    .offer
      .head
        position: -webkit-sticky
        position: sticky
        top: 0
        text-align: center
        background-color: var(--color-cloud)
        box-shadow: 0px 2px 8px rgba(238, 238, 238, 0.4)
        .name
          @include header2
          margin-bottom: 0
        .price
          @include header4
          color: var(--color-blue)
          margin-bottom: $global-padding-horizontal
      .content
        padding: $global-padding-horizontal * 2
        text-align: center
        .image
          margin: 0 auto ($global-padding-horizontal * 2) auto
          img
            width: 100%
            border-radius: $global-border-radius
        .labels
          @include text-bold
          position: absolute
          padding: 0 ($global-padding-horizontal * 2) 0 ($global-padding-horizontal * 2)
          max-height: 92px
          left: 0
          overflow-y: auto
          text-align: initial
          word-break: break-word
          @include scrollbar
          .auto-direct-debit
            @include details
            display: block
        ul
          margin: 0
          padding: 0
          li
            display: flex
            margin: 0
            list-style: none
            align-items: center
            &:first-child
              svg
                visibility: hidden
            svg
              margin-right: $global-padding-vertical
              path
                stroke: var(--color-100)
        .record
          .isActive
            svg
              width: 32px
              path
                stroke: var(--color-grey90)
              circle
                fill: var(--color-70)
                stroke: transparent
          .fees
            justify-content: center
            &.disabled
              visibility: hidden
            span ~ span
              margin-left: $global-padding-vertical / 3
          ~ .record
            margin: ($global-padding-horizontal * 2) 0
      ~ .offer
        border-left: 1px solid var(--color-70)
    .app-button
      min-width: initial
      margin: 0 auto $global-padding-horizontal auto
</style>
