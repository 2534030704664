<template>
  <div class="list-beneficiaries-container">
    <div v-if="!isLoading && beneficiaries">
      <div class="search cell auto">
        <app-input
          label="Rechercher un bénéficiaire"
          type="text"
          v-model="queries.search"
        >
        </app-input>
        <SvgSearch />
      </div>

      <div class="list-beneficiaries" @scroll="onScroll">
        <ListEntry
          v-for="(beneficiary, index) in beneficiaries"
          :key="beneficiary.id"
          :title="beneficiary.name"
          :subtitles="[beneficiary.iban, beneficiary.bic]"
          selectable
          :select="select"
          :isNext="index < beneficiaries.length - 1"
          :active="selectedBeneficiary && selectedBeneficiary.id === beneficiary.id"
        />
      </div>
    </div>

    <app-loader v-else></app-loader>
  </div>
</template>

<script>
// Api
import ApiBeneficiary from '../../../services/api/beneficiary';

// SVG
import SvgSearch from '../../../assets/img/icons/search.svg?inline';

// Components
import ListEntry from '../../Common/ListEntry.vue';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'list-beneficiaries-container',
  components: {
    SvgSearch,
    ListEntry,
  },
  props: {
    selectBeneficiary: {
      type: Function,
      required: true,
    },
    selectedBeneficiary: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      beneficiaries: null,

      isLoading: false,

      queries: {
        usableForSct: true,
        pageCount: 5,
        pageNumber: 0,
        search: '',
        count: 0,
      },
    };
  },
  watch: {
    // eslint-disable-next-line
    'queries.search'() {
      this.queries.pageNumber = 1;
      this.debouncedUpdateQuery();
    },
  },
  mounted() {
    this.debouncedUpdateQuery = this.$utils.debounce(this.getInitialBeneficiaries, 400);

    this.getInitialBeneficiaries();
  },
  methods: {
    async getInitialBeneficiaries() {
      this.isLoading = true;

      try {
        const tmpBeneficiaries = await ApiBeneficiary.getBeneficiaries({ ...this.queries });

        this.beneficiaries = tmpBeneficiaries.data;

        // Si des bénéficiaires sont trouvés, on met à jour pageNumber et count
        if (tmpBeneficiaries.data && tmpBeneficiaries.data.length > 0) {
          this.queries = {
            ...this.queries,
            pageNumber: tmpBeneficiaries.metadata.offset,
            count: tmpBeneficiaries.metadata.count,
          };
        }

        // On récupère directement la page suivante
        this.getMoreBeneficiaries();
      } catch (error) {
        this.$notification.show({
          title: 'Echec de la récupération des informations',
          text: 'Nous vous invitons à contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    async getMoreBeneficiaries() {
      if (this.beneficiaries.length >= this.queries.count) return;

      try {
        const tmpBeneficiaries = await ApiBeneficiary.getBeneficiaries({ ...this.queries, pageNumber: this.queries.pageNumber + 1 });

        this.beneficiaries.push(...tmpBeneficiaries.data);
        this.queries = {
          ...this.queries,
          pageNumber: tmpBeneficiaries.metadata.offset,
          count: tmpBeneficiaries.metadata.count,
        };
      } catch (error) {
        //
      }

      this.isLoading = false;
    },
    select(beneficiaryId) {
      // Recherche de l'objet bénéficiaire
      const beneficiary = this.beneficiaries.find((b) => b.id === beneficiaryId);

      this.selectBeneficiary(beneficiary);
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.getMoreBeneficiaries();
      }
    },
  },
};
</script>

<style lang="sass">
.list-beneficiaries-container
  .search
    @include search
    margin-bottom: $global-padding-vertical * 3.3
    .app-input p
      margin: revert
      @include text
      &.label
        background-color: var(--color-cloud)
  .list-beneficiaries
    max-height: 45vh
    overflow: scroll
    overflow-x: hidden
</style>
