<template>
  <div class="card-limits">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p v-if="action === 'add'">Ajouter une carte virtuelle</p>
        <p v-else>Gérez ici les plafonds <br> de votre carte</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">

          <template>
            <p class="label">Plafond mensuel de paiement</p>
            <p class="description">Le plafond mensuel de paiement concerne les paiement en magasin et en ligne.</p>
            <app-slider v-model="limitPaymentMonthComputed" :max="offer.benefits.MONTHLY_PAYMENT_LIMIT.limit.value | convertCentsToEuros">
              <p>0€</p>
              <p>{{ limitPaymentMonthComputed | formatPrice }} / {{ offer.benefits.MONTHLY_PAYMENT_LIMIT.limit.value | convertCentsToEuros | formatPrice }}</p>
            </app-slider>
          </template>

          <template v-if="kind !== 'virtual'">
            <p class="label">Plafond mensuel de retrait</p>
            <p class="description">Le plafond mensuel de retrait concerne tous les distributeurs automatiques.</p>
            <app-slider v-model="limitAtmMonthComputed" :max="offer.benefits.MONTHLY_WITHDRAWAL_LIMIT.limit.value | convertCentsToEuros">
              <p>0€</p>
              <p>{{ limitAtmMonthComputed | formatPrice }} / {{ offer.benefits.MONTHLY_WITHDRAWAL_LIMIT.limit.value | convertCentsToEuros | formatPrice }}</p>
            </app-slider>
          </template>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(typeof error ===  'string' ? error : error.message) }}
              </li>
            </ul>
          </div>

          <app-button class="cell auto submit" :disabled="!limitPaymentMonthComputed || (kind !== 'virtual' && !limitAtmMonthComputed)" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';

export default {
  name: 'card-limits',
  components: {
    SvgWarning,
  },
  props: {
    kind: {
      validator: (val) => ['physical', 'virtual'].indexOf(val) !== -1,
      required: true,
    },
    action: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
    offer: { // offer au lieu de package car package est un mot réservé
      type: Object,
      required: false,
    },
    secretCode: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    limitPaymentMonthComputed: {
      get() { return this.data?.limitPaymentMonth || 0; },
      set(newValue) {
        const tmp = {
          ...this.data,
          limitPaymentMonth: newValue,
        };
        this.$emit('onChange', tmp);
      },
    },
    limitAtmMonthComputed: {
      get() { return this.data?.limitAtmMonth || 0; },
      set(newValue) {
        const tmp = {
          ...this.data,
          limitAtmMonth: newValue,
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpData = {
        limitPaymentMonth: this.limitPaymentMonthComputed,
        limitAtmMonth: 2000,
      };

      if (this.kind === 'physical') {
        tmpData.limitAtmMonth = this.limitAtmMonthComputed;
      }

      try {
        if (this.action === 'add') {
          await ApiCard.createCard(tmpData.limitAtmMonth, tmpData.limitPaymentMonth);

          this.$notification.show({
            title: 'Carte virtuelle en cours de création ...',
            type: EnumNotificationType.PENDING,
          });
          // On attend quelques secondes avant d'envoyer la notification de succès et l'événement de rafaichissement de la liste
          // pour laisser à Treezor le temps d'envoyer le webhook de register3ds afin qu'on puisse demander l'activation de la carte
          setTimeout(() => {
            this.$notification.show({
              title: 'Carte virtuelle créée avec succès !',
              type: EnumNotificationType.SUCCESS,
            });
            this.$emit('click');
          }, 5000);
        } else {
          await ApiCard.setCard(this.data.cardId, this.secretCode, this.data.optionForeign, this.data.optionOnline, this.data.optionAtm, this.data.optionNfc, tmpData.limitAtmMonth, tmpData.limitPaymentMonth);

          this.$notification.show({
            title: 'Carte virtuelle modifiée avec succès !',
            type: EnumNotificationType.SUCCESS,
          });
          this.$emit('click');
        }
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }

          this.$notification.show({
            title: 'Echec de la sauvegarde des informations',
            text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'IBAN validation failed':
          newErrorMessage = 'L\'IBAN saisi n\'est pas valide.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.card-limits
  max-width: 484px
  form
    .app-button.submit
      display: inline-block
      margin-top: ($global-padding-horizontal * 3)
      &.add
        display: flex
        justify-content: center
    .label
      @include header4
    .description
      @include text
    .app-slider
      margin-top: ($global-padding-vertical * 2)
      margin-bottom: ($global-padding-vertical * 3)
    .errors
      display: flex
      svg
        max-width: 22px
        min-width: 22px
      ul
        padding-left: $global-padding-horizontal
        list-style: none
        color: var(--color-warning)
</style>
