<template>
  <app-modal class="app-mobile" :show="show">
    <div class=modal-wrapper>
      <SvgMobile class="mobile-svg" />
      <p>Pour garantir un affichage optimal de votre interface, nous vous conseillons de vous connecter à partir d’un ordinateur ou de télécharger notre application mobile</p>
      <app-button size="large" kind="primary" @click="redirectToMobileApp">
        Continuer sur l’application
      </app-button>
      <app-button class="button-to-store" size="large" kind="secondary" @click="downloadMobileApp">
        Télécharger l'application
      </app-button>

      <SvgCancel class="cancel" @click="goBack" />
    </div>
  </app-modal>
</template>

<script>
import SvgMobile from '../assets/img/mobile.svg?inline';
import SvgCancel from '../assets/img/icons/cancel.svg?inline';

export default {
  name: 'app-mobile',
  components: {
    SvgMobile,
    SvgCancel,
  },
  data() {
    return {
      show: true,

      botExistence: null,
      yelda: null,
      zendesk: null,
    };
  },
  mounted() {
    // Tant que les nots n'existent pas dans le DOM on bloucle toutes les secondes pour pouvoir le récupérer
    this.botExistence = setInterval(() => {
      if (!this.yelda) {
        this.yelda = document.getElementById('yelda_container');
      }
      if (!this.zendesk) {
        this.getZendeskWebWidgetIframe();
      }
      if (this.yelda && this.zendesk) {
        clearInterval(this.botExistence);
        this.botExistence = null;
      }
    }, 1000);
    // Au bout de 5 minutes détruit la boucle si jamais elle ne l'a pas été
    setTimeout(() => {
      if (this.botExistence) {
        clearInterval(this.botExistence);
        this.botExistence = null;
      }
    }, 300000);
  },
  watch: {
    yelda(element) {
      if (element) {
        this.hideYelda(element);
      }
    },
    zendesk(element) {
      if (element) {
        this.hideZendeskWebWidget(element);
      }
    },
  },
  beforeDestroy() {
    if (this.yelda) {
      this.showYelda();
      this.yelda = null;
    }
    if (this.zendesk && this.$route.name === 'support') {
      this.showZendeskWebWidget();
      this.zendesk = null;
    }
  },
  methods: {
    downloadMobileApp() {
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location = 'market://details?id=com.kalveen.app';
      } else if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
        window.location = 'itms-apps://itunes.apple.com/app/id1627195822';
      }
    },
    redirectToMobileApp() {
      if (this.$router.currentRoute.path === '/inscription' && this.$route.query.token) {
        window.open(`kvn://register-password/${this.$route.query.token}`, '_blank');
      } else if (this.$router.currentRoute.path === '/reinitialisation-de-mot-de-passe' && this.$route.query.token) {
        window.open(`kvn://reset-password/${this.$route.query.token}`, '_blank');
      } else {
        window.open('kvn://login', '_blank');
      }
    },
    goBack() {
      this.show = false;
      if (this.yelda) {
        this.showYelda();
      }
      if (this.zendesk && this.$route.name === 'support') {
        this.showZendeskWebWidget();
      } else {
        this.showYelda();
      }
    },
    hideYelda(element) {
      document.getElementById(element.id).style.display = 'none';
    },
    showYelda() {
      document.getElementById(this.yelda.id).style.display = 'flex';
    },
    hideZendeskWebWidget(element) {
      // eslint-disable-next-line
      element.style.visibility = 'hidden';
    },
    showZendeskWebWidget() {
      this.zendesk.style.visibility = 'visible';
    },
    getZendeskWebWidgetIframe() {
      const iframes = Array.from(document.getElementsByTagName('iframe'));
      const iframe = iframes.find((i) => i.id === 'launcher');

      this.zendesk = iframe;
    },
  },
};
</script>

<style lang="sass">
  .app-mobile
    background-color: var(--color-30)
    .modal-wrapper
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: $global-padding-vertical * 3
      margin: auto
      max-width: none
      max-height: none
      top: 20px
      left: 0
      right: 0
      text-align: center
      background-color: var(--color-30)
      transform: none
      overflow: scroll
      &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-repeat: no-repeat
        background-position: center
        z-index: 0
        background-image: url('../assets/img/individual/background-register.svg')
    p, .app-button, svg
      position: relative
      z-index: 1000
    .button-to-store
      margin-top: ($global-padding-horizontal * 2)
      background-color: #C3DBDE
    p
      @include text-bold
      margin: ($global-padding-vertical * 4) 0 ($global-padding-horizontal * 5) 0
    svg.mobile-svg
      min-height: 165px
    svg.cancel
      position: absolute
      top: 20px
      right: 20px
      width: 26px
      height: 26px
      path
        stroke: var(--color-black)
        stroke-width: 1.5
</style>
