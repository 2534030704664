import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient le portefeuille d'un client
 *
 * @returns
 */
const getWallet = async () => {
  try {
    const response = await axios.get(`${API_URL}/wallets`);
    return response.data.data[0];
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère un relevé de compte du portefeuille client
 *
 * @returns
 */
const getWalletStatement = async (year, month) => {
  try {
    const response = await axios.get(`${API_URL}/wallets/statement`, {
      params: {
        ...(year && { year }),
        ...(month && { month }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère la liste des relevés de compte disponible du portefeuille client
 *
 * @returns
 */
const getWalletAvailableStatements = async () => {
  try {
    const response = await axios.get(`${API_URL}/wallets/available-statements`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Renvoie le lien du document RIB du portefeuille d'un client
 *
 * @returns
 */
const getWalletAccountDetails = async () => {
  try {
    const response = await axios.get(`${API_URL}/wallets/account-details`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getWallet = getWallet;
api.getWalletStatement = getWalletStatement;
api.getWalletAvailableStatements = getWalletAvailableStatements;
api.getWalletAccountDetails = getWalletAccountDetails;

export default api;
