<template>
  <div class="support">
    <p>Support</p>

    <SvgSupportPart v-if="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase() "/>
    <SvgSupportPro v-else />

    <p>Vous avez des questions ? Des recommandations ? <br> Cliquez sur le bouton ci-dessous pour accéder au formulaire : </p>

    <app-button :disabled="isIframeLoading" class="text" @click="openZendeskWebWidget" kind="secondary">Accéder au formulaire de contact</app-button>

    <p class="text">Si besoin nous sommes aussi joignables à l’adresse mail suivante : </p>

    <div class="address">
      <span class="address-email" target="_blank">demande@kalveen.com</span>
      <app-button class="text" @click="copyToClipBoard" kind="secondary" size="small">Copier</app-button>
    </div>

    <app-spinner class="support-app-spinner" v-if="isIframeLoading" small color="color-black"></app-spinner>
  </div>
</template>

<script>
// Svg
import SvgSupportPart from '../assets/img/individual/support.svg?inline';
import SvgSupportPro from '../assets/img/business/support.svg?inline';

// Enums
import EnumCustomerType from '../services/enums/customer/type.enum';
import EnumNotificationType from '../services/enums/notification.enum';

export default {
  name: 'support',
  components: {
    SvgSupportPart,
    SvgSupportPro,
  },
  data() {
    return {
      EnumCustomerType,

      zendesk: {
        script: null,
        iframe: null,
      },
      zendeskIframeExistence: null,
      isIframeLoading: true,
    };
  },
  mounted() {
    if (!document.getElementById('ze-snippet')) {
      this.insertZendeskWebWidget();
    }

    // Tant que l'iframe n'existe pas dans le DOM on bloucle toutes les secondes pour pouvoir la récupérer
    this.zendeskIframeExistence = setInterval(() => {
      if (!this.zendesk.iframe) {
        this.getZendeskWebWidgetIframe();
      } else {
        clearInterval(this.zendeskIframeExistence);
        this.zendeskIframeExistence = null;
      }
    }, 1000);
    // Au bout de 5 minutes détruit la boucle si jamais elle ne l'a pas été
    setTimeout(() => {
      if (this.zendeskIframeExistence) {
        clearInterval(this.zendeskIframeExistence);
        this.zendeskIframeExistence = null;
      }
    }, 300000);
  },
  watch: {
    // eslint-disable-next-line
    'zendesk.iframe'(value) {
      if (value) {
        this.isIframeLoading = false;
        this.showZendeskWebWidget();
      }
    },
  },
  beforeDestroy() {
    this.hideZendeskWebWidget();
    document.getElementById('ze-snippet').remove();
  },
  methods: {
    insertZendeskWebWidget() {
      const zendeskScriptJs = document.createElement('script');
      zendeskScriptJs.setAttribute('id', 'ze-snippet');
      zendeskScriptJs.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=d50ef80f-48f7-4751-bc32-eec108964f0d');
      this.zendesk.script = document.body.appendChild(zendeskScriptJs);
    },
    hideZendeskWebWidget() {
      this.zendesk.iframe.style.visibility = 'hidden';
    },
    showZendeskWebWidget() {
      this.zendesk.iframe.style.visibility = 'visible';
    },
    getZendeskWebWidgetIframe() {
      const iframes = Array.from(document.getElementsByTagName('iframe'));
      const iframe = iframes.find((i) => i.id === 'launcher');

      this.zendesk.iframe = iframe;
    },
    openZendeskWebWidget() {
      this.zendesk.iframe.contentWindow.document.getElementsByTagName('button')[0].click();
    },
    copyToClipBoard() {
      const address = document.querySelector('.support .address-email');
      navigator.clipboard.writeText(address.textContent).then(() => {
        this.$notification.show({
          title: 'Presse-papier',
          text: 'Adresse copiée avec succès !.',
          type: EnumNotificationType.SUCCESS,
        });
      });
    },
  },
};
</script>

<style lang="sass">
.support
  p
    margin: 0
    @include text-bold
    color: var(--color-grey70)
    &:first-child
      @include header2
  svg
    margin: ($global-padding-horizontal * 2) 0
  a
    @include link
    display: block
    margin: $global-padding-horizontal 0 0 0
    cursor: initial
    pointer-events: none
  .text
    margin-top: $global-padding-horizontal * 3
  .support-app-spinner
    position: fixed
    right: $global-padding-horizontal * 2
    bottom: $global-padding-horizontal * 2
  .address
    span
      @include link
    button
      display: inline-block
    button
      margin-left: $global-padding-horizontal
</style>
