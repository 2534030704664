<template>
  <div class="operations-detail">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => isOpen = value">
      <template v-slot:app-flap-main>
        <p class="amount">{{ EnumOperationsDirection[data.direction] }} {{ data.amount.amount | convertCentsToEuros | formatPrice }}</p>
        <p v-if="data.ibanFullname" class="label">VIR {{ data.ibanFullname.toUpperCase() }}</p>
        <p v-else-if="data.operationType.toUpperCase() == 'FEES'" class="label">Kalveen</p>
        <p v-else-if="data.operationType.toUpperCase() == 'CREDITNOTE'" class="label">Kalveen</p>
        <p v-else-if="data.metadata && data.metadata.cardPayment && data.metadata.cardPayment.mcc.code === 6011" class="label">RETRAIT {{ data.label.toUpperCase() }}</p>
        <p v-else-if="data.label" class="label">{{ data.label.toUpperCase() }}</p>
        <p v-else class="label">--</p>
        <div class="icon">
          <div class="circle">
            <component v-if="data.instant === true" :is="EnumOperationType['BANKTRANSFER_INSTANT'].icon"></component>
            <component v-else-if="data.metadata && data.metadata.cardPayment && data.metadata.cardPayment.mcc.code === 6011" :is="EnumOperationType['CARDTRANSACTION_WITHDRAW'].icon"></component>
            <component v-else :is="EnumOperationType[data.operationType.toUpperCase()].icon"></component>
          </div>
        </div>
      </template>
      <template v-slot:app-flap-content>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">Libellé:</p>
          </div>
          <div class="cell auto">
            <p v-if="data.ibanFullname" class="libelle">VIR {{ data.ibanFullname.toUpperCase() }}</p>
            <p v-else class="libelle">{{ data.label.toUpperCase() }}</p>
          </div>
        </div>
        <div v-if="data.metadata && data.metadata.cardPayment && data.metadata.cardPayment.mid.value" class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">Information:</p>
          </div>
          <div class="cell auto">
            <p class="libelle">{{ data.metadata.cardPayment.mid.value.toUpperCase() }}</p>
          </div>
        </div>
        <div v-if="data.messageToUser" class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">Motif:</p>
          </div>
          <div class="cell auto">
            <p class="libelle">{{ data.messageToUser.toUpperCase() }}</p>
          </div>
        </div>
        <div v-if="data.dbtrIban" class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">IBAN:</p>
          </div>
          <div class="cell auto iban-box">
            <p class="libelle iban">{{ spaceIBAN(data.dbtrIban.toUpperCase()) }}</p>
          </div>
        </div>
        <div v-if="data.ibanBic" class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">BIC:</p>
          </div>
          <div class="cell auto">
            <p class="libelle">{{ data.ibanBic.toUpperCase() }}</p>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">Date:</p>
          </div>
          <div class="cell auto">
            <p class="libelle">{{ data.date.creation | formatDate }}</p>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p class="libelle">Moyen de paiement:</p>
          </div>
          <div class="cell auto">
            <p class="libelle" v-if="data.instant === true">{{ EnumOperationType['BANKTRANSFER_INSTANT'].label }}</p>
            <p class="libelle" v-else>{{ EnumOperationType[data.operationType.toUpperCase()].label }}</p>
          </div>
        </div>
         <app-action-card v-if="document" withIcon @click="openUrl">
          <template v-slot:icon><SvgFile /></template>
          <template v-slot:content>
            <p>Consulter la pièce-jointe</p>
          </template>
        </app-action-card>
      </template>
    </app-flap>
  </div>
</template>

<script>
import EnumOperationType from '../../services/enums/operations/type.enum';
import EnumOperationsDirection from '../../services/enums/operations/direction.enum';

import SvgFile from '../../assets/img/icons/file.svg?inline';

export default {
  name: 'operations-detail',
  components: {
    SvgFile,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    document: {
      type: Object || null,
      required: false,
    },
  },
  data() {
    return {
      isOpen: Object.keys(this.data).length > 0 || false,
      EnumOperationsDirection,
      EnumOperationType,
    };
  },
  watch: {
    data() {
      this.isOpen = Object.keys(this.data).length > 0 || false;
    },
  },
  methods: {
    spaceIBAN(iban = '') {
      return iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    openUrl() {
      window.open(this.document.url, '_blank');
    },
  },
};
</script>

<style lang="sass">
.iban
  font-size: 12px
.iban-box
  flex-basis: 200px !important
.operations-detail
  p
    margin: 0
  .amount
    @include header1
  .label
    @include header3
    color: var(--color-grey70)
  .app-flap
    .main .icon
      position: absolute
      margin: auto
      width: fit-content
      bottom: calc((54px / 2) * -1)
      left: 0
      right: 0
      svg
        height: 30px
        width: 30px
        path
          fill: var(--color-cloud)
      .circle
        display: flex
        height: 54px
        width: 54px
        justify-content: center
        align-items: center
        border-radius: 100%
        background-color: var(--color-black)
    .content
      .grid-x
        div:first-child p
          @include header5
          color: var(--color-grey70)
        div:last-child
          text-align: right
          @include text
        ~ .grid-x
          border-top: 1px solid var(--color-50)
      .app-button
        margin-top: $global-padding-horizontal
        border-radius: $global-border-radius
        background-color: var(--color-50)
        .icon svg
          margin-left: 1px
          path
            stroke: var(--color-black)
            fill: transparent
</style>
