import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient la liste des payouts d'un utilisateur
 */
const getPayouts = async ({ pageNumber, pageCount }) => {
  try {
    const response = await axios.get(`${API_URL}/payouts`, {
      params: {
        ...(pageNumber && { pageNumber }),
        ...(pageCount && { pageCount }),
        sortBy: 'createdDate',
        sortOrder: 'DESC',

      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Permet de créer un virement
 */
const createPayout = async ({
  beneficiaryId,
  label,
  amount,
  document,
  secretCode,
}) => {
  const formData = new FormData();
  if (document) formData.append('document', document);
  formData.append('beneficiaryId', beneficiaryId);
  formData.append('label', label);
  formData.append('amount', amount);
  formData.append('currency', 'EUR');
  formData.append('secretCode', secretCode);

  try {
    const response = await axios.post(`${API_URL}/payouts`, formData);

    return response;
  } catch (error) {
    throw error;
  }
};

const importPayouts = async ({
  document,
}) => {
  const formData = new FormData();
  formData.append('document', document);

  try {
    const response = await axios.post(`${API_URL}/payouts/import`, formData);

    return response;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getPayouts = getPayouts;
api.createPayout = createPayout;
api.importPayouts = importPayouts;

export default api;
