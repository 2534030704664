<template>
  <label class="app-input" :class="{ 'phone': type === 'phone'}">
    <p class="label" :class="{ 'label-at-top': isFocused || value }">{{ label }}</p>

    <span v-if="type === 'phone'" class="prefix">+33</span>
    <input
      ref="input"
      :class="{ 'has-suffix': suffix }"
      :type="type === 'phone' ? 'text' : type"
      :value="value"
      :required="required"
      :min="min"
      :max="max"
      :step="step"
      :minlength="minlength"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :name="name"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="$emit('input', $event.target.value)"
      :disabled="isDisabled"/>

    <div class="suffix">
      <slot name="suffix"></slot>
    </div>

    <component
      v-if="type === 'password'"
      class="password-icon"
      :class="{ visible: isPasswordVisible }"
      :is="isPasswordVisible ? SvgEye : SvgEyeClosed"
      @click="toggleIsPasswordVisible">
    </component>

    <p v-if="validationError" class="error"><SvgWarning /><span>{{ validationError }}</span></p>
  </label>
</template>

<script>
import SvgEye from '../../assets/img/icons/eye.svg?inline';
import SvgEyeClosed from '../../assets/img/icons/eye-closed.svg?inline';
import SvgWarning from '../../assets/img/icons/warning.svg?inline';

export default {
  components: {
    SvgWarning,
  },
  props: {
    value: [String, Array, Number],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'phone'].indexOf(val) !== -1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      default: 'off',
    },
    label: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    step: {
      type: String,
      required: false,
    },
    minlength: {
      type: String,
      required: false,
    },
    maxlength: {
      type: String,
      required: false,
    },
    suffix: {
      type: [String, Object],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isFocused: false,
      isPasswordVisible: false,
      validationError: null,
      SvgEye,
      SvgEyeClosed,
    };
  },
  watch: {
    isPasswordVisible() {
      switch (this.isPasswordVisible) {
        case true:
          this.$refs.input.type = 'text';
          break;
        default:
          this.$refs.input.type = 'password';
          break;
      }
    },
    value() {
      this.checkCustomValidation();
    },
  },
  computed: {
    input() {
      return this.value;
    },
    error() {
      return !!this.validationError;
    },
  },
  mounted() {
    this.checkCustomValidation();
  },
  methods: {
    toggleIsPasswordVisible() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    checkCustomValidation() {
      this.validationError = null;
    },
  },
};
</script>

<style lang="sass">
label.app-input
  position: relative
  display: block
  input
    @include text
    width: 100%
    height: 44px
    padding: $global-padding-vertical ($global-padding-vertical * 2)
    border-radius: $global-border-radius * 5
    border: 1px solid var(--color-grey70)
    background-color: transparent
    transition: all 0.2s ease-in-out
    &[disabled]
      color: var(--color-grey70)
    &:hover
      border-color: darken(white, 10%)
    &:focus
      outline: 0
      border-color: black
    &[type='password']
      padding: $global-padding-vertical ($global-padding-vertical * 6) $global-padding-vertical ($global-padding-vertical * 2)
    &.has-suffix
      padding-right: ($global-padding-vertical * 4)

    // Cacher les flèches de droite pour le type number
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
    /* Firefox */
    &[type=number]
      -moz-appearance: textfield
  &.phone
    .label
      margin-left: 30px
    input
      padding-left: ($global-padding-vertical * 2) + 30px
    .prefix
      @include text
      margin: 0 0 0 ($global-padding-vertical * 2)
  .label
    @include text
    position: absolute
    padding: 0 4px
    line-height: initial !important
    left: $global-padding-vertical * 2 - 4px
    color: var(--color-100) !important
    background-color: var(--color-30)
    transition: all 0.2s ease-in-out
    &.label-at-top
      font-size: 11px
      color: var(--color-grey70) !important
      transform: translateY(-$global-padding-vertical - 5px)
  .prefix
    position: absolute
    display: flex
    top: 0
    bottom: 0
    left: 0
    align-items: center
  .suffix
    @include text
    position: absolute
    display: flex
    margin-right: ($global-padding-vertical * 2)
    top: 0
    bottom: 0
    right: 0
    align-items: center
  svg.password-icon
    position: absolute
    right: $global-padding-vertical * 2
    top: 13px
    bottom: 0
    cursor: pointer
    &.visible
      top: 12px
  p.error
    display: flex
    align-items: center
    margin-top: 4px
    svg
      width: 16px
      height: 16px
    span
      margin-left: 6px
      @include text-bold
      line-height: initial
      font-size: 11px
      color: var(--color-warning)
</style>
