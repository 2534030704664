import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient une carte physique et virtuelle de l'utilisateur
 *
 * @param {boolean} isPhysical
 * @param {[EnumLockStatus]} lockStatus
 * @returns
 */
const getCard = async (isPhysical, lockStatus, cardId) => {
  try {
    const response = await axios.get(`${API_URL}/cards`, {
      params: {
        ...(typeof isPhysical === 'boolean' && { isPhysical }),
        lockStatus,
        ...(cardId && { cardId }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient les cartes physiques et virtuelles de l'utilisateur
 *
 * @param {boolean} isPhysical
 * @param {[EnumLockStatus]} lockStatus
 * @returns
 */
const getCards = async (isPhysical, lockStatus) => {
  try {
    const response = await axios.get(`${API_URL}/cards`, {
      params: {
        ...(typeof isPhysical === 'boolean' && { isPhysical }),
        lockStatus,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient l'image verso d'une carte physique d'un utilisateur
 *
 * @param {string} treezorCardId
 * @param {string} secretCode
 * @returns
 */
const getCardImage = async (treezorCardId, secretCode) => {
  try {
    const response = await axios.post(`${API_URL}/cards/${treezorCardId}/image`, {
      secretCode: secretCode.toString().replaceAll(',', ''),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la création d'une carte virtuelle pour un utilisateur
 *
 * @param {number} limitAtmMonth
 * @param {number} limitPaymentMonth
 * @returns
 */
const createCard = async (limitAtmMonth, limitPaymentMonth, physical = false) => {
  try {
    const response = await axios.post(`${API_URL}/cards`, {
      ...(limitAtmMonth && { limitAtmMonth }),
      ...(limitPaymentMonth && { limitPaymentMonth }),
      ...(physical && { physical }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande le blocage/déblocage d'une carte
 *
 * @param {string} cardId
 * @param {number} lockStatus
 * @returns
 */
const setCardLockUnlock = async (cardId, lockStatus) => {
  try {
    const response = await axios.post(`${API_URL}/cards/${cardId}/lock`, { lockStatus });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande l'activation d'une carte
 *
 * @param {string} cardId
 * @param {string} publicToken
 * @returns
 */
const setCardActivation = async (cardId, publicToken) => {
  try {
    const response = await axios.post(`${API_URL}/cards/${cardId}/activate`, {
      ...(publicToken && { publicToken }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la modification des options d'une carte
 *
 * @param {string} cardId
 * @param {string} secretCode
 * @param {number} foreign le paiement à l'étranger
 * @param {number} online le paiement sans contact
 * @param {number} atm le retrait au distributeur
 * @param {number} nfc le paiement sans contact
 * @param {number} limitAtmMonth la limite de plafond de retrait
 * @param {number} limitPaymentMonth la limite de plafond de paiement
 * @returns
 */
const setCard = async (cardId, secretCode, foreign, online, atm, nfc, limitAtmMonth, limitPaymentMonth, name) => {
  try {
    const response = await axios.put(`${API_URL}/cards/${cardId}`, {
      secretCode: secretCode.toString().replaceAll(',', ''),
      ...(foreign !== null && { optionForeign: foreign }),
      ...(online !== null && { optionOnline: online }),
      ...(atm !== null && { optionAtm: atm }),
      ...(nfc !== null && { optionNfc: nfc }),
      ...(limitAtmMonth && { limitAtmMonth }),
      ...(limitPaymentMonth && { limitPaymentMonth }),
      ...(name && { name }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la modification du code pin
 *
 * @param {string} cardId
 * @param {string} newPIN
 * @param {string} confirmPIN
 * @param {string} secretCode
 * @returns
 */
const setCardPin = async (cardId, newPIN, confirmPIN, secretCode) => {
  try {
    const response = await axios.put(`${API_URL}/cards/${cardId}/change-pin`, {
      newPIN: newPIN.toString().replaceAll(',', ''),
      confirmPIN: confirmPIN.toString().replaceAll(',', ''),
      secretCode: secretCode.toString().replaceAll(',', ''),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère les transactions d'une carte
 *
 * @param {string} treezorCardId
 * @returns
 */
const getCardTransactions = async (treezorCardId, limit, offset, paymentStatus) => {
  try {
    const response = await axios.get(`${API_URL}/cards/${treezorCardId}/transactions`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(paymentStatus && { paymentStatus }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getCards = getCards;
api.getCardImage = getCardImage;
api.createCard = createCard;
api.setCardLockUnlock = setCardLockUnlock;
api.setCardActivation = setCardActivation;
api.setCard = setCard;
api.setCardPin = setCardPin;
api.getCardTransactions = getCardTransactions;
api.getCard = getCard;

export default api;
