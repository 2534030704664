<template>
  <div class="profil-offer">
    <p class="title">Offre</p>
    <p class="subtitle">Vous pouvez changer votre offre à tout moment.</p>

    <app-loader v-if="isLoading || packages.length < 0 || !activeSubscription"></app-loader>

    <app-offers
      :packages="packages"
      :activeSubscription="activeSubscription"
      @selectedPackage="(packageId) => changeSubscription(packageId)"
    ></app-offers>
  </div>
</template>

<script>
// Api
import ApiPackages from '../../services/api/package';
import ApiSubscription from '../../services/api/subscription';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';

// components
import AppOffers from '../AppOffers.vue';

export default {
  name: 'profil-offer',
  inject: ['$getCustomer'],
  components: {
    AppOffers,
  },
  data() {
    return {
      packages: [],
      activeSubscription: null,
      isLoading: false,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
  },
  watch: {
    customer() {
      this.getSubscription();
    },
  },
  mounted() {
    this.getPackages();
    this.getSubscription();
  },
  methods: {
    async getPackages() {
      this.isLoading = true;
      try {
        this.packages = await ApiPackages.getPackages(this.$theme.toUpperCase());
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async getSubscription() {
      this.isLoading = true;
      try {
        const tmpSubscription = await ApiSubscription.getSubscription();
        // eslint-disable-next-line
        this.activeSubscription = tmpSubscription[0]; // [0] récupère le premier abonnement qui est celui actif
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    changeSubscription(packageId) {
      this.$message.show({
        title: 'Changement d\'abonnement',
        text: 'Êtes-vous sûr(e)s de vouloir changer d\'offre ?.',
        confirmText: 'Confirmer',
        hasCancel: true,
        onConfirm: () => this.confirmNewSubscription(packageId),
      });
    },
    async confirmNewSubscription(packageId) {
      this.isLoading = true;
      try {
        await ApiSubscription.setSubscription(packageId);
        this.$getCustomer(true);
      } catch (error) {
        this.$notification.show({
          title: 'Echec du changement d\'offre',
          text: 'Nous vous invitons à consulter le support client.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.profil-offer
  p
    &.title
      @include header2
      margin: 0
      + p
        margin-bottom: ($global-padding-horizontal * 2)
    &.subtitle
      @include text
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)

    &.name
      @include header2
      margin-top: $global-padding-horizontal
    &.price
      @include header1
    &.duration
      @include text
      margin-top: -5px
    &.description
      @include text
      margin-top: ($global-padding-vertical * 2)
  a
    @include link
    display: block
    color: var(--color-black)
    margin-top: ($global-padding-vertical * 2)

</style>
