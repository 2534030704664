<template>
  <div class="beneficiaries-list">
    <p class="title">Bénéficiaires</p>

    <div class="search cell auto">
      <app-input
        label="Rechercher un bénéficiaire"
        type="text"
        v-model="queries.search"
      >
      </app-input>
      <SvgSearch />
    </div>

    <div class="container-buttons">
      <app-button class="add" kind="primary" size="large" @click="() => $emit('click', { action: 'add' })">
        <div class="icon">
          <SvgPlus />
        </div>
        Ajouter un bénéficiaire
        <SvgArrowRight class="arrow-right" />
      </app-button>
    </div>

    <app-loader v-if="isLoading"></app-loader>
    <div v-else class="data">
      <p class="no-data" v-if="!beneficiariesComputed || beneficiariesComputed.length <= 0">
        <SvgNoBeneficiariesIndividual v-if="$theme === EnumCustomerType.INDIVIDUAL"/>
        <SvgNoBeneficiariesBusiness v-else/>
        <span>Aucun bénéficiaire sur votre compte</span>
      </p>
      <template v-else>
        <app-button v-for="(beneficiary, index) in beneficiariesComputed"
          :key="`${Math.random()}-${index}`"
          @click="$emit('click', { ...beneficiary, action: 'edit' })"
          class="record"
          :class="{ 'active': selectedBeneficiary && selectedBeneficiary.id === beneficiary.id }"
        >
          <div class="grid-x">
            <div class="cell">
              <p class="fullname">{{ (beneficiary.nickName ||  beneficiary.name).toUpperCase() }}</p>
              <p class="iban">IBAN: {{ spaceIBAN(beneficiary.clearIban) }}</p>
              <p class="bic">BIC: {{ beneficiary.clearBic }}</p>
            </div>
          </div>

          <SvgArrowRight class="arrow-right" />
        </app-button>

        <app-button class="see-more" :isDisabled="isSeeMoreDisabledComputed" :isLoading="isLoadingMoreData" @click="seeMore" size="small" kind="secondary">
          Voir plus
        </app-button>
      </template>
    </div>
  </div>
</template>

<script>
// Api
import ApiBeneficiary from '../../services/api/beneficiary';

// Svg
import SvgArrowRight from '../../assets/img/icons/arrow-right.svg?inline';
import SvgPlus from '../../assets/img/icons/plus.svg?inline';
import SvgSearch from '../../assets/img/icons/search.svg?inline';
import SvgNoBeneficiariesIndividual from '../../assets/img/individual/no-beneficiaries.svg?inline';
import SvgNoBeneficiariesBusiness from '../../assets/img/business/no-beneficiaries.svg?inline';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';
import EnumOrderBy from '../../services/enums/orderBy.enum';
import EnumSort from '../../services/enums/sort.enum';
import EnumCustomerType from '../../services/enums/customer/type.enum';

export default {
  name: 'beneficiaries-list',
  components: {
    SvgArrowRight,
    SvgPlus,
    SvgSearch,
    SvgNoBeneficiariesIndividual,
    SvgNoBeneficiariesBusiness,
  },
  props: {
    selectedBeneficiary: {
      type: Object,
      required: false,
    },
    needRefresh: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      beneficiaries: null,
      beneficiariesFiltered: null,
      queries: {
        usableForSct: true,
        pageCount: 5,
        pageNumber: 1,
        sort: EnumSort.UPDATEDAT,
        orderBy: EnumOrderBy.ASC,
        search: null,
      },

      isLoading: false,
      isLoadingMoreData: false,
      isSeeMoreDisabled: false,

      EnumOrderBy,
      EnumSort,
      EnumCustomerType,
    };
  },
  mounted() {
    this.debouncedUpdateQuery = this.$utils.debounce(this.getBeneficiaries, 400);

    this.getBeneficiaries();
  },
  computed: {
    beneficiariesComputed() {
      return this.beneficiaries?.data;
    },
    isSeeMoreDisabledComputed() {
      return this.isSeeMoreDisabled || this.beneficiariesComputed?.length <= 0;
    },
  },
  watch: {
    // eslint-disable-next-line
    'queries.search'() {
      this.queries.pageNumber = 1;
      this.isSeeMoreDisabled = false;
      this.debouncedUpdateQuery();
    },
    needRefresh(value) {
      if (value) {
        this.queries.pageNumber = 1;
        this.debouncedUpdateQuery();
      }
      this.$emit('hasBeenRefreshed');
    },
  },
  methods: {
    spaceIBAN(iban = '') {
      return iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    async getBeneficiaries() {
      this.isLoading = true;
      try {
        this.beneficiaries = await ApiBeneficiary.getBeneficiaries({ ...this.queries });

        if (this.beneficiaries.data.length >= this.beneficiaries.metadata.count) {
          this.isSeeMoreDisabled = true;
        }
      } catch (error) {
        this.$notification.show({
          title: 'Echec de la récupération des informations',
          text: 'Nous vous invitons à contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    async add() {
      // await ApiBeneficiary.getBeneficiaries();
    },
    async seeMore() {
      this.isLoadingMoreData = true;
      this.queries.pageNumber += 1;

      const tmpBeneficiaries = await ApiBeneficiary.getBeneficiaries({ ...this.queries });

      this.beneficiaries.data.push(...tmpBeneficiaries.data);
      this.beneficiaries.metadata = { ...tmpBeneficiaries.metadata };

      if (this.beneficiaries.data.length >= tmpBeneficiaries.metadata.count) {
        this.isSeeMoreDisabled = true;
      }

      this.isLoadingMoreData = false;
    },
  },
};
</script>

<style lang="sass">
.beneficiaries-list
  max-width: 484px
  .no-data
    @include no-data
  p
    margin: 0
  .title
    @include header2
    margin-bottom: $global-padding-vertical * 3.3
  .search
    @include search
    margin-bottom: $global-padding-vertical * 3.3
    .app-input p
      margin: revert
  .app-button.add, .data .app-button.record
    @include tile
  .app-button.add
    margin: $global-padding-horizontal 0
  .data .app-button
    &.record
      text-align: left
      border-radius: $global-border-radius
      .bic, .iban
        @include text
      ~ .app-button
        margin-top: $global-padding-horizontal
    &.see-more
      justify-content: center

    .app-spinner span
      border-color: var(--color-cloud) !important
</style>
