const parentType = Object.freeze({
  SHAREHOLDER: {
    name: 'SHAREHOLDER',
    label: 'Bénéficiaire effectif',
  },
  LEADER: {
    name: 'LEADER',
    label: 'Gérant(e)',
  },
  EMPLOYEE: {
    name: 'EMPLOYEE',
    label: 'Salarié(e)',
  },
});

export default parentType;
