<template>
  <section class="register">
    <app-loader v-if="isLoading"></app-loader>

    <template v-else-if="!$route.query.token && !$auth.isLoggedIn()">
      <StateAsk />
    </template>

    <template v-else-if="$route.query.token && !$auth.isLoggedIn()">
      <StateInitialize />
    </template>

    <template v-else-if="$auth.isLoggedIn()">
      <StateComplete />
    </template>
  </section>
</template>

<script>
// Api
// import ls from 'local-storage';
// import axios from 'axios';
import auth from '../services/auth';
import ApiCustomer from '../services/api/customer';

// Components
import StateAsk from '../components/Register/State/Ask.vue';
import StateInitialize from '../components/Register/State/Initialize.vue';
import StateComplete from '../components/Register/State/Complete.vue';

// Enums
import EnumParentType from '../services/enums/customer/parentType.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'register',
  components: {
    StateAsk,
    StateInitialize,
    StateComplete,
  },
  provide() {
    return {
      /**
      * needsToBeUpdated: Boolean, demande si les informations de l'utilisateur doivent être remplacées par un nouvel appel api
      * newCustomerData: Object, récupère un objet avec les nouvelles données, évite un nouvel appel api
      */
      $getCustomer: (needsToBeUpdated, newCustomerData) => {
        if (needsToBeUpdated) this.getCustomer();
        if (newCustomerData) this.customer = newCustomerData;
        return this.customerComputed;
      },
      $getLeader: (needsToBeUpdated, newLeaderData) => {
        if (needsToBeUpdated) this.getLeader();
        if (newLeaderData) this.leader = newLeaderData;
        return this.leaderComputed;
      },
      $isSelfEmployedPerson: () => this.isSelfEmployedPersonComputed,
    };
  },
  data() {
    return {
      customer: null,
      leader: null,
      isLoading: false,
    };
  },
  computed: {
    customerComputed() {
      return this.customer;
    },
    leaderComputed() {
      return this.leader;
    },
    isSelfEmployedPersonComputed() {
      return (this.customer.customer.type === EnumCustomerType.BUSINESS) && this.customer.customer.isSelfEmployedPerson;
    },
  },
  watch: {
    customer() {
      if (auth.isLoggedIn()) {
        if (this.customer?.customer.type === EnumCustomerType.BUSINESS) this.getLeader();
      }
    },
  },
  mounted() {
    if (auth.isLoggedIn()) {
      this.getCustomer();
    }
    this.setAppFooterPosition();
  },
  beforeDestroy() {
    this.resetAppFooterPosition();
  },
  methods: {
    async getCustomer() {
      try {
        this.customer = await ApiCustomer.getCustomer();
      } catch (error) {
        //
      }
    },
    async getLeader() {
      try {
        const [tmpLeader] = await ApiCustomer.getCustomerChildren(EnumParentType.LEADER.name);
        this.leader = tmpLeader;
      } catch (error) {
        //
      }
    },
    setAppFooterPosition() {
      const component = document.querySelector('.app-footer');
      component.style.margin = 'auto';
      component.style.left = '50%';
      component.style.right = '50%';
      component.style.transform = 'translate(-50%, 0%)';
    },
    resetAppFooterPosition() {
      const component = document.querySelector('.app-footer');
      component.style.removeProperty('margin');
      component.style.left = 'initial';
      component.style.right = 'initial';
      component.style.transform = 'initial';
    },
  },
};
</script>

<style lang="sass">
.register
</style>
