<template>
  <div class="operations grid-x">
    <div class="wrapper cell" :class="{ 'auto': Object.keys(this.selectedOperation).length > 0 }">
        <p class="balance">Solde du compte</p>
        <template v-if="balances">
          <p v-if="balances.currentBalance" class="balance-amount">{{ balances.currentBalance | formatPriceWithNegative }}</p>
          <p v-else class="balance-amount">{{ 0 | formatPrice }}</p>
        </template>
        <template v-else>
          <app-spinner :small="true"></app-spinner>
        </template>
        <p class="balance-previsional">prévisionnel :
          <template v-if="balances">
            <span v-if="balances.authorizedBalance">{{ balances.authorizedBalance | formatPriceWithNegative }}</span>
            <span v-else>{{ 0 | formatPrice }}</span>
          </template>
          <template v-else>
            <app-spinner :small="true"></app-spinner>
          </template>
        </p>

      <div class="grid-x export">
        <div class="cell auto">
          <app-select v-model="selectedPeriod" label="Période" :options="EnumPeriodComputed"></app-select>
        </div>
        <div class="cell shrink container-buttons">
          <app-button size="small" kind="primary">
            Exporter
          </app-button>
        </div>
      </div>

      <div class="list">
        <template v-if="operationsComputed.data">
          <template v-if="operationsComputed.data.length > 0">
            <OperationsList :selectedOperation="this.selectedOperation" :data="operationsComputed.data" @click="(value) => this.selectedOperation = value" />
            <app-button class="see-more" :isLoading="isLoadingMoreData" :disabled="isSeeMoreDisabledComputed" v-if="operationsComputed.metadata" size="small" kind="secondary" @click="seeMore">Voir plus <SvgArrowDown /></app-button>
          </template>
          <p v-else class="no-data">
            <SvgEmptyOperationsIndividual v-if="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase()"/>
            <SvgEmptyOperationsBusiness v-else-if="$theme === EnumCustomerType.BUSINESS.toLowerCase()"/>
            <span>Aucune opération sur votre compte</span>
          </p>
        </template>
        <template v-else>
          <app-loader></app-loader>
        </template>
      </div>
    </div>

    <div class="detail cell" :class="{ 'shrink': Object.keys(this.selectedOperation).length > 0 }">
      <OperationsDetail ref="operation-detail" :data="this.selectedOperation" :document="getDocument()" />
    </div>
  </div>
</template>

<script>
// Api
import ApiOperations from '../services/api/operation';
import ApiBalances from '../services/api/balance';
import ApiDocuments from '../services/api/document';

// Svg
import SvgArrowDown from '../assets/img/icons/down.svg?inline';
import SvgEmptyOperationsIndividual from '../assets/img/individual/no-operations.svg?inline';
import SvgEmptyOperationsBusiness from '../assets/img/business/no-operations.svg?inline';

// Components
import OperationsList from '../components/Operations/List.vue';
import OperationsDetail from '../components/Operations/Detail.vue';

// Enums
import EnumPeriod from '../services/enums/period.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';
import EnumNotificationType from '../services/enums/notification.enum';
import EnumOperationStatus from '../services/enums/operations/status.enum';

export default {
  name: 'operations',
  components: {
    SvgArrowDown,
    SvgEmptyOperationsIndividual,
    SvgEmptyOperationsBusiness,
    OperationsList,
    OperationsDetail,
  },
  data() {
    return {
      balances: null,
      operations: [],
      documentPayouts: null,
      selectedPeriod: null,
      selectedOperation: {},

      queries: {
        dateFrom: null,
        dateTo: this.$dayjs().format('YYYY-MM-DD'),
        operationType: null,
        status: [EnumOperationStatus.SETTLED.name, EnumOperationStatus.AUTHORIZED.name],
        cursor: null,
        limit: 15,
      },

      EnumPeriod,
      EnumCustomerType,
      EnumNotificationType,

      isLoading: false,
      isLoadingMoreData: false,
      isSeeMoreDisabled: false,
    };
  },
  computed: {
    EnumPeriodComputed() {
      return Object.keys(EnumPeriod).map((key) => EnumPeriod[key]);
    },
    operationsComputed() {
      return this.operations;
    },
    isSeeMoreDisabledComputed() {
      return this.isSeeMoreDisabled || !this.operations.metadata.cursor.next;
    },
  },
  mounted() {
    this.$watch(() => this.$refs['operation-detail'].isOpen,
      (newValue) => {
        if (!newValue) {
          this.selectedOperation = {};
        }
      });

    this.getBalances();
    this.getOperations();
    this.getDocumentsPayouts();
  },
  methods: {
    async getOperations() {
      this.isLoading = true;
      try {
        this.operations = await ApiOperations.getOperations({ ...this.queries });
      } catch (error) {
        this.$notification.show({
          title: 'Échec de la récupération des informations',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    async getBalances() {
      try {
        this.balances = await ApiBalances.getBalances();
      } catch (error) {
        throw error;
      }
    },
    async seeMore() {
      this.isLoadingMoreData = true;
      this.queries.cursor = this.operations.metadata.cursor.next;

      const tmpOperations = await ApiOperations.getOperations({ ...this.queries });

      this.operations.data.push(...tmpOperations.data);
      this.operations.metadata = { ...tmpOperations.metadata };

      this.isLoadingMoreData = false;
    },
    async getDocumentsPayouts() {
      try {
        this.documentPayouts = await ApiDocuments.getDocumentsPayouts();
      } catch (error) {
        throw error;
      }
    },
    getDocument() {
      return this.documentPayouts?.find((document) => document.treezorPayoutId === this.selectedOperation.objectId);
    },
  },
};
</script>

<style lang="sass">
// Surchage content
#app.menu-enabled
  >.content
    padding: 0 95px - ($global-padding-horizontal * 2)

.operations
  .no-data
    @include no-data
  .balance, .balance-amount, .balance-previsional
    margin: 0
  .balance, .balance-previsional
    color: var(--color-grey70) !important
  .balance
    @include header5
  .balance-amount
    @include header1
  .balance-previsional
    @include details
  .app-select
    max-width: 234px
    margin: auto ($global-padding-vertical * 2) auto auto
  > .wrapper > .app-button
    margin: ($global-padding-horizontal * 2) auto auto auto
  .export
    margin-bottom: ($global-padding-horizontal * 2)
  .app-button.see-more
    &:disabled
      opacity: 0.5
      .app-spinner
        &.small
          width: 1.3rem
          height: 1.3rem
        span
          border-color: var(--color-blue)
</style>
