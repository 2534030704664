const document = Object.freeze({
  BANK_IDENTIFIERS: {
    label: 'Identifiants bancaires',
    name: 'documents-rib',
  },
  BANK_DETAILS: {
    label: 'Relevés bancaires',
    name: 'documents-bank-statement',
  },
});

export default document;
