<template>
  <div class="card-physical-pin">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Changer code PIN <br> de la carte</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <p>Veuillez renseigner votre nouveau code pin</p>
          <app-input-pin ref="new-pin-app-input-pin" @input="(event) => newPIN = event" :isVisible="true"></app-input-pin>

          <p class="confirm">Veuillez renseigner votre confirmation</p>
          <app-input-pin ref="confirm-pin-app-input-pin" @input="(event) => confirmPIN = event" :isVisible="true"></app-input-pin>

          <app-button class="submit" :disabled="secretCode.length <= 0 || isLoading" type="submit">Valider</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'card-physical-pin',
  props: {
    data: {
      type: Object,
      required: false,
    },
    secretCode: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      newPIN: null,
      confirmPIN: null,

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    newPINComputed: {
      get() { return this.newPIN; },
      set(newValue) { this.newPIN = newValue; },
    },
    confirmPINComputed: {
      get() { return this.newPIN; },
      set(newValue) { this.newPIN = newValue; },
    },
  },
  watch: {
    newPIN() {
      if (this.newPIN.length >= 4) {
        this.$refs['confirm-pin-app-input-pin'].$refs['pin-1'][0].focus();
      }
    },
  },
  updated() {
    if (this.$refs['new-pin-app-input-pin']) {
      this.$nextTick(() => {
        this.$refs['new-pin-app-input-pin'].$refs['pin-1'][0].focus();
      });
    }
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      try {
        await ApiCard.setCardPin(this.data.cardId, this.newPINComputed, this.confirmPINComputed, this.secretCode);
        this.$notification.show({
          title: 'Code pin mis à jour avec succès !',
          type: EnumNotificationType.SUCCESS,
        });

        // Fermeture du volet d'édition du code PIN si réussite
        this.$emit('close-flap');
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }

          this.$notification.show({
            title: 'Echec de la mise à jour',
            text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.card-physical-pin
  .content
    p
      @include header3
    form
      text-align: center
      p.confirm
        margin-top: ($global-padding-horizontal * 2)
    .app-button
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center;
</style>
