<template>
  <div class="card-detail">
    <slot name="title"></slot>

    <app-loader v-if="isLoading || !card"></app-loader>
    <template v-else>
      <div v-if="card" class="grid-x align-middle status" :class="getStatus().name.toLowerCase()">
        <SvgDelete v-if="getStatus().name === 'DISABLED'" />
        <SvgValid v-else />

        <span>{{ getStatus().label }}</span>
      </div>

      <img v-if="activeSubscription" :src="`${baseUrl}/packages/${activeSubscription.packageId}/image`" alt="">
      <app-button v-if="kind === 'physical' && card.isLive === 0"
        class="activate"
        :class="{ 'active': actionRequested === 'activate' }"
        size="large"
        kind="primary"
        :isLoading="isActivateLoading"
        @click="() => { isTheFlapOpenActivate = true; actionRequested = 'activate' }"
      >
        Activer ma carte <SvgLockOpen />
      </app-button>

      <template v-if="card.isLive === 1">
        <div class="grid-x actions">
          <div class="see cell auto" :class="{ 'active': actionRequested === 'see' }" @click="() => { isTheFlapOpenSecretCode = true; actionRequested = 'see' }">
            <div class="icon"><SvgSee /></div>
            <p>Voir</p>
          </div>
          <div class="lock cell auto" :class="{ 'active': actionRequested === 'lock' || actionRequested === 'unlock' }">
            <div class="icon" @click="() => { actionRequested = EnumLockStatus[this.card.statusCode] === EnumLockStatus.LOCK ? 'unlock' : 'lock'; manage(); }">
              <SvgLock v-if="EnumLockStatus[this.card.statusCode] === EnumLockStatus.UNLOCK" />
              <SvgLockOpen v-else />
            </div>
            <p>{{ EnumLockStatus[this.card.statusCode] === EnumLockStatus.LOCK ? 'Débloquer' : 'Bloquer' }}</p>
          </div>
          <div v-if="kind === 'virtual'" :class="{ 'active': actionRequested === 'delete' }" class="delete cell auto" @click="() => { actionRequested = 'delete'; manage() }">
            <div class="icon">
              <template v-if="actionRequested !== 'delete'">
                <SvgTrash />
              </template>
              <template v-else>
                <app-spinner :small="true" :color="'--color-cloud'"/>
              </template>
            </div>
            <p>Supprimer</p>
          </div>
          <div v-else class="set-pin cell auto" @click="() => { isTheFlapOpenSecretCode = true; actionRequested = 'set-pin' }">
            <div class="icon"><SvgPin /></div>
            <p>Changer PIN</p>
          </div>
          <div v-show="customer && customer.customer.type === EnumCustomerType.BUSINESS && (card.physical !== 0 || card.virtualConverted !== 0)" class="see cell auto" :class="{ 'active': actionRequested === 'edit-name' }" @click="() => { isTheFlapOpenSecretCode = true; actionRequested = 'edit-name' }">
            <div class="icon"><SvgEdit /></div>
            <p>Renommer</p>
          </div>
        </div>

        <div class="state" v-if="packageComputed">
          <p>État carte</p>
          <app-slider type="progress" :value="card.totalPaymentMonth" :max="card.limitPaymentMonth">
            <p>Paiement avec la carte</p>
            <p>{{ card.totalPaymentMonth || 0 }}€ / {{ card.limitPaymentMonth }}€</p>
          </app-slider>
          <app-slider v-if="kind === 'physical'" type="progress" :value="card.totalAtmMonth" :max="card.limitAtmMonth">
            <p>Retrait avec la carte</p>
            <p>{{ card.totalAtmMonth || 0 }}€ / {{ card.limitAtmMonth }}€</p>
          </app-slider>
        </div>

        <div class="options" >
          <app-button v-show="kind === 'physical'" class="card-transactions" @click="() => { isTheFlapOpenCardTransactions = true; actionRequested = 'card-transactions' }" :class="{ 'active': actionRequested === 'card-transactions' }">
            <div class="icon">
              <SvgCard />
            </div>
            <p class="cell auto">Historique des transactions</p>
            <SvgArrowRight class="arrow-right" />
          </app-button>
          <app-button v-if="kind === 'physical'" class="settings" @click="() => { isTheFlapOpenSecretCode = true; actionRequested = 'settings' }" :class="{ 'active': actionRequested === 'settings' }">
            <div class="icon">
              <SvgSettings />
            </div>
            <p class="cell auto">Gérer les options</p>
            <SvgArrowRight class="arrow-right" />
          </app-button>
          <app-button class="limits" @click="() => { isTheFlapOpenSecretCode = true; actionRequested = 'limits' }" :class="[kind, { 'active': actionRequested === 'limits' }]" :data="{ ...card, package: packageComputed }">
            <div class="icon">
              <SvgLimit />
            </div>
            <p class="cell auto">Gérer les plafonds</p>
            <SvgArrowRight class="arrow-right" />
          </app-button>
          <app-button v-if="kind === 'physical'" class="cancel" @click="() => { actionRequested = 'cancel'; manage(); }" :class="{ 'active': actionRequested === 'cancel' }">
            <div class="icon">
              <SvgBlock />
            </div>
            <p class="cell auto">Faire opposition</p>
            <SvgArrowRight class="arrow-right" />
          </app-button>
        </div>

      </template>
    </template>

    <SecretCode ref="detail-secretcode"
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => { isTheFlapOpenSecretCode = value; (!value) ? resetAction() : null }"
      @click="manage"
      :isLoading="isSecretCodeLoading"
    />
    <Show v-if="see" ref="detail-see"
      :data="see"
      :isOpen="isTheFlapOpenSee"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => { isTheFlapOpenSee = value; (!value) ? resetAction() : null }"
      @click="() => { resetAction(); }"
    />
    <EditLimits ref="detail-limits"
      :kind="kind"
      action="edit"
      :data="limitsComputed"
      :offer="packageComputed"
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenLimits"
      @is-open="(value) => { isTheFlapOpenLimits = value; (!value) ? resetAction() : null }"
      @onChange="(newData) => limits = newData"
      @click="() => { isTheFlapOpenLimits = false; $emit('updated:card', limitsComputed); }"
    />
    <Cancel ref="detail-cancel"
      :data="cardComputed"
      :isOpen="isTheFlapOpenCancel"
      @is-open="(value) => { isTheFlapOpenCancel = value; (!value) ? resetAction() : null }"
      @click="$emit('destroy:card')"
    />
    <EditSettings ref="detail-settings"
      :data="cardComputed"
      :secretCode="secretCodeComputed"
      :isOpen="isTheFlapOpenSettings"
      @is-open="(value) => { isTheFlapOpenSettings = value; (!value) ? resetAction() : null }"
      @updated:card="(newValue) => $emit('updated:card', newValue)"
    />
    <EditPin ref="detail-pin"
      :data="cardComputed"
      :secretCode="secretCodeComputed"
      :isOpen="isTheFlapOpenPin"
      @is-open="(value) => { isTheFlapOpenPin = value; (!value) ? resetAction() : null }"
      @close-flap="() => isTheFlapOpenPin = false"
    />
    <Activation ref="detail-activation"
      :data="cardComputed"
      :isOpen="isTheFlapOpenActivate"
      @is-open="(value) => { isTheFlapOpenActivate = value; (!value) ? resetAction() : null }"
      @activated="cardActivated"
    />
    <EditName
      :cardId="card.cardId"
      :currentName="card.kalveenCustomName"
      :secretCode="secretCodeComputed"
      :isOpen="isTheFlapOpenEditName"
      @is-open="(value) => { isTheFlapOpenEditName = value; (!value) ? resetAction() : null }"
      @edited="cardNameEdited"
    />
    <Transactions
      :cardId="card.cardId"
      :isOpen="isTheFlapOpenCardTransactions"
      @is-open="(value) => { isTheFlapOpenCardTransactions = value; (!value) ? resetAction() : null }"
    />
  </div>
</template>

<script>
// Api
import ApiSubscription from '../../../services/api/subscription';
import ApiCard from '../../../services/api/card';

// Svg
import SvgLockOpen from '../../../assets/img/icons/lock-open.svg?inline';
import SvgDelete from '../../../assets/img/icons/delete.svg?inline';
import SvgValid from '../../../assets/img/icons/valid.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';
import SvgSee from '../../../assets/img/icons/see.svg?inline';
import SvgTrash from '../../../assets/img/icons/trash.svg?inline';
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';
import SvgLimit from '../../../assets/img/icons/limit.svg?inline';
import SvgBlock from '../../../assets/img/icons/block.svg?inline';
import SvgSettings from '../../../assets/img/icons/settings.svg?inline';
import SvgPin from '../../../assets/img/icons/pin.svg?inline';
import SvgEdit from '../../../assets/img/icons/edit.svg?inline';
import SvgCard from '../../../assets/img/icons/card.svg?inline';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumLockStatus from '../../../services/enums/cards/lockStatus';
import EnumNotificationType from '../../../services/enums/notification.enum';

// Component
import SecretCode from '../../Common/AppSecretCode.vue';
import Show from './Show.vue';
import EditLimits from './EditLimits.vue';
import Cancel from '../Physical/Cancel.vue';
import EditSettings from '../Physical/EditSettings.vue';
import EditPin from '../Physical/EditPin.vue';
import Activation from '../Physical/Activation.vue';
import AppSpinner from '../../../basics/components/AppSpinner.vue';
import EditName from '../Physical/EditName.vue';
import Transactions from '../Physical/Transactions.vue';

export default {
  name: 'card-detail',
  inject: ['$getCustomer', '$getPackage'],
  components: {
    SvgLockOpen,
    SvgDelete,
    SvgValid,
    SvgLock,
    SvgSee,
    SvgTrash,
    SvgArrowRight,
    SvgLimit,
    SvgBlock,
    SvgSettings,
    SvgPin,
    SvgEdit,
    SvgCard,

    SecretCode,
    Show,
    EditLimits,
    Cancel,
    EditSettings,
    EditPin,
    Activation,
    AppSpinner,
    EditName,
    Transactions,
  },
  props: {
    kind: {
      validator: (val) => ['physical', 'virtual'].indexOf(val) !== -1,
      required: true,
    },
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      activeSubscription: null,
      secretCode: [],
      limits: null,
      actionRequested: null, // see, lock, unlock, activate, delete, set-pin, cancel, settings, limits, edit-name

      see: null,

      isTheFlapOpenSecretCode: false,
      isTheFlapOpenSee: false,
      isTheFlapOpenLimits: false,
      isTheFlapOpenCancel: false,
      isTheFlapOpenSettings: false,
      isTheFlapOpenPin: false,
      isTheFlapOpenActivate: false,
      isTheFlapOpenEditName: false,
      isTheFlapOpenCardTransactions: false,

      isLoading: false,
      isSecretCodeLoading: false,
      isActivateLoading: false,

      EnumLockStatus,
      EnumCustomerType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    packageComputed() {
      return this.$getPackage();
    },
    secretCodeComputed: {
      get() { return this.secretCode; },
      set(newValue) { this.secretCode = newValue; },
    },
    limitsComputed: {
      get() { return { ...this.card, ...this.limits }; },
      set(newValues) { this.limits = newValues; },
    },
    cardComputed() {
      return { ...this.card };
    },
  },
  watch: {
    isTheFlapOpenSecretCode(newValue) {
      if (newValue) this.toogleisFlapOpen(true, false, false, false, false, false, false, false);
    },
    isTheFlapOpenSee(newValue) {
      if (newValue) this.toogleisFlapOpen(false, true, false, false, false, false, false, false);
    },
    isTheFlapOpenLimits(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, true, false, false, false, false, false);
    },
    isTheFlapOpenCancel(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, true, false, false, false, false);
    },
    isTheFlapOpenSettings(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, false, true, false, false, false);
    },
    isTheFlapOpenPin(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, false, false, true, false, false);
    },
    isTheFlapOpenActivate(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, false, false, false, true, false);
    },
    isTheFlapOpenCardTransactions(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, false, false, false, false, true);
    },
  },
  mounted() {
    this.getSubscription();
  },
  beforeDestroy() {
    this.$emit('destroy:card');
  },
  methods: {
    reset(value) {
      if (value) {
        this.isTheFlapOpenPassword = false;
      } else {
        this.secretCode = [];
        this.actionRequested = null;
      }
    },
    resetAction() {
      this.see = null;
      this.limits = null;
      this.secretCode = [];
      this.actionRequested = null;
    },
    async getSubscription() {
      this.isLoading = true;
      try {
        const tmpSubscription = await ApiSubscription.getSubscription();
        // eslint-disable-next-line
        this.activeSubscription = tmpSubscription[0]; // [0] récupère le premier abonnement qui est celui actif
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    getStatus() {
      let status;
      if (Object.keys(this.card).length <= 0 || this.card.isLive === 0) {
        status = {
          name: 'DISABLED',
          label: 'Désactivée',
        };
      } else if (this.card.isLive === 1 && (EnumLockStatus[this.card.statusCode].name === EnumLockStatus.UNLOCK.name)) {
        status = {
          name: 'ACTIVE',
          label: 'Active',
        };
      } else {
        status = EnumLockStatus[this.card.statusCode];
      }
      return status;
    },
    async manage() {
      this.isSecretCodeLoading = true;
      try {
        if (this.actionRequested === 'see') {
          this.see = await ApiCard.getCardImage(this.card.cardId, this.secretCode);
          this.isTheFlapOpenSee = true;
        } else if (this.actionRequested === 'lock' || this.actionRequested === 'unlock' || this.actionRequested === 'delete') {
          let tmpCard;
          if (this.actionRequested === 'lock') {
            tmpCard = await ApiCard.setCardLockUnlock(this.card.cardId, EnumLockStatus.LOCK.key);
            this.$emit('updated:card', tmpCard.data);
          } else if (this.actionRequested === 'unlock') {
            tmpCard = await ApiCard.setCardLockUnlock(this.card.cardId, EnumLockStatus.UNLOCK.key);
            this.$emit('updated:card', tmpCard.data);
          } else {
            tmpCard = await ApiCard.setCardLockUnlock(this.card.cardId, EnumLockStatus.STOLEN.key);
            this.$emit('destroy:card');
          }
          this.resetAction();
        } else if (this.actionRequested === 'cancel') {
          this.isTheFlapOpenCancel = true;
        } else if (this.actionRequested === 'settings') {
          this.isTheFlapOpenSettings = true;
        } else if (this.actionRequested === 'limits') {
          this.isTheFlapOpenLimits = true;
        } else if (this.actionRequested === 'set-pin') {
          this.isTheFlapOpenPin = true;
        } else if (this.actionRequested === 'activate') {
          this.isTheFlapOpenActivate = true;
        } else if (this.actionRequested === 'edit-name') {
          this.isTheFlapOpenEditName = true;
        }
      } catch (error) {
        let text = 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.';
        let title;

        if (`${error?.response?.data?.statusCode}` === '403') {
          title = 'Code erroné';
          text = 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.';
        } else if (this.actionRequested === 'see') {
          title = 'L\'obtention des informations de la carte a échoué.';
        } else if (this.actionRequested === 'lock' || this.actionRequested === 'unlock') {
          title = 'Le blocage/déblocage de votre carte a échoué.';
        } else if (this.actionRequested === 'delete') {
          title = 'La désactivation de votre carte a échouée.';
        }
        this.$notification.show({
          title,
          text,
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isSecretCodeLoading = false;
      this.isTheFlapOpenSecretCode = false;
    },
    toogleisFlapOpen(isTheFlapOpenSecretCode, isTheFlapOpenSee, isTheFlapOpenLimits, isTheFlapOpenCancel, isTheFlapOpenSettings, isTheFlapOpenPin, isTheFlapOpenActivate, isTheFlapOpenCardTransactions) {
      // Fermeture du volet du parent si ouvert
      if (this.$parent.$data.isTheFlapOpenLimits) this.$parent.$data.isTheFlapOpenLimits = false;

      this.isTheFlapOpenSecretCode = isTheFlapOpenSecretCode;
      this.isTheFlapOpenLimits = isTheFlapOpenLimits;
      this.isTheFlapOpenSee = isTheFlapOpenSee;
      this.isTheFlapOpenCancel = isTheFlapOpenCancel;
      this.isTheFlapOpenSettings = isTheFlapOpenSettings;
      this.isTheFlapOpenPin = isTheFlapOpenPin;
      this.isTheFlapOpenActivate = isTheFlapOpenActivate;
      this.isTheFlapOpenCardTransactions = isTheFlapOpenCardTransactions;
    },
    cardActivated() {
      // On ferme le volet d'activation
      this.isTheFlapOpenActivate = false;

      // La carte activée est-elle virtuelle ?
      const isCardVirtual = `${this.card.physical}` === '0' && `${this.card.virtualConverted}` === '0';

      if (isCardVirtual) {
        // Redirection vers la liste des cartes virtuelles
        this.$router.replace({ name: 'cards-card-virtual' }).catch(() => {});
      } else {
        // Redirection vers la liste des cartes physiques
        this.$router.replace({ name: 'cards-card-physical' }).catch(() => {});
      }
    },
    cardNameEdited() {
      // On ferme le volet d'édition du nom
      this.isTheFlapOpenEditName = false;

      // Redirection vers la liste des cartes physiques
      this.$router.replace({ name: 'cards-card-physical' }).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
.card-detail
  max-width: 323px
  p
    margin: 0
  .title
    @include header2
  .status
    svg
      width: 22px
    &.disabled span
      color: var(--color-warning)
    &.active
      span
        color: var(--color-success)
      svg path, svg circle
        stroke: var(--color-success)
    span
      @include header4
      margin-left: $global-padding-horizontal / 2

  img
    margin-top: $global-padding-vertical * 3.3
    width: 100%
    border-radius: $global-border-radius * 2

  .actions
    margin-top: $global-padding-vertical * 3
    @include header5
    .icon
      display: flex
      margin: auto auto 8px auto
      width: 48px
      height: 48px
      align-items: center
      justify-content: center
      border-radius: 100%
      background-color: var(--color-black)
      cursor: pointer
      &:hover
        background-color: var(--color-70)
    .see svg
      max-width: 30px
      width: 30px
    .delete svg, .set-pin svg
      max-width: 23px
      width: 23px
    .lock
      svg
        path:first-child
          fill: transparent
          stroke: var(--color-cloud)
        path:last-child
          fill: var(--color-cloud)
          stroke: transparent
    .see, .delete, .set-pin
      svg
        path, circle
          fill: var(--color-cloud)
          stroke: transparent
    .see, .delete, .lock, .set-pin
      text-align: center
      &.active
        .icon
          background-color: var(--color-70)
  .state
    @include tile
    margin-top: $global-padding-vertical * 3
    padding: $global-padding-vertical * 2
    &:hover
      background-color: var(--color-cloud)
    > p
      @include header4
      margin-bottom: $global-padding-vertical * 2
    .app-slider
      p:first-child
        @include header5
      p:last-child
        @include details
      ~ .app-slider
        margin-top: $global-padding-vertical * 2

  .options
    margin-top: $global-padding-horizontal
    .app-button
      @include tile
      text-align: left
    .settings
      border-radius: 0
    .limits
      svg
        width: 30px
        path
          stroke: var(--color-grey70)
      &.physical
        border-radius: 0
    .cancel
      color: var(--color-warning)
      .icon
        background-color: #FAE2DC
      svg
        path, circle
          stroke: var(--color-warning)
      border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
    .card-transactions
      border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0

  .app-button.activate
    margin: ($global-padding-vertical * 3) auto auto auto
    svg
      path
        fill: var(--color-cloud)
        stroke: transparent
</style>
