<template>
  <div class="proofs-add" v-if="customer">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>{{ datas.isLiveness ? 'Vérification identité' : 'Ajouter un document' }}</p>
        <p>{{ subtitleComputed }}</p>
      </template>
      <template v-slot:app-flap-content>
        <template v-if="datas.isLiveness">
          <p>Un lien va vous être envoyé par SMS, votre numéro actuel est le {{ datas.phone }} <br><br> Une pièce d’identité ainsi qu’un scan de votre visage vous seront demandés.</p>

          <app-loader v-if="isLoading"></app-loader>
          <app-button class="submit" @click="send">Envoyer</app-button>
        </template>
        <template v-else>
          <p>Si nous avons demandé des documents supplémentaires suite à notre analyse ou que vous souhaitez nous fournir des documents complémentaires.</p>

          <app-loader v-if="isLoading"></app-loader>
          <form v-else @submit.prevent="sendDocument">
            <app-upload v-model="selectedDocument" :progress="appProgressUpload" accept="image/*,application/pdf"/>

            <app-select
              v-model="selectedTypeComputed"
              label="Type de document *"
              :options="documentTypeOptionsComputed"
              :isDisabled="(datas.selectedType !== 'OTHER' && datas.isDisabled)"
            >
            </app-select>

            <app-select
              v-model="selectedBeneficiaryComputed"
              label="Personne concernée *"
              :options="beneficiariesComputed"
              :isDisabled="datas.isDisabled"
            >
            </app-select>

            <app-button
              :disabled="!selectedDocument"
              size="large"
              kind="primary"
              type="submit">
              Ajouter un document
            </app-button>
          </form>
        </template>
      </template>
    </app-flap>
  </div>
</template>

<script>
import AppUpload from '../../../basics/components/AppUpload.vue';

// Enums
import EnumDocumentType from '../../../services/enums/documents/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCustomerParentType from '../../../services/enums/customer/parentType.enum';

// Api
import ApiCustomer from '../../../services/api/customer';
import ApiDocument from '../../../services/api/document';

export default {
  name: 'proofs-add',
  inject: ['$getCustomer'],
  components: {
    AppUpload,
  },
  props: {
    datas: {
      type: Object,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedType: null,
      selectedBeneficiary: null,
      selectedDocument: null,
      appProgressUpload: 0,

      EnumDocumentType,

      isLoading: false,
    };
  },
  watch: {
    selectedBeneficiaryComputed() {
      this.selectedType = null;
    },
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    documentTypeOptionsComputed() {
      return this.documentFiltered();
    },
    selectedTypeComputed: {
      get() { return this.selectedType || this.datas.selectedType; },
      set(newValue) { this.selectedType = newValue; },
    },
    selectedBeneficiaryComputed: {
      get() { return this.selectedBeneficiary || this.datas.selectedBeneficiary; },
      set(newValue) { this.selectedBeneficiary = newValue; },
    },
    beneficiariesComputed() {
      return this.datas.beneficiaries;
    },
    subtitleComputed() {
      return this.selectedTypeComputed === 'OTHER' ? 'Autre' : Object.values(EnumDocumentType).find((type) => type.name === this.selectedTypeComputed).label;
    },
  },
  mounted() {
  },
  methods: {
    async send() {
      this.isLoading = true;
      try {
        await ApiCustomer.askKycLivenessValidation(this.selectedBeneficiaryComputed);
        this.$notification.show({
          title: 'SMS envoyé !',
          type: EnumNotificationType.SUCCESS,
        });
        this.$getCustomer(true);
      } catch (error) {
        this.$notification.show({
          title: 'L\'envoi du SMS a échoué.',
          text: 'Nous vous invitons à contacter le support client.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    async sendDocument() {
      this.isLoading = true;
      try {
        const tmpDocumentType = Object.keys(EnumDocumentType).find((key) => EnumDocumentType[key].name === this.selectedTypeComputed);
        let tmpCustomerId = this.selectedBeneficiaryComputed;
        // Si c'est un particulier ou une entreprise pas besoin de passer de tmpCustomerId
        if (!this.datas.parentType) tmpCustomerId = null;

        await ApiDocument.createDocument(tmpCustomerId, tmpDocumentType, this.selectedDocument[0]);
        this.$getCustomer(true);
        this.$notification.show({
          title: 'Document ajouté avec succès.',
          type: EnumNotificationType.SUCCESS,
        });

        this.selectedDocument = null;
      } catch (error) {
        this.$notification.show({
          title: 'L\'ajout du document a échoué.',
          text: 'Nous vous invitons à contacter le support client.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    documentFiltered() {
      /**
       *  Si le document fait partie de la catégorie "Autres documents"
       *  et que la personne ciblée est un responsable d'entreprise ou un particulier
       *  alors les documents d'identités sont supprimés
       */
      const hasRemove = (this.selectedTypeComputed === 'OTHER' && (this.datas.parentType === EnumCustomerParentType.LEADER.name || (!this.datas.parentType && this.datas.selectedBeneficiary === this.customer.customer.customerId)));
      const documentFiltered = Object.keys(EnumDocumentType).map((key) => EnumDocumentType[key]);

      if (hasRemove) {
        documentFiltered.splice(documentFiltered.findIndex((doc) => doc.name === EnumDocumentType.DOCUMENT_TYPE_9.name), 1);
        documentFiltered.splice(documentFiltered.findIndex((doc) => doc.name === EnumDocumentType.DOCUMENT_TYPE_15.name), 1);
        documentFiltered.splice(documentFiltered.findIndex((doc) => doc.name === EnumDocumentType.DOCUMENT_TYPE_16.name), 1);
        documentFiltered.splice(documentFiltered.findIndex((doc) => doc.name === EnumDocumentType.DOCUMENT_TYPE_17.name), 1);
      }

      return documentFiltered;
    },
  },
};
</script>

<style lang="sass">
.proofs-add
  .app-flap
    overflow-y: scroll
    &::-webkit-scrollbar
      width: 0 !important
    .main
      p:first-child
        margin-bottom: 0
        @include header2
      p:last-child
        @include header4
        margin-top: 0
        color: var(--color-100)
    .content form
      .app-select
        margin-top: $global-padding-horizontal * 2
        .label
          line-height: initial
          background-color: var(--color-cloud)

      .app-button
        width: 100%
        justify-content: center
</style>
