<template>
  <div class="payouts-list">
    <app-loader v-if="isLoading"></app-loader>

    <p class="no-data" v-else-if="payoutsWithBeneficiaries && payoutsWithBeneficiaries.length <= 0">
      <SvgEmptyPayoutsIndividual v-if="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase()"/>
      <SvgEmptyPayoutsBusiness v-else-if="$theme === EnumCustomerType.BUSINESS.toLowerCase()"/>
      <span>Aucun virement sur votre compte</span>
    </p>

    <div v-else-if="payoutsWithBeneficiaries && payoutsWithBeneficiaries.length > 0">
      <div
        class="record grid-x grid-margin-x"
        :class="{
          'active': (selectedPayout && selectedPayout.payout && selectedPayout.payout.payoutId) === payoutWithBeneficiary.payout.payoutId,
        }"
        v-for="(payoutWithBeneficiary) in payoutsWithBeneficiaries"
        :key="payoutWithBeneficiary.payout.payoutId"
        @click="$emit('click', payoutWithBeneficiary)"
      >
        <div class="cell medium-6">
          <p class="beneficiary-name">{{ payoutWithBeneficiary.beneficiary.name.toUpperCase() }}</p>
          <p class="payout-label">{{ payoutWithBeneficiary.payout.label.toUpperCase() }}</p>
        </div>
        <div class="cell medium-2 created-date">{{ $dayjs(payoutWithBeneficiary.payout.createdDate).format('DD/MM/YYYY') }}</div>
        <div class="cell medium-2 payout-status" :class="`payout-status-${payoutWithBeneficiary.payout.payoutStatus.toLowerCase()}`">{{ EnumPayoutStatus[payoutWithBeneficiary.payout.payoutStatus] }}</div>
        <div class="cell auto payout-amount">
          {{ +payoutWithBeneficiary.payout.amount | formatPrice }}
          <SvgArrowRight class="arrow-right" />
        </div>
      </div>

      <app-button class="see-more" :isDisabled="isSeeMoreDisabledComputed" :isLoading="isLoadingMoreData" @click="seeMore" size="small" kind="secondary">
        Voir plus <SvgArrowDown />
      </app-button>
    </div>
  </div>
</template>

<script>
// Api
import ApiPayout from '../../services/api/payout';

// Svg
import SvgArrowRight from '../../assets/img/icons/arrow-right.svg?inline';
import SvgEmptyPayoutsIndividual from '../../assets/img/individual/no-payouts.svg?inline';
import SvgEmptyPayoutsBusiness from '../../assets/img/business/no-payouts.svg?inline';
import SvgArrowDown from '../../assets/img/icons/down.svg?inline';

// Enums
import EnumPayoutStatus from '../../services/enums/payouts/payout-status.enum';
import EnumCustomerType from '../../services/enums/customer/type.enum';

export default {
  name: 'payouts-list',
  components: {
    SvgArrowRight,
    SvgEmptyPayoutsIndividual,
    SvgEmptyPayoutsBusiness,
    SvgArrowDown,
  },
  props: {
    selectedPayout: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      payoutsWithBeneficiaries: null,

      isLoading: false,
      isLoadingMoreData: false,

      queries: {
        pageCount: 5,
        pageNumber: 1,
        count: 0,
      },
      EnumPayoutStatus,
      EnumCustomerType,
    };
  },
  computed: {
    isSeeMoreDisabledComputed() {
      return this.payoutsWithBeneficiaries?.length >= this.queries.count;
    },
  },
  mounted() {
    this.debouncedUpdateQuery = this.$utils.debounce(this.getPayouts, 400);

    this.getPayouts();
  },
  methods: {
    async getPayouts() {
      this.isLoading = true;
      try {
        const tmpPayouts = await ApiPayout.getPayouts({ pageNumber: this.queries.pageNumber, pageCount: this.queries.pageCount });

        this.payoutsWithBeneficiaries = tmpPayouts.data;
        this.queries.pageNumber = tmpPayouts.metadata.offset;
        this.queries.pageCount = tmpPayouts.metadata.limit;
        this.queries.count = tmpPayouts.metadata.count;
      } catch (error) {
        this.payoutsWithBeneficiaries = [];
      }
      this.isLoading = false;
    },
    async seeMore() {
      this.isLoadingMoreData = true;
      this.queries.pageNumber += 1;

      const tmpPayouts = await ApiPayout.getPayouts({ pageNumber: this.queries.pageNumber, pageCount: this.queries.pageCount });

      this.payoutsWithBeneficiaries.push(...tmpPayouts.data);
      this.queries.pageNumber = tmpPayouts.metadata.offset;
      this.queries.pageCount = tmpPayouts.metadata.limit;
      this.queries.count = tmpPayouts.metadata.count;

      this.isLoadingMoreData = false;
    },
  },
};
</script>

<style lang="sass">
.payouts-list
  .no-data
    @include no-data
    span
      margin: 0
  .record
    @include clickable-row
    align-items: center
    border-bottom-width: 1px
    border-color: var(--color-grey70)
    .beneficiary-name
      @include header5
    .payout-label
      @include details
      color: var(--color-grey70)
    .created-date
      @include text
    .payout-status
      @include header5
    .payout-status-pending
      color: var(--color-100)
    .payout-status-canceled
      color: var(--color-warning)
    .payout-status-validated
      color: var(--color-success)
    .payout-amount
      @include header4
      display: flex
      align-items: center
      justify-content: right
      .arrow-right
        margin-left: 16px
  .see-more
    @include see-more
</style>
