import SvgHourglass from '../../assets/img/icons/hourglass.svg?inline';
import SvgSearch from '../../assets/img/icons/search.svg?inline';
import SvgValid from '../../assets/img/icons/valid.svg?inline';

const limits = Object.freeze({
  PENDING: {
    label: 'Attente des documents',
    icon: SvgHourglass,
    name: 'pending',
  },
  ANALYSIS: {
    label: 'Analyse des documents',
    icon: SvgSearch,
    name: 'analysis',
  },
  VALIDATED: {
    label: 'Compte Validé !',
    icon: SvgValid,
    name: 'validated',
  },
});

export default limits;
