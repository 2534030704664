import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 *  Obtient une liste d'opérations
 *
 * @param {Date} dateFrom required
 * @param {Date} dateTo required
 * @param {OperationType} operationType required
 * @param {operationStatus} status
 * @param {string} cursor
 * @param {number} limit
 * @returns
 */
const getOperations = async ({
  dateFrom, dateTo, operationType, status, cursor, limit,
}) => {
  try {
    const response = await axios.get(`${API_URL}/operations`, {
      params: {
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        ...(operationType && { operationType }),
        status,
        ...(cursor && { cursor }),
        ...(limit && { limit }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getOperations = getOperations;

export default api;
