<template>
  <div class="register-company-contact">
    <p>Adresse de l'entreprise</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x align-middle">
        <app-input
          class="cell medium-6 street-number"
          label="Numéro *"
          type="text"
          min="0"
          v-model="streetNumberComputed"
        ></app-input>

        <app-input
          class="cell small-12 route"
          label="Adresse *"
          type="text"
          v-model="routeComputed"
        ></app-input>

        <app-input
          class="cell auto postal-code"
          label="Code postal *"
          type="text"
          v-model="postalCodeComputed"
        ></app-input>

        <app-input
          class="cell auto locality"
          label="Ville *"
          type="text"
          v-model="localityComputed"
        ></app-input>

        <app-select
          class="cell small-12 country"
          v-model="countryComputed"
          label="Pays *"
          required
          :options="EnumCountries">
        </app-select>

        <app-input
          class="cell small-12 phone"
          label="Numéro de téléphone *"
          type="phone"
          v-model="phoneComputed"
        ></app-input>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-company-contact-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!phoneComputed || !streetNumberComputed || !routeComputed || !postalCodeComputed || !localityComputed || !countryComputed" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';

// Components

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCountries from '../../../../../commons/countries.json';

export default {
  name: 'register-company-contact',
  components: {
    SvgWarning,
    SvgArrow,
  },
  inject: ['$getCustomer'],
  data() {
    return {
      phone: null,
      streetNumber: 0,
      route: null,
      postalCode: null,
      locality: null,
      country: null,

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumNotificationType,
      EnumCountries: this.$utils.sortAlphabetically(EnumCountries),
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    phoneComputed: {
      get() { return this.phone || this.customer?.customer?.phone?.replace('+33', ''); },
      set(newValue) { this.phone = newValue; },
    },
    streetNumberComputed: {
      get() { return this.streetNumber || this.customer.customer.address?.streetNumber; },
      set(newValue) { this.streetNumber = newValue; },
    },
    routeComputed: {
      get() { return this.route || this.customer.customer.address?.route; },
      set(newValue) { this.route = newValue; },
    },
    postalCodeComputed: {
      get() { return this.postalCode || this.customer.customer.address?.postalCode; },
      set(newValue) { this.postalCode = newValue; },
    },
    localityComputed: {
      get() { return this.locality || this.customer.customer.address?.locality; },
      set(newValue) { this.locality = newValue; },
    },
    countryComputed: {
      get() { return this.country || this.customer.customer.address?.country; },
      set(newValue) { this.country = newValue; },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        phone: `+33${this.phoneComputed}`,
        address: {
          streetNumber: String(this.streetNumberComputed),
          route: this.routeComputed,
          postalCode: this.postalCodeComputed,
          locality: this.localityComputed,
          country: this.countryComputed,
          administrativeAreaLevel1: this.countryComputed,
        },
      };

      try {
        await ApiCustomer.setCustomer(tmpUser);
        this.$getCustomer(true);

        // Mise à jour de l'injection de dépendance customer
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'phone must match /^\\+[1-9]\\d{10,14}$/ regular expression':
          newErrorMessage = 'Le numéro de téléphone n\'est pas valide. Ex: +33 0222222222';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-company-contact
  text-align: center
  > p
    @include header1
    margin-bottom: $global-padding-horizontal * 3.5
  .app-input
    margin: 0

  form
    .street-number
      margin: auto
    .route
      margin-bottom: $global-padding-vertical * 3
    .route, .phone, .country
      margin-top: $global-padding-vertical * 3
    .postal-code
      margin-right: $global-padding-horizontal
    .locality
      margin-left: $global-padding-horizontal

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
