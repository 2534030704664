import axios from 'axios';
import ls from 'local-storage';

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const API_URL = process.env.VUE_APP_API_URL;
let TOKEN = ls('TOKEN') || false;
const redirectRouteName = 'login';

function messageHandler(event) {
  if (typeof event.data !== 'object' || event.data.type === 'webpackOk') {
    return;
  }
  const { action, value } = event.data;
  if (action === 'token') {
    TOKEN = value;
    ls('TOKEN', value);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;
  } else if (action === 'erase') {
    ls.remove('TOKEN');
  } else if (action === 'reload') {
    window.location.reload();
  }
}

window.addEventListener('message', messageHandler, false);

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    ls.remove('TOKEN');
    if (router.default.history.current.name !== redirectRouteName) router.default.push({ name: 'login' });
  }
  return Promise.reject(error);
});
/**
 * Récupération du token
 *
 * @returns { token, customerId }
 */
const getToken = () => ls('TOKEN');

/**
 * L'utilisateur est-il loggué
 *
 * @returns true or false
 */
const isLoggedIn = () => !!getToken();

/**
 * 1ère étape du login
 *
 * @param {string} email
 * @param {string} password
 * @returns
 */
const loginIdentifiers = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/login-check-identifiers`, {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * 2ème étape du login
 * C'est ici que sera sauvegardé le token
 *
 * @param {string} token
 * @param {string} secretCode
 * @returns
 */
const loginSecretCode = async (token, secretCode) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/login-check-secretcode?token=${token}`, {
      secretCode: secretCode.toString().replaceAll(',', ''),
    });

    TOKEN = response.data;
    ls('TOKEN', response.data);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

/**
 * Déconnexion
 */
const logout = () => {
  ls.remove('TOKEN');
  ls.remove('STATUS');
  delete axios.defaults.headers.common.Authorization;
  router.default.push({ name: 'login' });
};

// Authorization Header
const getAuthorizationHeader = () => axios.defaults.headers.common.Authorization;

/**
 * Demande de réinitialisation de mot de passe
 *
 * @param {string} email
 * @returns
 */
const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/reset-password`, { email });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie le mot de passe
 *
 * @param {string} token
 * @param {string} password
 * @param {string} passwordConfirm
 * @returns
 */
const resetPassword = async (token, password, passwordConfirm) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/reset-password/new?token=${token}`, { password, passwordConfirm });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande l'envoi d'un email contenant une chaîne de 6 caractères aléatoires
 * et la génération d'un token d'une validité de 5 minutes
 *
 * @returns token
 */
const requestDoubleAuthentication = async () => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/request-double-authentication`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande une inscription
 *
 * @param {EnumCustomerType} type
 * @param {string} packageId
 * @param {string} email
 * @returns
 */
const register = async (type, packageId, email) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/register`, {
      ...(type && { type }),
      ...(packageId && { packageId }),
      ...(email && { email }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande d'initialisation de mot de passe
 *
 * @param {string} token
 * @param {string} password
 * @param {string} passwordConfirm
 * @param {string} secretCode
 * @param {string} secretCodeConfirm
 * @returns
 */
const registerPassword = async (token, password, passwordConfirm, secretCode, secretCodeConfirm) => {
  try {
    const response = await axios.post(`${API_URL}/customers/auth/register-password?token=${token}`, {
      password,
      passwordConfirm,
      secretCode: secretCode.toString().replaceAll(',', ''),
      secretCodeConfirm: secretCodeConfirm.toString().replaceAll(',', ''),
    });

    TOKEN = response.data;
    ls('TOKEN', response.data);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;

    return response.data;
  } catch (error) {
    throw error;
  }
};

const auth = {};
auth.loginIdentifiers = loginIdentifiers;
auth.loginSecretCode = loginSecretCode;
auth.isLoggedIn = isLoggedIn;
auth.logout = logout;
auth.getToken = getToken;
auth.getAuthorizationHeader = getAuthorizationHeader;
auth.forgotPassword = forgotPassword;
auth.resetPassword = resetPassword;
auth.requestDoubleAuthentication = requestDoubleAuthentication;
auth.register = register;
auth.registerPassword = registerPassword;

export default auth;
