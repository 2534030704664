import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient les rentrées d'argent du client
 * @param {number} paymentMethodId de la méthode de rentrée d'argent (cheques: 26)
 * @param {PENDING | VALIDATED | CANCELED} payinStatus statut du payin
 * @param {number} pageCount numéro de la page
 * @param {number} pageNumber : nombre d'items dans la liste
 * @param {ASC | DESC} sortOrder ordonner par date de création croissante ou décroissante
 * @param {*} createdDateFrom date minimum de création
 * @param {*} createdDateTo date maximum de création
 * @returns liste des payins
 */
const getPayins = async ({
  paymentMethodId, payinStatus, pageCount, pageNumber, sortOrder, createdDateFrom, createdDateTo,
}) => {
  try {
    const response = await axios.get(`${API_URL}/payins`, {
      params: {
        ...(paymentMethodId && { paymentMethodId }),
        ...(payinStatus && { payinStatus }),
        ...(pageCount && { pageCount }),
        ...(pageNumber && { pageNumber }),
        ...(sortOrder && { sortOrder }),
        ...(createdDateFrom && { createdDateFrom }),
        ...(createdDateTo && { createdDateTo }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createPayin = async (payin) => {
  try {
    const response = await axios.post(`${API_URL}/payins`, payin);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getPayins = getPayins;
api.createPayin = createPayin;

export default api;
