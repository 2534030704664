const company = Object.freeze({
  description: '1 pièce au choix',
  KBIS: {
    document: 'DOCUMENT_TYPE_19',
    description: 'Kbis < 3 mois',
  },
  SIRENE: {
    document: 'DOCUMENT_TYPE_19',
    description: 'Avis de situation SIRENE',
  },
  URSSAF: {
    document: 'DOCUMENT_TYPE_19',
    description: 'Attestation URSSAF',
  },
  INSEE: {
    document: 'DOCUMENT_TYPE_19',
    description: 'Avis INSEE',
  },
  OTHER: {
    document: 'DOCUMENT_TYPE_19',
    description: 'Autre document justifiant le statut (ordre professionnel) Statuts certifiiés conformes < 1 an',
  },
});

export default company;
