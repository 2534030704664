<template>
  <div class="card-physical-settings">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Gérez ici les options <br> de votre carte</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x">
            <div class="cell small-12 grid-x">
              <div class="cell medium-9">Paiement sans contact</div>
              <app-switch size="small" class="cell medium-3" v-model="nfcComputed"></app-switch>
            </div>
            <div class="cell small-12 grid-x">
              <div class="cell medium-9">Retrait au distributeur</div>
              <app-switch size="small" class="cell medium-3" v-model="atmComputed"></app-switch>
            </div>
            <div class="cell small-12 grid-x">
              <div class="cell medium-9">Paiement à l’étranger</div>
              <app-switch size="small" class="cell medium-3" v-model="foreignComputed"></app-switch>
            </div>
            <div class="cell small-12 grid-x">
              <div class="cell medium-9">Paiement sur internet</div>
              <app-switch size="small" class="cell medium-3" v-model="onlineComputed"></app-switch>
            </div>
          </div>

          <app-button class="submit" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
// import EnumLockStatus from '../../../services/enums/cards/lockStatus';
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'card-physical-settings',
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
    secretCode: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      nfc: null,
      atm: null,
      foreign: null,
      online: null,

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    nfcComputed: {
      get() { return this.nfc === null ? !!this.data.optionNfc : this.nfc; },
      set(newValue) { this.nfc = newValue; },
    },
    atmComputed: {
      get() { return this.atm === null ? !!this.data.optionAtm : this.atm; },
      set(newValue) { this.atm = newValue; },
    },
    foreignComputed: {
      get() { return this.foreign === null ? !!this.data.optionForeign : this.foreign; },
      set(newValue) { this.foreign = newValue; },
    },
    onlineComputed: {
      get() { return this.online === null ? !!this.data.optionOnline : this.online; },
      set(newValue) { this.online = newValue; },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      // Transformation des données avant envoi
      const tmpData = {
        foreign: this.foreignComputed ? 1 : 0,
        online: this.onlineComputed ? 1 : 0,
        atm: this.atmComputed ? 1 : 0,
        nfc: this.nfcComputed ? 1 : 0,
      };

      try {
        const tmpCard = await ApiCard.setCard(this.data.cardId, this.secretCode, tmpData.foreign, tmpData.online, tmpData.atm, tmpData.nfc);
        this.$emit('updated:card', tmpCard.data);
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }

          this.$notification.show({
            title: 'Echec de l\'opposition',
            text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.card-physical-settings
  form
    > .grid-x
      margin: 0
      padding: 0 ($global-padding-horizontal)
      border: 1px solid var(--color-70)
      border-radius: $global-border-radius * 2
      @include text
      div.small-12
        padding: ($global-padding-horizontal) 0
      .app-switch
        text-align: right
        ~ div
          border-top: 1px solid var(--color-70)
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
</style>
