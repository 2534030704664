import Vue from 'vue';
import ls from 'local-storage';
import dayjs from 'dayjs';
import dayjsFR from 'dayjs/locale/fr';

import App from './App.vue';
import router from './router';
import auth from './services/auth';
import utils from './services/utils/utils';
import clickOutside from './basics/utils/clickOutside';

import Basics from './basics/index';
import * as components from './components/index';
import EnumCustomerType from './services/enums/customer/type.enum';

/**
 * Création de plusieurs variables globales
 * $theme: permet de récupérer le thème lié au rôle de l'utilisateur
 * $isFlapOpen: permet de savoir si un appflap est ouvert
 */
const globalData = new Vue({
  data: {
    $theme: ls.get('theme') || EnumCustomerType.INDIVIDUAL.toLowerCase(),
    $isFlapOpen: false,
    $lastFlapToggle: 0,
  },
});

/**
 * Ajout d'une mixin qui permet de rendre accessible en lecture et écriture les variables à travers l'app
 */
Vue.mixin({
  computed: {
    // Lecture + écriture
    $theme: {
      get() {
        return globalData.$data.$theme;
      },
      set(newTheme) {
        globalData.$data.$theme = newTheme;
      },
    },
    $isFlapOpen: {
      get() {
        return globalData.$data.$isFlapOpen;
      },
      set(newValue) {
        // Lorsqu'un flap s'ouvre pendant qu'un autre se ferme, l'évenement de fermeture peut arriver juste après celui d'ouverture
        // et l'application consière qu'il n'y a pas de flap
        // Pour éviter ce bug, vérifier que ce n'est pas un événement fermeture jusqte après un événement d'ouverture (< 50ms)
        const oldValue = globalData.$data.$isFlapOpen;
        if (!newValue && oldValue && (Date.now() - globalData.$data.$lastFlapToggle) < 50) {
          return;
        }
        globalData.$data.$lastFlapToggle = Date.now();
        globalData.$data.$isFlapOpen = newValue;
      },
    },
  },
});

// Filtre pour mettre la première lettre d'une chaîne de caractères en majuscule
Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const tmpValue = value.toString();
  return tmpValue.charAt(0).toUpperCase() + tmpValue.slice(1);
});

// Filtre pour formater un prix
Vue.filter('formatPrice', (value) => {
  if (typeof value !== 'number') return '';
  const format = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', signDisplay: 'never' });
  return format.format(value);
});

Vue.filter('formatPriceWithNegative', (value) => {
  if (typeof value !== 'number') return '';
  const format = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', signDisplay: 'auto' });
  return format.format(value);
});

// Filtre pour formater un prix des centimes vers les euros
Vue.filter('convertCentsToEuros', (value) => value / 100);

// Filtre pour afficher la date au format DD/MM/YYYY.
// Passer 'unix' en argument si la date attendue est au format unix (timestamp en seconde)
Vue.filter('formatDate', (value, unix) => {
  if (!value) return '';
  const day = unix === 'unix' ? dayjs.unix(value) : dayjs(value);
  return day.format('DD/MM/YYYY');
});

// Directive pour fermer un composant avec une data isOpen
Vue.directive('click-outside', clickOutside);

/**
 * Définition du nom de l'app
 */
Vue.prototype.$appName = 'Kalveen';
dayjs.locale(dayjsFR);
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$auth = auth;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

Vue.use(Basics);

/**
 * Ajout des composants spécifiques au projet
*/
Object.keys(components.default).forEach((moduleName) => {
  Vue.component(moduleName, components.default[moduleName]);
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
