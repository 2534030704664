<template>
  <div class="register-contract">
    <p class="title">Signature de votre contrat</p>
    <p class="subtitle">Pour valider votre inscription, merci de prendre connaissance de vos contrats Treezor & Kalveen et d’accepter les Conditions Générales d’Utilisation et la Politique de Confidentialité. Vous pourrez refuser et abandonner l’inscription ou signer vos contrats ci-dessous.</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <p class="info">Merci de prendre connaissance des 2 documents <br> afin de pouvoir signer votre contrat.</p>
      <div class="contracts grid-x grid-margin-x align-center">
        <template v-for="(contract, index) in contracts">
          <div class="cell medium-6 contract" @click="contractClick(contract.href)" :class="{'checked': contractsSelected.includes(contract.name)}" :key="`register-contract-checkbox-${index}`">
            <app-checkbox class="cell medium-1" :value="contract.name" v-model="contractsSelected"></app-checkbox>
            <p :key="`register-contract-contract-${index}`" class="cell medium-11">
              <SvgContractShow />
              {{ contract.label }}
            </p>
          </div>
        </template>
      </div>

      <div class="terms grid-x align-middle">
        <template v-for="(term, index) in terms">
          <app-checkbox class="term cell medium-1" :key="`register-contract-checkbox-${index}`" :value="term.name" v-model="termsSelected"></app-checkbox>
          <p :key="`register-contract-term-${index}`" class="term cell medium-11" v-html="term.label"></p>
        </template>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <div class="actions">
        <app-button kind="secondary" @click.prevent="cancel">Refuser</app-button>
        <app-button :disabled="contractsSelected.length < contracts.length || termsSelected.length < terms.length" type="submit">Signer mon contrat</app-button>
      </div>
    </form>
  </div>
</template>

<script>
// Api
import auth from '../../services/auth';
import ApiCustomer from '../../services/api/customer';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';
import EnumCustomerType from '../../services/enums/customer/type.enum';

// Svgs
import SvgContractShow from '../../assets/img/icons/contract_show.svg?inline';
import SvgWarning from '../../assets/img/icons/warning.svg?inline';

export default {
  name: 'register-contract',
  inject: ['$getCustomer'],
  components: {
    SvgContractShow,
    SvgWarning,
  },
  data() {
    return {
      contracts: [
        { name: 'treezorContract', label: 'Contrat treezor', href: '' },
        { name: 'kalveenContract', label: 'Contrat kalveen', href: '' },
      ],
      terms: [
        { name: 'privacyPolicy', label: 'J’ai lu et j’accepte la <a target="_blank" href="/assets/documents/SAS-KVN-politique-de-confidentialite.pdf">Politique de Confidentialité.</a>' },
        { name: 'generalConditions', label: 'J’ai lu et j’accepte les <a target="_blank" href="/assets/documents/SAS-KVN-CGU.pdf">Conditions Générales d\'Utilisation.</a>' },
      ],
      contractsSelected: [],
      termsSelected: [],

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
  },
  mounted() {
    // Si l'utilisateur a terminé son inscription il est redirigé vers son dashboard
    if (this.customer.customer.privacyPolicy && this.customer.customer.generalConditions && this.customer.customer.kalveenContract && this.customer.customer.treezorContract) this.$router.push({ path: '/' });

    this.contracts[0].href = this.customer.customer.type === EnumCustomerType.INDIVIDUAL ? '/assets/documents/SAS_KVN_Contrat_cadre_de_service_de_paiement_clientele_B2C_Treezor.pdf' : '/assets/documents/SAS_KVN_Contrat_cadre_de_service_de_paiement_clientele_B2B_Treezor.pdf';
    this.contracts[1].href = this.customer.customer.type === EnumCustomerType.INDIVIDUAL ? '/assets/documents/SAS_KVN_CGU_Carte_Particuliers_Treezor.pdf' : '/assets/documents/SAS-KVN-CGU-Carte-Business-Treezor.pdf';
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;
      try {
        await ApiCustomer.setCustomer({
          treezorContract: true,
          kalveenContract: true,
          privacyPolicy: true,
          generalConditions: true,
        });
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$router.push({ name: 'home' });
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    cancel() {
      this.$message.show({
        title: 'Annulation',
        text: 'Vous allez être redirigé vers l\'écran de connexion. Sachez que votre inscription ne sera effective qu\'une fois le contrat signé. Vous pouvez réaliser ceci à tout moment en vous connectant simplement à la plateforme avec vos identifiants.',
        confirmText: 'Ok',
        hasCancel: true,
        onConfirm: () => {
          auth.logout();
        },
      });
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case '':
          newErrorMessage = '';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
    contractClick(href) {
      window.open(href, '_blank');
    },
  },
};
</script>

<style lang="sass">
.register-contract
  text-align: center
  p
    &.title
      @include header1
      text-align: center
      margin-bottom: 20px
    &.subtitle
      @include header3
      color: var(--color-grey70)
  form
    .info
      @include text-bold
      margin-top: ($global-padding-vertical * 5)
      margin-bottom: $global-padding-vertical * 2
  .contracts
    margin-bottom: ($global-padding-vertical * 5)
    .contract
      position: relative
      @include text-bold
      width: 184px
      height: 122px
      text-decoration: underline
      align-items: center
      border-radius: $global-border-radius
      background-color: var(--color-cloud)
      &.checked
        background-color: var(--color-50)
      .app-checkbox
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        label
          margin: 0
        label, .checkmark
          height: inherit
          width: inherit
          border: none
          input:checked ~ .checkmark
            background-color: transparent
        .checkmark::after
          display: flex
          padding-bottom: $global-padding-vertical / 2
          content: 'A consulter'
          color: var(--color-warning)
          width: 100%
          height: initial
          bottom: 0
          left: 0
          transform: none
          border: none
          opacity: 1
          font-size: 10px
          align-items: flex-end
          justify-content: center
        input:checked  ~ .checkmark::after
          content: 'Consulté'
          color: var(--color-success)
      p.cell
        svg
          display: block
          margin: auto auto $global-padding-vertical auto

  .terms
    @include details
    color: var(--color-grey70)
    .term
      text-align: left

  .actions
    display: flex
    margin: ($global-padding-horizontal * 10) auto
    align-items: center
    justify-content: center
    .app-button ~ .app-button
      margin-left: $global-padding-horizontal

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
