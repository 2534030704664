<template>
  <div class="register-profil-estate">
    <p>Patrimoine</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x align-middle">
        <app-input
          class="occupation cell small-12"
          label="Définition du poste *"
          type="text"
          v-model="occupationComputed"
        ></app-input>

        <app-select
          class="income-range cell small-12"
          v-model="incomeRangeComputed"
          label="Revenus *"
          :options="EnumIncomeRanges">
        </app-select>

        <app-select
          class="personal-assets cell small-12"
          v-model="personalAssetsComputed"
          label="Patrimoine *"
          :options="EnumNPersonalAssetsRange">
        </app-select>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-profil-estate-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!occupationComputed || !incomeRangeComputed || !personalAssetsComputed" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';

// Components

// Enums
import EnumIncomeRanges from '../../../../../commons/incomeRanges.json';
import EnumNPersonalAssetsRange from '../../../../../commons/personalAssetsRanges.json';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'register-profil-estate',
  components: {
    SvgWarning,
    SvgArrow,
  },
  inject: ['$getCustomer', '$getLeader', '$isSelfEmployedPerson'],
  data() {
    return {
      occupation: null,
      incomeRange: null,
      personalAssets: null,

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumIncomeRanges,
      EnumNPersonalAssetsRange,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    leader() {
      return this.$getLeader();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    occupationComputed: {
      get() { return this.occupation || this.leader?.customer.occupation || this.customer.customer.occupation; },
      set(newValue) { this.occupation = newValue; },
    },
    incomeRangeComputed: {
      get() { return this.incomeRange || this.leader?.customer.incomeRange || this.customer.customer.incomeRange; },
      set(newValue) { this.incomeRange = newValue; },
    },
    personalAssetsComputed: {
      get() { return this.personalAssets || this.leader?.customer.personalAssets || this.customer.customer.personalAssets; },
      set(newValue) { this.personalAssets = newValue; },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        occupation: this.occupationComputed,
        incomeRange: this.incomeRangeComputed,
        personalAssets: this.personalAssetsComputed,
      };

      try {
        if ((this.customer.customer.type === EnumCustomerType.INDIVIDUAL) || this.isSelfEmployedPerson) {
          await ApiCustomer.setCustomer(tmpUser);
          this.$getCustomer(true);
        } else {
          await ApiCustomer.setLeader(tmpUser);
          this.$getLeader(true);
        }
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case '':
          newErrorMessage = '';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-profil-estate
  text-align: center
  > p
    @include header1
    margin-bottom: $global-padding-horizontal * 3.5
  .app-input
    margin: 0

  form
    .income-range, .personal-assets
      margin-top: $global-padding-vertical * 3

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
