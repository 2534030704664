<template>
  <div class="payouts-add">
    <div class="container-go-back" @click="goBackToList">
      <SvgArrow />
      <p>Retour</p>
    </div>

    <p class="title">Nouveau virement</p>

    <app-button class="add" kind="primary" size="large" @click="triggerAddBeneficiaryFlap">
      <div v-if="!selectedBeneficiary" class="icon">
        <SvgPlus />
      </div>
      <span v-if="!selectedBeneficiary">Ajouter un bénéficiaire</span>

      <ListEntry v-if="selectedBeneficiary"
        :title="selectedBeneficiary.name"
        :subtitles="[selectedBeneficiary.iban, selectedBeneficiary.bic]"
      />

      <SvgArrowRight class="arrow-right" />
    </app-button>

    <div class="container-form">
      <div class="amount cell auto">
        <app-input
          label="Montant"
          type="number"
          min="0"
          v-model="amount"
        >
          <template v-slot:suffix><SvgEuro /></template>
        </app-input>
      </div>

      <div class="label cell auto">
        <app-input
          label="Libellé (optionnel)"
          type="text"
          v-model="label"
        >
        </app-input>
      </div>

      <div class="supporting-file-container">
        <p>Justificatif</p>

        <p>Un justificatif de virement est obligatoire pour un virement supérieur à 10 000€. Poids maximum autorisé 1MB.</p>

        <app-upload v-model="selectedDocument" accept="image/*,application/pdf"/>
      </div>
    </div>

    <app-button :disabled="!amount" @click="triggerSecretCodeCreatePayout" size="large" kind="primary">
      Suivant <SvgArrow />
    </app-button>

    <AddFlapBeneficiary
      ref="add-flap-beneficiary"
      :isOpen="isTheFlapAddBeneficiaryOpen"
      @is-open="(value) => isTheFlapAddBeneficiaryOpen = value"
      :selectBeneficiary="selectBeneficiary"
      :selectedBeneficiary="selectedBeneficiary"
      :createBeneficiary="createBeneficiary"
    />

    <CreateBeneficiary
      action="add"
      @updated="callbackCreatedBeneficiary"
      :isOpen="isTheFlapCreateBeneficiaryOpen"
      @is-open="(value) => isTheFlapCreateBeneficiaryOpen = value"
      :data="{ ...tmpNewBeneficiary, secretCode }"
      @onChange="(newData) => tmpNewBeneficiary = newData"
    />

    <SecretCode
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => isTheFlapOpenSecretCode = value"
      @click="confirmSecretCode"
    />

    <SecretCode
      :secretCode="secretCodeCreatePayout"
      :isOpen="isTheFlapOpenSecretCodeCreatePayout"
      @onChange="(newData) => secretCodeCreatePayout = newData"
      @is-open="(value) => isTheFlapOpenSecretCodeCreatePayout = value"
      @click="createPayout"
      :isLoading="isCreatePayoutLoading"
    />
  </div>
</template>

<script>
// Api
import ApiPayout from '../../services/api/payout';

// Components
import AddFlapBeneficiary from './AddFlapBeneficiary.vue';
import ListEntry from '../Common/ListEntry.vue';
import CreateBeneficiary from '../Beneficiaries/Edit.vue';
import SecretCode from '../Common/AppSecretCode.vue';

// SVG
import SvgArrow from '../../assets/img/icons/arrow.svg?inline';
import SvgPlus from '../../assets/img/icons/plus.svg?inline';
import SvgArrowRight from '../../assets/img/icons/arrow-right.svg?inline';
import SvgEuro from '../../assets/img/icons/euro.svg?inline';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';

export default {
  name: 'payouts-add',
  components: {
    AddFlapBeneficiary,
    ListEntry,
    CreateBeneficiary,
    SecretCode,
    SvgArrow,
    SvgPlus,
    SvgArrowRight,
    SvgEuro,
  },
  props: {
    goBackToList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      amount: '',
      label: '',

      selectedBeneficiary: null,
      selectedDocument: null,
      tmpNewBeneficiary: null,
      secretCode: [],
      secretCodeCreatePayout: [],
      isCreatePayoutLoading: false,

      isTheFlapAddBeneficiaryOpen: false,
      isTheFlapCreateBeneficiaryOpen: false,
      isTheFlapOpenSecretCode: false,
      isTheFlapOpenSecretCodeCreatePayout: false,

      errors: [],
    };
  },
  methods: {
    async createPayout() {
      if (!this.selectedBeneficiary) {
        this.$notification.show({
          title: 'Formulaire incomplet',
          text: 'Merci de bien vouloir sélectionner un bénéficiaire',
          type: EnumNotificationType.ECHEC,
        });
        return;
      }

      if (!this.amount) {
        this.$notification.show({
          title: 'Formulaire incomplet',
          text: 'Merci de bien vouloir indiquer un montant',
          type: EnumNotificationType.ECHEC,
        });
        return;
      }

      this.errors = [];
      this.isCreatePayoutLoading = true;

      try {
        await ApiPayout.createPayout({
          beneficiaryId: this.selectedBeneficiary.id,
          label: this.label,
          amount: this.amount,
          ...(this.selectedDocument && { document: this.selectedDocument[0] }),
          secretCode: this.secretCodeCreatePayout.join(''),
        });

        // Virement réussi
        // On ferme tous les flaps
        this.isTheFlapOpenSecretCodeCreatePayout = false;
        this.isTheFlapAddBeneficiaryOpen = false;
        this.isTheFlapCreateBeneficiaryOpen = false;
        this.isTheFlapOpenSecretCode = false;

        this.$notification.show({
          title: 'Virement effectué !',
          text: '',
          type: EnumNotificationType.SUCCESS,
        });

        // On retourne sur la liste des virements
        this.goBackToList();
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }

          this.$notification.show({
            title: 'Echec de la création du virement',
            text: `${this.errors.map((tmpError) => this.transformError(typeof tmpError === 'string' ? tmpError : tmpError.message)).join(' ')}`,
            type: EnumNotificationType.ECHEC,
          });
        }
      } finally {
        this.isCreatePayoutLoading = false;
      }
    },
    triggerSecretCodeCreatePayout() {
      // Ferme les autres flaps
      this.isTheFlapAddBeneficiaryOpen = false;
      this.isTheFlapOpenSecretCode = false;
      this.isTheFlapCreateBeneficiaryOpen = false;

      this.isTheFlapOpenSecretCodeCreatePayout = true;
    },
    triggerAddBeneficiaryFlap() {
      // Ferme les autres flaps
      this.isTheFlapOpenSecretCodeCreatePayout = false;
      this.isTheFlapOpenSecretCode = false;
      this.isTheFlapCreateBeneficiaryOpen = false;

      this.isTheFlapAddBeneficiaryOpen = !this.isTheFlapAddBeneficiaryOpen;
    },
    selectBeneficiary(value) {
      this.selectedBeneficiary = value;
    },
    createBeneficiary() {
      // Ferme les autres flaps
      this.isTheFlapOpenSecretCodeCreatePayout = false;
      this.isTheFlapAddBeneficiaryOpen = false;
      this.isTheFlapCreateBeneficiaryOpen = false;

      // Ouvre le flap de choix du code secret
      this.isTheFlapOpenSecretCode = true;
    },
    confirmSecretCode() {
      // Ferme les autres flaps
      this.isTheFlapOpenSecretCodeCreatePayout = false;
      this.isTheFlapAddBeneficiaryOpen = false;
      this.isTheFlapOpenSecretCode = false;

      // Ouvre le flap de création de bénéficiaire
      this.isTheFlapCreateBeneficiaryOpen = true;
    },
    callbackCreatedBeneficiary(beneficiary) {
      this.selectedBeneficiary = {
        ...beneficiary.data,
        iban: beneficiary.data.metadata.iban,
      };

      // Fermeture de tous les flaps
      this.isTheFlapOpenSecretCodeCreatePayout = false;
      this.isTheFlapAddBeneficiaryOpen = false;
      this.isTheFlapCreateBeneficiaryOpen = false;
      this.isTheFlapOpenSecretCode = false;

      // Reset des infos
      this.tmpNewBeneficiary = null;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'File too large':
          newErrorMessage = 'Le justificatif joint est trop volumineux. Il doit peser au maximum 1MB.';
          break;
        case 'Support file mandatory for payouts exceeding 10 000€':
          newErrorMessage = 'Un justificatif est obligatoire pour un virement de plus de 10 000€.';
          break;
        case 'Secret code not valid':
          newErrorMessage = 'Le code secret n\'est pas valide.';
          break;
        case 'Supporting file could not be uploaded':
          newErrorMessage = 'Le fichier n\' a pas pu être téléchargé sur le stockage cloud.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.payouts-add
  max-width: 484px
  .container-go-back
    display: flex
    flex-direction: row
    cursor: pointer
    align-items: center
    transition: all ease-in-out 0.150s
    p
      @include text
      margin-left: 8px
      text-decoration: underline
      color: var(--color-black)
    svg
      transform: rotate(180deg)
      path
        stroke: var(--color-black)
  .title
    @include header2
  .app-button.add, .data .app-button.record
    @include tile
  .app-button.add
    margin: $global-padding-horizontal 0
  .container-form
    padding: 24px
    border-radius: 16px
    background-color: var(--color-cloud)
    .amount, .label
      margin-bottom: $global-padding-vertical * 3.3
      .app-input
        p
          margin: revert
        .label
          background-color: var(--color-cloud)
    .supporting-file-container
      p:first-of-type
        @include header3
      p:last-of-type
        @include details
        color: var(--color-grey70)
      .app-upload
        margin-top: 24px
  .beneficiaries-edit .app-flap
    .app-button.submit
      font-size: 14px
      color: var(--color-cloud)
      border-radius: $global-border-radius * 5
      background-color: var(--color-black)
      svg
        margin: 0
        margin-left: $global-padding-vertical
        path
          fill: var(--color-cloud)

  button
    margin-top: $global-padding-vertical * 2
    path
      stroke: white
</style>
