const civility = Object.freeze({
  M: {
    name: 'M',
    label: 'Un homme',
  },
  MME: {
    name: 'MME',
    label: 'Une femme',
  },
});

export default civility;
