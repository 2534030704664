<template>
  <div class="profil-profil-edit-estate">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p><span>{{ data.customer.lastname }}</span> <span>{{ data.customer.firstname }}</span></p>
        <p>Patrimoine</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x occupation align-middle">
            <app-input
              class="cell auto"
              label="Définition du poste *"
              type="text"
              v-model="occupationComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x income-range align-middle">
            <app-select
              class="cell auto"
              v-model="incomeRangeComputed"
              label="Revenus *"
              :options="EnumIncomeRanges"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x personal-assets align-middle">
            <app-select
              class="cell auto"
              v-model="personalAssetsComputed"
              label="Patrimoine *"
              :options="EnumNPersonalAssetsRange"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !occupationComputed || !incomeRangeComputed || !personalAssetsComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Components

// Enums
import EnumIncomeRanges from '../../../../../commons/incomeRanges.json';
import EnumNPersonalAssetsRange from '../../../../../commons/personalAssetsRanges.json';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-profil-edit-estate',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      EnumIncomeRanges,
      EnumNPersonalAssetsRange,

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    occupationComputed: {
      get() { return this.data.customer.occupation; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            occupation: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    incomeRangeComputed: {
      get() { return this.data.customer.incomeRange; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            incomeRange: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    personalAssetsComputed: {
      get() { return this.data.customer.personalAssets; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            personalAssets: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        occupation: this.occupationComputed,
        incomeRange: this.incomeRangeComputed,
        personalAssets: this.personalAssetsComputed,
      };

      try {
        /* eslint-disable */
        // Cas customer INDIVIDUAL
        if (this.customer.customer.type === EnumCustomerType.INDIVIDUAL) {
          await ApiCustomer.setCustomer(tmpUser);
        } else { // Cas customer BUSINESS
          // S'il y a un customerId il faut vérifier à quel type de customer on a à faire,
          // car la route de modification ne sera pas pas identique.
          if (this.data.customer.parentType === EnumParentType.LEADER.name) {
            await ApiCustomer.setLeader(tmpUser);
          } else if (this.data.customer.parentType === EnumParentType.SHAREHOLDER.name) {
            await ApiCustomer.setAssociate(this.data.customer.customerId, tmpUser);
          }
        }
        /* eslint-enable */
        this.$getCustomer(true);
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-profil-edit-estate
  .app-input
    margin: 0
  .app-input, .app-select, .app-datepicker
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3

  .app-flap
    .main
      p:first-child
        @include header4
        margin-bottom: 0
        color: var(--color-100)
      p:last-child
        margin-top: 0
        @include header2
    .content
      .occupation, .income-range, .personal-assets
        svg
          margin-left: $global-padding-vertical
          path
            &:first-child
              stroke-width: 1
              stroke: var(--color-grey70)
            &:last-child
              fill: var(--color-cloud)
              stroke: var(--color-grey70)
</style>
