<!-- COMPOSANT APP TOOLTIP

##########USAGE##########
  <app-tooltip position="right">
    <div class="step">1 / 3</div>
    <p class="title">Vestibulum ante ipsum primis</p>
    <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <template v-slot:app-tooltip-wrapper>
      <div>Hello</div>
    </template>
  </app-tooltip>
-->

<template>
  <div class="app-tooltip" ref="app-tooltip" :class="{ unfixed: !isFixed }"
  >
    <div class="app-tooltip-container" :class="[position, { hovered: !isFixed && isWrapperHovered }]" :style="wrapperPosition && containerPosition ? getStyle() : {}">
      <slot></slot>
      <div class="beak" :class="position"></div>
    </div>

    <div class="app-tooltip-wrapper" ref="app-tooltip-wrapper"
      @mouseover="isWrapperHovered = true"
      @mouseleave="isWrapperHovered = false">
      <slot name="app-tooltip-wrapper"></slot>
    </div>
  </div>
</template>

<script>
const margin = 7;
const beakSize = Object.freeze({
  height: 8,
  width: 16,
});
const positions = Object.freeze({
  top: 'top',
  bottom: 'bottom',
  'top-right': 'top-right',
  'top-left': 'top-left',
  right: 'right',
  left: 'left',
  'bottom-right': 'bottom-right',
  'bottom-left': 'bottom-left',
});

export default {
  props: {
    position: {
      default: 'top',
      validator: (val) => Object.values(positions).indexOf(val) !== -1,
      required: true,
    },
    isFixed: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      wrapperPosition: null,
      containerPosition: null,
      beakSize,
      isWrapperHovered: false,
    };
  },
  watch: {
    position() {
      this.wrapperPosition = this.getWrapperPosition();
      this.containerPosition = this.getContainerPosition();
    },
  },
  mounted() {
    this.wrapperPosition = this.getWrapperPosition();
    this.containerPosition = this.getContainerPosition();
  },
  methods: {
    getWrapperPosition() {
      return this.$refs['app-tooltip'].children[1].getBoundingClientRect();
    },
    getContainerPosition() {
      return this.$refs['app-tooltip'].children[0].getBoundingClientRect();
    },
    getStyle() {
      let position = null;

      switch (this.position) {
        case positions.bottom:
          position = { top: `${this.wrapperPosition.height + beakSize.height + margin}px`, left: `${-this.containerPosition.width + ((this.containerPosition.width / 2) + this.wrapperPosition.width / 2)}px` };
          break;
        case positions['top-right']:
          position = { bottom: `${this.wrapperPosition.height + beakSize.height + margin}px`, right: 0 };
          break;
        case positions['top-left']:
          position = { bottom: `${this.wrapperPosition.height + beakSize.height + margin}px`, left: 0 };
          break;
        case positions.right:
          position = { left: `${this.wrapperPosition.width + beakSize.height + margin}px `, top: `${-(this.containerPosition.height / 2) + (this.wrapperPosition.height / 2)}px` };
          break;
        case positions.left:
          position = { right: `${this.wrapperPosition.width + beakSize.height + margin}px`, top: `${-(this.containerPosition.height / 2) + (this.wrapperPosition.height / 2)}px` };
          break;
        case positions['bottom-right']:
          position = { top: `${this.wrapperPosition.height + beakSize.height + margin}px`, right: 0 };
          break;
        case positions['bottom-left']:
          position = { top: `${this.wrapperPosition.height + beakSize.height + margin}px`, left: 0 };
          break;
        default:
          position = { bottom: `${this.wrapperPosition.height + beakSize.height + margin}px`, left: `${-this.containerPosition.width + ((this.containerPosition.width / 2) + this.wrapperPosition.width / 2)}px` };
          break;
      }
      return position;
    },
  },
};
</script>

<style lang="sass">
.app-tooltip
  position: relative
  width: fit-content
  transition: all ease-in-out 0.150s
  svg
    widht: 20px
    height: 20px
  &.unfixed
    .app-tooltip-wrapper
      cursor: pointer
    .app-tooltip-container
      opacity: 0
      &.hovered
        opacity: 1
  .app-tooltip-container
    @include details
    position: absolute
    padding: ($global-padding-horizontal / 2)
    height: fit-content
    min-width: 232px
    border-radius: $global-border-radius
    background-color: var(--color-black)
    filter: drop-shadow(0px 2px 8px var(--color-grey70))
    opacity: 1
    color: var(--color-cloud)
    z-index: 1000
    transition: all ease-in-out 0.2s
    &.bottom
      bottom: 0
    .beak
      position: absolute
      right: 0
      width: 0
      border-right: 8px solid transparent
      border-left: 8px solid transparent
      &.top, &.bottom
        margin: auto
        left: 0
        border-right: 8px solid transparent
        border-left: 8px solid transparent
      &.top
        bottom: -8px
        border-top: 8px solid var(--color-black)
      &.top-right
        right: 16px
        bottom: -8px
        border-top: 8px solid var(--color-black)
      &.top-left
        left: 16px
        bottom: -8px
        border-top: 8px solid var(--color-black)
      &.bottom
        top: -8px
        border-bottom: 8px solid var(--color-black)
      &.bottom-right
        right: 16px
        top: -8px
        border-bottom: 8px solid var(--color-black)
      &.bottom-left
        left: 16px
        top: -8px
        border-bottom: 8px solid var(--color-black)
      &.left, &.right
        margin: auto 0
        height: 0
        top: 0
        bottom: 0
        border-bottom: 8px solid transparent
        border-top: 8px solid transparent
      &.left
        right: -16px
        border-left: 8px solid var(--color-black)
      &.right
        left: -16px
        border-right: 8px solid var(--color-black)
</style>
