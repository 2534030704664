<template>
  <div class="register-navigation">
    <div class="wrapper">
      <div class="arrow left" @click="$emit('click:previous')" :class="{ 'disabled': step === 1}">
        <SvgArrow/>
      </div>
      <div class="step grid-x">
        <div class="icon cell shrink">
          <component :is="icon"></component>
        </div>
        <div class="name cell auto">
          <span>{{ name }}</span>
        </div>
        <div class="progress">
          <span>{{ step }}/{{ stepsNumber }}</span>
          <span class="circle">
            <svg :data-progress="step" x="0px" y="0px" viewBox="0 0 80 80">
              <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
              <path class="fill" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" :style="{ strokeDashoffset: (100 - ((100 / stepsNumber) * step)) / 100 * 219.99078369140625}" />
            </svg>
          </span>
        </div>
      </div>
      <div class="arrow right" @click="$emit('click:next')" :class="{ 'disabled': step === stepsNumber || step === stepLocked}">
        <SvgArrow/>
      </div>
    </div>
  </div>
</template>

<script>
import SvgArrow from '../../assets/img/icons/arrow.svg?inline';

export default {
  name: 'register-navigation',
  components: {
    SvgArrow,
  },
  props: {
    icon: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    stepLocked: {
      type: Number,
      required: true,
    },
    stepsNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="sass">
.register-navigation
  position: absolute
  top: 0
  right: 50%
  height: var(--navigation-height)
  z-index: 101
  transform: translate(50%, 0)
  &.profil-fiscal-residence
    .wrapper .icon svg path
      fill: var(--color-cloud)
  .wrapper
    display: flex
    margin: auto
    height: inherit
    align-items: center
    justify-content: center
    width: 500px
    .icon
      display: flex
      height: 32px
      width: 32px
      align-items: center
      justify-content: center
      border-radius: $global-border-radius * 100
      background-color: var(--color-black)
    .step
      margin: 0 ($global-padding-vertical * 2)
      padding: $global-padding-vertical $global-padding-horizontal
      width: 360px
      align-items: center
      border-radius: $global-border-radius * 6
      background-color: var(--color-cloud)
      box-shadow: 0px 4px 20px rgba(226, 225, 225, 0.7)
      .name
        @include text
        text-align: left
        margin: 0 $global-padding-horizontal
      .progress
        display: flex
        @include text-bold
        align-items: center
        .circle
          display: inline-block
          width: 35px
          height: 35px
          margin-left: $global-padding-horizontal
          svg
            width: inherit
            height: inherit
            .track, .fill
              fill: transparent
              stroke-width: 4
              transform: rotate(90deg)translate(0px, -80px)
            .track
              stroke: var(--color-70)
            .fill
              stroke: var(--color-black)
              stroke-dasharray: 219.99078369140625
              stroke-dashoffset: -219.99078369140625
              transition: stroke-dashoffset 1s
    .arrow
      &:not(.disabled)
        cursor: pointer
      &.disabled
        pointer-events: none
        path
          stroke: var(--color-grey70)
      &:hover, &:active
        svg path
          stroke: var(--color-grey70)
      svg
        path
          stroke: var(--color-black)
      &.left
        transform: rotate(-180deg)
    svg
      height: 16px
      width: 16px

</style>
