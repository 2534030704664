<template>
  <button class="app-button" :class="[size, kind]" :type="type" @click="handleClick" :disabled="isDisabled">
    <app-spinner v-if="isLoading" :small="true" ></app-spinner>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    size: {
      default: 'large',
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
    },
    kind: {
      default: 'primary',
      validator: (val) => ['primary', 'secondary'].indexOf(val) !== -1,
    },
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: flex
  margin: 0
  align-items: center
  @include cta
  line-height: initial
  // min-width: 123px
  font-size: 14px
  border-radius: $global-border-radius * 5
  border: none
  color: white
  cursor: pointer
  transition: all ease-in-out 0.150s
  svg
    margin-left: 10px
    path
      stroke: transparent

  // type
  &.large
    padding: 20px ($global-padding-horizontal * 2)
  &.small
    padding: ($global-padding-horizontal - 2) ($global-padding-vertical * 2)

  // kind
  &.primary
    background-color: var(--color-black)
  &.secondary
    color: var(--color-black)
    background-color: var(--color-70)
    svg path
      stroke: var(--color-black)

  // selector
  &:disabled
    cursor: initial
    pointer-events: none
    opacity: 0.2
  &:hover
    &.primary
      background-color: #515865
    &.secondary
      background-color: #E4E3E2

  .app-spinner
    margin: auto
    &.small
      height: 18px
      width: 18px
    svg
      border-width: 1px

@keyframes fade
</style>
