const utils = {};

/**
 * Similaire au _debounce de lodash: Retarde l'execution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Permet de limiter la fréquence d'utilisation d'une fonction en ne l'appelant au maximum 1 fois toutes les 'wait'ms
 * @param {Function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Number} wait : temps d'attente minimum entre 2 appels
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const throttle = (func, wait) => {
  let timeout;
  let storedArgs;
  return function executedFunc(...args) {
    storedArgs = args;
    if (!timeout) {
      func(...storedArgs);
      storedArgs = null;
      timeout = setTimeout(() => {
        if (storedArgs) {
          func(...storedArgs);
        }
        timeout = null;
      }, wait);
    }
  };
};

const transformEnumToArray = (data) => {
  const tmpArray = Object.keys(data).map((key) => {
    const tmpObject = {
      key,
      ...data[key],
    };
    return tmpObject;
  });
  return tmpArray;
};

const isInUpperCase = (character) => character === character.toUpperCase();

const transformPascalCaseToClassName = (value) => {
  const newValue = Object.values(value).map((char, index) => {
    let tmpChar = char;
    if (index <= 0) {
      tmpChar = char.toLowerCase();
    } else if (isInUpperCase(char)) {
      tmpChar = `-${char.toLowerCase()}`;
    }
    return tmpChar;
  });
  return newValue.join('');
};

const sortAlphabetically = (array) => array.sort((a, b) => {
  if (a.label < b.label) { return -1; }
  if (a.label > b.label) { return 1; }
  return 0;
});

utils.debounce = debounce;
utils.throttle = throttle;
utils.transformEnumToArray = transformEnumToArray;
utils.isInUpperCase = isInUpperCase;
utils.transformPascalCaseToClassName = transformPascalCaseToClassName;
utils.sortAlphabetically = sortAlphabetically;

export default utils;
