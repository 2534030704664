<template>
  <div v-click-outside :ref="_uid" class="app-select" :class="[{ open: isOpen }, { 'content-opened': isOpen }, { 'disabled': isDisabled }]">
    <p class="label" :class="{ 'label-at-top': isOpen || value }">{{ label }}</p>

    <div class="main grid-x" @click="toggleIsOpen">
      <div class="cell auto">
        <slot v-if="options.find(o => o.name === value)">{{ options.find(o => o.name === value)['label'] }}</slot>
      </div>
      <div class="cell shrink arrow">
        <SvgArrowSelect />
      </div>
    </div>
    <p v-if="validationError" class="error"><SvgWarning /><span>{{ validationError }}</span></p>

    <transition name="select">
      <div v-if="isOpen" class="content">
        <div class="search">
          <app-input type="text" v-model="searchValue"></app-input>
          <SvgSearch />
        </div>

        <ul>
          <li
            :class="{ 'active': option.name === value }"
            v-for="option in filteredOptionsComputed.length > 0 ? filteredOptionsComputed : options"
            :key="option.name"
            @click="$emit('input', option.name), isOpen = false">
              {{ option.label }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import SvgArrowSelect from '../../assets/img/icons/arrow-select.svg?inline';
import SvgWarning from '../../assets/img/icons/warning.svg?inline';
import SvgSearch from '../../assets/img/icons/search.svg?inline';

export default {
  components: {
    SvgArrowSelect,
    SvgWarning,
    SvgSearch,
  },
  props: {
    value: [String, Array, Number],
    // Format [{ name: 'lorem', label: 'Lorem Ipsum'}]
    options: [Array],
    label: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      validationError: null,
      searchValue: null,
    };
  },
  computed: {
    error() {
      return !!this.validationError;
    },
    filteredOptionsComputed() {
      return this.options.filter((option) => {
        if (!this.searchValue) return false;
        const tmpOption = option.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const tmpSearchValue = this.searchValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return tmpOption.includes(tmpSearchValue);
      });
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    checkCustomValidation() {
      this.validationError = null;
    },
  },
};
</script>

<style lang="sass">
.app-select
  position: relative
  background-color: transparent
  &.disabled
    cursor: pointer
    pointer-events: none
    div
      color: var(--color-grey70)

  .main
    @include text
    width: 100%
    min-height: 44px
    text-align: left
    padding: $global-padding-vertical ($global-padding-vertical * 2)
    border-radius: $global-border-radius * 5
    border: 1px solid var(--color-grey70)
    background-color: transparent
    cursor: pointer
    transition: all 0.2s ease-in-out
    div:first-child
      padding-right: $global-padding-vertical
    .arrow
      display: flex
      align-self: center
      transition: all ease-in-out 0.2s
  .label
    @include text
    position: absolute
    padding: 0 4px
    left: $global-padding-vertical * 2 - 4px
    line-height: initial
    color: var(--color-black)
    background-color: var(--color-30)
    transition: all 0.2s ease-in-out
    &.label-at-top
      font-size: 11px
      color: var(--color-grey70)
      transform: translateY(-$global-padding-vertical - 5px)
      z-index: 10
  &.content-opened
    .main
      .arrow
        transform: rotate(180deg)

  .content
    @include text
    position: absolute
    width: 100%
    margin: ($global-padding-horizontal / 2) 0
    border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0
    filter: drop-shadow(0px 3px 2px rgba(#dadada, 0.8))
    background-color: var(--color-cloud)
    z-index: 1000
    &::after
      content: ''
      position: absolute
      bottom: -10px
      left: 0
      height: 10px
      width: 100%
      border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
      background-color: var(--color-cloud)
    .search
      padding: 10px
      border-radius: ($global-border-radius * 2)
      background-color: var(--color-cloud)
      transform: translate3d(0,0,0)
      -webkit-transform: translate3d(0,0,0)
      .app-input input
        border-color: var(--color-70)
      svg
        position: absolute
        margin: auto
        top: 0
        bottom: 0
        right: 30px
    ul
      margin-top: 0
      padding: 0
      height: inherit
      max-height: calc(176px - 44px)
      list-style: none
      overflow-y: scroll
      li
        padding: $global-padding-vertical ($global-padding-vertical * 2)
        cursor: pointer
        &.active
          background-color: var(--color-50)
        &:hover
          background-color: var(--color-50)

  p.error
    display: flex
    align-items: center
    margin-top: 4px
    svg
      width: 16px
      height: 16px
    span
      margin-left: 6px
      @include text-bold
      line-height: initial
      font-size: 11px
      color: var(--color-warning)

.select-enter-active, .select-leave-active
  transition: all 0.25s ease-in-out

.select-enter, .select-leave-to
  opacity: 0
  transform: translateY(-10px)

</style>
