<!--
  Exemple pour la propriété "values"

  values = [
    {
      name<string>: '',
      label<String>: '',
      disabled<Boolean>: false,
      active<Boolean>: false,
    }
  ]
-->

<template>
  <div class="app-radio-custom">
    <app-button
      v-for="(tmpValue, index) in valuesComputed"
      :key="`app-radio-custom-button-value-${index}`"
      @click="() => $emit('value', tmpValue)"
      :class="{ active: (value ? tmpValue.name === value.name : null) || valuesComputed.active, single: valuesComputed.length === 1 }"
      :disabled="tmpValue.disabled || isDisabled"
      size="small">
      <span>{{ tmpValue.label }}</span>
    </app-button>
  </div>
</template>

<script>
import AppButton from '../basics/components/AppButton.vue';

export default {
  name: 'app-radio-custom',
  components: {
    AppButton,
  },
  props: {
    values: {
      type: [Array, Object],
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    valuesComputed: {
      get() {
        return this.values;
      },
    },
  },
};
</script>

<style lang="sass">
.app-radio-custom
  display: flex
  .app-button
    display: flex
    margin: 0
    color: var(--color-black)
    border: 1px solid var(--color-black)
    border-radius: 0
    justify-content: center
    background-color: transparent
    &.single
      border-radius: 40px
    &:first-child:not(.single)
      border-radius: 40px 0 0 40px
    &:last-child:not(.single)
      border-radius: 0 40px 40px 0
    &.active
      color: white
      background-color: var(--color-black)
    &:hover
      color: var(--color-black)
      background-color: var(--color-70)
</style>
