<template>
  <app-button
    @click="$emit('click')"
    :class="{ active }"
    class="app-action-card"
  >
    <div class="grid-x align-middle">
      <div class="icon cell shrink" v-if="withIcon">
        <div class="circle">
          <slot name="icon"></slot>
        </div>
      </div>
      <div class="cell auto">
        <slot name="content"></slot>
      </div>
      <div class="cell shrink arrow">
        <SvgArrowRight />
      </div>
    </div>
  </app-button>
</template>

<script>
import SvgArrowRight from '@/assets/img/icons/arrow-right.svg?inline';

export default {
  name: 'app-action-card',
  components: {
    SvgArrowRight,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="sass">
.app-action-card.app-button
  @include header3
  width: 100%
  border-radius: 0
  padding: $global-padding-horizontal ($global-padding-vertical * 2)
  background-color: var(--color-cloud)
  text-align: left
  &:hover, &.active
    background-color: var(--color-50)
  > div
    width: 100%
  div.arrow
    display: flex
    svg
      path
        stroke: var(--color-grey70)
  p
    margin-top: 0
    margin-bottom: 0
    &:not(:first-child)
      @include text
      color: var(--color-grey70)
  .icon
    margin-right: $global-padding-horizontal
    svg
      margin: 0
      width: 20px
      path, circle
        fill: var(--color-black)
        stroke: transparent
    .circle
      display: flex
      height: 38px
      width: 38px
      justify-content: center
      align-items: center
      border-radius: 100%
      background-color: var(--color-70)
</style>
