<template>
  <div class="beneficiaries">
    <div class="list">
      <List
        @click="(value) => { selectedBeneficiary = value; isTheFlapOpenEditComputed() }"
        :selectedBeneficiary="selectedBeneficiary"
        :needRefresh="doesListNeedRefreshedComputed"
        @hasBeenRefreshed="() => doesListNeedRefreshedComputed = false"
      />
    </div>

    <Edit ref="beneficiaries-edit"
      :action="selectedBeneficiary ? selectedBeneficiary.action : selectedBeneficiary"
      :data="{ ...selectedBeneficiary, secretCode }"
      @onChange="(newData) => selectedBeneficiary = newData"
      :isOpen="isTheFlapOpenEdit"
      @is-open="(value) => isTheFlapOpenEdit = value"
      @updated="() => {doesListNeedRefreshedComputed = true; isTheFlapOpenEdit = false;}"
    />

    <SecretCode ref="beneficiaries-secretcode"
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => isTheFlapOpenSecretCode = value"
      :isLoading="isAddLoading"
      @click="manage"
    />
  </div>
</template>

<script>
// Components
import List from './List.vue';
import Edit from './Edit.vue';
import SecretCode from '../Common/AppSecretCode.vue';

export default {
  name: 'beneficiaries',
  components: {
    List,
    Edit,
    SecretCode,
  },
  data() {
    return {
      selectedBeneficiary: null,
      doesListNeedRefreshed: false,
      secretCode: [],

      isTheFlapOpenSecretCode: false,
      isTheFlapOpenEdit: false,

      isAddLoading: false,
    };
  },
  computed: {
    doesListNeedRefreshedComputed: {
      get() { return this.doesListNeedRefreshed; },
      set(newValue) {
        this.doesListNeedRefreshed = newValue;
      },
    },
  },
  mounted() {
    if (this.$refs['beneficiaries-edit'] && this.$refs['beneficiaries-secretcode']) {
      this.$watch(() => this.$refs['beneficiaries-edit'].isOpen || this.$refs['beneficiaries-secretcode'].isOpen,
        (newValue) => {
          this.$isFlapOpen = newValue;
        });
    }
  },
  methods: {
    isTheFlapOpenEditComputed() {
      if (this.selectedBeneficiary.action === 'edit') {
        this.isTheFlapOpenEdit = true;
        this.isTheFlapOpenSecretCode = false;
      } else {
        this.isTheFlapOpenEdit = false;
        this.isTheFlapOpenSecretCode = true;
      }
    },
    async manage() {
      this.isAddLoading = true;
      try {
        this.isTheFlapOpenEdit = true;
        this.isTheFlapOpenSecretCode = false;
      } catch (error) {
        //
      }
      this.isAddLoading = false;
    },
  },
};
</script>

<style lang="sass">
.beneficiaries
  max-width: 484px
  p
    margin: 0

</style>
