<template>
  <div class="app-dropdown" :class="[{ 'content-opened': isOpen }, { 'disabled': disabled }]">
    <div class="main grid-x align-middle" @click="toggleIsOpen">
      <div class="cell auto">
        <slot name="main"></slot>
      </div>
      <div class="cell-shrink arrow">
        <SvgArrowSelect />
      </div>
    </div>

    <transition name="dropdown">
      <div v-if="isOpen" class="content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import SvgArrowSelect from '../../assets/img/icons/arrow-select.svg?inline';

export default {
  name: 'app-dropdown',
  components: {
    SvgArrowSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
      this.$emit('is-open', this.isOpen);
    },
  },
};
</script>

<style lang="sass">
.app-dropdown
  position: relative
  background-color: transparent
  &.disabled
    .main
      background-color: var(--color-50) !important
      pointer-events: none
  .main
    cursor: pointer
    .arrow
      transition: all ease-in-out 0.150s
  &.content-opened
    .main
      .arrow
        transform: rotate(180deg)
  .content
    @include header4
    position: absolute
    width: 100%
    left: 0
    padding: $global-padding-vertical $global-padding-horizontal
    border-radius: $global-border-radius * 2
    filter: drop-shadow(0px 3px 2px rgba(#dadada, 0.8))
    background-color: var(--color-cloud)
    z-index: 1000

.dropdown-enter, .dropdown-leave-to
  height: 0
  opacity: 0

.dropdown-enter-active, .dropdown-leave-active
  transition: all 0.5s ease-in-out

.dropdown-enter-to, .dropdown-leave
  max-height: 500px

</style>
