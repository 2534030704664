<template>
  <div class="app-menu" :class="{ 'small': !isOpen }">
    <div class="top">
      <app-dropdown ref="app-menu-dropdown">
        <template v-slot:main>
          <p>Bonjour,</p>
          <template v-if="customer">
            <p v-if="customer.customer.type === EnumCustomerType.INDIVIDUAL">{{ customer.customer.firstname }} {{ customer.customer.lastname }}</p>
            <p v-else>{{ customer.customer.legalName }}</p>
          </template>
          <template v-else>
            <app-spinner :small="true"></app-spinner>
          </template>
        </template>
        <template v-slot:content>
          <app-button v-for="(route, index) in EnumProfil" :key="`app-menu-checkbox-app-button-${index}`"
            @click="route.action($route); recordClick()"
            :class="{ 'active': $route.path.includes(route.name) }"
          >
            <span>
              <component :is="route.icon" ></component>
            </span>
            <span>{{ route.label }} {{ route.test }}</span>
          </app-button>
        </template>
      </app-dropdown>
    </div>
    <hr ref="hr">
    <div class="content">
      <app-button v-for="(route, index) in EnumMenu" :key="`app-menu-app-button-${index}`"
        @click="route.action($route); recordClick()"
        :class="{ 'active': $route.name.includes(route.name) }"
        v-show="route.name !== 'accounting' || (route.name === 'accounting' && customer && customer.customer.type === 'BUSINESS')">
        <span>
          <component :is="route.icon"></component>
        </span>
        <span>{{ route.label }}</span>
      </app-button>
    </div>
    <div class="bottom">
      <span class="menu-action" @click="toggleIsOpen">
        <SvgMenuOpened v-if="isOpen" />
        <SvgMenuClosed v-else />
      </span>
      <span class="logo" v-if="isOpen"><SvgLogoKVN /></span>
    </div>
  </div>
</template>

<script>
// Components
import AppDropdown from '../basics/components/AppDropdown.vue';
import AppButton from '../basics/components/AppButton.vue';

// Svg
import SvgMenuOpened from '../assets/img/icons/menu-opened.svg?inline';
import SvgMenuClosed from '../assets/img/icons/menu-closed.svg?inline';
import SvgLogoKVN from '../assets/img/logo-kvn-text.svg?inline';

// Enums
import EnumProfil from '../services/enums/profil.enum';
import EnumMenu from '../services/enums/menu.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'app-menu',
  components: {
    AppDropdown,
    AppButton,
    SvgMenuOpened,
    SvgMenuClosed,
    SvgLogoKVN,
  },
  props: {
    customer: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      EnumProfil,
      EnumMenu,
      EnumCustomerType,

      isOpen: true,
    };
  },
  mounted() {
    // eslint-disable-next-line
    this.$watch(() => this.$refs['app-menu-dropdown']['_data'].isOpen,
      (newValue) => {
        if (newValue) {
          const root = document.querySelector(':root');
          root.style.setProperty('--app-menu-app-dropdown-content-top', `${this.$refs.hr.offsetTop}px`);
        }
      });
  },
  watch: {
    isOpen(value) {
      this.$emit('is-open', value);
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    recordClick() {
      // eslint-disable-next-line
      this.$refs['app-menu-dropdown']['_data'].isOpen = false;
    },
  },
};
</script>

<style lang="sass">
// Etend root
\:root
  --app-menu-width-open: 253px
  --app-menu-width-close: 86px
  --app-menu-app-dropdown-content-top: 0

// Surcharge personnalisée des couleurs
body
  &[data-theme="individual"]
    .app-menu
      .top .app-dropdown .main p:first-child
        color: var(--color-blue)
      .content .app-button
        &:active, &.active
          color: var(--color-black) !important
          background-color: var(--color-50) !important
          svg
            path, circle
              fill: var(--color-blue) !important
      .bottom .menu-action svg circle:first-child
        fill: var(--color-100)

.app-menu
  position: relative
  width: var(--app-menu-width-open)
  min-height: 643px
  max-height: 643px
  height: 643px
  padding: $global-padding-horizontal
  border-radius: $global-border-radius * 2
  background-color: white
  box-shadow: 0px 4px 20px #EEEEEE
  transition: all ease-in-out 0.150s
  z-index: 1000
  // Top
  .top
    .app-dropdown
      height: inherit
      min-height: 38px
      .main
        height: inherit
        min-height: inherit
        p
          margin: 0
        p:first-child
          @include header5
          color: var(--color-100)
        p:last-child
          @include header3
        .arrow
          display: flex
          align-items: center
          svg path
            stroke: var(--color-black)
        > div
          height: inherit
          text-align: left
          transition: opacity ease-in-out 0.5s
      .content
        top: var(--app-menu-app-dropdown-content-top)
        padding: 0
        .app-button
          margin: 0
          padding: ($global-padding-vertical * 1.5) $global-padding-horizontal
          &:first-child
            border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0
          &:last-child
            border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
          &:not(:first-child):not(:last-child)
            &:hover
              border-radius: 0
          &.active:not(:first-child):not(:last-child)
            border-radius: 0
          &:active, &.active
            color: white
            background-color: var(--color-black)
            svg
              path, circle
                fill: white
  // Content
  > .content
    margin-top: 48px
  // Bottom
  .bottom
    position: absolute
    display: flex
    align-items: center
    bottom: 0 + $global-padding-horizontal
    left: 0 + $global-padding-horizontal
    right: 0 + $global-padding-horizontal
    .menu-action
      cursor: pointer
      svg
        circle:first-child
          fill: var(--color-70)
        circle:not(:first-child)
          stroke: white
        path
          stroke: white
    span.logo
      width: 64px
      margin-left: $global-padding-horizontal
  hr
    margin-top: $global-padding-horizontal
    margin-left: -$global-padding-horizontal
    margin-right: -$global-padding-horizontal
    height: 1px
    border: none
    background-color: var(--color-50)
  // Buttons
  .app-button
    @include header4
    width: 100%
    padding: $global-padding-vertical $global-padding-horizontal
    border-radius: 8px
    background-color: transparent
    ~ .app-button
      margin-top: 16px
    &:hover
      background-color: var(--color-30)
    &:active, &.active
      color: white
      background-color: var(--color-black)
      svg path
        fill: white
    span
      display: flex
      flex-direction: column
      line-height: initial
      transition: all ease-in-out 0.150s
    svg
      margin: 0 16px 0 0
      width: 22px
      path, circle
        stroke: transparent
        fill: var(--color-100)
  // Gestion du rétrécissement
  &.small
    width: 86px
    // Top
    .top
      min-height: 38px
      .app-dropdown
        min-height: inherit
        .main div:not(.arrow)
          flex-grow: 0
          height: 0
          opacity: 0
          transition: flex-grow ease-in-out 0.1s
        .main div.arrow
          margin: auto
          z-index: 10
        .content
          width: max-content
          top: - $global-padding-vertical - 4px
          left: 80px
          .app-button
            padding: ($global-padding-vertical * 1.5) ($global-padding-horizontal * 3) ($global-padding-vertical * 1.5) ($global-padding-vertical * 1.5)
    // Content
    > .content
        .app-button
          min-width: auto
          span:last-child
            opacity: 0
    // Bottom
    .bottom
      justify-content: center
      span
        margin: 0
</style>
