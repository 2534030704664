import AppMenu from './AppMenu.vue';
import AppRadioCustom from './AppRadioCustom.vue';
import AppNavigation from './AppNavigation.vue';
import AppInputPin from './AppInputPin.vue';
import AppFlap from './AppFlap.vue';
import AppFooter from './AppFooter.vue';
import AppTwoBlocks from './Layouts/AppTwoBlocks.vue';
import AppActionCard from './AppActionCard.vue';
import AppErrors from './AppErrors.vue';
import AppMobile from './AppMobile.vue';

const index = {
  'app-menu': AppMenu,
  'app-radio-custom': AppRadioCustom,
  'app-navigation': AppNavigation,
  'app-input-pin': AppInputPin,
  'app-flap': AppFlap,
  'app-footer': AppFooter,
  'app-two-blocks': AppTwoBlocks,
  'app-action-card': AppActionCard,
  'app-errors': AppErrors,
  'app-mobile': AppMobile,
};

export default index;
