<template>
  <div class="cards">
    <app-navigation
      v-if="$route.meta.hasNavigation"
      :type="$route.meta.navigationType"
      :routes="navigation"
    ></app-navigation>
    <router-view></router-view>
  </div>
</template>

<script>
// Enums
import EnumNavigationCard from '../services/enums/navigation/cards.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

// Api
import ApiSubscription from '../services/api/subscription';
import ApiPackages from '../services/api/package';

export default {
  name: 'cards',
  inject: ['$getCustomer'],
  provide() {
    return {
      $getPackage: () => this.packageComputed,
    };
  },
  data() {
    return {
      navigation: [],

      activeSubscription: null,
      package: null,

      EnumNavigationCard,
      EnumCustomerType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    packageComputed() {
      return this.package;
    },
  },
  watch: {
    customer() {
      this.getPackages();
    },
  },
  mounted() {
    this.navigation = this.cleanNavigation();
    this.getSubscription();
    this.getPackages();
  },
  methods: {
    cleanNavigation() {
      return Object.values(EnumNavigationCard).map((route) => route);
    },
    async getSubscription() {
      this.isLoading = true;
      try {
        const tmpSubscription = await ApiSubscription.getSubscription();
        // eslint-disable-next-line
        this.activeSubscription = tmpSubscription[0]; // [0] récupère le premier abonnement qui est celui actif
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async getPackages() {
      this.isLoading = true;
      try {
        const tmpPackages = await ApiPackages.getPackages(EnumCustomerType[this.customer.customer.type]);
        this.package = tmpPackages.find((pack) => pack.packageId === this.activeSubscription.packageId);
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.cards
  nav.app-navigation.links a
    padding-top: 0
</style>
