<template>
  <div class="edit-name">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Renommer carte</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <p class="description">Veuillez renseigner dans le champs ci-dessous le nom que vous souhaitez donner à votre carte.</p>

          <app-input
            label="Nom de la carte"
            type="text"
            v-model="nameComputed"
            ></app-input>

          <app-button :disabled="!nameComputed" class="submit" type="submit">Valider</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'edit-name',
  props: {
    cardId: {
      type: Number,
      required: false,
    },
    currentName: {
      type: String,
      required: false,
    },
    secretCode: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      name: '',

      isLoading: false,
    };
  },
  computed: {
    nameComputed: {
      get() { return this.currentName; },
      set(newValue) {
        this.name = newValue;
      },
    },
  },
  methods: {
    async sendData() {
      this.isLoading = true;

      try {
        await ApiCard.setCard(`${this.cardId}`, this.secretCode, null, null, null, null, null, null, this.name);
        this.$notification.show({
          title: 'Votre carte a bien été renommée.',
          type: EnumNotificationType.SUCCESS,
        });
        this.$emit('edited');
      } catch {
        this.$notification.show({
          title: 'Votre carte n\'a pas pu être renommée',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.edit-name
  text-align: center
  .app-flap
    .content
      p
        &.description
          @include header4
          color: var(--color-grey70)
          margin-bottom: $global-padding-vertical * 3
        &.info
          @include header5
          text-align: left
          font-weight: 300
      svg path
        fill: var(--color-cloud)
  form
    .app-input
      line-height: initial
      p.label
        margin: revert
        background-color: var(--color-cloud)
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
</style>
