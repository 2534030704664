<template>
  <div class="statement">
    <div class="title-container">
      <p class="title">Relevés bancaires</p>

      <app-select
        class="select"
        v-model="selectedYear"
        required
        :options="availableYears"
      >
      </app-select>
    </div>

    <div v-for="(statement, index) in currentStatements" :key="index" class="rib">
      <div class="main grid-x" @click="downloadStatement(statement.year, statement.month)">
        <div class="cell auto">
          <p>{{ new Date(`2000-${statement.month}-01`).toLocaleString('fr-FR', { month: 'long' }) | capitalize }} {{ statement.year }}</p>
        </div>

        <app-loader v-if="loadingYear === statement.year && loadingMonth === statement.month" class="loader-statement"></app-loader>
        <div v-else class="download shrink"><SvgDownload /></div>
      </div>
    </div>

    <p class="no-data" >
      <SvgEmptyStatementsIndividual v-if="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase()"/>
      <SvgEmptyStatementsBusiness v-else-if="$theme === EnumCustomerType.BUSINESS.toLowerCase()"/>
      <span>Aucune opération sur votre compte</span>
    </p>
  </div>
</template>

<script>
// API
import ApiWallet from '../../services/api/wallet';

// Svg
import SvgEmptyStatementsIndividual from '../../assets/img/individual/no-statements.svg?inline';
import SvgEmptyStatementsBusiness from '../../assets/img/business/no-statements.svg?inline';
import SvgDownload from '../../assets/img/icons/download.svg?inline.svg';

// Enums
import EnumCustomerType from '../../services/enums/customer/type.enum';
import EnumNotificationType from '../../services/enums/notification.enum';

export default {
  name: 'statement',
  inject: ['$getCustomer'],
  components: {
    SvgEmptyStatementsIndividual,
    SvgEmptyStatementsBusiness,
    SvgDownload,
  },
  data() {
    return {
      isLoading: false,

      availableStatements: [],
      selectedYear: null,
      availableYears: [],

      loadingYear: null,
      loadingMonth: null,

      EnumCustomerType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    currentStatements() {
      return this.availableStatements.filter((statement) => statement.year === this.selectedYear);
    },
  },
  mounted() {
    this.getAvailableStatements();
  },
  methods: {
    async getAvailableStatements() {
      try {
        const availableStatements = await ApiWallet.getWalletAvailableStatements();
        this.availableStatements = availableStatements;

        const tmpAvailableYears = availableStatements.map((availableStatement) => ({
          label: availableStatement.year,
          name: availableStatement.year,
        }));

        this.availableYears = [...new Map(tmpAvailableYears.map((item) => [item.label, item])).values()];
        this.selectedYear = this.availableYears[0].name;
      } catch {
        this.availableYears = [];
      }
    },
    async downloadStatement(year, month) {
      this.loadingYear = year;
      this.loadingMonth = month;

      try {
        const statement = await ApiWallet.getWalletStatement(year, month);
        if (statement && statement.url) window.open(statement.url, '_blank');
      } catch {
        this.$notification.show({
          title: 'Echec du téléchargement',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      } finally {
        this.loadingYear = null;
        this.loadingMonth = null;
      }
    },
  },
};
</script>

<style lang="sass">
.statement
  max-width: 484px
  .title-container
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    .title
      @include header2
    .select
      min-width: 144px
  .rib
    margin-top: $global-padding-vertical * 3.3
    border-radius: $global-border-radius * 2
    box-shadow: 0px 4px 20px #EEEEEE
    overflow: hidden
    .main
      padding: $global-padding-vertical ($global-padding-vertical * 2)
      @include header3
      align-items: center
      background-color: var(--color-cloud)
      cursor: pointer
      &:hover
        background-color: var(--color-50)
      .loader-statement
        width: 45px
        height: 45px
        svg
          width: 45px
          height: 45px
      .download
        display: flex
        width: 35px
        align-items: center
  .no-data
    @include no-data
</style>
