const status = Object.freeze({
  PENDING: {
    name: 'PENDING',
    label: 'En validation',
  },
  CANCELED: {
    name: 'CANCELED',
    label: 'Refusé',
  },
  VALIDATED: {
    name: 'VALIDATED',
    label: 'Validé',
  },
});

export default status;
