import SvgHome from '../../assets/img/icons/home.svg?inline';
import SvgTransfer from '../../assets/img/icons/transfer.svg?inline';
import SvgCard from '../../assets/img/icons/card.svg?inline';
import SvgCheck from '../../assets/img/icons/check.svg?inline';
import SvgAccounting from '../../assets/img/icons/accounting.svg?inline';
import SvgDocument from '../../assets/img/icons/document.svg?inline';

import router from '../../router';

const menu = Object.freeze({
  COMPTE: {
    label: 'Compte',
    icon: SvgHome,
    name: 'transactions',
    action: (currentRoute) => {
      if (currentRoute.path === '/transactions') return;
      router.push({ name: 'transactions' }).catch(() => {});
    },
  },
  VIREMENTS: {
    label: 'Virements',
    icon: SvgTransfer,
    name: 'payouts',
    action: (currentRoute) => {
      if (currentRoute.path === '/virements') return;
      router.push({ name: 'payouts' }).catch(() => {});
    },
  },
  CARTES: {
    label: 'Cartes',
    icon: SvgCard,
    name: 'cards',
    action: (currentRoute) => {
      if (currentRoute.path === '/cartes') return;
      router.push({ name: 'cards' }).catch(() => {});
    },
  },
  CHEQUES: {
    label: 'Chèques',
    icon: SvgCheck,
    name: 'checks',
    action: (currentRoute) => {
      if (currentRoute.path === '/cheques') return;
      router.push({ name: 'checks' }).catch(() => {});
    },
  },
  EXPORT_ACCOUNTING: {
    label: 'Comptabilité',
    icon: SvgAccounting,
    name: 'accounting',
    action: (currentRoute) => {
      if (currentRoute.path === '/comptabilite') return;
      router.push({ name: 'accounting' }).catch(() => {});
    },
  },
  DOCUMENTS: {
    label: 'Documents',
    icon: SvgDocument,
    name: 'documents',
    action: (currentRoute) => {
      if (currentRoute.path === '/documents') return;
      router.push({ name: 'documents' }).catch(() => {});
    },
  },
});

export default menu;
