<template>
  <div class="card-virtual">
    <div class="grid-x align-middle">
      <div class="cell auto"><p class="title">Cartes virtuelles</p></div>
      <div class="cell shrink container-buttons">
        <app-button v-if="Object.keys($route.query).length <= 0" class="activate"
          size="large"
          kind="primary"
          :class="{ 'active': this.isTheFlapOpenLimits }"
          @click="() => isTheFlapOpenLimits = true">
          Nouvelle carte virtuelle <SvgPlus/>
        </app-button>
      </div>
    </div>

    <app-loader v-if="isLoading || !cards"></app-loader>
    <template v-else>
      <List v-if="Object.keys($route.query).length <= 0"
        :cards="cardsComputed"
        @click="(card) => this.getVirtualCard(card.cardId)"
      />
      <Detail ref="virtual-detail" v-else
        kind="virtual"
        :card="selectedCard || {}"
        @destroy:card="selectedCard = null"
        @updated:card="(newValue) => { selectedCard = newValue; }"
      />
    </template>

    <EditLimits ref="detail-edit-limits"
      action="add"
      kind="virtual"
      :data="limitsComputed"
      :offer="packageComputed"
      :isOpen="isTheFlapOpenLimits"
      @onChange="(newData) => limitsComputed = newData"
      @is-open="(value) => isTheFlapOpenLimits = value"
      @click="() => { isTheFlapOpenLimits = false; getVirtualCards() }"
    />
  </div>
</template>

<script>
// Components
import List from './List.vue';
import Detail from '../common/Detail.vue';
import EditLimits from '../common/EditLimits.vue';

// Svg
import SvgPlus from '../../../assets/img/icons/plus.svg?inline';

// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumLockStatus from '../../../services/enums/cards/lockStatus';

export default {
  name: 'card-virtual',
  inject: ['$getPackage'],
  components: {
    List,
    Detail,
    EditLimits,

    SvgPlus,
  },
  data() {
    return {
      cards: null,
      selectedCard: null,
      limits: null,

      isLoading: false,

      isTheFlapOpenLimits: false,

      EnumLockStatus,
    };
  },
  computed: {
    packageComputed() {
      return this.$getPackage();
    },
    cardsComputed: {
      get() { return this.cards; },
      set(newValue) { this.cards = newValue; },
    },
    limitsComputed: {
      get() { return this.limits; },
      set(newValues) { this.limits = newValues; },
    },
  },
  watch: {
    $route() {
      if (this.$route.query.cardId) {
        this.isTheFlapOpenLimits = false;
      } else {
        // Rafraîchissement de la liste
        this.getVirtualCards();
      }
    },
    cardsComputed() {
      if (this.$route.query.cardId) {
        this.selectedCard = this.cards.data.find((card) => String(card.cardId) === this.$route.query.cardId);
      }
    },
    selectedCard(value) {
      if (value) {
        this.$router.replace({ name: this.$route.name, query: { cardId: value.cardId } }).catch(() => {});
      } else {
        this.$router.replace({ name: this.$route.name }).catch(() => {});
      }
    },
    isTheFlapOpenLimits(newValue) {
      if (!newValue) this.limitsComputed = null;
      this.isTheFlapOpenLimits = newValue;
    },
  },
  mounted() {
    if (this.$route.query.cardId) {
      this.cards = this.getVirtualCard(this.$route.query.cardId);
    } else {
      this.getVirtualCards();
    }
  },
  methods: {
    async getVirtualCard(cardId) {
      this.isLoading = true;
      try {
        const cards = await ApiCard.getCard(false, [EnumLockStatus.UNLOCK.name, EnumLockStatus.LOCK.name], cardId);
        [this.selectedCard] = cards.data;
        this.isLoading = false;
        return cards;
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    },
    async getVirtualCards() {
      this.isLoading = true;
      try {
        this.cards = await ApiCard.getCards(false, [EnumLockStatus.UNLOCK.name, EnumLockStatus.LOCK.name]);
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.card-virtual
  p
    margin: 0
  .title
    @include header2
  button.app-button.activate.active
    color: var(--color-black)
    background-color: var(--color-70) !important
    svg path
      fill: var(--color-black)
</style>
