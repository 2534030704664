<template>
  <div class="card-physical-list">
    <p class="no-data" v-if="cardsComputed.data.length <= 0">
      <SvgNoVirtualCardsIndividual v-if="customer && customer.customer.type === EnumCustomerType.INDIVIDUAL"/>
      <SvgNoVirtualCardsBusiness v-else/>
      <span>Aucune carte physique sur votre compte</span>
    </p>

    <div v-else
      :class="{ 'disabled': (EnumLockStatus[card.statusCode].name === EnumLockStatus.STOLEN.name) || (EnumLockStatus[card.statusCode].name === EnumLockStatus.LOST.name) }"
      class="record grid-x align-middle grid-margin-x"
      v-for="(card, index) in cardsComputed.data"
      :key="`cards-physical-list-month-${index}`"
      @click="$emit('click', card)"
    >
      <div class="cell medium-3">
        <p class="fullname">{{ fullNameComputed ? fullNameComputed.toUpperCase() : null }}</p>
        <p class="pan">{{ card.maskedPan }}</p>
      </div>
      <div class="cell medium-2">
        <p class="pan">{{ card.createdDate | formatDate }}</p>
      </div>
      <div class="cell auto">
        <!--
        <app-slider :value="card.totalPaymentMonth" :max="card.limitPaymentMonth" type="progress">
          <p>{{ card.totalPaymentMonth || 0 }}€ / {{ card.limitPaymentMonth }}€</p>
        </app-slider>
        -->
      </div>
      <div class="status cell medium-3 grid-x align-middle align-right" :class="getStatus(card).name.toLowerCase()">
        <span>{{ getStatus(card).label.toUpperCase() }}</span>
        <SvgArrowRight />
      </div>
    </div>
  </div>
</template>

<script>
// Enums
import EnumLockStatus from '../../../services/enums/cards/lockStatus';
import EnumCustomerType from '../../../services/enums/customer/type.enum';

// Svg
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';
import SvgNoVirtualCardsIndividual from '../../../assets/img/individual/no-virtual-cards.svg?inline';
import SvgNoVirtualCardsBusiness from '../../../assets/img/business/no-virtual-cards.svg?inline';

export default {
  name: 'card-physical-list',
  inject: ['$getCustomer'],
  components: {
    SvgArrowRight,
    SvgNoVirtualCardsIndividual,
    SvgNoVirtualCardsBusiness,
  },
  props: {
    cards: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,

      EnumCustomerType,
      EnumLockStatus,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    fullNameComputed() {
      if (!this.customer) return null;
      return this.customer.customer.type === EnumCustomerType.BUSINESS ? this.customer.customer.legalName : `${this.customer.customer.firstname} ${this.customer.customer.lastname}`;
    },
    cardsComputed() {
      return this.cards;
    },
  },
  methods: {
    getStatus(card) {
      let status;
      if (card.isLive === 0 || (EnumLockStatus[card.statusCode].name === EnumLockStatus.STOLEN.name)) {
        status = {
          name: 'DISABLED',
          label: (card.isLive === 0) ? 'Désactivée' : 'Supprimée',
        };
      } else if (card.isLive === 1 && (EnumLockStatus[card.statusCode].name === EnumLockStatus.UNLOCK.name)) {
        status = {
          name: 'ACTIVE',
          label: 'Active',
        };
      } else {
        status = EnumLockStatus[card.statusCode];
      }
      return status;
    },
  },
};
</script>

<style lang="sass">
.card-physical-list
  margin-top: $global-padding-vertical * 4
  .no-data
    @include no-data
  .record
    @include clickable-row
    padding: 20px ($global-padding-vertical * 2)
    &.disabled
      cursor: none
      pointer-events: none
    .fullname
      @include header5
    .pan
      @include text
    .app-slider
      .infos
        float: right
    .status
      @include header5
      &.active
        color: var(--color-success)
      &.disabled
        color: var(--color-warning)
      svg
        margin-left: $global-padding-vertical * 2
    &:hover, &:active, &.active
      .app-slider .slider
        background-color: var(--color-cloud)
</style>
