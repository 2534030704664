<template>
  <section class="dashboard" :class="{ 'account-not-validated': isAccountNotValidatedComputed }">
    <router-view></router-view>
  </section>
</template>

<script>
import yeldaChat from 'yelda-webchat';

import ApiCustomer from '../services/api/customer';

// Enum
import EnumCustomerReadyForReviewStatus from '../services/enums/customer/readyForReviewStatus.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'dashboard',
  provide() {
    return {
      /**
      * needsToBeUpdated: Boolean, demande si les informations de l'utilisateur doivent être remplacées par un nouvel appel api
      * newCustomerData: Object, récupère un objet avec les nouvelles données, évite un nouvel appel api
      */
      $getCustomer: (needsToBeUpdated, newCustomerData) => {
        if (needsToBeUpdated) this.getCustomer();
        if (newCustomerData) this.customer = newCustomerData;
        return this.customerComputed;
      },
      $isAccountNotValidated: () => this.isAccountNotValidatedComputed,
      $isSelfEmployedPerson: () => this.isSelfEmployedPersonComputed,
    };
  },
  data() {
    return {
      customer: null,

      EnumCustomerType,
    };
  },
  computed: {
    customerComputed() {
      return this.customer;
    },
    isAccountNotValidatedComputed() {
      if (this.customer) {
        return (
          (this.customer.customer.readyForReviewStatus !== EnumCustomerReadyForReviewStatus.VALIDATED)
          && (this.customer.treezorUser.kycReview !== 2)
        );
      }
      return false;
    },
    isSelfEmployedPersonComputed() {
      return (this.customer.customer.type === EnumCustomerType.BUSINESS) && this.customer.customer.isSelfEmployedPerson;
    },
  },
  watch: {
    isAccountNotValidatedComputed(value) {
      if (value && this.$route.name !== 'home') this.showAlert();
    },
    $route(to) {
      if (this.isAccountNotValidatedComputed && to.name !== 'home') {
        this.showAlert();
      }
    },
  },
  mounted() {
    this.$watch(() => this.$isFlapOpen,
      (newValue) => {
        if (newValue) {
          const yelda = document.getElementById('yelda_container');
          yelda.style.zIndex = 9999;
        }
      });

    this.getCustomer();

    this.insertYeldaScript();
  },
  beforeDestroy() {
    this.destroyAlerts();
    yeldaChat.unLoadChat();
  },
  methods: {
    async getCustomer() {
      try {
        this.customer = await ApiCustomer.getCustomer();
        this.$emit('update:customer', this.customerComputed);
      } catch (error) {
        throw error;
      }
    },
    showAlert() {
      if (document.querySelector('.app-alerts .app-alert')) return;
      let initialPayinTxt = `L'accès à votre compte ainsi qu'à certaines fonctionnalités sont limités.
      Veuillez nous transmettre les éléments manquants listés dans l'onglet "Limitations" pour valider votre inscription.
      ${this.isAccountNotValidatedComputed || !this.customer.customer.initialPayin ? 'Rappel : vous devez effectuer un virement initial d\'un montant minimum de 10€ depuis un compte à votre nom.' : ''}`;
      if (this.customer.customer.type === EnumCustomerType.BUSINESS) {
        initialPayinTxt = `L'accès à votre compte ainsi qu'à certaines fonctionnalités sont limités.
        Veuillez nous transmettre les éléments manquants listés dans l'onglet "Limitations" pour valider votre inscription notamment les éléments reconstituant la ligne capitalistique (Kbis de moins de 3 mois, statuts de la société...).
        ${this.isAccountNotValidatedComputed || !this.customer.customer.initialPayin ? 'Rappel : vous devez effectuer un virement initial d\'un montant minimum de 10€ depuis un compte au nom de la société.' : ''}`;
      }
      this.$alert.show({
        title: 'Accès limité',
        text: initialPayinTxt,
        isCancelDisplayed: false,
        onClick: () => this.$router.push({ name: 'limits' }),
      });
    },
    destroyAlerts() {
      this.$alert.cancel();
    },
    async insertYeldaScript() {
      await yeldaChat.init({
        settingId: process.env.VUE_APP_YELDA_ID,
        canBeClosed: true,
      });
    },
  },
};
</script>

<style lang="sass">
.dashboard
  padding: 0 ($global-padding-horizontal * 2) 0 ($global-padding-horizontal * 2)
  &.account-not-validated
    margin-top: $global-padding-horizontal
    .payouts, .beneficiaries, .cards .card-virtual, .checks, .operations
      .container-buttons, .search
        cursor: not-allowed
      button.app-button, .search label.app-input
        pointer-events: none
    .operations
      .export
        .app-select
          cursor: not-allowed
          .main
            pointer-events: none
</style>
