<template>
  <section id="app" :class="[{ 'menu-enabled': $route.meta.hasMenu, 'flap-open': $isFlapOpen }, $theme]">
    <app-menu v-if="$route.meta.hasMenu" @is-open="(value) => isMenuOpen = value" :customer="customerComputed"></app-menu>
    <SvgLogoKVN class="logo-kvn" v-if="$route.meta.hasMenu" />

    <app-navigation
      v-if="$route.meta.hasNavigation"
      :type="$route.meta.navigationType"
    ></app-navigation>

    <!-- Global basics components integration -->
    <app-alert/>

    <div class="content" :class="{ 'menu-close': !isMenuOpen }">
      <router-view @update:customer="(value) => customerComputed = value" />
    </div>

    <app-footer v-if="$route.meta.hasFooter"></app-footer>

    <!-- Global basics components integration -->
    <app-message/>
    <app-notifications/>

    <app-mobile v-if="isMobileComputed"/>
  </section>
</template>

<script>
// Enums
import EnumNavigationProfil from './services/enums/navigation/profil.enum';
import EnumNavigationPayouts from './services/enums/navigation/payouts.enum';
import EnumNavigationCards from './services/enums/navigation/cards.enum';
import EnumNavigationDocuments from './services/enums/navigation/documents.enum';

// Svg
import SvgLogoKVN from './assets/img/logo-kvn-img.svg?inline';

export default {
  name: 'app',
  components: {
    SvgLogoKVN,
  },
  data() {
    return {
      customer: null,

      isMenuOpen: true,

      EnumNavigationProfil,
      EnumNavigationPayouts,
      EnumNavigationCards,
      EnumNavigationDocuments,

      resizeObserver: null,
      clientWidth: null,
    };
  },
  watch: {
    $theme() {
      this.setDataThemeAttribute();
    },
  },
  computed: {
    customerComputed: {
      get() { return this.customer; },
      set(newValue) { this.customer = newValue; },
    },
    clientWidthComputed: {
      get() { return this.clientWidth; },
      set(newValue) { this.clientWidth = newValue; },
    },
    isMobileComputed() {
      return this.clientWidthComputed < 768;
    },
  },
  created() {
    this.resizeObserver = new ResizeObserver((element) => { this.clientWidth = element[0].target.clientWidth; });
    this.resizeObserver.observe(document.body);
  },
  mounted() {
    this.$watch(() => this.$isFlapOpen,
      (newValue) => {
        this.isFlapOpen = newValue;
      });

    this.setDataThemeAttribute();
    this.clientWidth = document.body.clientWidth;
  },
  destroyed() {
    this.resizeObserver.unobserve(document.body);
    this.resizeObserver = null;
  },
  methods: {
    setDataThemeAttribute() {
      const body = document.querySelector('body');
      body.setAttribute('data-theme', this.$theme);
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/sass/app.sass"
@import "./assets/css/colors.css"

// Etend root
\:root
  --app-logo-kvn: 40px

#app
  position: relative
  min-height: 100vh
  text-align: left
  color: var(--color-black)
  background-color: var(--color-30)
  .logo-kvn
    position: absolute
    right: 0
    width: var(--app-logo-kvn)
  // Gestion du container quand le menu est présent
  &.menu-enabled
    padding: $global-padding-horizontal * 2
    .logo-kvn
      right: $global-padding-horizontal * 2
    .app-menu
      position: fixed
    > .content
      position: relative
      margin-left: var(--app-menu-width-open)
      width: calc( 100% - var(--app-menu-width-open) - var(--app-logo-kvn))
      &.menu-close
        margin-left: var(--app-menu-width-close)
        width: calc( 100% - var(--app-menu-width-close) - var(--app-logo-kvn))
    > .app-alerts
      position: static
      padding: 0 0 ($global-padding-horizontal) ($global-padding-horizontal * 2)
      margin-left: var(--app-menu-width-open)
      margin-right: calc((#{$global-padding-horizontal} * 2) + var(--app-logo-kvn))
      bottom: initial
      width: -webkit-fill-available
      height: fit-content
    > .app-navigation
      margin: 0
      &::after
        border: none
  // gestion du contenu quand le volet est ouvert
  &.flap-open
    > .content
      padding-right: calc(var(--app-flap-width) - (#{$global-padding-horizontal}) * 2) !important
    .logo-kvn
      width: 0
    &.business
      > .content
        padding-left: 0

  .content
    transition: all ease-in-out 0.150s
  .logo
    text-align: center
    width: 64px
    img
      display: block
      margin: 2rem auto
      width: 100%
      max-width: 200px
</style>
