<template>
  <div class="profil-company">
    <p>Profil</p>
    <p>Les informations de votre entreprise.</p>

    <div class="data">
      <app-button @click="() => isTheFlapOpenData = true" :class="{ 'active': isTheFlapOpenData }">
        <div class="grid-x align-middle">
          <p class="cell auto">Informations entreprise</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>
    </div>

    <div class="contact">
      <app-button @click="() => isTheFlapOpenContact = true" :class="{ 'active': isTheFlapOpenContact }">
        <div class="grid-x align-middle">
          <p class="cell auto">Adresse de l'entreprise</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>
    </div>

    <div class="numbers">
      <app-button @click="() => isTheFlapOpenNumbers = true" :class="{ 'active': isTheFlapOpenNumbers }">
        <div class="grid-x align-middle">
          <p class="cell auto">Chiffres entreprise</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>
    </div>

    <div class="embargo">
      <app-button @click="() => isTheFlapOpenEmbargo = true" :class="{ 'active': isTheFlapOpenEmbargo }">
        <div class="grid-x align-middle">
          <p class="cell auto">Embargo entreprise</p>
          <div class="cell shrink arrow">
            <SvgArrowRight />
          </div>
        </div>
      </app-button>
    </div>

    <EditData ref="profil-company-edit-data" v-if="tmpCustomerComputed"
      :data="tmpCustomerComputed"
      @onChange="(newData) => tmpCustomerComputed = newData"
      :isOpen="isTheFlapOpenData"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenData = value"
    />
    <EditContact ref="profil-company-edit-contact" v-if="tmpCustomerComputed"
      :data="tmpCustomerComputed"
      @onChange="(newData) => tmpCustomerComputed = newData"
      :isOpen="isTheFlapOpenContact"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenContact = value"
    />
    <EditNumbers ref="profil-company-edit-numbers" v-if="tmpCustomerComputed"
      :data="tmpCustomerComputed"
      @onChange="(newData) => tmpCustomerComputed = newData"
      :isOpen="isTheFlapOpenNumbers"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenNumbers = value"
    />
    <EditEmbargo ref="profil-company-edit-embargo" v-if="tmpCustomerComputed"
      :data="tmpCustomerComputed"
      @onChange="(newData) => tmpCustomerComputed = newData"
      :isOpen="isTheFlapOpenEmbargo"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenEmbargo = value"
    />
  </div>
</template>

<script>
// Svg
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';

// Components
import EditData from './EditData.vue';
import EditNumbers from './EditNumbers.vue';
import EditEmbargo from './EditEmbargo.vue';
import EditContact from './EditContact.vue';

// Enums
import EnumNafCodes from '../../../../../commons/nafEmbargo.json';

export default {
  name: 'profil-company',
  inject: ['$getCustomer', '$isAccountNotValidated'],
  components: {
    SvgArrowRight,

    EditData,
    EditNumbers,
    EditEmbargo,
    EditContact,
  },
  data() {
    return {
      tmpCustomer: {},

      isTheFlapOpenData: false,
      isTheFlapOpenContact: false,
      isTheFlapOpenNumbers: false,
      isTheFlapOpenEmbargo: false,
    };
  },
  watch: {
    customer() {
      this.tmpCustomer = { ...this.customer };
      if (this.doesTurnoverOrNafCodeMatch()) {
        this.isTheFlapOpenData = false;
        this.isTheFlapOpenContact = false;
        this.isTheFlapOpenNumbers = false;
        this.isTheFlapOpenEmbargo = true;
      }
    },
    isTheFlapOpenData(newValue) {
      if (newValue) { this.isTheFlapOpenNumbers = false; this.isTheFlapOpenEmbargo = false; this.isTheFlapOpenContact = false; }
    },
    isTheFlapOpenContact(newValue) {
      if (newValue) { this.isTheFlapOpenNumbers = false; this.isTheFlapOpenData = false; this.isTheFlapOpenEmbargo = false; }
    },
    isTheFlapOpenNumbers(newValue) {
      if (newValue) { this.isTheFlapOpenData = false; this.isTheFlapOpenEmbargo = false; this.isTheFlapOpenContact = false; }
    },
    isTheFlapOpenEmbargo(newValue) {
      if (newValue) { this.isTheFlapOpenData = false; this.isTheFlapOpenNumbers = false; this.isTheFlapOpenContact = false; }
    },
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    isAccountNotValidated() {
      return this.$isAccountNotValidated();
    },
    areFieldsDisabledComputed() {
      return !this.isAccountNotValidated;
    },
    tmpCustomerComputed: {
      get() { return this.tmpCustomer; },
      set(data) { this.tmpCustomer = data; },
    },
  },
  mounted() {
    this.tmpCustomer = { ...this.customer };
  },
  methods: {
    doesTurnoverOrNafCodeMatch() {
      // CA > 1M€ || Code naf inclu dans la liste
      const ca = this.customer.customer.legalAnnualTurnOver.split('-');
      const doesTurnoverExceed1M = ca.some((number) => Number(number) >= 1000);
      const isNafCodeIncluded = EnumNafCodes.find((code) => code.name === this.customer.customer.legalSector);
      return doesTurnoverExceed1M || isNafCodeIncluded;
    },
  },
};
</script>

<style lang="sass">
.profil-company
  > p
    margin: 0
    @include text-bold
    &:first-child
      @include header2
      + p
        margin-bottom: ($global-padding-horizontal * 2)
    + :nth-child(2)
      @include text
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)
  div
    &.data, &.numbers, &.embargo, &.contact
      max-width: 382px
      background-color: var(--color-cloud)
      .app-button:hover, .app-button.active
        border-radius: 0
      > .app-button
        @include header3
        width: 100%
        border-radius: ($global-border-radius * 2)
        padding: ($global-padding-vertical * 2)
        background-color: var(--color-cloud)
        > div
          width: 100%
        div.arrow
          display: flex
          svg
            path
              stroke: var(--color-grey70)
        p
          text-align: left
        .icon
          margin-right: $global-padding-horizontal
          svg
            margin: 0
            path
              fill: var(--color-grey70)
              stroke: transparent
        &.active, &:hover
          background-color: var(--color-50)
    &.data, &.data .app-button:hover, &.data .app-button.active
      border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0
    &.embargo, &.embargo .app-button:hover, &.embargo > .app-button.active
      border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
    &.contact, &.numbers, &.embargo
      border: 1px solid var(--color-50)
      border-bottom: none
      border-left: none
      border-right: none
    p
      margin: 0

  form
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
    .errors
      display: flex
      svg
        max-width: 22px
        min-width: 22px
      ul
        padding-left: $global-padding-horizontal
        list-style: none
        color: var(--color-warning)
</style>
