<template>
  <div class="accounting">
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h2>Comptabilité</h2>

        <div v-if="initialized">
          <SvgExportAccounting />

          <p>Exportez vos données automatiquement chaque 1er du mois, votre comptable les reçois directement par mail pour faciliter votre gestion.</p>

          <div class="card trigger-is-export-container">
            <p>Activer l’export de données automatique</p>

            <app-switch size="small" class="cell medium-3" v-model="isExportAccounting"></app-switch>
          </div>

          <div v-show="isExportAccounting" class="card email-export-container">
            <p>À qui souhaitez-vous envoyer l'export ?</p>

            <app-input v-model="exportAccountingEmail" label="Adresse e-mail" />

            <app-button type="button" @click="confirmEmail">Valider</app-button>
          </div>
        </div>
        <app-loader v-else />
      </div>
    </div>
  </div>
</template>

<script>
import SvgExportAccounting from '@/assets/img/business/export-accounting.svg?inline';

import ApiCustomer from '../services/api/customer';

import EnumNotificationType from '../services/enums/notification.enum';

export default {
  name: 'accounting',
  inject: ['$getCustomer'],
  components: {
    SvgExportAccounting,
  },
  data() {
    return {
      initialized: false,

      isExportAccounting: false,
      exportAccountingEmail: '',
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
  },
  watch: {
    customer() {
      // Vérification si l'utilisateur est bien B2B
      if (this.customer.customer.type !== 'BUSINESS') {
        this.$router.replace({ name: 'transactions' }).catch(() => {});
      }

      // Initialisation des valeurs
      this.isExportAccounting = this.customer.customer.isExportAccounting;
      this.exportAccountingEmail = this.customer.customer.exportAccountingEmail;

      this.initialized = true;
    },
    isExportAccounting() {
      if (this.customer.customer.isExportAccounting !== this.isExportAccounting) {
        this.triggerExport();
      }
    },
  },
  methods: {
    async triggerExport() {
      try {
        await ApiCustomer.setAccounginExportSettings(this.isExportAccounting);
      } catch {
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
      }
    },
    async confirmEmail() {
      try {
        await ApiCustomer.setAccounginExportSettings(true, this.exportAccountingEmail);

        this.$notification.show({
          title: 'Demande prise en compte',
          text: `Votre export sera désormais envoyé à l'adresse mail "${this.exportAccountingEmail}"`,
          type: EnumNotificationType.SUCCESS,
        });
      } catch {
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.accounting
  max-width: 484px
  .header
    margin-bottom: 48px
    h2
      @include header2
    .card
      width: 100%
      border-radius: ($global-border-radius * 2)
      padding: $global-padding-horizontal ($global-padding-vertical * 2)
      background-color: var(--color-cloud)
      padding: ($global-padding-vertical * 2)
    .trigger-is-export-container
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      p
        @include header4
        color: var(--color-black)
      .app-switch
        width: auto
    .email-export-container
      display: flex
      flex-direction: column
      align-items: flex-end
      margin-top: ($global-padding-vertical * 2)
      p
        width: 100%
        @include header5
        color: var(--color-black)
        margin-bottom: ($global-padding-vertical * 2)
      .app-input
        width: 100%
        margin-bottom: ($global-padding-vertical * 2)
        .label
          width: auto
          background-color: var(--color-cloud)
      .app-button
        align-self: right
  p
    @include header3
    color: var(--color-grey70)
</style>
