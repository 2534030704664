<template>
  <div class="profil-company-edit-embargo">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Embargo entreprise</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x activity-outside-eu align-middle">
            <p class="cell small-10">Votre société a-t-elle une activité commerciale hors UE ? *</p>
            <div class="cell small-2"><SvgLock v-if="isDisabled"/></div>
            <app-radio-custom class="cell small-12" :values=EnumYesNoActivityOutsideEuComputed @value="(value) => activityOutsideEu = value" :value="activityOutsideEuComputed"></app-radio-custom>
          </div>

        <template v-if="(activityOutsideEuComputed && (activityOutsideEuComputed.name === 'true'))">
            <div class="grid-x economic-sanctions align-middle">
              <p class="cell small-10">Votre société, ses succursales, ses filiales, joint-ventures, ses dirigeants ou ses employés sont-ils visés par des mesures de Sanctions Économiques ? *</p>
              <div class="cell small-2"><SvgLock v-if="isDisabled"/></div>
              <app-radio-custom class="cell small-12" :values=EnumYesNo @value="(value) => economicSanctions = value" :value="economicSanctionsComputed"></app-radio-custom>
            </div>

            <div class="grid-x resident-countries-sanctions align-middle">
              <p class="cell small-10">Votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils physiquement présents dans, ou opèrent-ils depuis, des pays soumis à des sanctions étendues ? * </p>
              <div class="cell small-2"><SvgLock v-if="isDisabled"/></div>
              <app-radio-custom class="cell small-12" :values=EnumYesNo @value="(value) => residentCountriesSanctions = value" :value="residentCountriesSanctionsComputed"></app-radio-custom>
            </div>

            <div class="grid-x involved-sanctions align-middle">
              <p class="cell small-10">Après un examen minutieux et à votre connaissance, votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils impliqués dans des opérations, des investissements, des activités ou toutes autres transactions impliquant ou profitant directement ou indirectement à (i) des pays soumis à des sanctions étendues ou à (ii) toute personne ou entité visée par des Sanctions (« Personnes Sanctionnées ») ? *</p>
              <div class="cell small-2"><SvgLock v-if="isDisabled"/></div>
              <app-radio-custom class="cell small-12" :values=EnumYesNo @value="(value) => involvedSanctions = value" :value="involvedSanctionsComputed"></app-radio-custom>
            </div>

            <div class="errors" v-if="errors && errors.length > 0">
              <SvgWarning width="22" />
              <ul>
                <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                  {{ transformError(error) }}
                </li>
              </ul>
            </div>
          </template>

          <app-button class="submit" :disabled="isDisabled || !moreInformationRequired || !activityOutsideEuComputed || (activityOutsideEuComputed && activityOutsideEuComputed.name === 'true' && (!economicSanctionsComputed || !involvedSanctionsComputed || !residentCountriesSanctionsComputed))" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumYesNo from '../../../services/enums/yesNo.enum';
import EnumLegalAnnualTurnOverRanges from '../../../../../commons/legalAnnualTurnOverRanges.json';
import EnumNafCodes from '../../../../../commons/nafEmbargo.json';

// Components
import AppRadioCustom from '../../AppRadioCustom.vue';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-company-edit-embargo',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
    AppRadioCustom,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      activityOutsideEu: null,
      economicSanctions: null,
      residentCountriesSanctions: null,
      involvedSanctions: null,

      moreInformationRequired: false,

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumYesNo,
      EnumLegalAnnualTurnOverRanges,
      EnumNafCodes,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    EnumYesNoActivityOutsideEuComputed() {
      return Object.keys(EnumYesNo).map((key) => ({
        ...EnumYesNo[key],
        // eslint-disable-next-line
        disabled: (!this.isDisabled && this.moreInformationRequired) ? false : true,
      }));
    },
    activityOutsideEuComputed: {
      get() { return (this.moreInformationRequired ? this.activityOutsideEu : EnumYesNo.false) || EnumYesNo[this.data.customer.activityOutsideEu]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            activityOutsideEu: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    economicSanctionsComputed: {
      get() { return (this.economicSanctions ? this.economicSanctions : EnumYesNo.false) || EnumYesNo[this.data.customer.economicSanctions]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            economicSanctions: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    residentCountriesSanctionsComputed: {
      get() { return (this.residentCountriesSanctions ? this.residentCountriesSanctions : EnumYesNo.false) || EnumYesNo[this.data.customer?.residentCountriesSanctions]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            residentCountriesSanctions: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    involvedSanctionsComputed: {
      get() { return (this.involvedSanctions ? this.involvedSanctions : EnumYesNo.false) || EnumYesNo[this.data.customer?.involvedSanctions]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            involvedSanctions: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  watch: {
    customer() {
      this.moreInformationRequired = this.needMoreInformation();
    },
  },
  mounted() {
    if (this.data.customer && this.data.customer.legalAnnualTurnOver) this.moreInformationRequired = this.needMoreInformation();
  },
  methods: {
    needMoreInformation() {
      // CA > 1M€ || Code naf inclu dans la liste
      const ca = this.data.customer?.legalAnnualTurnOver.split('-');
      const doesTurnoverExceed1M = ca.some((number) => Number(number) >= 1000);
      const isNafCodeIncluded = EnumNafCodes.find((code) => code.name === this.data?.customer.legalSector);
      return doesTurnoverExceed1M || isNafCodeIncluded;
    },
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        activityOutsideEu: false,
        economicSanctions: false,
        residentCountriesSanctions: false,
        involvedSanctions: false,
      };

      if (this.moreInformationRequired) {
        tmpUser.activityOutsideEu = (this.activityOutsideEuComputed.name === 'true');
        tmpUser.economicSanctions = (this.economicSanctionsComputed.name === 'true');
        tmpUser.residentCountriesSanctions = (this.residentCountriesSanctionsComputed.name === 'true');
        tmpUser.involvedSanctions = (this.involvedSanctionsComputed.name === 'true');
      }

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-company-edit-embargo
  .app-input
    margin: 0
  .app-input, .app-select
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3
  .legal-registration-number, .legal-name, .legal-form, .legal-sector, .legal-tva-number
    width: 100%

  .content .grid-x
    svg
      display: block
      margin: auto 0 auto auto
      path
        &:first-child
          stroke-width: 1
          stroke: var(--color-grey70)
        &:last-child
          fill: var(--color-cloud)
          stroke: var(--color-grey70)
    p
      @include header4
    .app-radio-custom
      margin-top: $global-padding-vertical * 2 !important
</style>
