<template>
  <div class="state-ask">
    <component v-if="step === 1" :is="RegisterIntro"
      @click="(value) => type = value"
    ></component>
    <component v-if="step === 2" :is="RegisterOffer"
      :data="packages"
      @click="(value) => packageId = value"
    ></component>
    <component v-if="step === 3" :is="RegisterEmail"
      :email="email"
      :hasEmailSent="hasEmailSent"
      @onChange="(value) => email = value"
      @click="onClick"
      :isLoading="isLoading"
      :errors="errors"
    ></component>
  </div>
</template>

<script>
// Api
import ls from 'local-storage';
import ApiPackage from '../../../services/api/package';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';

import RegisterIntro from '../Introduction/Register.vue';
import RegisterOffer from '../Introduction/Offer.vue';
import RegisterEmail from '../Introduction/Email.vue';

export default {
  name: 'state-ask',
  data() {
    return {
      step: 1,
      packages: [],
      hasEmailSent: false,

      type: null,
      packageId: null,
      email: '',

      isLoading: false,
      errors: [],

      RegisterIntro,
      RegisterOffer,
      RegisterEmail,
    };
  },
  watch: {
    step(value) {
      if (value === 2) {
        this.getPackages();
      }
    },
    type(newValue) {
      this.type = newValue;
      // Sauvegarde du thème en fonction du type sélectionné
      this.$theme = EnumCustomerType[this.type].toLowerCase();
      ls('theme', this.$theme);
      this.step += 1;
    },
    packageId(newValue) {
      this.packageId = newValue;
      this.step += 1;
    },
  },
  methods: {
    async getPackages() {
      this.isLoading = true;
      try {
        this.packages = await ApiPackage.getPackages(this.$theme.toUpperCase());
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async onClick() {
      this.isLoading = true;
      this.errors = [];

      try {
        await this.$auth.register(this.type, this.packageId, this.email);
        this.hasEmailSent = true;
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'L\'inscription a échouée',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.state-ask
</style>
