<template>
  <div class="profil-profil-edit-fiscal-residence">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p><span>{{ data.customer.lastname }}</span> <span>{{ data.customer.firstname }}</span></p>
        <p>Résidence fiscale</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x is-french-fiscal-resident align-middle">
            <p class="cell auto">Je certifie que je suis uniquement un résident fiscal français *</p>
            <SvgLock v-if="isDisabled" class="cell shrink" />
            <div v-else class="cell small-offset-1"></div>
            <app-radio-custom :isDisabled="isDisabled" class="cell small-12" :values=EnumYesNo @value="(value) => isFrenchFiscalResidentComputed = value" :value="isFrenchFiscalResidentComputed"></app-radio-custom>
          </div>

          <div class="grid-x specified-us-person align-middle">
            <p class="cell auto">Je certifie que je ne suis pas une US Person *</p>
            <SvgLock v-if="isDisabled" class="cell shrink" />
            <div v-else class="cell small-offset-1"></div>
            <app-radio-custom :isDisabled="isDisabled" class="cell small-12" :values=EnumYesNo @value="(value) => specifiedUsPersonComputed = value" :value="specifiedUsPersonComputed"></app-radio-custom>
          </div>

          <template v-if="(isFrenchFiscalResidentComputed && specifiedUsPersonComputed) && (isFrenchFiscalResidentComputed.name === 'false' || specifiedUsPersonComputed.name === 'false')">
            <app-select
              class="cell small-12 tax-residences-country"
              v-model="countryComputed"
              label="Pays de résidence fiscale *"
              required
              :options="EnumCountries">
            </app-select>

            <app-input
              class="cell small-12 tax-residences-taxPayerId"
              label="Numéro d’identification fiscale *"
              type="text"
              required
              v-model="taxPayerIdComputed"
            ></app-input>
          </template>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !isFrenchFiscalResidentComputed || !specifiedUsPersonComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Components

// Enums
import EnumCountries from '../../../../../commons/countries.json';
import EnumYesNo from '../../../services/enums/yesNo.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';

// Api
import ApiTaxResidences from '../../../services/api/taxResidences';
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-profil-edit-fiscal-residence',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      EnumCustomerType,
      EnumNotificationType,
      EnumCountries: this.$utils.sortAlphabetically(EnumCountries),
      EnumYesNo,

      taxResidences: {
        country: null,
        taxPayerId: null,
      },

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    isFrenchFiscalResidentComputed: {
      get() { return EnumYesNo[this.data.customer.isFrenchFiscalResident]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            isFrenchFiscalResident: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    specifiedUsPersonComputed: {
      get() { return EnumYesNo[!this.data.customer.specifiedUsPerson]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            specifiedUsPerson: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    countryComputed: {
      get() { return this.taxResidences.country || EnumCountries[this.taxResidences.country]; },
      set(newValue) { this.taxResidences.country = newValue; },
    },
    taxPayerIdComputed: {
      get() { return this.taxResidences.taxPayerId || this.data.customer.taxPayerId; },
      set(newValue) { this.taxResidences.taxPayerId = newValue; },
    },
  },
  watch: {
    customer() {
      this.getTaxResidences();
    },
  },
  mounted() {
    this.getTaxResidences();
  },
  methods: {
    async getTaxResidences() {
      try {
        this.taxResidences = await ApiTaxResidences.getTaxResidences();
      } catch (error) {
        //
      }
    },
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        isFrenchFiscalResident: (this.isFrenchFiscalResidentComputed.name === 'true'),
        specifiedUsPerson: (this.specifiedUsPersonComputed.name === 'true'),
      };

      try {
        // Modification des informations de la tax residences
        if ((!tmpUser.isFrenchFiscalResident || !tmpUser.specifiedUsPerson) && (!this.countryComputed || !this.taxPayerIdComputed)) {
          if (!this.countryComputed && !this.taxPayerIdComputed) {
            this.errors.push('country must be a valid enum value', 'taxPayerId must be a string');
          } else if (!this.countryComputed) {
            this.errors.push('country must be a valid enum value');
          } else if (!this.taxPayerIdComputed) {
            this.errors.push('taxPayerId must be a string');
          }

          throw new Error();
        } else if ((!tmpUser.isFrenchFiscalResident || !tmpUser.specifiedUsPerson) && (this.countryComputed && this.taxPayerIdComputed)) {
          // Si on est en création
          if (!this.taxResidences.id) {
            await ApiTaxResidences.createTaxResidences(this.countryComputed, this.taxPayerIdComputed);
          } else {
            this.taxResidences = await ApiTaxResidences.setTaxResidence(this.taxResidences.id, this.countryComputed, this.taxPayerIdComputed);
          }
        } else if (tmpUser.isFrenchFiscalResident && tmpUser.specifiedUsPerson && (this.taxResidences.country && this.taxResidences.taxPayerId)) {
          await ApiTaxResidences.deleteTaxResidence(this.taxResidences.id);
        }

        // Modification des informations de l'utilisateur
        /* eslint-disable */
        tmpUser.specifiedUsPerson = !tmpUser.specifiedUsPerson;
        // Cas customer INDIVIDUAL
        if (this.customer.customer.type === EnumCustomerType.INDIVIDUAL) {
          await ApiCustomer.setCustomer(tmpUser);
        } else { // Cas customer BUSINESS
          // S'il y a un customerId il faut vérifier à quel type de customer on a à faire,
          // car la route de modification ne sera pas pas identique.
          if (this.data.customer.parentType === EnumParentType.LEADER.name) {
            await ApiCustomer.setLeader(tmpUser);
          } else if (this.data.customer.parentType === EnumParentType.SHAREHOLDER.name) {
            await ApiCustomer.setAssociate(this.data.customer.customerId, tmpUser);
          }
        }

        /* eslint-enable */
        this.$getCustomer(true);
      } catch (error) {
        if (error.response?.data.message && error.response?.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'country must be a valid enum value':
          newErrorMessage = 'Le pays de résidence fiscale doit-être renseigné.';
          break;
        case 'taxPayerId must be a string':
          newErrorMessage = 'Le numéro d\'identification fiscale doit-être renseigné.';
          break;
        case 'country must be a string':
          break;
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
    manageRadioCustom(value) {
      const tmp = { ...value };
      tmp.active = true;
      this.appRadioCustom = tmp;
    },
  },
};
</script>

<style lang="sass">
.profil-profil-edit-fiscal-residence
  .app-input
    margin: 0
  .app-input, .app-select
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3

  .app-flap
    .main
      p:first-child
        @include header4
        margin-bottom: 0
        color: var(--color-100)
      p:last-child
        margin-top: 0
        @include header2
    .content
      .is-french-fiscal-resident, .specified-us-person
        p
          @include header4
      .is-french-fiscal-resident, .specified-us-person
        svg
          margin-left: $global-padding-vertical
          path
            &:first-child
              stroke-width: 1
              stroke: var(--color-grey70)
            &:last-child
              fill: var(--color-cloud)
              stroke: var(--color-grey70)
      .tax-residences-country, .tax-residences-taxPayerId
        margin-top: $global-padding-vertical * 3
</style>
