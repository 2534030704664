<template>
  <div class="security-edit-secretcode">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Changer de code de <br> sécurité</p>
        <p class="step">{{ (currentStep + 1) }} / {{ totalSteps }}</p>
      </template>
      <template v-slot:app-flap-content>
        <p>Nombre de demandes autorisées: 5</p>
        <div class="errors" v-if="errors && errors.length > 0">
          <SvgWarning width="22" />
          <ul>
            <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
              {{ transformError(error) }}
            </li>
          </ul>
        </div>

        <template v-if="currentStep === 0">
          <div class="wrapper">
            <p class="old">Ancien code</p>
            <app-input-pin ref="security-edit-secretcode-app-input-pin" :isVisible="false" v-model="oldSecretCode"></app-input-pin>
            <p class="new">Nouveau code</p>
            <app-input-pin ref="security-edit-secretcode-confirm-app-input-pin" :isVisible="false" v-model="newSecretCode"></app-input-pin>
          </div>

          <app-button
            :disabled="isLoading || (!oldSecretCode || !newSecretCode)"
            size="small"
            kind="primary"
            @click="$emit('click:step-1')"
          >
            <span v-if="!isLoading">Suivant</span>
            <app-spinner v-else :small="true"></app-spinner>
          </app-button>
        </template>

        <template v-else>
          <p>Veuillez consulter votre boîte e-mail un code vient de vous être envoyé afin de sécuriser votre démarche.</p>
          <app-input
            label="Chaîne de caractères"
            type="password"
            v-model="doubleAuthent"
          ></app-input>
          <app-button
            :disabled="isLoading || !doubleAuthent"
            size="small"
            kind="primary"
            @click="$emit('click:step-2', sendData())"
          >
            <span v-if="!isLoading">Enregistrer les modifications</span>
            <app-spinner v-else :small="true"></app-spinner>
          </app-button>
        </template>
      </template>
    </app-flap>
  </div>
</template>

<script>
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';

export default {
  name: 'security-edit-secretcode',
  components: {
    SvgWarning,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      oldSecretCode: null,
      newSecretCode: null,
      doubleAuthent: null,

      totalSteps: 2,
    };
  },
  watch: {
    oldSecretCode() {
      if (this.oldSecretCode.length >= 4) {
        this.$refs['security-edit-secretcode-confirm-app-input-pin'].$refs['pin-1'][0].focus();
      }
    },
  },
  updated() {
    if (this.$refs['security-edit-secretcode-app-input-pin']) {
      this.$refs['security-edit-secretcode-app-input-pin'].$refs['pin-1'][0].focus();
    }
  },
  methods: {
    sendData() {
      // eslint-disable-next-line
      const { oldSecretCode, newSecretCode, doubleAuthent } = {...this['_data']};
      return { oldSecretCode, newSecretCode, doubleAuthent };
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Secret code modification is blocked, contact customer service':
          newErrorMessage = 'Trop de tentatives ont été effectuées, la modification du code secret est bloquée. Veuillez contacter le support client.';
          break;
        case 'Secret code not valid !':
          newErrorMessage = 'Ancien code non valide. Nous vous invitons à faire un nouvel essai.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.security-edit-secretcode
  .app-flap
    .main
      p.step
        @include text
    .content
      > p:first-child
        @include text-bold
        text-align: center
        margin-bottom: $global-padding-horizontal
      p
        @include text
        // margin: 0
        font-size: 11px
        &.new
          margin-top: ($global-padding-vertical * 3)
      .wrapper
        margin: auto
        width: 272px
        max-width: 272px
      .app-input
        p
          margin: revert
          background-color: var(--color-cloud)

  .app-button
    display: flex
    margin-top: $global-padding-horizontal * 4
    width: 100%
    justify-content: center
  .errors
    display: flex
    svg
      min-width: 22px
      max-width: 22px
      width: 22px
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
