<template>
  <div class="checks">
    <CheckAdd v-if="addingNew" @click:close="addingNew = false"/>
    <template v-else>
      <ChecksList
        :getCheckStatus="getCheckStatus"
        :selectedCheck="selectedCheck"
        @click:check="selectedCheck = $event"
        @click:new="addingNew = true"
      />
      <CheckDetails :selectedCheck="selectedCheck" @click:close="selectedCheck = null" :getCheckStatus="getCheckStatus"/>
    </template>
  </div>
</template>

<script>
import ChecksList from '@/components/Checks/List.vue';
import CheckDetails from '@/components/Checks/Details.vue';
import CheckAdd from '@/components/Checks/Add.vue';
import CheckStatus from '@/services/enums/checks/check-status.enum';

export default {
  name: 'checks',
  components: {
    ChecksList,
    CheckDetails,
    CheckAdd,
  },
  data() {
    return {
      selectedCheck: null,
      addingNew: false,
    };
  },
  methods: {
    getCheckStatus(check) {
      switch (check.codeStatus) {
        case '151124': return CheckStatus.SHIPPING; // The cheque can now be sent to the treatement center
        case '151132': return CheckStatus.PENDING; // The check has been received by the treatment center of our partner
        case '140001': return CheckStatus.PENDING; // Payment is pending, request is being processed by our partner
        case '151130': return CheckStatus.PENDING; // Payment is in the 11 days delay period, to prevent bounced cheques.
        case '151134': return CheckStatus.SHIPPING_FAILED; // The check has not been received by the treatement center in the allowed delay of 15 calendar days
        case '151119': return CheckStatus.FAIL; // A technical error happened while attempting to create the payment in our partner system
        case '151120': return CheckStatus.FAIL; // The CMC7 Line of the cheque is not valid
        case '151121': return CheckStatus.FAIL; // The RLMC Key of the cheque is not valid
        case '151122': return CheckStatus.REFUSED; // This payment has already been treated
        case '151123': return CheckStatus.FAIL; // The partner response is incorrect
        case '151125': return CheckStatus.FAIL; // The controls of the cheque have failed
        case '151126': return CheckStatus.REFUSED; // The payment has been invalidated by our partner
        case '151127': return CheckStatus.REFUSED; // Cheque cannot be treated, the amount is higher than specified on the Payin
        case '151128': return CheckStatus.REFUSED; // Cheque cannot be treated, the amount is lower than specified on the Payin
        case '151131': return CheckStatus.REFUSED; // Payin has been canceled due to default
        case '151133': return CheckStatus.REFUSED; // Default received on payin check during the delaying phase
        case '151129': return CheckStatus.VALIDATED; // Cheque has been successfully treated. Waiting for bank settlement
        case '140005': return CheckStatus.CASHED; // Funds received by Treezor
        default: return CheckStatus.PENDING;
      }
    },
  },
};
</script>

<style lang="sass">
.checks
  .check-list-wrapper
    margin: auto
</style>
