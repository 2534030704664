<template>
  <div class="app-progress grid-x">
    <template v-for="(step, index) in data">
      <div class="part cell shrink" :key="`app-progress-part-${index}`" :class="step.status">
        <div class="icon">
          <component :is="step.icon"></component>
        </div>
        <span class="part-title">{{ step.label }}</span>
      </div>
      <div class="cell auto line" :key="`app-progress-line-${index}`" v-if="index < (data.length - 1)" :class="step.status"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: [Array],
  },
};
</script>

<style lang="sass">
.app-progress
  display: flex
  align-items: center
  width: 100%
  .part
    display: flex
    flex-direction: column
    align-items: center
    z-index: 1
    &.valid
      .icon
        background-color: var(--color-success)
    &.pending
      .icon
        background-color: var(--color-100)
      .part-title
        color: var(--color-100)
    &.active .icon
      background-color: var(--color-grey90)
    .icon
      display: flex
      height: 38px
      width: 38px
      align-items: center
      justify-content: center
      border-radius: $global-border-radius * 5
      svg
        path, circle
          stroke: var(--color-cloud)
    span
      @include header5
      margin-top: $global-padding-vertical
      max-width: 72px
      color: var(--color-grey90)
      text-align: center
  .line
    align-self: flex-start
    margin-top: calc(38px / 2)
    margin-left: calc(-38px / 2)
    margin-right: calc(-38px / 2)
    height: 2px
    width: 100%
    background-color: var(--color-success)
    z-index: 0
    &.valid
      background-color: var(--color-success)
    &.pending
      background-color: var(--color-100)
    &.active
      background-color: var(--color-grey90)
</style>
