<template>
  <div class="forgot-password">
    <app-two-blocks>
      <template v-slot:main>
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
          <div class="grid-y">
            <p class="title">Mot de passe oublié</p>
            <p class="subtitle">Renseignez votre email, nous vous enverrons un lien pour réinitialiser votre mot de passe</p>

            <form ref="forgotPassword" @submit.prevent="manageClick">
              <app-input autocomplete="email"
                type="email"
                label="Adresse e-mail"
                required
                v-model="user.email"
              />
              <app-button :disabled="!user.email || isLoading" type="submit">Valider</app-button>
            </form>

            <router-link :to="{ name: 'login' }">Se connecter</router-link>
            <span>Pas encore de compte ? <router-link :to="{ name: 'register' }">S'inscrire</router-link></span>
          </div>
        </template>
      </template>
      <template v-slot:animation>
        <app-lottie
          :dataAppearance="ForgotPasswordAppeareance"
          :dataLoop="ForgotPasswordLoop"
        ></app-lottie>
      </template>
    </app-two-blocks>
  </div>
</template>

<script>
// Components
import LoginIdentifiers from '../components/Login/Identifiers.vue';
import LoginSecretCode from '../components/Login/SecretCode.vue';
import AppLottie from '../components/AppLottie.vue';

// Enums
import EnumNotificationType from '../services/enums/notification.enum';

// Animations
import ForgotPasswordAppeareance from '../assets/animations/individual/forgot-password-appearance.json';
import ForgotPasswordLoop from '../assets/animations/individual/forgot-password-loop.json';

export default {
  name: 'forgot-password',
  components: {
    AppLottie,
  },
  data() {
    return {
      user: {
        email: '',
      },
      isLoading: false,

      // Components
      LoginIdentifiers,
      LoginSecretCode,

      ForgotPasswordAppeareance,
      ForgotPasswordLoop,
    };
  },
  methods: {
    async manageClick() {
      this.isLoading = true;

      if (this.$refs.forgotPassword.checkValidity()) {
        try {
          await this.$auth.forgotPassword(this.user.email);
          this.$notification.show({
            title: 'Un e-mail vient de vous être envoyé',
            text: 'Consultez vos e-mails et suivez la démarche renseignée pour réinitialiser votre mot de passe',
            type: EnumNotificationType.SUCCESS,
          });
        } catch (error) {
          this.$notification.show({
            title: 'Echec de l\'envoi du mail',
            text: 'Nous vous invitons à vérifier votre adresse e-mail.',
            type: EnumNotificationType.ECHEC,
          });
        }
        this.isLoading = false;
      } else {
        this.$refs.forgotPassword.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.forgot-password
  > div
    .content
      p:not(.label)
        margin: 0
      .title
        @include header1
      .subtitle
        @include header4
        color: var(--color-grey70)
    .app-input
      margin-top: $global-padding-horizontal * 2
    .app-button
      margin-top: $global-padding-vertical * 6
      margin-bottom: $global-padding-horizontal * 2
      align-self: flex-start
    a
      @include link
    span
      @include link
      color: var(--color-grey70)
      text-decoration: none
  .illustration
    &::after
      background-image: url('../assets/img/individual/background-register.svg')
</style>
