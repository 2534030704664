import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient les soldes du client
 *
 * @returns
 */
const getBalances = async () => {
  try {
    const response = await axios.get(`${API_URL}/balances`);
    return response.data.data[0];
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getBalances = getBalances;

export default api;
