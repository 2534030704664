<template>
  <div class="reset-password">
    <app-two-blocks>
      <template v-slot:main>
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
          <div class="grid-y">
            <p class="title">Réinitialiser mon mot de passe</p>
            <p class="subtitle">Choisissez votre nouveau mot de passe. Vous pourrez vous connecter à votre compte.</p>

            <app-input
              type="password"
              label="Nouveau mot de passe *"
              required
              v-model="user.password"
            />
            <app-input
              type="password"
              label="Répéter le nouveau mot de passe *"
              required
              v-model="user.passwordConfirm"
            />
            <div class="errors" v-if="errors && errors.length > 0">
              <SvgWarning width="22" />
              <ul>
                <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                  {{ transformError(error) }}
                </li>
              </ul>
            </div>

            <app-button :disabled="(!user.password || !user.passwordConfirm) || isLoading" @click="manageClick">Valider mon nouveau mot de passe</app-button>
          </div>
        </template>
      </template>
      <template v-slot:animation>
        <app-lottie
          :dataAppearance="ForgotPasswordAppeareance"
          :dataLoop="ForgotPasswordLoop"
        ></app-lottie>
      </template>
    </app-two-blocks>
  </div>
</template>

<script>
// Components
import LoginIdentifiers from '../components/Login/Identifiers.vue';
import LoginSecretCode from '../components/Login/SecretCode.vue';
import AppLottie from '../components/AppLottie.vue';

// Enums
import EnumNotificationType from '../services/enums/notification.enum';

// Animations
import ForgotPasswordAppeareance from '../assets/animations/individual/forgot-password-appearance.json';
import ForgotPasswordLoop from '../assets/animations/individual/forgot-password-loop.json';

// Svg
import SvgWarning from '../assets/img/icons/warning.svg?inline';

export default {
  name: 'reset-password',
  components: {
    SvgWarning,
    AppLottie,
  },
  data() {
    return {
      user: {
        password: '',
        passwordConfirm: '',
      },
      errors: [],
      isLoading: false,

      // Components
      LoginIdentifiers,
      LoginSecretCode,

      ForgotPasswordAppeareance,
      ForgotPasswordLoop,
    };
  },
  methods: {
    async manageClick() {
      this.resetErrors();
      this.isLoading = true;
      try {
        await this.$auth.resetPassword(this.$route.query.token, this.user.password, this.user.passwordConfirm);
        this.$notification.show({
          title: 'Mot de passe modifié avec succès !',
          text: 'Vous allez être redirigé vers l\'écran de connexion dans 3 secondes.',
          type: EnumNotificationType.SUCCESS,
        });
        setTimeout(() => {
          this.$router.push({ name: 'login' });
        }, 3000);
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la réinitialisation',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    resetErrors() {
      this.errors = [];
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'password must be longer than or equal to 8 characters':
          newErrorMessage = 'Le mot de passe doit contenir au moins 8 caractères.';
          break;
        case 'passwordConfirm must be longer than or equal to 8 characters':
          newErrorMessage = 'La confirmation du mot de passe doit contenir au moins 8 caractères.';
          break;
        case 'Password and PasswordConfirm must be the same':
          newErrorMessage = 'Les deux mots de passe doivent être identiques.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.reset-password
  > div
    .content
      p:not(.label)
        margin: 0
      .title
        @include header1
      .subtitle
        @include header4
        color: var(--color-grey70)
    .app-input
      margin-top: $global-padding-horizontal * 2
    .app-button
      margin-top: $global-padding-vertical * 6
      margin-bottom: $global-padding-horizontal * 2
      align-self: flex-start
  .illustration
    &::after
      background-image: url('../assets/img/individual/background-register.svg')
  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
