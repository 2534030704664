<template>
  <div class="payouts-import">
    <div class="container-go-back" @click="goBackToList">
      <SvgArrow />
      <p>Retour</p>
    </div>

    <p class="title">Importer des virements</p>

    <app-upload v-model="selectedDocument" accept="text/csv, text/plain" />

    <app-button class="confirm-button" @click="triggerSecretCodeFlap" size="large" kind="primary">
      Valider
    </app-button>

    <SecretCode
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => isTheFlapOpenSecretCode = value"
      @click="importPayouts"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
// Api
import ApiPayout from '../../services/api/payout';

// SVG
import SvgArrow from '../../assets/img/icons/arrow.svg?inline';

// Components
import SecretCode from '../Common/AppSecretCode.vue';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';

export default {
  name: 'payouts-import',
  components: {
    SvgArrow,
    SecretCode,
  },
  props: {
    goBackToList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedDocument: null,
      secretCode: [],
      isLoading: false,

      isTheFlapOpenSecretCode: false,

      EnumNotificationType,
    };
  },
  methods: {
    triggerSecretCodeFlap() {
      this.isTheFlapOpenSecretCode = true;
    },
    async importPayouts() {
      if (!this.selectedDocument || (this.selectedDocument && this.selectedDocument[0] && this.selectedDocument[0].type !== 'text/plain' && this.selectedDocument[0].type !== 'text/csv')) {
        this.$notification.show({
          title: 'Formulaire incomplet',
          text: 'Merci de bien vouloir ajouter un document (type CSV SEPA attendu)',
          type: EnumNotificationType.ECHEC,
        });
        return;
      }

      this.isLoading = true;

      try {
        await ApiPayout.importPayouts({
          document: this.selectedDocument[0],
          secretCode: this.secretCode.join(''),
        });

        // Virement réussi
        // On ferme tous les flaps
        this.isTheFlapOpenSecretCode = false;

        this.$notification.show({
          title: 'Import effectué !',
          text: '',
          type: EnumNotificationType.SUCCESS,
        });

        // On retourne sur la liste des virements
        this.goBackToList();
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          this.$notification.show({
            title: 'Echec de l\'import des virements',
            text: 'Nous vous invitons à réessayer ou contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="sass">
.payouts-import
  max-width: 484px
  .container-go-back
    display: flex
    flex-direction: row
    cursor: pointer
    align-items: center
    transition: all ease-in-out 0.150s
    p
      @include text
      margin-left: 8px
      text-decoration: underline
      color: var(--color-black)
    svg
      transform: rotate(180deg)
      path
        stroke: var(--color-black)
  .title
    @include header2
    margin-bottom: 16px
  .confirm-button
    margin-top: 16px
    min-width: fit-content
</style>
