<template>
  <div class="register-offer">
    <p class="title">Bienvenue chez Kalveen !</p>
    <p class="subtitle">Commencez par choisir la formule adaptée à votre situation & vos besoins</p>

    <app-offers
      :packages="data"
      :activeSubscription="{}"
      @selectedPackage="(packageId) => emitPackageId(packageId)"
    />
  </div>
</template>

<script>
import AppOffers from '../../AppOffers.vue';

export default {
  name: 'register-offer',
  components: {
    AppOffers,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
  },
  methods: {
    emitPackageId(packageId) {
      this.$emit('click', packageId);
    },
  },
};
</script>

<style lang="sass">
.register-offer
  padding-bottom: ($global-padding-horizontal * 10)
  p
    &.title, &.subtitle
      text-align: center
    &.subtitle
      @include header3
      color: var(--color-grey70)
    &.name
      @include header2
      margin: $global-padding-horizontal 0
    &.price, &.title
      @include header1
      margin: 0
    &.duration
      @include text
      margin-top: -5px
    &.description
      @include text
      margin: ($global-padding-vertical * 2) 0
      color: var(--color-grey90)
  a
    @include link
    display: block
    color: var(--color-black)
    margin-top: ($global-padding-vertical * 2)
  .app-offers
    margin: auto

</style>
