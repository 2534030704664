<template>
  <div class="app-input-pin" :class="{ 'invisible': isVisible }">
    <input v-for="(item, index) in length" :key="`app-input-pin-input-${index}`"
      :type="isVisible ? 'text' : 'password'"
      maxlength="1"
      oninput="this.value = this.value.replace(/[^0-9]/g,'')"
      :name="`pin-${item}`"
      @keydown.prevent="type($event, index)"
      :value="input[index] ? input[index] : null"
      :ref="`pin-${item}`"
      :disabled="isDisabled(index)"
      autocomplete="off"/>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Array, Number],
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      length: 4,
      input: [],
    };
  },
  mounted() {
    // focus first input
    this.$nextTick(() => {
      this.goto(1);
    });
  },
  methods: {
    isDisabled(index) {
      return this.input.length < index;
    },
    type(event, index) {
      const tmpInput = [...this.input];
      // only allow numbers
      const key = event.key.replace(/\D/g, '');

      if (event.key === 'Backspace') {
        tmpInput.splice(index, (tmpInput.length - index));
        this.input = tmpInput;
        this.$emit('input', this.input);
        this.$nextTick(() => {
          this.goto(Number(index));
        });
      } else if (key) {
        tmpInput[index] = event.key;
        this.input = tmpInput;
        this.$emit('input', this.input);
        // go to the next field
        this.$nextTick(() => {
          if (Number(index) + 1 < this.length) this.goto(Number(index) + 2);
        });
      }
    },
    goto(n) {
      if (!n || n > this.length) {
        // eslint-disable-next-line
        n = 1;
      }
      const input = this.$refs[`pin-${n}`][0];

      input.focus();
    },
  },
};
</script>

<style lang="sass">
.app-input-pin
  position: relative
  display: block
  margin-top: 8px
  &.invisible
    visibility: initial
  input
    @include header2
    height: 72px
    width: 56px
    border: 1px solid var(--color-grey70)
    border-radius: $global-border-radius
    text-align: center
    ~ input
      margin-left: $global-padding-horizontal
    &:focus
      outline: none
    &[type="password"]
      font-size: 40px
</style>
