<template>
  <div class="app-countdown">
    <p>{{ minutes }} : {{ seconds }}</p>
  </div>
</template>

<script>

export default {
  name: 'app-countdown',
  data() {
    return {
      minutes: 2,
      seconds: 0,

      timerInstance: null,
    };
  },
  computed: {
    stateComputed() {
      return this.minutes + this.seconds;
    },
  },
  watch: {
    stateComputed(value) {
      if (value === 0) this.$emit('onEnd');
    },
  },
  mounted() {
    this.decrease();
  },
  methods: {
    decrease() {
      this.timerInstance = setInterval(() => {
        if (this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timerInstance);
          return;
        }
        if (this.seconds > 0) {
          this.seconds -= 1;
        } else {
          this.seconds = 59;
          if (this.minutes > 0) this.minutes -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="sass">
.app-countdown
</style>
