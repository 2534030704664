const type = Object.freeze({
  DOCUMENT_TYPE_4: {
    name: 4,
    description: 'Company Registration',
    label: 'Enregistrement de la société',
  },
  DOCUMENT_TYPE_6: {
    name: 6,
    description: 'CV',
    label: 'CV',
  },
  DOCUMENT_TYPE_7: {
    name: 7,
    description: 'Sworn statement',
    label: 'Déclaration sous serment',
  },
  DOCUMENT_TYPE_8: {
    name: 8,
    description: 'Turnover',
    label: 'Chiffre d\'affaires',
  },
  DOCUMENT_TYPE_9: {
    name: 9,
    description: 'Identity card',
    label: 'Carte d\'identité',
  },
  DOCUMENT_TYPE_11: {
    name: 11,
    description: 'Bank name entity Statement',
    label: 'Relevé d\'identité bancaire',
  },
  DOCUMENT_TYPE_12: {
    name: 12,
    description: 'Proof of address',
    label: 'Un justificatif de domicile',
  },
  DOCUMENT_TYPE_13: {
    name: 13,
    description: 'Mobile phone invoice',
    label: 'Facture de téléphone portable',
  },
  DOCUMENT_TYPE_14: {
    name: 14,
    description: 'Invoice, other than Mobile phone invoice',
    label: 'Facture, autre que la facture de téléphone portable',
  },
  DOCUMENT_TYPE_15: {
    name: 15,
    description: 'A resnameence permit',
    label: 'Un titre de séjour',
  },
  DOCUMENT_TYPE_16: {
    name: 16,
    description: 'A driving licence',
    label: 'Un permis de conduire',
  },
  DOCUMENT_TYPE_17: {
    name: 17,
    description: 'A passport',
    label: 'Un passeport',
  },
  DOCUMENT_TYPE_18: {
    name: 18,
    description: 'A proxy granting an employee',
    label: 'Une procuration accordant un employé',
  },
  DOCUMENT_TYPE_19: {
    name: 19,
    description: 'A company registration official paper',
    label: 'Un document officiel d\'enregistrement de la société',
  },
  DOCUMENT_TYPE_20: {
    name: 20,
    description: 'Official tax certificate',
    label: 'Attestation fiscale officielle',
  },
  DOCUMENT_TYPE_21: {
    name: 21,
    description: 'Employee payment notice',
    label: 'Avis de paiement de l\'employé',
  },
  DOCUMENT_TYPE_22: {
    name: 22,
    description: 'User bank statement',
    label: 'Relevé bancaire de l\'utilisateur',
  },
  DOCUMENT_TYPE_23: {
    name: 23,
    description: 'Business legal status',
    label: 'Statut juridique de l\'entreprise',
  },
  DOCUMENT_TYPE_24: {
    name: 24,
    description: 'Tax Statement',
    label: 'Déclaration fiscale',
  },
  DOCUMENT_TYPE_25: {
    name: 25,
    description: 'Exemption Statement',
    label: 'Déclaration d\'exemption',
  },
  DOCUMENT_TYPE_26: {
    name: 26,
    description: 'Liveness result',
    label: 'Résultat de vivacité',
  },
  DOCUMENT_TYPE_27: {
    name: 27,
    description: 'Health insurance card',
    label: 'Carte d\'assurance maladie',
  },
});

export default type;
