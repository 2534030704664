<template>
  <div class="app-upload" :class="{ drag: isDragOver || progress > 0 || value }"
    @dragenter.prevent="isDragOver = true"
    @dragover.prevent="isDragOver = true"
    @dragleave.prevent="isDragOver = false"
    @drop.prevent="dropFiles">
    <label>
      <p v-if="label">{{ label }}</p>
      <SvgUpload v-if="!value || value.length <= 0" />
      <!-- <small v-if="value && multiple">{{ value.length }} fichiers</small> -->
      <div v-else class="grid-x align-middle file" v-for="(file, index) in value" :key="`app-upload-label-${index}`">
        <SvgFile />
        <div>
          <p>{{ file.name }}</p>
          <p>{{ formatBytes(file.size) }}</p>
        </div>
        <SvgDownload />
      </div>
      <span v-if="progress > 0">Envoi en cours : {{ progress }} %</span>
      <span v-else-if="!value || value.length <= 0">
        {{ `Importez ou glissez ${ multiple ? 'vos' : 'votre' } document` }}
      </span>
      <input ref="inputFile" type="file" @input="addFiles" :accept="accept" :multiple="multiple">
    </label>
  </div>
</template>

<script>
import SvgUpload from '../../assets/img/icons/upload.svg?inline';
import SvgFile from '../../assets/img/icons/file.svg?inline';
import SvgDownload from '../../assets/img/icons/download.svg?inline';

export default {
  components: {
    SvgUpload,
    SvgFile,
    SvgDownload,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Array,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      isDragOver: false,
    };
  },
  watch: {
    value: 'handleInputFile',
  },
  methods: {
    dropFiles(event) {
      this.isDragOver = false;
      if (event.dataTransfer.files.length <= 0) {
        this.$emit('input', []);
      } else {
        const upload = this.multiple ? [...event.dataTransfer.files] : [[...event.dataTransfer.files][0]];
        this.$emit('input', upload);
      }
    },
    addFiles(event) {
      if (event.target.files.length <= 0) {
        this.$emit('input', []);
      } else {
        const upload = this.multiple ? [...event.target.files] : [[...event.target.files][0]];
        this.$emit('input', upload);
      }
    },
    handleInputFile(value) {
      if (!value) this.$refs.inputFile.value = null;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
    },
  },
};
</script>

<style lang="sass">
.app-upload
  label
    @include details
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: ($global-padding-vertical * 2) ($global-padding-horizontal * 2)
    border: 2px dashed var(--color-70)
    border-radius: $global-border-radius
    background-color: var(--color-30)
    transition: all ease-in-out 0.2s
    svg
      max-width: 44px
      path
        // fill: grey
        transition: all ease-in 0.2s
    input
      display: none
    span
      margin: $global-padding-horizontal 0 0 0
    div.grid-x
      div
        margin: 0 $global-padding-horizontal
        p
          margin: 0
          @include label-form
          &:first-child
            @include header5
    &:hover
      background-color: var(--color-50)
    .file
      justify-content: center
      text-align: center
      > div
        margin: $global-padding-vertical $global-padding-horizontal !important
      ~ .file
        margin-top: $global-padding-vertical
        padding-top: $global-padding-vertical
        border-top: 1px solid var(--color-50)
</style>
