<template>
  <div class="ubble">
    <div class="wrapper">
      <div class="grid-x align-middle align-center">
        <SvgLogoImg />
        <SvgLogoText />
      </div>
      <SvgUbbleIndividual v-if="!$route.query.type || $route.query.type === 'individual'" />
      <SvgUbbleBusiness v-else />
      <p class="title">Merci pour ces informations !</p>
      <p class="info">Vous pouvez retourner sur notre site web <br> pour finaliser la procédure</p>
      <p>Vous venez de l’application  mobile Kalveen ? </p>

      <app-button size="large" kind="primary" @click="redirectToMobileApp">
        Ouvrir l’application
      </app-button>
    </div>
  </div>
</template>

<script>
import ls from 'local-storage';

// Svg
import SvgLogoImg from '../assets/img/logo-kvn-img.svg?inline';
import SvgLogoText from '../assets/img/logo-kvn-text.svg?inline';
import SvgUbbleBusiness from '../assets/img/business/ubble.svg?inline';
import SvgUbbleIndividual from '../assets/img/individual/ubble.svg?inline';

export default {
  name: 'ubble',
  components: {
    SvgLogoImg,
    SvgLogoText,
    SvgUbbleBusiness,
    SvgUbbleIndividual,
  },
  mounted() {
    this.setDataThemeAttribute();
  },
  methods: {
    setDataThemeAttribute() {
      const theme = !this.$route.query.type || this.$route.query.type === 'individual' ? 'individual' : 'business';
      ls('theme', theme);
      this.$theme = theme;
      const body = document.querySelector('body');
      body.setAttribute('data-theme', theme);
    },
    redirectToMobileApp() {
      window.open('kvn://login');
    },
  },
};
</script>

<style lang="sass">
  .ubble
    display: flex
    min-height: 100vh
    padding: 27px
    flex-direction: column
    justify-content: center
    margin: auto
    text-align: center
    background-color: var(--color-30)
    .svg-logo-img
      width: 24px
    .svg-logo-text
      width: 87px
      margin-left: 17px
    .svg-business-ubble, .svg-individual-ubble
      margin: 104px 0 37px 0
    p
      @include text
      margin: 16px 0
      font-family: 'nexaRegular'
      color: var(--color-grey70)
      &.title
        @include header2
      &.info
        margin: 37px 0 64px 0
        font-family: 'nexaBook'
    .app-button
      margin: auto
</style>
