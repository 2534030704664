<template>
  <div class="profil-proofs" v-if="customer">
    <p>Justificatifs</p>
    <p>Lorsque votre compte est limité, vous pouvez ajouter ici les justificatifs nécessaires et consulter leur état de validation.</p>

    <app-loader v-if="isLoading"></app-loader>
    <div class="record" v-for="(child, index) in getCompleteDataComputed" :key="`proofs-record-child-${index}`">
      <app-dropdown
        :ref="'profil-proofs-dropdown'"
        :disabled="isProfilIncomplete(child)"
      >
        <template v-slot:main>
          <div class="grid-x align-middle">
            <div class="cell auto">
              <!-- Carte pour l'entreprise non auto-entrepreneur -->
              <template v-if="(child.customer.type === EnumCustomerType.BUSINESS) && !isSelfEmployedPerson">
                <p>{{ child.customer.legalName }}</p>
                <p>Entreprise</p>
              </template>
              <template v-else>
                <!-- Carte pour une personne physique ou entreprise auto-entrepreneur -->
                <p>{{ child.customer.firstname }} {{ child.customer.lastname }}</p>
                <p>{{ child.customer.parentType ? EnumParentType[child.customer.parentType].label : null }}</p>
                <p class="warning" v-if="isProfilIncomplete(child)">Profil incomplet</p>
              </template>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <list
            :child="child"
            :entityKey="entityKeyComputed"
            @click="(document) => { isTheFlapOpenAddDocument = true; dataAddComputed = { child, document }; }"
          />
        </template>
      </app-dropdown>
    </div>

    <Add ref="proofs-add"
      :datas="dataAddComputed"
      :isOpen="this.isTheFlapOpenAddDocument"
      @is-open="(value) => isTheFlapOpenAddDocument = value"
    />
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';
import EnumDocumentType from '../../../services/enums/documents/type.enum';
import EnumProofsCorrelation from '../../../services/enums/proofs/correlation.enum';

// Components
import AppDropdown from '../../../basics/components/AppDropdown.vue';
import Add from './Add.vue';
import List from './List.vue';

// Svg

import utils from '../../../services/utils/utils';

export default {
  name: 'proofs-proofs',
  inject: ['$getCustomer', '$isSelfEmployedPerson'],
  components: {
    AppDropdown,

    Add,
    List,
  },
  data() {
    return {
      children: [],
      dataAdd: {},

      isLoading: false,
      isTheFlapOpenAddDocument: false,
      isLiveness: false,

      EnumCustomerType,
      EnumParentType,
      EnumDocumentType,
      EnumProofsCorrelation,

      utils,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    documentsComputed: {
      get() { return this.documents; },
      set(newValue) { this.documents = newValue; },
    },
    getCompleteDataComputed() {
      // eslint-disable-next-line
      /**
      * 1er cas -> customer de type BUSINESS non auto-entrepreneur renvoie l'entreprise customer BUSINESS + les enfants customer INDIVIDUAL
      * 2ème cas -> customer de type INDIVIDUAL ou business auto-entrepreneur renvoie le customer
      */
      return !this.isSelfEmployedPerson
        ? [this.customer, ...this.children]
        : [this.customer];
    },
    dataAddComputed: {
      get() {
        return this.dataAdd;
      },
      set(newValue) {
        const tmpCustomer = { ...newValue.child };
        const tmpDocument = { ...newValue.document };

        let tmpBeneficiaries;
        if (this.children.length > 0) {
          tmpBeneficiaries = [{ name: this.customer.customer.customerId, label: this.customer.customer.legalName }, ...this.children.map((customer) => ({ name: customer.customer.customerId, label: `${customer.customer.firstname} ${customer.customer.lastname}` }))];
        } else {
          tmpBeneficiaries = [{ name: tmpCustomer.customer.customerId, label: `${tmpCustomer.customer.firstname} ${tmpCustomer.customer.lastname}` }];
        }
        this.dataAdd = {
          parentType: tmpCustomer.customer.parentType,
          selectedType: EnumDocumentType[tmpDocument.document]?.name || tmpDocument.document,
          selectedBeneficiary: tmpCustomer.customer.customerId,
          phone: tmpCustomer.customer.phone,
          beneficiaries: tmpBeneficiaries,
          isLiveness: tmpDocument.isLiveness,
          isDisabled: !!tmpCustomer,
        };
      },
    },
    entityKeyComputed() {
      /**
      * 1er cas -> si le customer n'a pas de legalForm et n'a pas d'employés alors on affiche les justificatifs pour les personnes physiques
      * 2ème cas -> si le customer est un business auto entrepreneur on affiche les justificatifs de l'entreprise liés et les justificatifs d'une personne physique
      * 3ème cas -> si le customer est un business non auto entrepreneur on affiche les justificatifs liés
      */
      // eslint-disable-next-line
      return (!this.customer.customer.parentUserId && (this.customer.customer.type === EnumCustomerType.INDIVIDUAL))
        ? 'NATURAL_PERSON'
        : (this.isSelfEmployedPerson)
          ? 'SELF_EMPLOYED_PERSON'
          : Object.keys(EnumProofsCorrelation).find((key) => key === this.customer.customer.businessType);
    },
  },
  mounted() {
    if (this.$refs['proofs-add']) {
      this.$watch(() => this.$refs['proofs-add'].isOpen,
        (newValue) => {
          this.$isFlapOpen = newValue;
        });
    }

    this.getCustomerChildren();

    setTimeout(() => {
      this.overwriteDropdown();
    }, 300);
  },
  methods: {
    async getCustomerChildren(parentType) {
      this.isLoading = true;
      try {
        this.children = await ApiCustomer.getCustomerChildren(parentType);
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },
    overwriteDropdown() {
      if (this.$refs['profil-proofs-dropdown'] && this.$refs['profil-proofs-dropdown'].length > 0) {
        this.$refs['profil-proofs-dropdown'][0].isOpen = true;
      }
    },
    isProfilIncomplete(child) {
      return !child.treezorUser;
    },
  },
};
</script>

<style lang="sass">
.profil-proofs
  max-width: 484px
  > p
    margin: 0
    @include header3
    &:first-child
      @include header2
    &:nth-child(2)
      @include text
      margin-bottom: $global-padding-horizontal * 2
      color: var(--color-grey70)
    ~ p
      margin-top: $global-padding-horizontal / 2
  .app-button
    margin-top: $global-padding-vertical * 4
    svg path
      stroke-width: 0px
  div.record
    ~ .record
      margin-top: $global-padding-horizontal
    p
      margin: 0
    > .app-dropdown
      padding: 0
      background-color: transparent
      p
        @include header3
        margin: auto
        ~ p
          @include details
          color: var(--color-grey70)
        text-align: left
      &.content-opened
        .main
          border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0
          border-bottom: 1px solid var(--color-50)
      .main
        border-radius: ($global-border-radius * 2)
        padding: ($global-padding-vertical * 2)
        background-color: var(--color-cloud)
        &:hover
          background-color: var(--color-50)
      .content
        position: relative
        padding: 0
        border-radius: 0
        height: 300px
        &::after
          content: ''
          position: absolute
          bottom: -10px
          left: 0
          height: 10px
          width: 100%
          border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
          background-color: var(--color-cloud)
        .proofs-list
          @include scrollbar
          padding: $global-padding-vertical $global-padding-horizontal
          height: inherit
          overflow-y: scroll
        .category
          .description
            margin-top: $global-padding-vertical
            text-align: center
          .proof
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-between
            margin: 0 auto // fix Firefox
            margin-top: ($global-padding-vertical * 2)
            padding: $global-padding-vertical
            border: 2px dashed var(--color-70)
            border-radius: $global-border-radius
            background-color: var(--color-30)
            .label
              @include header5
            p
              margin: 0
            .app-button, p
              margin-top: $global-padding-vertical
              text-align: center
            .app-button
              margin-top: $global-padding-vertical * 2
              width: 100%
              @include details
              color: var(--color-cloud)
              span
                margin: auto
  .warning
    font-family: 'nexaBold' !important
    color: var(--color-warning) !important
</style>
