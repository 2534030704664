const status = Object.freeze({
  CANCELED: {
    name: 'CANCELED',
    label: 'Annulé',
  },
  AUTHORIZED: {
    name: 'AUTHORIZED',
    label: 'À venir',
  },
  SETTLED: {
    name: 'SETTLED',
    label: 'Validé',
  },
  DECLINED: {
    name: 'DECLINED',
    label: 'Refusé',
  },
});

export default status;
