import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient les formules disponibles
 *
 * @param {PackageType} type
 * @returns
 */
const getPackages = async (type) => {
  try {
    const response = await axios.get(`${API_URL}/packages`, {
      params: {
        ...(type && { type }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient l'image d'une formule
 *
 * @param {string} packageId
 * @returns
 */
const getPackageImage = async (packageId) => {
  try {
    const response = await axios.get(`${API_URL}/packages/${packageId}/image`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère le détail de l'abonnement de l'utilisateur
 *
 * @returns
 */
const getMyPackage = async () => {
  try {
    const response = await axios.get(`${API_URL}/packages/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getPackages = getPackages;
api.getPackageImage = getPackageImage;
api.getMyPackage = getMyPackage;

export default api;
