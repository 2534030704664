<!--
  Exemple d'intégration
  import EmailAppeareanceIndividual from '../../../assets/animations/individual/email-appearance.json';
  import EmailLoopIndividual from '../../../assets/animations/individual/email-loop.json';

  data() {
    return {
      EmailAppeareanceIndividual,
      EmailLoopIndividual,
    };
  },

  <app-lottie
    :dataAppearance="EmailAppeareanceIndividual"
    :dataLoop="EmailLoopIndividual"
  ></app-lottie>
-->
<template>
  <div class="app-lottie" ref="animation">
  </div>
</template>

<script>
import Lottie from 'lottie-web';

export default {
  name: 'app-lottie',
  props: {
    dataAppearance: {
      required: true,
    },
    dataLoop: {
      required: true,
    },
  },
  data() {
    return {
      animation: null,
      isAnimationAppearanceCompleted: false,
    };
  },
  watch: {
    dataAppearance() {
      this.animation.destroy();
      this.runAnimationAppearance();
    },
    isAnimationAppearanceCompleted(newValue) {
      if (newValue) this.runAnimationLoop();
    },
  },
  mounted() {
    this.runAnimationAppearance();
  },
  beforeDestroy() {
    this.animation.destroy();
  },
  methods: {
    initAnimation() {
      return {
        container: this.$refs.animation,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: this.dataAppearance,
      };
    },
    runAnimationAppearance() {
      this.isAnimationAppearanceCompleted = false;
      const settings = this.initAnimation();
      this.animation = Lottie.loadAnimation(settings);
      this.animation.onComplete = () => { (this.isAnimationAppearanceCompleted = true); };
    },
    runAnimationLoop() {
      const settings = { ...this.initAnimation(), loop: true, animationData: this.dataLoop };
      this.animation.destroy();
      this.animation = Lottie.loadAnimation(settings);
    },
  },
};
</script>

<style lang="sass">
.app-lottie
</style>
