<template>
  <section class="check-add">
    <div class="header">
      <app-button size="small" kind="secondary" @click="handleBack"><SvgArrow /><span>Retour</span></app-button>
      <h2>{{ step === 0 ? 'Comment déposer un chèque ?' : 'Déposer un chèque' }}</h2>
    </div>
    <div class="content">
      <template v-if="step === 0">
        <div v-if="step === 0" class="guide">
          <div v-for="(guideStep, index) in guideSteps" :key="guideStep.title" class="guide-step">
            <div class="step-index"><h3>{{ index + 1 }}</h3></div>
            <div>
              <div class="step-title"><h3>{{ guideStep.title }}</h3></div>
              <div class="step-description">{{ guideStep.description }}</div>
            </div>
            <div class="step-line"></div>
          </div>
        </div>
        <div class="next-cta"><app-button size="large" @click="step += 1">J'ai compris <SvgArrow /></app-button></div>
      </template>
      <template v-else>
        <div class="check-info">
          <div @click="openFlap=null; openFlap = 'check'" class="info">?</div>
          <form class="check-info-form">
            <app-input required v-model="check.amount" label="Montant" type="number" min="0.01" step="0.01" name="amount"><template v-slot:suffix><SvgEuro /></template></app-input>
            <app-input label="Code CMC7" minlength="31" maxlength="31" v-model="checkNumber" name="checkNumber"></app-input>
            <app-input label="Clé RLMC" required v-model="check.RLMCKey" maxlength="2" minlength="2" name="key"></app-input>
            <app-errors :errors="errors" />
          </form>
        </div>
        <app-action-card withIcon @click="openFlap=null; openFlap = 'info'">
          <template v-slot:icon><SvgParameters /></template>
          <template v-slot:content>
            <p>Information émetteur</p>
            <p v-if="check.issuerLastName && check.issuerFirstName">
              {{ check.issuerFirstName }} {{ check.issuerLastName }}<br/>
              {{ check.issuerIsNatural ? 'Personne physique' : 'Personne morale' }}
            </p>
          </template>
        </app-action-card>

        <app-action-card withIcon @click="openFlap=null; openFlap = 'contact'">
          <template v-slot:icon><SvgContact /></template>
          <template v-slot:content>
            <p>Contact émetteur</p>
            <p v-if="check.issuerEmail">
              {{ check.issuerAddress.streetNumber }} {{ check.issuerAddress.route }}<br/>
              {{ check.issuerAddress.postalCode }} {{ check.issuerAddress.locality }}<br/>
              {{ check.issuerEmail }}<br/>
              {{ check.issuerPhone }}<br/>
            </p>
          </template>
        </app-action-card>

        <EditIssuerInfo
          :isOpen="openFlap === 'info'"
          :isNaturalPerson="check.issuerIsNatural"
          :firstname="check.issuerFirstName"
          :lastname="check.issuerLastName"
          @click:is-open="openFlap = null"
          @submit="submitIssuerInfo"
          />

        <EditIssuerContact
          :isOpen="openFlap === 'contact'"
          :streetNumber="check.issuerAddress.streetNumber"
          :route="check.issuerAddress.route"
          :postalCode="check.issuerAddress.postalCode"
          :locality="check.issuerAddress.locality"
          :phone="check.issuerPhone"
          :email="check.issuerEmail"
          @click:is-open="openFlap = null"
          @submit="submitIssuerContact"
          />

        <app-flap class="check-info-details" :isOpen="openFlap === 'check'" @click:is-open="openFlap = null">
          <template v-slot:app-flap-main>Détails chèque</template>
          <template v-slot:app-flap-content>
            <form>
              <h3>Pour remplir votre demande de dépôt de chèque, vous pouvez vous référer au schéma ci-dessous : </h3>
              <img v-if="$theme === EnumCustomerType.INDIVIDUAL" src="../../assets/img/individual/check-info.png" alt="">
              <img v-else src="../../assets/img/business/check-info.png" alt="">
              <app-button size="large" @click="openFlap = null">Valider</app-button>
            </form>
          </template>
        </app-flap>
        <div class="next-cta"><app-button size="large" @click="submitCheck" :isLoading="isPosting">Valider</app-button></div>
      </template>
    </div>
  </section>
</template>

<script>
import payinApi from '@/services/api/payin';

import EnumNotificationType from '@/services/enums/notification.enum';
import EnumCustomerType from '@/services/enums/customer/type.enum';

import SvgArrow from '@/assets/img/icons/arrow.svg?inline';
import SvgParameters from '@/assets/img/icons/parameters.svg?inline';
import SvgContact from '@/assets/img/icons/contact.svg?inline';
import SvgEuro from '@/assets/img/icons/euro.svg?inline';

import EditIssuerInfo from './EditIssuerInfo.vue';
import EditIssuerContact from './EditIssuerContact.vue';

export default {
  components: {
    SvgArrow,
    SvgParameters,
    SvgContact,
    SvgEuro,
    EditIssuerInfo,
    EditIssuerContact,
  },
  data() {
    return {
      check: {
        amount: null,
        CMC7A: '',
        CMC7B: '',
        CMC7C: '',
        RLMCKey: '',
        issuerLastName: '',
        issuerFirstName: '',
        issuerIsNatural: true,
        issuerEmail: '',
        issuerAddress: {
          streetNumber: '',
          route: '',
          postalCode: '',
          locality: '',
        },
      },
      step: 0,
      guideSteps: [{
        title: 'Déposez en ligne votre chèque',
        description: 'Remplissez les informations demandées sur votre espace Kalveen. Nous aurons besoin du montant, du numéro de chèque et d’informations sur l’émetteur.',
      }, {
        title: 'Envoyez votre chèque à Kalveen',
        description: 'Une fois déposé virtuellement sur votre espace, envoyez-nous votre chèque par voie postale. Vous avez X jours pour nous le faire parvenir à partir de la date de dépôt en ligne. Vous trouverez les informations postales en détail.',
      }, {
        title: 'Nous traitons votre demande',
        description: 'Notre équipe examine votre dépôt et votre chèque pour vérifier la conformité des informations. A partir de ce moment, nous pouvons valider et encaisser le chèque ou le refuser.',
      }],
      openFlap: null,
      errors: [],
      isPosting: false,
      EnumCustomerType,
    };
  },
  computed: {
    checkNumber: {
      get() {
        return `${this.check.CMC7A || ''}${this.check.CMC7B || ''}${this.check.CMC7C || ''}`;
      },
      set(value) {
        this.check.CMC7A = value.slice(0, 7);
        this.check.CMC7B = value.slice(7, 19);
        this.check.CMC7C = value.slice(19, 32);
      },
    },
  },
  methods: {
    handleBack() {
      if (this.step === 0) {
        this.$emit('click:close');
      } else {
        this.step -= 1;
      }
    },
    submitIssuerInfo(issuer) {
      this.check.issuerFirstName = issuer.firstname;
      this.check.issuerLastName = issuer.lastname;
      this.check.issuerIsNatural = issuer.isNaturalPerson;
      this.openFlap = null;
    },
    submitIssuerContact(issuer) {
      this.check.issuerAddress.streetNumber = issuer.streetNumber;
      this.check.issuerAddress.route = issuer.route;
      this.check.issuerAddress.postalCode = issuer.postalCode;
      this.check.issuerAddress.locality = issuer.locality;
      this.check.issuerEmail = issuer.email;
      this.check.issuerPhone = issuer.phone;
      this.openFlap = null;
    },
    checkValidity() {
      this.errors = [];
      const fields = [
        { name: 'amount', label: 'Montant' },
        { name: 'checkNumber', label: 'Numéro de chèque' },
        { name: 'key', label: 'Clé RLMC' },
      ];
      fields.forEach((field) => {
        const input = document.getElementsByName(field.name)[0];
        if (input.validationMessage) {
          this.errors.push(`${field.label}: ${input.validationMessage}`);
        }
      });
      if (!this.check.issuerLastName) {
        this.errors.push('Veuillez remplir les informations émetteur.');
      }
      if (!this.check.issuerEmail) {
        this.errors.push('Veuillez remplir le contact émetteur.');
      }
      if (this.errors.length) {
        return false;
      }
      return true;
    },
    async submitCheck() {
      if (this.checkValidity()) {
        this.isPosting = true;
        try {
          await payinApi.createPayin({
            ...this.check,
            amount: parseFloat(this.check.amount),
            paymentMethodId: 26,
            currency: 'EUR',
          });
          this.$notification.show({ title: 'Demande envoyée !', text: 'Pour valider votre demande, merci d\'envoyer le chèque à l\'adresse précisée dans le détail du dépôt.', type: EnumNotificationType.SUCCESS });
          this.$emit('click:close');
        } catch (err) {
          this.$notification.show({ title: 'Demande refusée', text: 'Votre chèque n\'est pas valide. Veuillez vérifier les informations et réessayez.', type: EnumNotificationType.ECHEC });
        }
        this.isPosting = false;
      }
    },
  },
};
</script>

<style lang="sass">
.check-add
  .header
    margin-top: 1rem
    margin-bottom: 46px
    .app-button
      background-color: transparent
      padding: 0
      margin: 0
      svg
        transform: rotate(180deg)
        margin: 0 8px 0 0
      span
        @include link
    h2
      @include header2
      margin-top: 0
  .content
    margin-bottom: 40px
    max-width: 500px
    h3
      @include header3
    .guide
      .guide-step
        display: flex
        padding-bottom: $global-padding-vertical * 4
        position: relative
        .step-index
          background-color: var(--color-70)
          width: 38px
          height: 38px
          padding: 8px 16px
          border-radius: 50px
          display: flex
          justify-content: center
          align-items: center
          margin-right: 24px
        .step-title
          h3
            margin-top: 0
        .step-description
          @include text
        .step-line
          position: absolute
          left: 19px
          top: 38px
          width: 2px
          height: calc(100% - 38px)
          background-color: var(--color-70)
        &:last-child
          .step-line
            display: none
    .check-info
      background: white
      box-shadow: 0px 2px 8px rgba(238, 238, 238, 0.4)
      border-radius: 16px
      padding: 32px
      .info
        @include text-bold
        display: flex
        margin: auto 0 auto auto
        width: 20px
        height: 20px
        align-items: center
        justify-content: center
        color: var(--color-cloud)
        font-size: 12px
        font-family: 'nexaHeavy'
        background-color: var(--color-black)
        border-radius: 100%
        cursor: pointer
      .check-info-form
        > .app-input .label
          background-color: var(--color-cloud)
        .app-input ~ .app-input
          margin-top: 40px
        .app-input:first-child, .app-input:last-child
          width: 80%

    .check-info-details
      text-align: center
      img
        margin-top: $global-padding-horizontal
        width: 100%
      .app-button
        display: flex
        margin-top: $global-padding-horizontal * 6
        width: 100%
        justify-content: center

    .app-action-card
      border-radius: $global-border-radius * 2
      margin: 24px 0
      width: 100%

  .next-cta .app-button
    svg
      path
        stroke: white
        fill: white
</style>
