const payout = Object.freeze({
  PAYOUTS: {
    label: 'Virements',
    name: 'payouts',
  },
  BENEFICIARIES: {
    label: 'Bénéficiaires',
    name: 'payouts-beneficiaries',
  },
  CREDITORS: {
    label: 'Prélèvements SEPA',
    name: 'payouts-creditors',
  },
});

export default payout;
