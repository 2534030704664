<template>
  <app-flap class="edit-creditor-info" :isOpen="isOpen" @click:is-open="$emit('click:is-open', $event)" parent="info">
    <template v-slot:app-flap-main>Informations émetteur</template>
    <template v-slot:app-flap-content>
      <form>
        <h3>Type de l'émetteur</h3>
        <app-radio-custom
          :values="[{ name: 'true', label: 'Personne physique'}, { name: 'false', label: 'Personne morale'}]"
          :value="{ name: creditor.isNaturalPerson }"
          @value="creditor.isNaturalPerson = $event.name"
          required
        ></app-radio-custom>
        <app-input label="Nom *" v-model="creditor.lastname" required name="lastname"></app-input>
        <app-input label="Prénom *" v-model="creditor.firstname" required name="firstname"></app-input>

        <app-errors :errors="errors" />
        <app-button class="validate-btn" @click="submit">Valider</app-button>
      </form>
    </template>
  </app-flap>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isNaturalPerson: {
      type: Boolean,
      required: false,
    },
    firstname: {
      type: String,
      required: false,
    },
    lastname: {
      type: String,
      required: false,
    },
  },
  emits: ['click:is-open', 'submit'],
  data() {
    return {
      creditor: {
        isNaturalPerson: 'true',
        firstname: null,
        lastname: null,
      },
      errors: [],
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.creditor.isNaturalPerson = this.isNaturalPerson ? 'true' : 'false';
        this.creditor.firstname = this.firstname;
        this.creditor.lastname = this.lastname;
      }
    },
  },
  methods: {
    submit() {
      if (this.checkValidity()) {
        const creditor = {
          ...this.creditor,
          isNaturalPerson: this.creditor.isNaturalPerson === 'true',
        };
        this.$emit('submit', creditor);
      }
    },
    checkValidity() {
      this.errors = [];
      const fields = [{ name: 'firstname', label: 'Prénom' }, { name: 'lastname', label: 'Nom' }];
      fields.forEach((field) => {
        const input = document.getElementsByName(field.name)[0];
        if (input.validationMessage) {
          this.errors.push(`${field.label}: ${input.validationMessage}`);
        }
      });
      if (this.errors.length) {
        return false;
      }
      return true;
    },
  },

};
</script>

<style lang="sass">
.edit-creditor-info
  .validate-btn
    width: 100%
    justify-content: center
  .app-input
    margin: 40px 0
    .label
      background-color: var(--color-cloud)
</style>
