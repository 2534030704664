<template>
  <div class="register-company-data" v-if="customer">
    <p>Informations sur votre entreprise</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x legal-registration-number align-middle">
        <div class="search cell auto">
          <app-input
            label="Numéro de SIRET *"
            type="text"
            v-model="legalRegistrationNumberComputed"
          ></app-input>
          <app-button :disabled="isResearching" kind="primary" size="small" :isLoading="isResearching" @click="search">Rechercher</app-button>
        </div>

        <template v-if="data || (legalNameComputed && legalFormComputed && legalSectorComputed && legalTvaNumberComputed && entityTypeComputed)">
          <p class="info">
            Ces informations ont été complétées automatiquement à partir de votre SIRET. Merci de bien vérifier leurs exactitudes et de les modifier si nécessaire.
          </p>

          <app-input
            class="cell small-12 legal-name"
            label="Raison Sociale *"
            type="text"
            v-model="legalNameComputed"
          ></app-input>

          <app-select
            class="cell small-12 business-type"
            v-model="businessTypeComputed"
            label="Type d'entreprise *"
            :options="EnumBusinessType"
          >
          </app-select>

          <div class="cell small-12 legal-form">
            <app-select
              v-model="legalFormComputed"
              label="Forme juridique *"
              :options="EnumLegalForms"
              :isDisabled="!!customer.treezorUser">
            </app-select>
            <span class="annotation" :style="{ color: 'var(--color-warning)'}">(1)</span>
          </div>

          <app-select
            class="cell small-12 legal-sector"
            v-model="legalSectorComputed"
            label="Secteur d’activité *"
            :options="EnumLegalSectors">
          </app-select>

          <app-input
            class="cell small-12 legal-tva-number"
            label="Numéro de TVA *"
            type="text"
            v-model="legalTvaNumberComputed"
          ></app-input>

          <div class="cell small-12 entity-type">
            <app-select
              v-model="entityTypeComputed"
              label="Type d'entité *"
              :options="EnumEntityTypes"
              :isDisabled="!!customer.treezorUser">
            </app-select>
            <span class="annotation" :style="{ color: 'var(--color-warning)'}">(1)</span>
          </div>

          <div v-if="legalFormComputed === '1000'" class="cell small-12 grid-x is-self-employed-person align-middle">
            <p class="cell small-8 info">Avez-vous le statut d'auto-entrepreneur ? * <span :style="{ color: 'var(--color-warning)'}">(1)</span></p>
            <app-radio-custom class="cell auto align-right" :values=EnumYesNoIsSelfEmployedPersonComputed @value="(value) => isSelfEmployedPerson = value" :value="isSelfEmployedPersonComputed"></app-radio-custom>
          </div>

          <p class="info info-warning"><span :style="{ color: 'var(--color-warning)'}">(1) : Attention vous ne pourrez plus modifier cette information après avoir complété l'étape "Chiffres de votre entreprise".</span></p>
        </template>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-company-data-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!legalRegistrationNumberComputed || !legalNameComputed || !businessTypeComputed || !legalFormComputed ||  !legalSectorComputed || !legalTvaNumberComputed || !entityTypeComputed || (!isSelfEmployedPersonComputed && (legalFormComputed === '1000'))" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';
import ApiTreezor from '../../../services/api/treezor';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';

// Enums
import EnumLegalForms from '../../../../../commons/legalForms.json';
import EnumLegalSectors from '../../../../../commons/legalSectors.json';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumYesNo from '../../../services/enums/yesNo.enum';
import EnumBusinessType from '../../../services/enums/customer/businessType.enum';
import EnumEntityTypes from '../../../../../commons/companyEntityTypes.json';

export default {
  name: 'register-company-data',
  components: {
    SvgWarning,
    SvgArrow,
  },
  inject: ['$getCustomer'],
  data() {
    return {
      data: null,

      legalRegistrationNumber: null,
      legalName: null,
      businessType: null,
      legalForm: null,
      legalSector: null,
      legalTvaNumber: null,
      isSelfEmployedPerson: null,
      entityType: null,

      errors: [],
      isResearching: false,
      isLoading: false,

      EnumLegalForms: this.$utils.sortAlphabetically(EnumLegalForms),
      EnumLegalSectors: this.$utils.sortAlphabetically(EnumLegalSectors),
      EnumCustomerType,
      EnumNotificationType,
      EnumYesNo,
      EnumEntityTypes: this.$utils.sortAlphabetically(EnumEntityTypes),
      EnumBusinessType: this.$utils.transformEnumToArray(EnumBusinessType),
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    EnumYesNoIsSelfEmployedPersonComputed() {
      return Object.keys(EnumYesNo).map((key) => ({
        ...EnumYesNo[key],
        // eslint-disable-next-line
        disabled: this.customer.treezorUser ? true : false,
      }));
    },
    legalRegistrationNumberComputed: {
      get() { return this.legalRegistrationNumber || this.customer.customer.legalRegistrationNumber; },
      set(newValue) { this.legalRegistrationNumber = newValue; },
    },
    legalNameComputed: {
      get() { return this.legalName || this.customer.customer.legalName; },
      set(newValue) { this.legalName = newValue; },
    },
    businessTypeComputed: {
      get() { return this.businessType || (this.customer.customer.businessType && EnumBusinessType[this.customer.customer.businessType].name); },
      set(newValue) { this.businessType = newValue; },
    },
    legalFormComputed: {
      get() { return this.legalForm || this.customer.customer.legalForm; },
      set(newValue) { this.legalForm = newValue; },
    },
    legalSectorComputed: {
      get() { return this.legalSector || this.customer.customer.legalSector; },
      set(newValue) { this.legalSector = newValue; },
    },
    legalTvaNumberComputed: {
      get() { return this.legalTvaNumber || this.customer.customer.legalTvaNumber; },
      set(newValue) { this.legalTvaNumber = newValue; },
    },
    isSelfEmployedPersonComputed: {
      get() { return this.isSelfEmployedPerson || EnumYesNo[this.customer.customer.isSelfEmployedPerson]; },
      set(newValue) { this.isSelfEmployedPerson = newValue; },
    },
    entityTypeComputed: {
      get() { return this.entityType || this.customer.customer.entityType; },
      set(newValue) { this.entityType = newValue; },
    },
  },
  watch: {
    data(newValue) {
      this.legalNameComputed = newValue.legalName;
      this.businessTypeComputed = newValue.businessType;
      this.legalFormComputed = newValue.legalForm;
      this.legalSectorComputed = newValue.legalSector;
      this.legalTvaNumberComputed = newValue.legalTvaNumber;
      this.isSelfEmployedPersonComputed = newValue.isSelfEmployedPerson;
      this.entityTypeComputed = newValue.entityType;
    },
  },
  methods: {
    async search() {
      await this.getBusinessSearchs();
      await this.getBusinessInformations();
    },
    async getBusinessSearchs() {
      this.isResearching = true;
      try {
        const tmpData = await ApiTreezor.getBusinessSearchs(this.legalRegistrationNumberComputed);
        // eslint-disable-next-line
        const { legalName, legalForm, legalSector, legalTvaNumber, externalId } = tmpData[0];
        this.data = {
          ...this.data,
          legalName,
          legalForm,
          legalSector,
          legalTvaNumber,
          externalId,
        };
      } catch (error) {
        //
      }
      this.isResearching = false;
    },
    async getBusinessInformations() {
      this.isResearching = true;
      try {
        const tmpData = await ApiTreezor.getBusinessInformations(this.legalRegistrationNumberComputed);
        // eslint-disable-next-line
        const { legalName, legalForm, legalSector, legalTvaNumber } = tmpData[0];

        /* eslint-disable */
        this.data = {
          ...this.data,
          ...(legalName && { legalName }),
          ...(legalForm && { legalForm }),
          ...(legalSector && { legalSector }),
          ...(legalTvaNumber && { legalTvaNumber }),
          /* eslint-enable */
        };
      } catch (error) {
        if (error.response.status === 404) {
          this.data = {};
        }
      }
      this.isResearching = false;
    },
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        legalRegistrationNumber: this.legalRegistrationNumberComputed,
        legalName: this.legalNameComputed,
        businessType: this.businessTypeComputed,
        legalForm: this.legalFormComputed,
        legalSector: this.legalSectorComputed,
        legalTvaNumber: this.legalTvaNumberComputed,
        isSelfEmployedPerson: false,
        entityType: this.entityTypeComputed,
      };

      if (this.legalFormComputed === '1000') {
        tmpUser.isSelfEmployedPerson = (this.isSelfEmployedPersonComputed.name === 'true');
      }

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case '':
          newErrorMessage = '';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-company-data
  text-align: center
  > p
    @include header1
    margin-bottom: $global-padding-horizontal * 3.5
  .app-input
    margin: 0
  form
    .search
      position: relative
      input
        padding-right: $global-padding-horizontal * 10
      .app-button
        position: absolute
        right: 0
        top: 0
        height: 44px
    .info
      @include header4
      margin: ($global-padding-vertical * 4) 0
    .info-warning
      margin-bottom: 0
    p.legal-form.info, p.entity-type.info
      margin: 0
      text-align: left
    .legal-form, .legal-sector, .legal-tva-number, .business-type, .entity-type
      position: relative
      margin-top: $global-padding-vertical * 3
    .is-self-employed-person
      p.info
        text-align: left
    .annotation
      position: absolute
      top: 0
      right: -25px
    .app-button.next
      svg path
        stroke: white
</style>
