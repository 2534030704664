<template>
  <div class="card-physical">
    <div class="grid-x align-middle">
      <div class="cell auto" v-show="customer && customer.customer.type === EnumCustomerType.BUSINESS && !selectedCard"><p class="title">Cartes physiques</p></div>
      <div class="cell auto" v-show="customer && customer.customer.type === EnumCustomerType.BUSINESS && selectedCard"><p class="title">{{ selectedCard && selectedCard.kalveenCustomName || 'Carte physique' }}</p></div>

      <!--<div class="cell shrink container-buttons" v-show="customer && customer.customer.type === EnumCustomerType.BUSINESS">
        <app-button v-if="Object.keys($route.query).length <= 0" class="activate"
          size="large"
          kind="primary"
          :class="{ 'active': this.isTheFlapOpenCreatePhysicalCard }"
          @click="() => isTheFlapOpenCreatePhysicalCard = true">
          Nouvelle carte <SvgPlus/>
        </app-button>
      </div>-->
    </div>

    <app-loader v-if="isLoading || !cards"></app-loader>
    <template v-else>
      <List
        v-if="Object.keys($route.query).length <= 0"
        :cards="cardsComputed"
        @click="(card) => this.getPhysicalCard(card.cardId)"
      />
      <Detail
        v-else
        ref="physical-detail"
        kind="physical"
        :card="selectedCard || {}"
        @destroy:card="selectedCard = null"
        @updated:card="(newValue) => { selectedCard = newValue; }"
      />
    </template>

    <!--<CreatePhysicalCard
      kind="physical"
      :isOpen="isTheFlapOpenCreatePhysicalCard"
      @onChange="(newData) => limitsComputed = newData"
      @is-open="isCreatePhysicalCardFlapOpen"
      @click="() => { isTheFlapOpenCreatePhysicalCard = false; getPhysicalCard() }"
      :price="createCardPrice"
    />-->
  </div>
</template>

<script>
// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumPackageBenefits from '../../../services/enums/benefits/package-benefit';
import EnumLockStatus from '../../../services/enums/cards/lockStatus';

// Components
import List from './List.vue';
import Detail from '../common/Detail.vue';
// import CreatePhysicalCard from './CreatePhysicalCard.vue';

// SVG
// import SvgPlus from '../../../assets/img/icons/plus.svg?inline';

// Api
import ApiCard from '../../../services/api/card';
import ApiPackage from '../../../services/api/package';

export default {
  name: 'card-physical',
  inject: ['$getCustomer'],
  components: {
    Detail,
    List,
    // CreatePhysicalCard,

    // SvgPlus,
  },
  data() {
    return {
      cards: null,
      selectedCard: null,
      isLoading: false,
      businessPackage: null,

      isTheFlapOpenCreatePhysicalCard: false,

      EnumCustomerType,
      EnumPackageBenefits,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    cardsComputed: {
      get() { return this.cards; },
      set(newValue) { this.cards = newValue; },
    },
    createCardPrice() {
      const benefit = this.businessPackage?.data?.benefits?.[this.EnumPackageBenefits.NUMBER_OF_PHYSICAL_CARDS];

      const fee = benefit?.fees[0]?.amount;

      if (typeof fee !== 'number') return null;

      const format = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', signDisplay: 'never' });
      return format.format(fee / 100);
    },
  },

  watch: {
    $route() {
      if (this.$route.query.cardId) {
        this.isTheFlapOpenLimits = false;
      } else {
        // Rafraîchissement de la liste
        this.getPhysicalCards();
      }
    },
    cardsComputed() {
      if (this.$route.query.cardId) {
        this.selectedCard = this.cards.data.find((card) => String(card.cardId) === this.$route.query.cardId);
      }
    },
    selectedCard(value) {
      if (value) {
        this.$router.replace({ name: this.$route.name, query: { cardId: value.cardId } }).catch(() => {});
      } else {
        this.$router.replace({ name: this.$route.name }).catch(() => {});
      }
    },
    isTheFlapOpenCreatePhysicalCard(newValue) {
      if (!newValue) this.limitsComputed = null;
      this.isTheFlapOpenCreatePhysicalCard = newValue;
    },
  },
  mounted() {
    if (this.$route.query.cardId) {
      this.cards = this.getPhysicalCard(this.$route.query.cardId);
    } else {
      this.getPhysicalCards();
    }
    this.getPackage();
  },
  methods: {
    async getPhysicalCard(cardId) {
      this.isLoading = true;
      try {
        const cards = await ApiCard.getCard(true, [EnumLockStatus.UNLOCK.name, EnumLockStatus.LOCK.name], cardId);
        [this.selectedCard] = cards.data;
        this.isLoading = false;
        return cards;
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    },
    async getPhysicalCards(showLoader = true) {
      if (showLoader) this.isLoading = true;

      try {
        this.cards = await ApiCard.getCards(true, [EnumLockStatus.LOCK.name, EnumLockStatus.UNLOCK.name]);
      } catch (error) {
        throw error;
      }

      if (showLoader) this.isLoading = false;
    },
    async getPackage() {
      try {
        this.businessPackage = await ApiPackage.getMyPackage();
      } catch (error) {
        //
      }
    },
    isCreatePhysicalCardFlapOpen(value) {
      this.isTheFlapOpenCreatePhysicalCard = value;

      if (!value) {
        this.getPhysicalCards();
      }
    },
  },
};
</script>

<style lang="sass">
.card-physical
  p
    margin: 0
  .title
    @include header2
  button.app-button.activate.active
    color: var(--color-black)
    background-color: var(--color-70) !important
    svg path
      fill: var(--color-black)
</style>
