<template>
  <div class="register-company-numbers">
    <p>Chiffres de votre entreprise</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x align-middle">
        <app-datepicker
          class="cell auto legal-registration-date"
          v-model="legalRegistrationDateComputed"
          label="Date d'immatriculation *"
          :disabledDates="datepickerDisabledDates()"
          isUtc
        ></app-datepicker>

        <app-input
          class="cell auto legal-share-capital"
          v-model="legalShareCapitalComputed"
          label="Capital social *"
          type="number"
         >
          <template v-slot:suffix><SvgEuro /></template>
         </app-input>

        <app-select
          class="cell small-12 legal-annual-turnover"
          v-model="legalAnnualTurnOverComputed"
          label="Chiffre d’affaires annuel (K€) *"
          :options="EnumLegalAnnualTurnOverRanges"
         ></app-select>

        <app-select
          class="cell small-12 legal-net-income-range"
          v-model="legalNetIncomeRangeComputed"
          label="Résultat net annuel (K€) *"
          :options="EnumLegalNetIncomeRanges"
         ></app-select>

        <app-select
          class="cell small-12 legal-number-of-employee-ranges"
          v-model="legalNumberOfEmployeeRangeComputed"
          label="Tranche de salariés *"
          :options="EnumLegalNumberOfEmployeeRanges"
         ></app-select>

        <div class="cell small-12 share-holder-number grid-x align-middle">
          <p class="info">En dehors du responsable légal, combien <br> avez-vous de bénéficiaires effectifs à plus de 25% ?</p>
          <app-select
            class="cell auto"
            v-model="shareholderNumberComputed"
            label="Bénéficiaires"
            :options="shareholderNumberOptionsComputed"
          ></app-select>
        </div>

      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-company-numbers-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!legalRegistrationDateComputed || !legalShareCapitalComputed || !legalAnnualTurnOverComputed || !legalNetIncomeRangeComputed || !legalNumberOfEmployeeRangeComputed || !shareholderNumberComputed" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';
import SvgEuro from '../../../assets/img/icons/euro.svg?inline';

// Enums
import EnumLegalAnnualTurnOverRanges from '../../../../../commons/legalAnnualTurnOverRanges.json';
import EnumLegalNetIncomeRanges from '../../../../../commons/legalNetIncomeRanges.json';
import EnumLegalNumberOfEmployeeRanges from '../../../../../commons/legalNumberOfEmployeeRanges.json';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';

export default {
  name: 'register-company-numbers',
  components: {
    SvgWarning,
    SvgArrow,
    SvgEuro,
  },
  inject: ['$getCustomer'],
  data() {
    return {
      legalRegistrationDate: this.$dayjs().toDate(),
      legalShareCapital: null,
      legalAnnualTurnOver: null,
      legalNetIncomeRange: null,
      legalNumberOfEmployeeRange: null,
      shareholderNumber: null,

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumLegalNumberOfEmployeeRanges,
      EnumLegalNetIncomeRanges,
      EnumLegalAnnualTurnOverRanges,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    legalRegistrationDateComputed: {
      get() { return this.legalRegistrationDate || this.$dayjs(this.customer.customer.legalRegistrationDate).toDate(); },
      set(newValue) { this.legalRegistrationDate = newValue; },
    },
    legalShareCapitalComputed: {
      get() { return this.legalShareCapital || this.customer.customer.legalShareCapital; },
      set(newValue) { this.legalShareCapital = newValue; },
    },
    legalAnnualTurnOverComputed: {
      get() { return this.legalAnnualTurnOver || this.customer.customer.legalAnnualTurnOver; },
      set(newValue) { this.legalAnnualTurnOver = newValue; },
    },
    legalNetIncomeRangeComputed: {
      get() { return this.legalNetIncomeRange || this.customer.customer.legalNetIncomeRange; },
      set(newValue) { this.legalNetIncomeRange = newValue; },
    },
    legalNumberOfEmployeeRangeComputed: {
      get() { return this.legalNumberOfEmployeeRange || this.customer.customer.legalNumberOfEmployeeRange; },
      set(newValue) { this.legalNumberOfEmployeeRange = newValue; },
    },
    shareholderNumberComputed: {
      get() { return this.shareholderNumber || this.customer.customer.shareholderNumber; },
      set(newValue) { this.shareholderNumber = newValue; },
    },
    shareholderNumberOptionsComputed() {
      const full = [{ name: '0', label: '0' }, { name: '1', label: '1' }, { name: '2', label: '2' }, { name: '3', label: '3' }];
      const selfEmployedPerson = [{ name: '0', label: '0' }];
      return this.customer.customer.isSelfEmployedPerson ? selfEmployedPerson : full;
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        legalRegistrationDate: this.legalRegistrationDateComputed,
        legalShareCapital: Number(this.legalShareCapitalComputed),
        legalAnnualTurnOver: this.legalAnnualTurnOverComputed,
        legalNetIncomeRange: this.legalNetIncomeRangeComputed,
        legalNumberOfEmployeeRange: this.legalNumberOfEmployeeRangeComputed,
        shareholderNumber: Number(this.shareholderNumberComputed),
      };

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    datepickerDisabledDates() {
      // eslint-disable-next-line
      const dayjs = require('dayjs');
      // Objet spécifique au package vuejs-datepicker
      return {
        customPredictor(date) {
          if (dayjs() < dayjs(date)) {
            return true;
          }
          return false;
        },
      };
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case '':
          newErrorMessage = '';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-company-numbers
  text-align: center
  > p
    @include header1
    margin-bottom: $global-padding-horizontal * 3.5
  .app-input
    margin: 0
  form
    .legal-registration-date, .info
      margin-right: $global-padding-horizontal !important
    .legal-share-capital, .share-holder-number .app-select
      margin-left: $global-padding-horizontal
    .legal-annual-turnover, .legal-net-income-range, .legal-number-of-employee-ranges, .share-holder-number
      margin-top: $global-padding-vertical * 3
    .share-holder-number
      margin: ($global-padding-vertical * 4) 0
    .info
      @include header4
      margin: 0
      text-align: left
  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
