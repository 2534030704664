<template>
  <div class="payouts-detail">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => isOpen = value" small>
      <template v-slot:app-flap-main>
        <p class="amount" v-if="data && data.payout && data.payout.amount">{{ data.payout.amount | formatPrice }}</p>
        <p class="amount" v-else>{{ '--' }}</p>
        <p class="label">à {{ data && data.beneficiary && data.beneficiary.name || '--' }}</p>
      </template>
      <template v-slot:app-flap-content>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p>Libellé:</p>
          </div>
          <div class="cell auto">
            <p>{{ data && data.payout && data.payout.label || '--' }}</p>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p>Date:</p>
          </div>
          <div class="cell auto">
            <p>{{ data && data.payout && $dayjs(data.payout.createdDate).format('DD/MM/YYYY') || '--' }}</p>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell auto">
            <p>État:</p>
          </div>
          <div class="cell auto">
            <p class="payout-status" :class="`payout-status-${data.payout.payoutStatus.toLowerCase()}`">{{ data && data.payout && EnumPayoutStatus[data.payout.payoutStatus] || '--' }}</p>
          </div>
        </div>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Enums
import EnumPayoutStatus from '../../services/enums/payouts/payout-status.enum';

export default {
  name: 'payouts-detail',
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,

      EnumPayoutStatus,
    };
  },
  watch: {
    data() {
      this.isOpen = (this.data && Object.keys(this.data).length > 0) || false;
    },
  },
};
</script>

<style lang="sass">
.payouts-detail
  p
    margin: 0
  .amount
    @include header1
  .label
    @include header3
    color: var(--color-grey70)
  .content
    .grid-x
      div:first-child p
        @include header5
        color: var(--color-grey70)
      div:last-child
        text-align: right
        text-transform: uppercase
        @include text
      ~ .grid-x
        border-top: 1px solid var(--color-50)
  .payout-status
    @include header5
  .payout-status-pending
    color: var(--color-100)
  .payout-status-canceled
    color: var(--color-warning)
  .payout-status-validated
    color: var(--color-success)
</style>
