<template>
  <div class="loader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 150 150"
      preserveAspectRatio="xMidYMid"
    >
      <g>
        <circle class="one"></circle>
        <circle class="two"></circle>
        <circle class="three"></circle>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
};
</script>
<style lang="sass">
.loader
  svg
    display: block
    margin: auto
    width: 150px
    height: 150px
    shape-rendering: auto
    background-color: transparent
    g
      transform: translate(35px, 75px)
      .one, .two, .three
        cy: 0
        cx: 0px
        animation: circle-move 0.6s infinite alternate
      .one
        fill: var(--color-50)
        animation-delay: -0.5s
      .two
        fill: var(--color-70)
        cx: 40px
        animation-delay: -0.3s
      .three
        fill: var(--color-100)
        cx: 80px
        animation-delay: -0.1s
@keyframes circle-move
  0%
    r: 0
    opacity: 0
  100%
    r: 10px
    // opacity: 1
</style>
