<template>
  <section class="app-table">
    <div class="grid-x headers">
      <slot name="headers"></slot>
    </div>

    <div v-if="loading" class="loading">
      <slot name="loading">
        <Loader />
      </slot>
    </div>
    <div v-else class="body">
      <slot name="body">
        <slot name="empty-table">
          <div class="empty">
            No results found.
          </div>
        </slot>
      </slot>
    </div>
  </section>
</template>

<script>
import Loader from './AppLoader.vue';

export default {
  name: 'app-table',
  components: { Loader },
  props: {
    loading: [Boolean],
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%
  background: white
  .headers, .body .row
    padding: 0 1rem
  .headers
    color: black
    font-weight: 700
  .body
    .row
      height: 64px
      border-radius: $global-border-radius
      background: white
      color: black
      margin: 8px 0
  .empty
    text-align: center
    padding: 2rem 0
  .loading
    display: flex
    justify-content: center
    align-items: center
    padding: 2rem 0
</style>
