const card = Object.freeze({
  PHYSICAL: {
    label: 'Cartes physiques',
    name: 'cards-card-physical',
  },
  VIRTUAL: {
    label: 'Cartes virtuelles',
    name: 'cards-card-virtual',
  },
});

export default card;
