<template>
  <div class="documents">
    <app-navigation
      v-if="$route.meta.hasNavigation"
      :type="$route.meta.navigationType"
      :routes="navigation"
    ></app-navigation>
    <router-view></router-view>
  </div>
</template>

<script>
import EnumNavigationDocument from '../services/enums/navigation/documents.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'documents',
  data() {
    return {
      EnumNavigationDocument,
      EnumCustomerType,
      navigation: [],
    };
  },
  mounted() {
    this.navigation = this.cleanNavigation();
  },
  methods: {
    cleanNavigation() {
      return Object.values(EnumNavigationDocument).map((route) => route);
    },
  },
};
</script>

<style lang="sass">
.documents
  nav.app-navigation.links a
    padding-top: 0
</style>
