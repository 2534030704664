<template>
  <div class="login-secret-code">
    <p>Saisissez votre code secret !</p>
    <p>Il s'agit du code secret que vous avez choisi lors de la création de votre compte.</p>

    <app-input-pin @input="(event) => onChangeSecretCode(event)" :isVisible="false"></app-input-pin>
    <app-button :disabled="!secretCode || secretCode.length < 4" @click="manageClick">Se connecter</app-button>
  </div>
</template>

<script>
import AppInputPin from '../AppInputPin.vue';

export default {
  name: 'login-secretcode',
  components: {
    AppInputPin,
  },
  props: {
    secretCode: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onChangeSecretCode(event) {
      this.$emit('onChange', { secretCode: event });
    },
    manageClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="sass">
.login-secret-code
  p
    margin: 0
  > p:first-child
    @include header1
  > p:last-child
    @include header4
  .app-input
    ~ .app-input
      margin-top: $global-padding-horizontal * 2
      margin-bottom: $global-padding-horizontal * 2
    .label
      background-color: var(--color-30)
  .app-input-pin
    margin-top: $global-padding-horizontal * 2
  .app-button
    align-self: flex-start
    margin: $global-padding-horizontal * 2 0
</style>
