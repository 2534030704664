<template>
  <div class="app-footer grid-x small-offset-1">
    <a href="/assets/documents/SAS-KVN-politique-de-confidentialite.pdf" target="_blank">Politique de confidentialité</a>

    <span>/</span>

    <a href="/assets/documents/SAS-KVN-mentions-legales.pdf" target="_blank">Mentions légales</a>

    <span>/</span>

    <a href="/assets/documents/traceurs.pdf" target="_blank">Traceurs</a>

    <span>/</span>

    <a href="/assets/documents/SAS-KVN-CGU.pdf" target="_blank">CGU Kalveen</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resizeObserver: null,
    };
  },
  created() {
    this.resizeObserver = new ResizeObserver((element) => document.documentElement.style.setProperty('--app-footer-top', `${element[0].target.clientHeight}px`));
    this.resizeObserver.observe(document.body);
  },
  mounted() {
  },
  destroyed() {
    this.resizeObserver.unobserve(document.body);
    this.resizeObserver = null;
  },
  methods: {
  },
};
</script>

<style lang="sass">
// Etend root
\:root
  --app-footer-height: 24px
  --app-footer-top: 0px

.app-footer
  position: absolute
  width: max-content
  padding-bottom: $global-padding-vertical * 2
  top: calc(var(--app-footer-top) - 50px)
  z-index: 1000
  a
    @include link
    font-size: 10px
    line-height: 24px
    color: var(--color-black)
  span
    margin: 3px 3px 0 3px
</style>
