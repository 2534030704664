const labels = Object.freeze({
  IRREGULAR_USE: 'Utilisation irrégulière',
  OPPOSITION_SEPA: 'Opposition prélèvement SEPA',
  SEIZURE_AND_SALE: 'Saisies-attribution ou saisies-conservatoires',
  SEPA_REJECT_SDDR: 'Rejet de prélèvement',
  NUMBER_OF_CHEQUES: 'Nombre de dépôts de chèques par mois',
  NUMBER_OF_SET_PIN: 'Nombre de modification de code pin par an',
  WITHDRAWAL_OUT_EU: 'Retraits hors Europe',
  TRANSACTION_OUT_EU: 'Paiements hors Europe',
  TRANSFER_PROCEDURE: 'Demande d\'intervention sur une opération de virement',
  BALANCE_CERTIFICATE: 'Attestation de solde',
  WITHDRAWAL_INTRA_EU: 'Retraits intra Europe mais hors France',
  NUMBER_OF_WITHDRAWAL: 'Nombre de retraits par mois',
  MONTHLY_PAYMENT_LIMIT: 'Plafond paiement standard',
  NUMBER_OF_VIRTUAL_CARDS: 'Nombre de cartes virtuelles actives simultanément',
  MONTHLY_WITHDRAWAL_LIMIT: 'Plafond retrait standard',
  NUMBER_OF_PHYSICAL_CARDS: 'Remplacement de carte physique',
  RESEARCH_UNJUSTIFIED_SEPA: 'Recherche sur contestation non justifiée d\'un mandat de prélèvement SEPA',
  PHOTOCOPY_CHEQUE_LESS_YEAR: 'Photocopie de chèque de moins de un an',
  PHOTOCOPY_CHEQUE_MORE_YEAR: 'Photocopie de chèque de plus de un an',
  RESEARCH_UNKNOWN_PLI_ADDRESS: 'Recherche d\'adresse inconnue Pli non Distribué',
  PAYMENT_OF_SUPPORT_MANAGEMENT: 'Gestion d’un paiement direct d’une pension alimentaire',
  UNAUTHORIZED_PAYMENT_TRANSACTIONS: 'Opérations de paiement non autorisées',
  RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_FR: 'Recherche sur contestation non justifiée d\'une opération effectuée par carte, en France',
  RESEARCH_UNJUSTIFIED_CARD_TRANSACTION_NOT_FR: 'Recherche sur contestation non justifiée d\'une opération effectuée par carte, à l\'étranger',
});

export default labels;
