<template>
  <div class="login-identifiers grid-y">
    <p>Bienvenue !</p>

    <form ref="loginIdentifiers" @submit.prevent="manageClick">
      <app-input autocomplete="email"
        type="email"
        label="Adresse e-mail"
        required
        :value="email"
        @input="(event) => onChangeEmail(event)"
      />
      <app-input autocomplete="current-password"
        type="password"
        label="Mot de passe"
        required
        :value="password"
        @input="(event) => onChangePassword(event)"
      />

      <router-link class="forgot-password" :to="{ name: 'forgot-password' }">Mot de passe oublié ?</router-link>
      <app-button :disabled="!email || !password" type="submit">Se connecter</app-button>
    </form>

    <span>Pas encore de compte ? <router-link :to="{ name: 'register' }">S'inscrire</router-link></span>
  </div>
</template>

<script>
export default {
  name: 'login-identifiers',
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  methods: {
    onChangeEmail(event) {
      this.$emit('onChange', { email: event });
    },
    onChangePassword(event) {
      this.$emit('onChange', { password: event });
    },
    manageClick() {
      if (this.$refs.loginIdentifiers.checkValidity()) {
        this.$emit('click');
      } else {
        this.$refs.loginIdentifiers.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.login-identifiers
  > p
    @include header1

  form
    text-align: right

  .app-input
    ~ .app-input
      margin-top: $global-padding-horizontal * 2
      margin-bottom: $global-padding-horizontal * 2
    .label
      background-color: var(--color-30)
  span
    @include link
    color: var(--color-grey70)
    text-decoration: none
  a
    padding: 0
    @include link

  .app-button
    align-self: flex-start
    margin: $global-padding-horizontal * 2 0
</style>
