import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient les informations classiques et treezor d'un client
 *
 * @returns
 */
const getCustomer = async () => {
  try {
    const response = await axios.get(`${API_URL}/customers`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient les utilisateurs enfants de l'utilisateur
 *
 * @param {EnumParentType} parentType
 * @returns
 */
const getCustomerChildren = async (parentType) => {
  try {
    const response = await axios.get(`${API_URL}/customers/children`, {
      params: {
        ...(parentType && { parentType }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie le mot de passe d'un utilisateur
 *
 * @param {string} actualPassword
 * @param {string} password
 * @param {string} passwordConfirm
 * @returns
 */
const setPassword = async (actualPassword, password, passwordConfirm) => {
  try {
    const response = await axios.put(`${API_URL}/customers/set-password`, {
      ...(actualPassword && { actualPassword }),
      ...(password && { password }),
      ...(passwordConfirm && { passwordConfirm }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie le code secret d'un utilisateur
 *
 * @param {string} oldSecretCode
 * @param {string} newSecretCode
 * @param {string} tokenDoubleAuthentication
 * @param {string} doubleAuthent
 * @returns
 */
const setSecretCode = async (oldSecretCode, newSecretCode, tokenDoubleAuthentication, doubleAuthent) => {
  try {
    const response = await axios.put(`${API_URL}/customers/change-secret-code`, {
      ...(oldSecretCode && { oldSecretCode: oldSecretCode.toString().replaceAll(',', '') }),
      ...(newSecretCode && { newSecretCode: newSecretCode.toString().replaceAll(',', '') }),
      ...(tokenDoubleAuthentication && { tokenDoubleAuthentication }),
      ...(doubleAuthent && { doubleAuthent }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie le champ readyForReviewStatus d'un utilisateur
 *
 * @param {EnumReadyForReviewStatus} readyForReviewStatus
 * @returns
 */
const setReadyForReviewStatus = async (readyForReviewStatus) => {
  try {
    const response = await axios.put(`${API_URL}/customers/change-ready-for-review-status`, {
      ...(readyForReviewStatus && { readyForReviewStatus }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie les informations d'un utilisateurvalues
 *
 * Data values
 * @param {string} legalRegistrationNumber
 * @param {string} legalName
 * @param {string} legalForm
 * @param {string} legalSector
 * @param {string} legalTvaNumber
 * @param {string} legalRegistrationDate
 * @param {string} legalShareCapital
 * @param {CustomerLegalAnnualTurnOver} legalAnnualTurnOver
 * @param {CustomerLegalNetIncomeRange} legalNetIncomeRange
 * @param {CustomerLegalNumberOfEmployeeRange} legalNumberOfEmployeeRange
 * @param {number} shareholderNumber
 * @param {number} sharesNumber
 * @param {boolean} activityOutsideEu
 * @param {boolean} economicSanctions
 * @param {boolean} residentCountriesSanctions
 * @param {boolean} involvedSanctions
 * @param {UserTitle} civility
 * @param {string} lastname
 * @param {string} firstname
 * @param {string} birthdate
 * @param {string} placeOfBirth
 * @param {CountryISO} placeBirthCountry
 * @param {string} nationality
 * @param {string} email
 * @param {string} occupation
 * @param {CustomerLegalNetIncomeRange} incomeRange
 * @param {CustomerPersonalAssets} personalAssets
 * @param {boolean} isFrenchFiscalResident
 * @param {boolean} specifiedUsPerson
 * @param {AddressObject} address
 * @param {string} phone
 * @param {boolean} privacyPolicy
 * @param {boolean} generalConditions
 * @param {CustomerParentType} parentType
 * @param {string} parentUserId
 * @param {boolean} treezorContract
 * @param {boolean} kalveenContract
 * @param {boolean} isActive
 * @param {CustomerReadyForReviewStatus} readyForReviewStatus
 * @returns
 */
const setCustomer = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/customers`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer un utilisateur leader pour une souscription business
 *
 * @param {EnumCustomerCivility} civility
 * @param {string} lastname
 * @param {string} firstname
 * @param {string} email
 * @returns
 */
const createLeader = async (civility, lastname, firstname, email) => {
  try {
    const response = await axios.post(`${API_URL}/customers/leader-user`, {
      ...(civility && { civility }),
      ...(lastname && { lastname }),
      ...(firstname && { firstname }),
      ...(email && { email }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifier un utilisateur leader pour une souscription business
 *
 * Data values
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} email
 * @param {string} birthdate
 * @param {string} placeOfBirth
 * @param {string} placeBirthCountry
 * @param {string} nationality
 * @param {string} phone
 * @param {AddressObject} address
 * @param {string} occupation
 * @param {string} personalAssets
 * @param {string} incomeRange
 * @param {boolean} specifiedUsPerson
 * @param {boolean} isFrenchFiscalResident
 * @returns
 */
const setLeader = async (data) => {
  try {
    const response = await axios.put(`${API_URL}/customers/leader-user`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Créer un utilisateur associé à un customer business
 *
 * Data values
 *
 * @param {string} parentType
 * @param {string} civility
 * @param {string} lastname
 * @param {string} firstname
 * @param {string} email
 *
 * @returns
 */
const createAssociate = async (parentType, civility, lastname, firstname, email) => {
  try {
    const response = await axios.post(`${API_URL}/customers/associate-user`, {
      ...(parentType && { parentType }),
      ...(civility && { civility }),
      ...(lastname && { lastname }),
      ...(firstname && { firstname }),
      ...(email && { email }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Met à jour un utilisateur associé
 *
 * Data values
 * Voir setCustomer
 *
 * @param {string} customerId
 * @returns
 */
const setAssociate = async (customerId, data) => {
  try {
    const response = await axios.put(`${API_URL}/customers/associate-user/${customerId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Envoie une demande d'ajout de document avec ubble (liveness)
 *
 * @param {string} customerId
 * @returns
 */
const askKycLivenessValidation = async (customerId) => {
  try {
    const response = await axios.post(`${API_URL}/customers/kyc-liveness`, {
      ...(customerId && { customerId }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modifie les paramètres d'export comptable de l'utilisateur
 *
 * @param {boolean} isExportAccounting
 * @param {string} exportAccountingEmail
 * @returns
 */
const setAccounginExportSettings = async (isExportAccounting, exportAccountingEmail) => {
  try {
    await axios.put(`${API_URL}/customers/export-accounting`, {
      ...(typeof isExportAccounting === 'boolean' && { isExportAccounting }),
      ...(exportAccountingEmail && { exportAccountingEmail }),
    });

    return;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getCustomer = getCustomer;
api.getCustomerChildren = getCustomerChildren;
api.setPassword = setPassword;
api.setSecretCode = setSecretCode;
api.setCustomer = setCustomer;
api.setReadyForReviewStatus = setReadyForReviewStatus;
api.createLeader = createLeader;
api.setLeader = setLeader;
api.createAssociate = createAssociate;
api.setAssociate = setAssociate;
api.askKycLivenessValidation = askKycLivenessValidation;
api.setAccounginExportSettings = setAccounginExportSettings;

export default api;
