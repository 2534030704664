<template>
  <div class="payout-add-beneficiary">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>{{ flapTitle }}</p>
      </template>
      <template v-slot:app-flap-content>
        <ListBeneficiaries :selectedBeneficiary="selectedBeneficiary" :selectBeneficiary="selectBeneficiary" />

        <app-button class="add-beneficiary-container" kind="primary" size="large" @click="createBeneficiary">
          <div class="icon">
            <SvgPlus />
          </div>
          <span>Créer un bénéficiaire</span>

          <SvgArrowRight class="arrow-right" />
        </app-button>
      </template>
    </app-flap>
  </div>
</template>

<script>
// SVG
import SvgPlus from '../../assets/img/icons/plus.svg?inline';
import SvgArrowRight from '../../assets/img/icons/arrow-right.svg?inline';

// Components
import ListBeneficiaries from './components/ListBeneficiaries.vue';

export default {
  name: 'payout-add-beneficiary',
  components: {
    SvgPlus,
    SvgArrowRight,
    ListBeneficiaries,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectBeneficiary: {
      type: Function,
      required: true,
    },
    selectedBeneficiary: {
      type: Object,
      required: false,
    },
    createBeneficiary: {
      type: Function,
      required: true,
    },
  },
  computed: {
    flapTitle: {
      get() {
        if (this.step === 1) return 'Ajouter un bénéficiaire';
        if (this.step === 2) return 'Sécurité code secret';

        return 'Vos bénéficiaires';
      },
    },
  },
};
</script>

<style lang="sass">
.payout-add-beneficiary
  max-width: 484px
  .app-button.add-beneficiary-container
    @include tile
    margin: $global-padding-horizontal 0
    background-color: var(--color-50)
    .icon
      background-color: var(--color-100)
</style>
