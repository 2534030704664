<template>
  <div class="register-profil-fiscal-residence">
    <p>Résidence fiscale</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x align-middle">
        <p class="cell medium-6 is-french-fiscal-resident">Je certifie que je suis uniquement un résident fiscal français *</p>
        <app-radio-custom class="cell medium-6 is-french-fiscal-resident align-right" :values=EnumYesNo @value="(value) => isFrenchFiscalResidentComputed = value" :value="isFrenchFiscalResidentComputed"></app-radio-custom>

        <p class="cell shrink specified-us-person">Je certifie que je ne suis pas une US Person *</p>
        <span class="specified-us-person cell shrink"><SvgInfos /></span>
        <app-radio-custom class="cell auto specified-us-person align-right" :values=EnumYesNo @value="(value) => specifiedUsPersonComputed = value" :value="specifiedUsPersonComputed"></app-radio-custom>

        <template v-if="(isFrenchFiscalResidentComputed && specifiedUsPersonComputed) && (isFrenchFiscalResidentComputed.name === 'false' || specifiedUsPersonComputed.name === 'false')">
          <app-select
            class="cell small-12 tax-residences-country"
            v-model="countryComputed"
            label="Pays de résidence fiscale *"
            required
            :options="EnumCountries">
          </app-select>

          <app-input
            class="cell small-12 tax-residences-taxPayerId"
            label="Numéro d’identification fiscale *"
            type="text"
            required
            v-model="taxPayerIdComputed"
          ></app-input>
        </template>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-profil-fiscal-residence-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!isFrenchFiscalResidentComputed || !specifiedUsPersonComputed" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';
import ApiTaxResidences from '../../../services/api/taxResidences';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';
import SvgInfos from '../../../assets/img/icons/infos.svg?inline';

// Components

// Enums
import EnumCountries from '../../../../../commons/countries.json';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumYesNo from '../../../services/enums/yesNo.enum';

export default {
  name: 'register-profil-fiscal-residence',
  components: {
    SvgWarning,
    SvgArrow,
    SvgInfos,
  },
  inject: ['$getCustomer', '$getLeader', '$isSelfEmployedPerson'],
  data() {
    return {
      isFrenchFiscalResident: null,
      specifiedUsPerson: null,
      taxResidencesStatic: null,
      taxResidences: {
        country: null,
        taxPayerId: null,
      },

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumNotificationType,
      EnumCountries: this.$utils.sortAlphabetically(EnumCountries),
      EnumYesNo,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    leader() {
      return this.$getLeader();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    isFrenchFiscalResidentComputed: {
      get() { return this.isFrenchFiscalResident || EnumYesNo[this.leader?.customer.isFrenchFiscalResident] || EnumYesNo[this.customer.customer.isFrenchFiscalResident]; },
      set(newValue) { this.isFrenchFiscalResident = newValue; },
    },
    specifiedUsPersonComputed: {
      get() {
        return this.specifiedUsPerson
          || (this.leader && (this.leader.customer.specifiedUsPerson !== null ? EnumYesNo[!this.leader.customer.specifiedUsPerson] : null))
          || (this.customer && (this.customer.customer.specifiedUsPerson !== null ? EnumYesNo[!this.customer.customer.specifiedUsPerson] : null));
      },
      set(newValue) { this.specifiedUsPerson = newValue; },
    },
    countryComputed: {
      get() { return this.taxResidences.country || this.taxResidencesStatic?.country || EnumCountries[this.taxResidences.country]; },
      set(newValue) { this.taxResidences.country = newValue; },
    },
    taxPayerIdComputed: {
      get() { return this.taxResidences.taxPayerId || this.taxResidencesStatic?.taxPayerId || this.customer.customer.taxPayerId; },
      set(newValue) { this.taxResidences.taxPayerId = newValue; },
    },
  },
  watch: {
    customer() {
      this.getTaxResidences();
    },
  },
  mounted() {
    this.getTaxResidences();
  },
  methods: {
    async getTaxResidences() {
      try {
        this.taxResidencesStatic = await ApiTaxResidences.getTaxResidences(this.leader?.customer.treezorUserId);
      } catch (error) {
        //
      }
    },
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        isFrenchFiscalResident: (this.isFrenchFiscalResidentComputed.name === 'true'),
        specifiedUsPerson: (this.specifiedUsPersonComputed.name === 'true'),
      };

      try {
        // Modification des informations de l'utilisateur avant la taxe pour avoir un treezorUserId
        tmpUser.specifiedUsPerson = !tmpUser.specifiedUsPerson;
        if ((this.customer.customer.type === EnumCustomerType.INDIVIDUAL) || this.isSelfEmployedPerson) {
          await ApiCustomer.setCustomer(tmpUser);
          this.$getCustomer(true);
        } else {
          await ApiCustomer.setLeader(tmpUser);
          this.$getLeader(true);
        }

        // Si l'utilisateur est une US Person et qu'au moins un champ obligatoire n'est pas renseigné
        if ((!tmpUser.isFrenchFiscalResident || tmpUser.specifiedUsPerson) && (!this.countryComputed || !this.taxPayerIdComputed)) {
          if (!this.countryComputed && !this.taxPayerIdComputed) {
            this.errors.push('country must be a valid enum value', 'taxPayerId must be a string');
          } else if (!this.countryComputed) {
            this.errors.push('country must be a valid enum value');
          } else if (!this.taxPayerIdComputed) {
            this.errors.push('taxPayerId must be a string');
          }

          throw new Error();
        }

        const tmpTreezorUserId = this.customer.customer.type === EnumCustomerType.BUSINESS ? this.leader?.customer.treezorUserId : this.customer.customer.treezorUserId;
        if ((!tmpUser.isFrenchFiscalResident || tmpUser.specifiedUsPerson) && (this.countryComputed && this.taxPayerIdComputed)) {
          // création
          if (!this.taxResidencesStatic) {
            await ApiTaxResidences.createTaxResidences(this.countryComputed, this.taxPayerIdComputed, tmpTreezorUserId);
          } else {
            // modification
            this.taxResidences = await ApiTaxResidences.setTaxResidence(this.taxResidencesStatic.id, this.countryComputed, this.taxPayerIdComputed, tmpTreezorUserId);
          }
        } else if (this.taxResidencesStatic) {
          // suppression
          await ApiTaxResidences.deleteTaxResidence(this.taxResidencesStatic.id, tmpTreezorUserId);
        }

        this.$emit('click');
      } catch (error) {
        if (error.response?.data.message && error.response?.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      } finally {
        this.isLoading = false;
      }
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'country must be a valid enum value':
          newErrorMessage = 'Le pays de résidence fiscale doit-être renseigné.';
          break;
        case 'taxPayerId must be a string':
          newErrorMessage = 'Le numéro d\'identification fiscale doit-être renseigné.';
          break;
        case 'country must be a string':
          newErrorMessage = 'Le pays doit-être une chaîne de caractères.';
          break;
        case 'The email is already used':
          newErrorMessage = 'L\'adresse email est déjà utilisée, veuilllez contacter le support.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-profil-fiscal-residence
  > p
    @include header1
    text-align: center
    margin-bottom: 20px
  .app-input
    margin: 0
  form
    .is-french-fiscal-resident
      margin: $global-padding-vertical * 3 0
    .specified-us-person
      svg
        margin: 0 0 5px 5px
        width: 14px
    .is-french-fiscal-resident, .specified-us-person
      @include header4
    .tax-residences-country, .tax-residences-taxPayerId
      margin-top: $global-padding-vertical * 3

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
