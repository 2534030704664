<template>
  <div class="register-profil-personal-information">
    <p>Informations personnelles</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x align-middle">
        <p class="civility cell medium-6">Vous êtes ? * <SvgInfos /></p>
        <app-radio-custom class="civility cell medium-6" :values=civilities @value="(value) => civility = value" :value="civilityComputed"></app-radio-custom>

        <app-input
          class="cell small-12 lastname"
          label="Nom *"
          type="text"
          required
          v-model="lastnameComputed"
        ></app-input>

        <app-input
          class="cell small-12 firstname"
          label="1er Prénom *"
          type="text"
          required
          v-model="firstnameComputed"
        ></app-input>

        <app-input v-if="(customer.customer.type === EnumCustomerType.BUSINESS) && !customer.customer.isSelfEmployedPerson"
          class="cell small-12 email"
          label="Email *"
          type="text"
          required
          v-model="emailComputed"
        ></app-input>
      </div>

      <div class="errors" v-if="errors && errors.length > 0">
        <SvgWarning width="22" />
        <ul>
          <li v-for="(error, index) in errors" :key="`register-profil-personal-information-error-${index}`">
            {{ transformError(error) }}
          </li>
        </ul>
      </div>

      <app-button :disabled="!civilityComputed || !lastnameComputed || !firstnameComputed || (customer.customer.type === EnumCustomerType.BUSINESS ? !emailComputed : null)" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgInfos from '../../../assets/img/icons/infos.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';

// Components
import AppRadioCustom from '../../AppRadioCustom.vue';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumCivility from '../../../services/enums/customer/civility.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';
import utils from '../../../services/utils/utils';

export default {
  name: 'register-profil-personal-information',
  components: {
    SvgWarning,
    SvgInfos,
    SvgArrow,

    AppRadioCustom,
  },
  inject: ['$getCustomer', '$getLeader', '$isSelfEmployedPerson'],
  data() {
    return {
      civilities: utils.transformEnumToArray(EnumCivility),

      civility: null,
      lastname: null,
      firstname: null,
      email: null,

      errors: [],
      isLoading: false,

      EnumCustomerType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    leader() {
      return this.$getLeader();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    civilityComputed: {
      get() { return this.civility || EnumCivility[this.leader?.customer?.civility] || EnumCivility[this.customer?.customer?.civility]; },
      set(newValue) { this.civility = newValue; },
    },
    lastnameComputed: {
      get() { return this.lastname || this.leader?.customer?.lastname || this.customer?.customer?.lastname; },
      set(newValue) { this.lastname = newValue; },
    },
    firstnameComputed: {
      get() { return this.firstname || this.leader?.customer?.firstname || this.customer?.customer?.firstname; },
      set(newValue) { this.firstname = newValue; },
    },
    emailComputed: {
      get() { return this.email || (((this.customer?.customer?.type === EnumCustomerType.BUSINESS) && !this.customer?.customer?.isSelfEmployedPerson) ? this.leader?.customer?.email : this.customer?.customer?.email); },
      set(newValue) { this.email = newValue.toLowerCase(); },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        civility: this.civilityComputed.name,
        lastname: this.lastnameComputed,
        firstname: this.firstnameComputed,
      };

      try {
        if ((this.customer.customer.type === EnumCustomerType.INDIVIDUAL) || this.isSelfEmployedPerson) {
          await ApiCustomer.setCustomer(tmpUser);
          this.$getCustomer(true);
        } else {
          // eslint-disable-next-line
          if (this.leader && this.leader.customer.firstname && this.leader.customer.lastname && this.leader.customer.email) {
            await ApiCustomer.setLeader({ ...tmpUser, email: this.emailComputed });
          } else {
            await ApiCustomer.createLeader(this.civilityComputed.name, this.lastnameComputed, this.firstnameComputed, this.emailComputed);
          }
          this.$getLeader(true);
        }
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case `Key (lower(email::text))=(${this.emailComputed}) already exists.`:
          newErrorMessage = 'Cet adresse e-mail est déjà utilisée, veuillez en renseigner une autre.';
          break;
        case 'email must be an email':
          newErrorMessage = 'Cette adresse e-mail n\'est pas valide.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-profil-personal-information
  text-align: center
  > p
    @include header1
    margin-bottom: $global-padding-horizontal * 3.5
  .app-input
    margin: 0
  form
    .lastname
      margin-top: $global-padding-vertical * 5
    .firstname, .email
      margin-top: $global-padding-vertical * 3
    .civility
      @include header4
      color: var(--color-grey90)
      text-align: center
      p
        text-align: left
      .app-radio-custom
        justify-content: flex-end
      svg
        margin: 0 0 5px 5px
        width: 14px

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
