<template>
  <div class="app-alerts">
    <transition-group name="alert">
      <div class="app-alert grid-x align-middle" v-for="(alert, index) in alerts" :key="`alert-${index}`" @click="alert.onClick" :class="{ 'clickable': alert.onClick }">
        <SvgWarning class="cell shrink"/>

        <div class="cell auto">
          <p class="title">{{ alert.title }}</p>
          <p class="text">{{ alert.text }}</p>
        </div>

        <SvgCancel v-if="alert.isCancelDisplayed" class="cancel" @click="() => cancelAlert(alert)" />

        <div class="blur"></div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import AppEvent from '../utils/AppEvent';
import SvgCancel from '../../assets/img/icons/cancel.svg?inline';
import SvgWarning from '../../assets/img/icons/warning.svg?inline';

export default {
  components: {
    SvgCancel,
    SvgWarning,
  },
  data() {
    return {
      visible: false,
      alerts: [],
    };
  },
  beforeMount() {
    AppEvent.$on('app-alert-show', (params) => {
      this.show(params);
    });
    AppEvent.$on('app-alert-cancel', (alert) => {
      this.cancelAlert(alert);
    });
  },
  beforeDestroy() {
    AppEvent.$off('app-alert-show', (params) => {
      this.show(params);
    });
    AppEvent.$off('app-alert-cancel', (alert) => {
      this.cancelAlert(alert);
    });
  },
  methods: {
    show(params) {
      this.visible = true;
      const alert = {
        title: params.title,
        text: params.text,
        isCancelDisplayed: params.isCancelDisplayed,
        onClick: params.onClick,
      };
      this.alerts.push(alert);
    },
    cancelAlert(alert) {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
    },
  },
};
</script>

<style lang="sass">
.app-alerts
  position: fixed
  bottom: $global-padding-vertical
  z-index: 10000
  .app-alert
    position: relative
    padding: $global-padding-horizontal ($global-padding-vertical * 2)
    border-radius: $global-border-radius * 2
    text-align: left
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05)
    background-color: rgba(26, 32, 44, 0.75)
    overflow: hidden
    &.clickable
      cursor: pointer
  .title, .text
    margin: 0 0 ($global-padding-horizontal / 4) 0
    color: var(--color-cloud) !important
  .title
    @include header5
  .text
    @include details

  svg
    &:first-child
      height: 24px
      width: 24px
      margin-right: $global-padding-horizontal + 4
      circle
        stroke: var(--color-cloud)
      path
        fill: var(--color-cloud)
    &.type
      margin-right: ($global-padding-horizontal / 2)
    &.cancel
      align-self: flex-start
      cursor: pointer
      width: 15px
      height: 15px
      margin-left: ($global-padding-horizontal / 2)
      path
        stroke: var(--color-cloud)

.alert-enter-active, .alert-leave-active
  transition: all 0.35s ease-in-out

.alert-enter, .alert-leave-to
  transform: translateY(40px)
  opacity: 0

</style>
