<template>
  <div class="register-email">
    <app-two-blocks>
      <template v-slot:main>
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
          <p class="title">Inscription</p>
          <template v-if="!hasEmailSent">
            <p class="subtitle">Saisissez votre adresse e-mail, vous en aurez besoin pour vous connecter à votre espace Kalveen. </p>

            <form ref="registerEmail" @submit.prevent="manageClick">
              <app-input autocomplete="email"
                type="email"
                label="Adresse e-mail"
                required
                :value="email"
                @input="(event) => onChangeEmail(event)"
              />
              <div class="errors" v-if="errors && errors.length > 0">
                <SvgWarning width="22" />
                <ul>
                  <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                    {{ transformError() }}
                  </li>
                </ul>
              </div>

              <app-button :disabled="!email" type="submit">M'inscrire</app-button>
            </form>
          </template>
          <template v-else>
            <p class="subtitle">{{ `Un mail vient d’être envoyé à l’adresse ${ email }`}}.<br><br></p>
            <p class="subtitle">Pour poursuivre votre inscription, veuillez valider votre email en cliquant sur le lien prévu à cet effet.</p>
          </template>
        </template>
      </template>
      <template v-slot:animation>
        <app-lottie
          :dataAppearance="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase() ? EmailAppeareanceIndividual : EmailAppeareanceBusiness"
          :dataLoop="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase() ? EmailLoopIndividual : EmailLoopBusiness"
        ></app-lottie>
      </template>
    </app-two-blocks>
  </div>
</template>

<script>
// Api
// import ls from 'local-storage';

// Animations
import EmailAppeareanceIndividual from '../../../assets/animations/individual/email-appearance.json';
import EmailLoopIndividual from '../../../assets/animations/individual/email-loop.json';
import EmailAppeareanceBusiness from '../../../assets/animations/business/email-appearance.json';
import EmailLoopBusiness from '../../../assets/animations/business/email-loop.json';

// Components
import AppLottie from '../../AppLottie.vue';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';

export default {
  name: 'register-email',
  components: {
    AppLottie,
    SvgWarning,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    hasEmailSent: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Animation
      animation: null,
      isAnimationAppearanceCompleted: false,

      EnumCustomerType,

      EmailAppeareanceIndividual,
      EmailLoopIndividual,
      EmailAppeareanceBusiness,
      EmailLoopBusiness,
    };
  },
  mounted() {
    this.setAppFooterPosition();
  },
  methods: {
    onChangeEmail(event) {
      this.$emit('onChange', event.toLowerCase());
    },
    manageClick() {
      if (this.$refs.registerEmail.checkValidity()) {
        this.$emit('click');
      } else {
        this.$refs.registerEmail.reportValidity();
      }
    },
    transformError() {
      const newErrorMessage = 'L\'adresse email saisie est déjà utilisée. Nous vous invitons à en choisir une autre.';
      return newErrorMessage;
    },
    setAppFooterPosition() {
      const component = document.querySelector('.app-footer');
      component.style.removeProperty('margin');
      component.style.left = 'initial';
      component.style.right = 'initial';
      component.style.transform = 'initial';
    },
  },
};
</script>

<style lang="sass">
#app.business
  .register
    .register-email
      .illustration::after
        background-image: url('../../../assets/img/business/background-register.svg')

.register-email
  p
    &.title
      @include header1
    &.subtitle
      @include header3
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)
  .content
    text-align: left
    p:first-child
      margin-bottom: ($global-padding-horizontal / 2)
    form
      margin-top: $global-padding-horizontal * 2
      .app-button
        margin-top: $global-padding-horizontal * 5
  .illustration
    &::after
      background-image: url('../../../assets/img/individual/background-register.svg')

  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
