<template>
  <div class="proofs-list">
    <app-loader v-if="!listComputed"></app-loader>
    <div v-else class="category grid-x grid-margin-x" v-for="(proof, pindex) in listComputed" :key="`${Math.random()}-${pindex}`">
      <!-- Affiche 1 carte dans le cas d'une action liveness -->
      <template v-if="proof[1].isLiveness">
        <div class="proof cell small-12 medium-6" :key="`${Math.random()}-${pindex}`">
          <SvgBadgeCheck v-if="isDisabled(proof[0].IDENTITY_CARD.document, proof[1].isLiveness)"/>
          <SvgImagePortrait v-else></SvgImagePortrait>
          <p class="label">Vérification d’identité</p>
          <app-button
            :disabled="isDisabled(proof[0].IDENTITY_CARD.document, proof[1].isLiveness)"
            size="small"
            @click="() => $emit('click', { ...proof[0].IDENTITY_CARD, isLiveness: proof[1].isLiveness })">
            <span>{{ getDocumentStatus(proof[0].IDENTITY_CARD.document, proof[1].isLiveness) }}</span>
          </app-button>
        </div>
      </template>
      <!-- Sinon affiche un nombre de carte correspondant au nombre de documents demandés -->
      <template v-else v-for="(categories) in proof">
        <template v-for="(document, dindex) in categories">
          <!-- Affiche la description-->
          <p v-if="typeof document === 'string'"
            class="description cell small-12"
            :key="`${Math.random()}-${dindex}`">
            {{ document }}
          </p>
          <!-- Affiche les documents demandés -->
          <div
            v-if="typeof document === 'object'"
            class="proof cell small-12 medium-6"
            :key="`${Math.random()}-${dindex}`"
          >
            <SvgFile />
            <p class="label">{{ document.description }}</p>
            <app-button
              :disabled="isDisabled(document.document, proof[1].isLiveness)"
              size="small"
              @click="() => $emit('click', { ...document, isLiveness: proof[1].isLiveness })">
              <span>{{ getDocumentStatus(document.document, proof[1].isLiveness) }}</span>
            </app-button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
// Enums
import EnumDocumentStatus from '../../../services/enums/documents/status.enum';
import EnumProofsCorrelation from '../../../services/enums/proofs/correlation.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumLivenessStatus from '../../../services/enums/liveness/status.enum';
import EnumDocumentType from '../../../services/enums/documents/type.enum';

// Svg
import SvgFile from '../../../assets/img/icons/file.svg?inline';
import SvgImagePortrait from '../../../assets/img/icons/image-portrait.svg?inline';
import SvgBadgeCheck from '../../../assets/img/icons/badge-check.svg?inline';

// Api
import ApiDocuments from '../../../services/api/document';

export default {
  name: 'proofs-list',
  inject: ['$getCustomer', '$isAccountNotValidated', '$isSelfEmployedPerson'],
  components: {
    SvgFile,
    SvgImagePortrait,
    SvgBadgeCheck,
  },
  props: {
    child: {
      type: Object,
      required: true,
    },
    entityKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documents: [],
      isLoading: false,

      EnumDocumentStatus,
      EnumProofsCorrelation,
      EnumCustomerType,
      EnumLivenessStatus,
      EnumDocumentType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    listComputed() {
      const proofs = this.getCorrectProofs(this.entityKey, this.child);
      // Permet d'ajouter la tuile autres document pour que l'utilisateur importe des documents autres que ceux requis
      const otherProof = {
        OTHER: [[
          'Autres documents',
          {
            description: 'Sélectionner un document',
            document: 'OTHER',
          },
          ...this.documents.map((document) => ({ description: Object.values(EnumDocumentType).find((value) => value.name === document.documentType).label, document: Object.keys(EnumDocumentType).find((key) => EnumDocumentType[key].name === document.documentType) })),
        ], { isLiveness: false }],
      };
      const proofListCompleted = {
        ...proofs,
        ...otherProof,
      };
      return proofListCompleted;
    },
    isAccountNotValidated() {
      return this.$isAccountNotValidated();
    },
  },
  watch: {
    customer() {
      this.getDocuments();
    },
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    async getDocuments() {
      let tmpCustomerId = this.child.customer.customerId;
      // Si c'est un particulier ou une entreprise pas besoin de passer de tmpCustomerId
      if (!this.child.customer.parentType) tmpCustomerId = null;
      try {
        this.documents = await ApiDocuments.getDocuments(tmpCustomerId, null, null);
      } catch (error) {
        throw error;
      }
    },
    getCorrectProofs(key, customer) {
      /**
      * 1er cas -> customer de type BUSINESS non auto-entrepreneur affiche les justificatifs pour l'entreprise
      * 2ème cas -> customer de type BUSINESS auto-entrepreneur affiche les justificatifs de l'entreprise + ceux pour un particulier
      * 3ème cas -> si le customer possède un parentType (LEADER ou SHAREHOLDER) on affiche les justificatifs liés
      * 4ème cas -> alors c'est un particulier donc on affiche ses justificatifs
      */
      /* eslint-disable */
      return (customer.customer.type === EnumCustomerType.BUSINESS) && !this.isSelfEmployedPerson
        ? EnumProofsCorrelation[key].BUSINESS
        : (customer.customer.type === EnumCustomerType.BUSINESS) && this.isSelfEmployedPerson
          ? { ...EnumProofsCorrelation[key].BUSINESS, ...EnumProofsCorrelation[key].LEADER }
          : customer.customer.parentType
            ? EnumProofsCorrelation[key][customer.customer.parentType]
            : Object.values(EnumProofsCorrelation[key].LEADER);
      /* eslint-enable */
    },
    isDisabled(documentTypeId, hasLiveness) {
      if (!documentTypeId) return null;
      if (documentTypeId === 'OTHER') return false;

      const isLivenessBeingProcessed = hasLiveness && (this.child.customer.livenessStatus === EnumLivenessStatus.INITIATED
        || this.child.customer.livenessStatus === EnumLivenessStatus.PROCESSED);

      const documentType = EnumDocumentType[Object.keys(EnumDocumentType).find((key) => key === documentTypeId)].name;
      const isDocumentExist = this.documents.some((document) => document.documentType === documentType);

      const isValidated = isDocumentExist ? this.isDocumentValidated(documentType) : false;

      return !this.isAccountNotValidated
        || isLivenessBeingProcessed
        || (isDocumentExist
          ? (isDocumentExist && isValidated)
          : false
        );
    },
    isDocumentValidated(documentType) {
      if (!documentType) return false;

      const tmpDocument = this.documents.find((document) => document.documentType === documentType);
      if (!tmpDocument) return false;

      let isValidated;
      if (tmpDocument.documentStatus) {
        isValidated = (tmpDocument.documentStatus === EnumDocumentStatus.VALIDATED.name
        || tmpDocument.documentStatus === EnumDocumentStatus.PENDING.name);
      }

      return isValidated;
    },
    getDocumentStatus(documentTypeId, hasLiveness) {
      let value = 'Importer';
      if (documentTypeId === 'OTHER') return value;

      const documentType = EnumDocumentType[Object.keys(EnumDocumentType).find((key) => key === documentTypeId)].name;
      const tmpDocument = this.documents.find((document) => document.documentType === documentType);

      if ((tmpDocument && tmpDocument.documentStatus === EnumDocumentStatus.PENDING.name)
        || (hasLiveness && (this.child.customer.livenessStatus === EnumLivenessStatus.INITIATED
        || this.child.customer.livenessStatus === EnumLivenessStatus.INITIATED))) {
        value = EnumDocumentStatus.PENDING.label;
      } else if ((tmpDocument && (tmpDocument.documentStatus === EnumDocumentStatus.VALIDATED.name))
        || this.child.customer.livenessStatus === EnumLivenessStatus.PROCESSED) {
        value = EnumDocumentStatus.VALIDATED.label;
      } else if ((tmpDocument && (tmpDocument.documentStatus === EnumDocumentStatus.CANCELED.name))
        || this.child.customer.livenessStatus === EnumLivenessStatus.REFUSED) {
        value = EnumDocumentStatus.CANCELED.label;
      }

      return value;
    },
  },
};
</script>

<style lang="sass">
.proofs-list
</style>
