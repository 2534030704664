let clickOutsideEvent = {};

/**
 *
 * ### Documentation intégration
 * Dans le composant souhaité ajouter à la balise html racine les attributs suivants pour que la directive puisse correctement s'activer
 *
 * v-click-outside: appel la directive
 * :ref="_uid": permet de récupérer l'id de la balise racine du composant
 *
 * ### Documentation code
 *
 * vnode.context['_uid']: permet de récupérer l'id de l'élément sur lequel le clic a été effectué
 * parentElement: permet de récupérer le noeud html de la balise racine du composant cible
 * [...parentElement.querySelectorAll('*')]: permet de récupérer tous les éléments html inclus dans la balise racine du composant cible
 *
 */
export default {
  inserted: (el, binding, vnode) => {
    let counter = 0;
    clickOutsideEvent = (event) => {
      // eslint-disable-next-line
      const parentElement = vnode.context.$refs[vnode.context['_uid']];

      if (!parentElement) return; // fix l'erreur Cannot read properties of undefined (reading 'querySelectorAll')
      const fullChildrenArray = [...parentElement.querySelectorAll('*')];
      const isOpenProps = vnode.context.$props.isOpen;

      // console.log(fullChildrenArray);
      // console.log(event.target);

      /* eslint-disable */
      // Cas d'une data
      if (!(fullChildrenArray.includes(event.target)) && !isOpenProps && vnode.context.isOpen) {
        // eslint-disable-next-line
        vnode.context.isOpen = false;
      } else { // Cas d'une props
        // Evite de fermer le volet à peine ouvert
        if (counter === 0) {
          counter += 1;
        } else {
          vnode.context.$emit('click:is-open', false);
          counter -= 1;
        }
      }
      /* eslint-enable */
    };

    document.documentElement.addEventListener('click', clickOutsideEvent);
  },
  unbind: () => {
    document.documentElement.removeEventListener('click', clickOutsideEvent);
    clickOutsideEvent = {};
  },
};
