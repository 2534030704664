import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 *
 * @param {string} customerId
 * @param {EnumDocumentStatus} documentStatus
 * @param {EnumDocumentType} documentTypeId
 * @returns
 */
const getDocuments = async (customerId, documentStatus, documentTypeId) => {
  try {
    const response = await axios.get(`${API_URL}/documents`, {
      params: {
        ...(customerId && { customerId }),
        ...(documentStatus && { documentStatus }),
        ...(documentTypeId && { documentTypeId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient la liste des pièces-jointes des virements
 *
 * @returns
 */
const getDocumentsPayouts = async () => {
  try {
    const response = await axios.get(`${API_URL}/documents/payouts`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {string} customerId
 * @param {EnumDocumentType} documentTypeId
 * @param {FormData} file
 * @returns
 */
const createDocument = async (customerId, documentTypeId, file) => {
  const formData = new FormData();
  if (customerId) formData.append('customerId', customerId);
  formData.append('documentTypeId', documentTypeId);
  formData.append('file', file);

  try {
    const response = await axios.post(`${API_URL}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getDocuments = getDocuments;
api.createDocument = createDocument;
api.getDocumentsPayouts = getDocumentsPayouts;

export default api;
