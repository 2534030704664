<template>
  <div class="app-notifications">
    <transition-group name="notification">
      <div class="app-notification grid-x align-middle" :class="EnumNotificationType[notification.type].toLowerCase()" v-for="(notification, index) in notifications" :key="`notif-${index}`">
        <component class="cell shrink type" :is="notification.type === EnumNotificationType.SUCCESS ? SvgValid : notification.type === EnumNotificationType.ECHEC ? SvgDelete : SvgTimer"></component>

        <div class="cell auto">
          <div>
            <p v-if="notification.title" class="title">{{ notification.title }}</p>
            <p v-if="notification.text" class="text">{{ notification.text }}</p>
          </div>
        </div>

        <SvgCancel class="cancel" @click="() => cancelNotification(notification)" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import AppEvent from '../utils/AppEvent';
import SvgDelete from '../../assets/img/icons/delete.svg?inline';
import SvgValid from '../../assets/img/icons/valid.svg?inline';
import SvgCancel from '../../assets/img/icons/cancel.svg?inline';
import SvgTimer from '../../assets/img/icons/timer.svg?inline';
import EnumNotificationType from '../../services/enums/notification.enum';

export default {
  components: {
    SvgDelete,
    SvgValid,
    SvgCancel,
  },
  data() {
    return {
      visible: false,
      notifications: [],
      EnumNotificationType,
      SvgDelete,
      SvgValid,
      SvgTimer,
    };
  },
  beforeMount() {
    AppEvent.$on('app-notification-show', (params) => {
      this.show(params);
    });
  },
  beforeDestroy() {
    AppEvent.$off('app-notification-show', (params) => {
      this.show(params);
    });
  },
  methods: {
    show(params) {
      this.visible = true;
      const notification = { title: params.title, text: params.text, type: params.type };
      this.notifications.push(notification);
      setTimeout(() => { this.cancelNotification(notification); }, 5000);
    },
    cancelNotification(notification) {
      this.notifications.splice(this.notifications.indexOf(notification), 1);
    },
  },
};
</script>

<style lang="sass">
.app-notifications
  position: fixed
  top: 20px
  right: 20px
  z-index: 100000
  .app-notification
    position: relative
    width: 408px
    padding: $global-padding-horizontal ($global-padding-vertical * 2)
    border-radius: $global-border-radius * 2
    text-align: left
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05)
    background-color: var(--color-cloud)
    overflow: hidden
    &.success
      .title
        color: var(--color-success)
      svg.type circle, svg.type path
        stroke: var(--color-success)
      &::after
        background-color: var(--color-success)
    &.echec
      .title
        color: var(--color-warning)
      svg.type circle, svg.type path
        color: var(--color-warning)
      &::after
        background-color: var(--color-warning)
    &::after
      content: ''
      position: absolute
      height: 100%
      width: 4px
      top: 0
      left: 0
    svg
      width: 22px
  .title
    @include header5
    margin: 0
  .text
    @include details
    margin: ($global-padding-horizontal / 4) 0 0 0

  svg
    &.type
      margin-right: ($global-padding-horizontal / 2)
    &.cancel
      cursor: pointer
      width: 15px
      height: 15px
      margin-left: ($global-padding-horizontal / 2)
      path
        stroke: var(--color-grey70)

.notification-enter-active, .notification-leave-active
  transition: all 0.35s ease-in-out

.notification-enter, .notification-leave-to
  transform: translateX(40px)
  opacity: 0

</style>
