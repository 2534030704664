<template>
  <div class="creditors-edit">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>{{ actionComputed }}</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <app-input
            label="Nom *"
            type="text"
            v-model="fullNameComputed"
          ></app-input>
          <app-input
            label="Identifiant Créancier SEPA (ICS) *"
            type="text"
            v-model="sepaCreditorIdentifierComputed"
          ></app-input>
          <app-input
            label="Référence Unique de Mandat (RUM) *"
            type="text"
            v-model="uniqueMandateReferenceComputed"
          ></app-input>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(typeof error ===  'string' ? error : error.message) }}
              </li>
            </ul>
          </div>

          <template v-if="action === 'add'">
            <div class="grid-x">
              <app-button class="cell auto submit add" :disabled="!fullNameComputed || !sepaCreditorIdentifierComputed || !uniqueMandateReferenceComputed" type="submit">Créer prélèvement</app-button>
            </div>
          </template>
          <template v-else>
            <div class="grid-x grid-margin-x">
              <app-button class="cell auto submit" :disabled="!fullNameComputed || !sepaCreditorIdentifierComputed || !uniqueMandateReferenceComputed" type="submit">Modifier</app-button>
            </div>
          </template>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiBeneficiary from '../../services/api/beneficiary';

// Enums
import EnumNotificationType from '../../services/enums/notification.enum';

// Svg
import SvgWarning from '../../assets/img/icons/warning.svg?inline';

export default {
  name: 'creditors-edit',
  components: {
    SvgWarning,
  },
  props: {
    action: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    actionComputed() {
      return this.action === 'add' ? 'Ajouter un prélèvement' : 'Détails prélèvement';
    },
    fullNameComputed: {
      get() { return this.data?.name; },
      set(newValue) {
        const tmp = {
          ...this.data,
          name: newValue,
        };
        this.$emit('onChange', tmp);
      },
    },
    sepaCreditorIdentifierComputed: {
      get() { return this.data?.sepaCreditorIdentifier; },
      set(newValue) {
        const tmp = {
          ...this.data,
          sepaCreditorIdentifier: newValue,
        };
        this.$emit('onChange', tmp);
      },
    },
    uniqueMandateReferenceComputed: {
      get() { return this.data?.sddB2bWhitelist && this.data?.sddB2bWhitelist[0] && this.data?.sddB2bWhitelist[0]?.uniqueMandateReference; },
      set(newValue) {
        const tmp = {
          ...this.data,
          sddB2bWhitelist: [{
            uniqueMandateReference: newValue,
          }],
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      try {
        let beneficiary = null;
        if (this.action === 'add') {
          beneficiary = await ApiBeneficiary.createBeneficiary(this.data.name, null, null, this.data.sepaCreditorIdentifier, this.data?.sddB2bWhitelist[0]?.uniqueMandateReference, this.data.secretCode);
        } else {
          beneficiary = await ApiBeneficiary.setBeneficiary(this.data.id, this.data.name, null, null, this.data.sepaCreditorIdentifier, this.data?.sddB2bWhitelist[0]?.uniqueMandateReference);
        }
        this.$emit('updated', beneficiary);
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }
          this.$notification.show({
            title: 'Une erreur est survenue',
            text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      }
      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'IBAN validation failed':
          newErrorMessage = 'L\'IBAN saisi n\'est pas valide.';
          break;
        case 'Invalid BIC, expected pattern is [A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}, see SEPA Credit Transfer C2B Implementation Guidelines Version 8.0 Approved – November 2014':
          newErrorMessage = 'Le BIC saisi n\'est pas valide.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.creditors-edit
  max-width: 484px
  p, .app-input
    margin: 0
  .app-input
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
    ~ .app-input
      margin-top: $global-padding-vertical * 3

  form
    .app-button.submit
      display: inline-block
      margin-top: ($global-padding-horizontal * 3)
      &.add
        display: flex
        justify-content: center
    .errors
      display: flex
      svg
        max-width: 22px
        min-width: 22px
      ul
        padding-left: $global-padding-horizontal
        list-style: none
        color: var(--color-warning)
</style>
