const address = Object.freeze({
  description: '1 pièce au choix < 3 mois',
  INVOICE_PHONE: {
    document: 'DOCUMENT_TYPE_13',
    description: 'Facture Téléphone mobile / Téléphone fixe',
  },
  INVOICE_INTERNET: {
    document: 'DOCUMENT_TYPE_14',
    description: 'Facture Internet',
  },
  INVOICE_ENERGY: {
    document: 'DOCUMENT_TYPE_14',
    description: 'Facture Electricité / Gaz / Eau',
  },
  RECEIPT_RENT: {
    document: 'DOCUMENT_TYPE_12',
    description: 'Quittance de loyer du gestionnaire ou titre de propriété',
  },
  RECEIPT_INSURANCE: {
    document: 'DOCUMENT_TYPE_12',
    description: 'Attestation d’assurance (en cours de validité) ou quittance assurance',
  },
  TAX_ASSESSMENT: {
    document: 'DOCUMENT_TYPE_12',
    description: 'Dernier avis d\'imposition ou certificat de non imposition / exonération fiscale',
  },
  TAX_RESIDENCE: {
    document: 'DOCUMENT_TYPE_12',
    description: 'Dernière taxe d\'habitation',
  },
  TAX_PROPERTY: {
    document: 'DOCUMENT_TYPE_12',
    description: 'Dernière taxe foncière',
  },
});

export default address;
