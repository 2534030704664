<template>
  <div class="creditors">
    <div class="list">
      <List
        @click="(value) => { selectedCreditor = value; isTheFlapOpenEditComputed() }"
        :selectedCreditor="selectedCreditor"
        :needRefresh="doesListNeedRefreshedComputed"
        @hasBeenRefreshed="() => doesListNeedRefreshedComputed = false"
      />
    </div>

    <Edit ref="creditors-edit"
      :action="selectedCreditor ? selectedCreditor.action : selectedCreditor"
      :data="{ ...selectedCreditor, secretCode }"
      @onChange="(newData) => selectedCreditor = newData"
      :isOpen="isTheFlapOpenEdit"
      @is-open="(value) => isTheFlapOpenEdit = value"
      @updated="() => {doesListNeedRefreshedComputed = true; isTheFlapOpenEdit = false;}"
    />

    <SecretCode ref="creditors-secretcode"
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => isTheFlapOpenSecretCode = value"
      :isLoading="isAddLoading"
      @click="manage"
    />
  </div>
</template>

<script>
// Components
import List from './List.vue';
import Edit from './Edit.vue';
import SecretCode from '../Common/AppSecretCode.vue';

export default {
  name: 'creditors',
  inject: ['$getCustomer'],
  components: {
    List,
    Edit,
    SecretCode,
  },
  data() {
    return {
      selectedCreditor: null,
      doesListNeedRefreshed: false,
      secretCode: [],

      isTheFlapOpenSecretCode: false,
      isTheFlapOpenEdit: false,

      isAddLoading: false,
    };
  },
  computed: {
    doesListNeedRefreshedComputed: {
      get() { return this.doesListNeedRefreshed; },
      set(newValue) {
        this.doesListNeedRefreshed = newValue;
      },
    },
    customer() {
      return this.$getCustomer();
    },
  },
  watch: {
    customer() {
      // Vérification si l'utilisateur est bien B2B
      if (this.customer.customer.type !== 'BUSINESS') {
        this.$router.replace({ name: 'payouts' }).catch(() => {});
      }
    },
  },
  mounted() {
    if (this.$refs['creditors-edit'] && this.$refs['creditors-secretcode']) {
      this.$watch(() => this.$refs['creditors-edit'].isOpen || this.$refs['creditors-secretcode'].isOpen,
        (newValue) => {
          this.$isFlapOpen = newValue;
        });
    }
  },
  methods: {
    isTheFlapOpenEditComputed() {
      if (this.selectedCreditor.action === 'edit') {
        this.isTheFlapOpenEdit = true;
        this.isTheFlapOpenSecretCode = false;
      } else {
        this.isTheFlapOpenEdit = false;
        this.isTheFlapOpenSecretCode = true;
      }
    },
    async manage() {
      this.isAddLoading = true;
      try {
        this.isTheFlapOpenEdit = true;
        this.isTheFlapOpenSecretCode = false;
      } catch (error) {
        //
      }
      this.isAddLoading = false;
    },
  },
};
</script>

<style lang="sass">
.creditors
  max-width: 484px
  p
    margin: 0

</style>
