<template>
  <div class="document-rib">
    <p class="title">Identifiants bancaires</p>

    <app-loader v-if="isLoading"></app-loader>
    <div v-else class="rib">
      <div class="main grid-x">
        <div class="cell auto">
          <p>{{ fullNameComputed.toUpperCase() }} </p>
        </div>
        <div class="download shrink" @click="downloadAccountDetails"><SvgDownload /></div>
      </div>
      <div class="content grid-x" v-if="wallet">
        <div class="iban cell auto">
          <p class="libelle">IBAN</p>
          <p>{{ wallet.iban }}</p>
        </div>
        <div class="bic cell auto">
          <p class="libelle">BIC</p>
          <p>{{ wallet.bic}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Api
import ApiWallet from '../../services/api/wallet';

// Enums
import EnumCustomerType from '../../services/enums/customer/type.enum';
import EnumNotificationType from '../../services/enums/notification.enum';

// Svg
import SvgDownload from '../../assets/img/icons/download.svg?inline.svg';

export default {
  name: 'document-rib',
  inject: ['$getCustomer'],
  components: {
    SvgDownload,
  },
  data() {
    return {
      wallet: null,

      isLoading: false,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    fullNameComputed() {
      return this.customer?.customer.type === EnumCustomerType.INDIVIDUAL
        ? `${this.customer?.customer.firstname} ${this.customer?.customer.lastname}`
        : `${this.customer?.customer.legalName}`;
    },
  },
  mounted() {
    this.getWallet();
  },
  methods: {
    async getWallet() {
      this.isLoading = true;
      try {
        this.wallet = await ApiWallet.getWallet();
      } catch (error) {
        //
      }
      this.isLoading = false;
    },
    async downloadAccountDetails() {
      try {
        const accountDetails = await ApiWallet.getWalletAccountDetails();

        if (accountDetails && accountDetails.url) window.open(accountDetails.url, '_blank');
      } catch {
        this.$notification.show({
          title: 'Echec du téléchargement',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.document-rib
  max-width: 484px
  p
    margin: 0
  .title
    @include header2
  .rib
    margin-top: $global-padding-vertical * 3.3
    border-radius: $global-border-radius * 2
    box-shadow: 0px 4px 20px #EEEEEE
    overflow: hidden
    .main
      padding: $global-padding-vertical ($global-padding-vertical * 2)
      @include header3
      align-items: center
      background-color: var(--color-70)
      .download
        display: flex
        width: 35px
        align-items: center
        cursor: pointer
    .content
      @include text
      padding: $global-padding-vertical * 2
      background-color: var(--color-cloud)
      .libelle
        @include header5
        color: var(--color-grey70)
      .bic
        text-align: right

</style>
