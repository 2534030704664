<template>
  <div class="payouts grid-x">
    <div class="wrapper cell" :class="{ 'auto': selectedPayout && Object.keys(selectedPayout).length > 0 }">
      <div v-if="activeScreen === payoutActiveScreen.LIST">
        <div class="grid-x container-header">
          <p>Virements</p>

          <div class="cell shrink container-buttons">
            <app-button size="large" kind="secondary" @click="displayImportPayout">
              Importer virements (CSV)
            </app-button>

            <app-button size="large" kind="primary" @click="displayAddPayout">
              Nouveau virement <SvgPlus />
            </app-button>
          </div>
        </div>

        <div class="list">
          <PayoutsList @click="setSelectedPayout" :selectedPayout="selectedPayout" />
        </div>
      </div>

      <div v-else-if="activeScreen === payoutActiveScreen.ADD" class="add">
        <PayoutsAdd :goBackToList="goBackToList" />
      </div>

      <div v-else-if="activeScreen === payoutActiveScreen.IMPORT">
        <PayoutsImport :goBackToList="goBackToList" />
      </div>
    </div>

    <div class="cell" :class="{ 'shrink': selectedPayout && Object.keys(selectedPayout).length > 0 }">
      <PayoutsDetail ref="payout-detail" :data="selectedPayout" @click="(value) => isTheFlapOpen = value" />
    </div>

    <SecretCode ref="payouts-secretcode"
      :secretCode="secretCode"
      :isOpen="isTheFlapOpenSecretCode"
      @onChange="(newData) => secretCode = newData"
      @is-open="(value) => isTheFlapOpenSecretCode = value"
      :isLoading="isAddLoading"
    />
  </div>
</template>

<script>
// Components
import PayoutsList from './List.vue';
import PayoutsDetail from './Detail.vue';
import PayoutsAdd from './Add.vue';
import PayoutsImport from './Import.vue';
import SecretCode from '../Common/AppSecretCode.vue';

// Svg
import SvgPlus from '../../assets/img/icons/plus.svg?inline';

// Enums
import payoutActiveScreen from '../../services/enums/payouts/active-screen.enum';

export default {
  name: 'payouts',
  components: {
    PayoutsList,
    PayoutsDetail,
    PayoutsAdd,
    PayoutsImport,
    SecretCode,
    SvgPlus,
  },
  data() {
    return {
      secretCode: [],
      selectedPayout: null,

      activeScreen: payoutActiveScreen.LIST,

      isTheFlapOpen: false,
      isTheFlapOpenSecretCode: false,
      isAddLoading: false,

      payoutActiveScreen,
    };
  },
  mounted() {
    this.$watch(() => this.$refs['payout-detail'].isOpen,
      (newValue) => {
        if (!newValue) {
          this.selectedPayout = {};
        }
        this.$isFlapOpen = newValue;
      });
  },
  methods: {
    setSelectedPayout(value) {
      if ((!this.selectedPayout || Object.keys(this.selectedPayout).length <= 0) || (this.selectedPayout && this.selectedPayout.payout.payoutId !== value.payout.payoutId)) {
        this.selectedPayout = value;
      } else {
        this.selectedPayout = {};
      }
    },
    displayAddPayout() {
      this.activeScreen = payoutActiveScreen.ADD;
      this.selectedPayout = {}; // On ferme le flap détail si ouvert
    },
    displayImportPayout() {
      this.activeScreen = payoutActiveScreen.IMPORT;
    },
    goBackToList() {
      this.activeScreen = payoutActiveScreen.LIST;
    },
  },
};
</script>

<style lang="sass">
.payouts
  p
    margin: 0
  .container-header
    justify-content: space-between
    align-items: center
    margin-bottom: $global-padding-vertical * 3.3
    p
      @include header2
    .container-buttons
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      button:first-child
        margin-right: 16px
</style>
