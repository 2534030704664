<template>
  <div class="profil-company-edit-data">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Informations entreprise</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x legal-registration-number align-middle">
            <app-input
              class="cell auto"
              label="Numéro de SIRET *"
              type="text"
              v-model="legalRegistrationNumberComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-name align-middle">
            <app-input
              class="cell auto"
              label="Raison Sociale *"
              type="text"
              v-model="legalNameComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x business-type align-middle">
            <app-select
              class="cell auto"
              v-model="businessTypeComputed"
              label="Type d'entreprise *"
              :options="EnumBusinessType"
              :isDisabled="!!data.treezorUser"
            >
            </app-select>
            <SvgLock v-if="isDisabled || !!data.treezorUser" class="cell shrink" />
          </div>

          <div class="grid-x legal-form align-middle">
            <app-select
              class="cell auto"
              v-model="legalFormComputed"
              label="Forme juridique *"
              :options="EnumLegalForms"
              :isDisabled="!!data.treezorUser">
            </app-select>
            <SvgLock v-if="isDisabled || !!data.treezorUser" class="cell shrink" />
          </div>

          <div class="grid-x legal-sector align-middle">
            <app-select
              class="cell auto"
              v-model="legalSectorComputed"
              label="Secteur d’activité"
              :options="EnumLegalSectors"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-tva-number align-middle">
            <app-input
              class="cell auto"
              label="Numéro de TVA *"
              type="text"
              v-model="legalTvaNumberComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x entity-type align-middle" v-if="data.customer.entityType">
            <app-input
              class="cell auto"
              label="Type d'entité *"
              type="text"
              v-model="entityTypeComputed"
              :isDisabled="true"
            ></app-input>
            <SvgLock class="cell shrink" />
          </div>

          <div class="grid-x is-self-employed-person align-center">
            <p class="info cell auto">Avez-vous le statut d'auto-entrepreneur ? *</p>
            <SvgLock v-if="true" class="cell shrink" />
            <div v-else class="cell small-offset-1"></div>
            <app-radio-custom :isDisabled="true" class="cell small-12" :values=EnumYesNo @value="(value) => isSelfEmployedPerson = value" :value="isSelfEmployedPersonComputed"></app-radio-custom>
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !legalRegistrationNumberComputed || !legalNameComputed || !legalFormComputed ||  !legalSectorComputed || !legalTvaNumberComputed || !entityTypeComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Enums
import EnumLegalForms from '../../../../../commons/legalForms.json';
import EnumLegalSectors from '../../../../../commons/legalSectors.json';
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumYesNo from '../../../services/enums/yesNo.enum';
import EnumBusinessType from '../../../services/enums/customer/businessType.enum';
import EnumEntityTypes from '../../../../../commons/companyEntityTypes.json';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-company-edit-data',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      legalRegistrationNumber: null,
      legalName: null,
      businessType: null,
      legalForm: null,
      legalSector: null,
      legalTvaNumber: null,
      isSelfEmployedPerson: null,

      EnumLegalForms: this.$utils.sortAlphabetically(EnumLegalForms),
      EnumLegalSectors: this.$utils.sortAlphabetically(EnumLegalSectors),
      EnumYesNo,
      EnumEntityTypes: this.$utils.sortAlphabetically(EnumEntityTypes),
      EnumBusinessType: this.$utils.transformEnumToArray(EnumBusinessType),

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    legalRegistrationNumberComputed: {
      get() { return this.data.customer.legalRegistrationNumber; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalRegistrationNumber: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalNameComputed: {
      get() { return this.data.customer.legalName; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalName: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    businessTypeComputed: {
      get() { return EnumBusinessType[this.data.customer.businessType].name; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            businessType: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalFormComputed: {
      get() { return this.data.customer.legalForm; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalForm: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalSectorComputed: {
      get() { return this.data.customer.legalSector; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalSector: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalTvaNumberComputed: {
      get() { return this.data.customer.legalTvaNumber; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalTvaNumber: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    isSelfEmployedPersonComputed: {
      get() { return this.isSelfEmployedPerson || EnumYesNo[this.data.customer.isSelfEmployedPerson]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            isSelfEmployedPerson: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    entityTypeComputed: {
      get() {
        const type = EnumEntityTypes.find((elem) => elem.name === this.data.customer.entityType);
        if (!type) return null;

        return type.label;
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        legalRegistrationNumber: this.legalRegistrationNumberComputed,
        legalName: this.legalNameComputed,
        legalForm: this.legalFormComputed,
        businessType: this.businessTypeComputed,
        legalSector: this.legalSectorComputed,
        legalTvaNumber: this.legalTvaNumberComputed,
      };

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-company-edit-data
  .app-input
    margin: 0
  .app-input, .app-select
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3
  .legal-registration-number, .legal-name, .legal-form, .legal-sector, .legal-tva-number, .entity-type
    width: 100%
  p.info
    @include header4
    margin-bottom: $global-padding-vertical * 3
  .content .grid-x
    > svg
      margin-left: $global-padding-vertical
      path
        &:first-child
          stroke-width: 1
          stroke: var(--color-grey70)
        &:last-child
          fill: var(--color-cloud)
          stroke: var(--color-grey70)
</style>
