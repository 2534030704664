const businessType = Object.freeze({
  VSB_SMB: {
    name: 'VSB_SMB',
    label: 'Très petite, petite ou moyenne entreprise',
  },
  SELF_EMPLOYED_PERSON: {
    name: 'SELF_EMPLOYED_PERSON',
    label: 'Auto-entrepreneur',
  },
  INDEPENDENT_AND_LIBERAL_PROFESSION: {
    name: 'INDEPENDENT_AND_LIBERAL_PROFESSION',
    label: 'Professions libérales et indépendantes',
  },
  MAJOR_ACCOUNT: {
    name: 'MAJOR_ACCOUNT',
    label: 'Grand compte',
  },
});

export default businessType;
