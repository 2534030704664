<!--
  routes: [
    {
      name: 'home',
      label: 'Accueil',
      isActive: true
    }
  ]
-->
<template>
  <nav :class="type" class="app-navigation grid-x align-justify align-middle">
    <template v-if="type === EnumNavigationType.DEFAULT">
      <div class="cell shrink">
        <router-link :to="{ name: 'login' }">
          <SvgLogoKalveenImg class="logo" />
          <SvgLogoKalveenText class="text" />
        </router-link>
      </div>
      <div class="cell shrink">
        <router-link class="website" :to="{ name: 'login' }"><SvgCancel class="cancel"/>Retour au site</router-link>
      </div>
    </template>

    <template v-else>
      <router-link
        v-for="(route, index) in routes"
        :key="`navigation-route-${index}`"
        :to="{ name: route.name }"
        :class="{ 'active': index === 0 }">
          {{ route.label }}
      </router-link>
    </template>
  </nav>
</template>

<script>
import SvgLogoKalveenImg from '../assets/img/logo-kvn-img.svg?inline';
import SvgLogoKalveenText from '../assets/img/logo-kvn-text.svg?inline';
import SvgCancel from '../assets/img/icons/cancel.svg?inline';
import EnumNavigationType from '../services/enums/navigation/type.enum';

export default {
  name: 'navigation',
  components: {
    SvgLogoKalveenImg,
    SvgLogoKalveenText,
    SvgCancel,
  },
  props: {
    routes: {
      type: [Array, Object],
      required: false,
    },
    type: {
      type: String,
      default: 'default',
      required: false,
      validator: (val) => ['default', 'links'].indexOf(val) !== -1,
    },
  },
  data() {
    return {
      EnumNavigationType,
    };
  },
};
</script>

<style lang="sass">
// Etend root
\:root
  --navigation-height: 85px

.cards
  nav.app-navigation.links .router-link-active
    padding-bottom: $global-padding-horizontal - 1px // Corrige le sursaut de 1px à cause de la bordure du bas
    border-bottom: 1px solid var(--color-black)

nav.app-navigation
  // Common
  position: relative
  display: flex
  padding: ($global-padding-horizontal * 2) 42px
  z-index: 100
  // Default
  &.default
    svg.logo
      margin-right: $global-padding-horizontal - 2px
      margin-bottom: -7px
      width: 25px
    svg.text
      height: 16px
    a.website
      display: flex
      @include link
      line-height: initial
      max-height: 19px // fix safari
      svg.cancel
        margin-right: $global-padding-horizontal / 2
        width: 13px
        path
          stroke: var(--color-black)
          stroke-width: 1.5

  // Links
  &.links
    margin-bottom: $global-padding-vertical * 4
    padding: 0
    justify-content: flex-start
    a
      @include header5
      margin: 0 ($global-padding-vertical * 2)
      padding: ($global-padding-horizontal * 2) 0 $global-padding-horizontal 0
      color: var(--color-grey70)
      text-decoration: none
      cursor: pointer
      z-index: 10
      &.router-link-exact-active, .active
        padding-bottom: $global-padding-horizontal - 1px // Corrige le sursaut de 1px à cause de la bordure du bas
        border-bottom: 1px solid var(--color-black)
      &:first-child
        margin-left: 0
      &:last-child
        margin-right: 0
    &::after
      content: ''
      position: absolute
      height: 100%
      width: 100%
      bottom: 0
      border-bottom: 1px solid var(--color-50)
      z-index: 0

</style>
