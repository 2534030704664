<template>
  <div class="app-switch" :class="[size]">
      <label>
        <span class="switch">
          <input type="checkbox"
          :checked="input"
          @change="$emit('input', $event.target.checked)"/>
          <span class="slider"></span>
        </span>
        <span class="label"><slot></slot></span>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [Boolean],
    size: {
      default: 'large',
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
    },
  },
};
</script>

<style lang="sass">
.app-switch
  display: inline-block
  label
    display: inline-block
    position: relative
    margin: 0 5px
    cursor: pointer
    user-select: none
  .switch
    position: relative
    display: inline-block
  .label
    display: inline-block
    padding: 5px
    vertical-align: middle
  input
    opacity: 0
    width: 0
    height: 0
  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: var(--color-70)
    border-radius: 28px
    transition: .4s
    transition: .4s
  .slider:before
    position: absolute
    content: ""
    background-color: white
    border-radius: 28px
    transition: .4s
  input:checked + .slider
    background-color: var(--color-black)
  input:focus + .slider
    box-shadow: 0 0 1px var(--color-70)

  &.large
    .switch
      width: 64px
      height: 32px
      bottom: 3px
    .slider:before
      height: 26px
      width: 26px
      bottom: 3px
      left: 3px
    input:checked + .slider:before
      transform: translateX(32px)
  &.small
    .switch
      width: 49px
      height: 24px
    .slider:before
      height: 20px
      width: 20px
      bottom: 2px
      left: 2px
    input:checked + .slider:before
      transform: translateX(25px)

</style>
