const CheckStatus = Object.freeze({
  SHIPPING: 'SHIPPING', // Chèque doit être envoyé au centre de traitement
  PENDING: 'PENDING', // En traitement
  SHIPPING_FAILED: 'SHIPPING_FAILED', // Chèque non reçu par le centre de traitement
  FAIL: 'FAIL', // Une erreur est survenue pendant le traitement
  REFUSED: 'REFUSED', // Cheque refusé
  VALIDATED: 'VALIDATED', // Chèque traité avec succès, en attente du virement des fonds
  CASHED: 'CASHED', // Chèque reçu et validé. Fonds utilisables
});

export default CheckStatus;
