<template>
  <div class="state-initialize">
    <component v-show="step === 1" :is="RegisterPassword"
      :password="password"
      :passwordConfirm="passwordConfirm"
      :errors="passwordErrors"
      @onChangePassword="(value) => password = value"
      @onChangePasswordConfirm="(value) => passwordConfirm = value"
      @click="() => {step += 1; resetErrors()}"
    ></component>
    <component v-show="step === 2" :is="RegisterSecretCode" ref="register-secretcode"
      :secretCode="secretCode"
      :secretCodeConfirm="secretCodeConfirm"
      :errors="passwordErrors"
      :isLoading="isLoading"
      @onChangeSecretCode="(value) => secretCode = value"
      @onChangeSecretCodeConfirm="(value) => secretCodeConfirm = value"
      @click="onClick"
    ></component>
  </div>
</template>

<script>
// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

import RegisterPassword from '../Introduction/Password.vue';
import RegisterSecretCode from '../Introduction/SecretCode.vue';

export default {
  name: 'state-initialize',
  data() {
    return {
      step: 1,

      password: '',
      passwordConfirm: '',
      secretCode: [],
      secretCodeConfirm: [],

      passwordErrors: [],

      isLoading: false,

      RegisterPassword,
      RegisterSecretCode,
    };
  },
  watch: {
    passwordErrors() {
      if (this.passwordErrors.some((el) => el.includes('password') || el.includes('Password'))) this.step = 1;
    },
    step() {
      this.$nextTick(() => {
        this.$refs['register-secretcode'].$refs['register-secretcode-app-input-pin'].$refs['pin-1'][0].focus();
      });
    },
    secretCode() {
      if (this.secretCode.length >= 4) {
        this.$refs['register-secretcode'].$refs['register-secretcode-confirm-app-input-pin'].$refs['pin-1'][0].focus();
      }
    },
  },
  methods: {
    async onClick() {
      this.resetErrors();
      this.isLoading = true;
      try {
        await this.$auth.registerPassword(this.$route.query.token, this.password, this.passwordConfirm, this.secretCode, this.secretCodeConfirm);
        this.$notification.show({
          title: 'Mot de passe initialisé avec succès !',
          text: '',
          type: EnumNotificationType.SUCCESS,
        });

        this.$router.push({ name: 'login' });
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.passwordErrors.push((error.response.data.message));
          else this.passwordErrors = [...this.passwordErrors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de l\'enregistrement',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    resetErrors() {
      this.passwordErrors = [];
    },
  },
};
</script>

<style lang="sass">
.state-initialize
</style>
