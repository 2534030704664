<template>
  <div class="app-secret-code">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Sécurité code secret</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="$emit('click')">
          <p>Veuillez renseigner votre code secret pour effectuer cette action</p>
          <app-input-pin @input="(event) => onChange(event)" :isVisible="false"></app-input-pin>

          <app-button class="submit" :disabled="secretCode.length <= 0 || isLoading" type="submit">Valider</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
export default {
  name: 'app-secret-code',
  props: {
    secretCode: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event);
    },
  },
};
</script>

<style lang="sass">
.app-secret-code
  .content
    p
      @include header3
    form
      text-align: center
      .app-input-pin
        margin-top: ($global-padding-horizontal * 2)
    .app-button
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center;
</style>
