<template>
  <div class="register-password">
    <app-two-blocks>
      <template v-slot:main>
        <div class="grid-y">
          <p class="title">Définissez votre mot de passe</p>
          <p class="subtitle">Choisissez votre mot de passe qui vous permettra de vous connecter à votre compte.</p>

          <app-input
            type="password"
            label="Mot de passe *"
            required
            :value="password"
            @input="(event) => onChangePassword(event)"
          />
          <app-input
            type="password"
            label="Répéter le mot de passe *"
            required
            :value="passwordConfirm"
            @input="(event) => onChangePasswordConfirm(event)"
          />
          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="next" :disabled="(!password || !passwordConfirm)" @click="manageClick">Suivant <SvgArrow /></app-button>
        </div>
      </template>
       <template v-slot:animation>
        <app-lottie
          :dataAppearance="ForgotPasswordAppeareance"
          :dataLoop="ForgotPasswordLoop"
        ></app-lottie>
       </template>
    </app-two-blocks>
  </div>
</template>

<script>
// Animations
import ForgotPasswordAppeareance from '../../../assets/animations/individual/forgot-password-appearance.json';
import ForgotPasswordLoop from '../../../assets/animations/individual/forgot-password-loop.json';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';

// Component
import AppLottie from '../../AppLottie.vue';

export default {
  name: 'register-password',
  components: {
    SvgWarning,
    SvgArrow,

    AppLottie,
  },
  props: {
    password: {
      type: String,
      required: true,
    },
    passwordConfirm: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ForgotPasswordAppeareance,
      ForgotPasswordLoop,
    };
  },
  mounted() {
    this.setAppFooterPosition();
  },
  methods: {
    onChangePassword(event) {
      this.$emit('onChangePassword', event);
    },
    onChangePasswordConfirm(event) {
      this.$emit('onChangePasswordConfirm', event);
    },
    manageClick() {
      this.$emit('click');
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'password must be longer than or equal to 8 characters':
          newErrorMessage = 'Le mot de passe doit contenir au moins 8 caractères.';
          break;
        case 'passwordConfirm must be longer than or equal to 8 characters':
          newErrorMessage = 'La confirmation du mot de passe doit contenir au moins 8 caractères.';
          break;
        case 'Password and PasswordConfirm must be the same':
          newErrorMessage = 'Les deux mots de passe doivent être identiques.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
    setAppFooterPosition() {
      const component = document.querySelector('.app-footer');
      component.style.removeProperty('margin');
      component.style.left = 'initial';
      component.style.right = 'initial';
      component.style.transform = 'initial';
    },
  },
};
</script>

<style lang="sass">
// Surcharge personnalisée des couleurs
body
  &[data-theme="business"]
    .register-password
      .illustration::after
        background-image: url('../../../assets/img/business/background-register.svg')

.register-password
  text-align: left
  > div
    .content
      p:not(.label)
        margin: 0
      .title
        @include header1
      .subtitle
        @include header4
        margin-top: ($global-padding-horizontal / 2) !important
        color: var(--color-grey70)
    .app-input
      margin-top: $global-padding-horizontal * 2
    .app-button.next
      margin-top: $global-padding-vertical * 6
      margin-bottom: $global-padding-horizontal * 2
      align-self: flex-start
      svg path
        stroke: white
  .illustration
    &::after
      background-image: url('../../../assets/img/individual/background-register.svg')
  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
