<template>
  <div class="profil-company-edit-numbers">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Chiffres entreprise</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x legal-registration-date align-middle">
            <app-datepicker
              class="cell auto"
              v-model="legalRegistrationDateComputed"
              :isDisabled="isDisabled"
              label="Date d'immatriculation *"
              :disabledDates="datepickerDisabledDates()"
              isUtc
            ></app-datepicker>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-share-capital align-middle">
            <app-input
              class="cell auto legal-share-capital"
              v-model="legalShareCapitalComputed"
              label="Capital social *"
              type="number"
            >
              <template v-slot:suffix><SvgEuro /></template>
            </app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-annual-turnover align-middle">
            <app-select
              class="cell auto"
              v-model="legalAnnualTurnOverComputed"
              label="Chiffre d’affaires annuel (K€) *"
              :options="EnumLegalAnnualTurnOverRanges"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-net-income-range align-middle">
            <app-select
              class="cell auto"
              v-model="legalNetIncomeRangeComputed"
              label="Résultat net annuel (K€) *"
              :options="EnumLegalNetIncomeRanges"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x legal-number-of-employee-ranges align-middle">
            <app-select
              class="cell auto"
              v-model="legalNumberOfEmployeeRangeComputed"
              label="Tranche de salariés *"
              :options="EnumLegalNumberOfEmployeeRanges"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x share-holder-number align-middle">
            <p>En dehors du responsable légal, combien <br> avez-vous de bénéficiaires effectifs à plus de 25% ?</p>
            <app-select
              class="cell small-12"
              v-model="shareholderNumberComputed"
              label="Bénéficiaires"
              :options="[{ name: '0', label: '0' }, { name: '1', label: '1' }, { name: '2', label: '2' }, { name: '3', label: '3' }]"
              :isDisabled="isDisabled">
            </app-select>
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !legalRegistrationDateComputed || !legalShareCapitalComputed || !legalAnnualTurnOverComputed || !legalNetIncomeRangeComputed || !legalNumberOfEmployeeRangeComputed || !shareholderNumberComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';
import SvgEuro from '../../../assets/img/icons/euro.svg?inline';

// Enums
import EnumLegalAnnualTurnOverRanges from '../../../../../commons/legalAnnualTurnOverRanges.json';
import EnumLegalNetIncomeRanges from '../../../../../commons/legalNetIncomeRanges.json';
import EnumLegalNumberOfEmployeeRanges from '../../../../../commons/legalNumberOfEmployeeRanges.json';
import EnumNotificationType from '../../../services/enums/notification.enum';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-company-edit-numbers',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
    SvgEuro,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      legalRegistrationDate: null,
      legalShareCapital: null,
      legalAnnualTurnOver: null,
      legalNetIncomeRange: null,
      legalNumberOfEmployeeRange: null,
      shareholderNumber: null,

      errors: [],
      isLoading: false,

      EnumLegalNumberOfEmployeeRanges,
      EnumLegalNetIncomeRanges,
      EnumLegalAnnualTurnOverRanges,
    };
  },
  computed: {
    legalRegistrationDateComputed: {
      get() { return this.$dayjs(this.data.customer.legalRegistrationDate).toDate(); },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalRegistrationDate: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalShareCapitalComputed: {
      get() { return this.data.customer.legalShareCapital; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalShareCapital: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalAnnualTurnOverComputed: {
      get() { return this.data.customer.legalAnnualTurnOver; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalAnnualTurnOver: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalNetIncomeRangeComputed: {
      get() { return this.data.customer.legalNetIncomeRange; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalNetIncomeRange: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    legalNumberOfEmployeeRangeComputed: {
      get() { return this.data.customer.legalNumberOfEmployeeRange; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            legalNumberOfEmployeeRange: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    shareholderNumberComputed: {
      get() { return this.data.customer.shareholderNumber; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            shareholderNumber: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        legalRegistrationDate: this.legalRegistrationDateComputed,
        legalShareCapital: Number(this.legalShareCapitalComputed),
        legalAnnualTurnOver: this.legalAnnualTurnOverComputed,
        legalNetIncomeRange: this.legalNetIncomeRangeComputed,
        legalNumberOfEmployeeRange: this.legalNumberOfEmployeeRangeComputed,
        shareholderNumber: Number(this.shareholderNumberComputed),
      };

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    datepickerDisabledDates() {
      // eslint-disable-next-line
      const dayjs = require('dayjs');
      // Objet spécifique au package vuejs-datepicker
      return {
        customPredictor(date) {
          if (dayjs() < dayjs(date)) {
            return true;
          }
          return false;
        },
      };
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-company-edit-numbers
  .app-input
    margin: 0
  .app-input, .app-select, .app-datepicker
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .app-datepicker input
    background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3
  .legal-registration-data, .legal-share-capital, .legal-annual-turnover, .legal-net-income-range, .legal-number-of-employee-ranges, .share-holder-number
    width: 100%
  .share-holder-number
    .app-select
      max-width: 200px
      margin: auto
      margin-top: $global-padding-horizontal
    > p
      @include header4
      text-align: center

  .content .grid-x
    > svg
      margin-left: $global-padding-vertical
      path
        &:first-child
          stroke-width: 1
          stroke: var(--color-grey70)
        &:last-child
          fill: var(--color-cloud)
          stroke: var(--color-grey70)
</style>
