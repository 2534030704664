<template>
  <div class="profil-profil-edit-personal-information">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p><span>{{ firstnameComputed }}</span> <span>{{ lastnameComputed }}</span></p>
        <p>Informations personnelles</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x civility align-middle">
            <p class="cell small-12">Vous êtes ? * <SvgInfos /></p>
            <app-radio-custom class="cell small-12" :isDisabled="isDisabled" :values=civilities @value="(value) => civilityComputed = value" :value="civilityComputed"></app-radio-custom>
          </div>

          <div class="grid-x lastname align-middle">
            <app-input
              class="cell auto"
              label="Nom *"
              type="text"
              v-model="lastnameComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x firstname align-middle">
            <app-input
              class="cell auto"
              label="Prénom *"
              type="text"
              v-model="firstnameComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x email align-middle">
            <app-input
              class="cell auto"
              label="Email *"
              type="text"
              v-model="emailComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !civilityComputed || !lastnameComputed || !firstnameComputed || !emailComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';
import SvgInfos from '../../../assets/img/icons/infos.svg?inline';

// Components
import AppRadioCustom from '../../AppRadioCustom.vue';

// Enums
import EnumCivility from '../../../services/enums/customer/civility.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-profil-edit-personal-information',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
    SvgInfos,

    AppRadioCustom,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      civilities: this.$utils.transformEnumToArray(EnumCivility),

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    civilityComputed: {
      get() { return EnumCivility[this.data.customer.civility]; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            civility: newValue.name,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    lastnameComputed: {
      get() { return this.data.customer.lastname; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            lastname: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    firstnameComputed: {
      get() { return this.data.customer.firstname; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            firstname: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    emailComputed: {
      get() { return this.data.customer.email; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            email: newValue.toLowerCase(),
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        civility: this.civilityComputed.name,
        lastname: this.lastnameComputed,
        firstname: this.firstnameComputed,
        email: this.emailComputed,
      };

      try {
        /* eslint-disable */
        // Cas customer INDIVIDUAL
        if (this.customer.customer.type === EnumCustomerType.INDIVIDUAL) {
          await ApiCustomer.setCustomer(tmpUser);
        } else { // Cas customer BUSINESS
          // S'il n'y a pas de customerId alors c'est un actionnaire
          // et lors une première connexion il est créé
          if (!this.data.customer.customerId) {
            await ApiCustomer.createAssociate(EnumParentType.SHAREHOLDER.name, tmpUser.civility, tmpUser.firstname, tmpUser.lastname, tmpUser.email);
          } else {
            // S'il y a un customerId il faut vérifier à quel type de customer on a à faire,
            // car la route de modification ne sera pas pas identique.
            if (this.data.customer.parentType === EnumParentType.LEADER.name) {
              await ApiCustomer.setLeader(tmpUser);
            } else if (this.data.customer.parentType === EnumParentType.SHAREHOLDER.name) {
              await ApiCustomer.setAssociate(this.data.customer.customerId, tmpUser);
            }
          }
        }
        /* eslint-enable */
        this.$getCustomer(true);
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-profil-edit-personal-information
  .app-input
    margin: 0
  .app-input
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3

  .app-flap
    .main
      p:first-child
        @include header4
        margin-bottom: 0
        color: var(--color-100)
      p:last-child
        margin-top: 0
        @include header2
    .content
      .civility
        @include header4
        color: var(--color-grey70)
        text-align: center
        .app-radio-custom
          justify-content: center
        svg
          width: 14px
      .lastname, .firstname, .email
        svg
          margin-left: $global-padding-vertical
          path
            &:first-child
              stroke-width: 1
              stroke: var(--color-grey70)
            &:last-child
              fill: var(--color-cloud)
              stroke: var(--color-grey70)
</style>
