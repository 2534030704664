<template>
  <div class="two-blocks">
    <div class="grid-x align-justify">
      <div class="cell medium-4 content align-self-middle medium-offset-1">
        <slot name="main"></slot>
      </div>
      <div class="cell medium-6 illustration hide-for-small">
        <div class="animation">
          <slot name="animation"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'two-blocks',
  components: {
  },
};
</script>

<style lang="sass">
.two-blocks
  // height: calc( 100vh - var(--navigation-height) - var(--app-footer-height) * 4)
  > div
    height: inherit
  .content
    z-index: 1
  .illustration
    position: relative
    display: flex
    &::after
      position: fixed
      content: ''
      display: block
      top: calc(-1 * var(--navigation-height))
      height: calc(100vh + var(--navigation-height))
      width: inherit
      background-size: cover
      background-repeat: no-repeat
      z-index: 0
    .animation
      position: relative
      z-index: 1
</style>
