import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Demande la création d'une taxe résidence pour l'utilisateur lors d'une souscription
 *
 * @param {EnumCountries} country
 * @param {string} taxPayerId
 * @param {string} treezorId
 * @returns
 */
const createTaxResidences = async (country, taxPayerId, treezorId) => {
  try {
    const response = await axios.post(`${API_URL}/tax-residences`, {
      ...(country && { country }),
      ...(taxPayerId && { taxPayerId }),
      ...(treezorId && { treezorId }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtient les tax residences
 * @param {string} treezorId
 *
 * @returns
 */
const getTaxResidences = async (treezorId) => {
  try {
    const response = await axios.get(`${API_URL}/tax-residences`, {
      params: {
        ...(treezorId && { treezorId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la mise à jour d'une taxe residence
 *
 * @param {*} taxResidenceId
 * @param {*} country
 * @param {*} taxPayerId
 * @param {string} treezorId
 * @returns
 */
const setTaxResidence = async (taxResidenceId, country, taxPayerId, treezorId) => {
  try {
    const response = await axios.put(`${API_URL}/tax-residences/${taxResidenceId}`, {
      ...(country && { country }),
      ...(taxPayerId && { taxPayerId }),
      ...(treezorId && { treezorId }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la suppression d'une taxe residence
 *
 * @param {*} taxResidenceId
 * @param {string} treezorId
 * @returns
 */
const deleteTaxResidence = async (taxResidenceId, treezorId) => {
  try {
    const response = await axios.delete(`${API_URL}/tax-residences/${taxResidenceId}`, {
      params: {
        ...(treezorId && { treezorId }),
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.createTaxResidences = createTaxResidences;
api.getTaxResidences = getTaxResidences;
api.setTaxResidence = setTaxResidence;
api.deleteTaxResidence = deleteTaxResidence;

export default api;
