<template>
  <div class="security-edit-password">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Changer de mot de <br> passe</p>
      </template>
      <template v-slot:app-flap-content>
        <div>
          <app-input
            label="Mot de passe actuel"
            type="password"
            v-model="actualPassword"
          ></app-input>
          <app-input
            label="Nouveau mot de passe"
            type="password"
            v-model="password"
          ></app-input>
          <app-input
            label="Répétez mot de passe"
            type="password"
            v-model="passwordConfirm"
          ></app-input>
          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>
        </div>

        <app-button
          :disabled="isLoading"
          size="small"
          kind="primary"
          @click="$emit('click', sendData())"
        >
          <span v-if="!isLoading">Enregistrer les modifications</span>
          <app-spinner v-else :small="true"></app-spinner>
        </app-button>
      </template>
    </app-flap>
  </div>
</template>

<script>
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';

export default {
  name: 'security-edit-password',
  components: {
    SvgWarning,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      actualPassword: null,
      password: null,
      passwordConfirm: null,
    };
  },
  methods: {
    sendData() {
      // eslint-disable-next-line
      return {...this['_data']};
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'actualPassword must be shorter than or equal to 32 characters':
          newErrorMessage = 'Le mot de passe actuel doit contenir maximum 32 caractères.';
          break;
        case 'actualPassword must be longer than or equal to 8 characters':
          newErrorMessage = 'Le mot de passe actuel doit contenir minimum 8 caractères.';
          break;
        case 'password must be shorter than or equal to 32 characters':
          newErrorMessage = 'Le nouveau mot de passe doit contenir maximum 32 caractères.';
          break;
        case 'password must be longer than or equal to 8 characters':
          newErrorMessage = 'Le nouveau mot de passe doit contenir minimum 8 caractères.';
          break;
        case 'passwordConfirm must be shorter than or equal to 32 characters':
          newErrorMessage = 'La confirmation doit contenir maximum 32 caractères.';
          break;
        case 'passwordConfirm must be longer than or equal to 8 characters':
          newErrorMessage = 'La confirmation doit contenir minimum 8 caractères.';
          break;
        case 'Passwords are not the same !':
          newErrorMessage = 'Les deux mots de passe doivent être identiques.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.security-edit-password
  .app-input
    .label
      margin: revert
      background-color: var(--color-cloud)
    ~ .app-input
      margin-top: $global-padding-vertical * 3
  .app-button
    display: flex
    margin-top:  $global-padding-horizontal * 4
    width: 100%
    justify-content: center
  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
