<template>
  <div class="app-slider" :class="{ progress: type === 'progress' }">
    <div class="slider one" :class="{ disabled: disabled }" v-if="value || value === 0">
      <input
        type="range"
        :value="value"
        @input="$emit('input', parseInt($event.target.value, 10))"
        :min="min"
        :max="max"
        :disabled="disabled"
      />
      <div class="progress" :style="{
        width: `min(calc(${(value * 100) / max}% - 4px), calc(100% - 4px))`,
        minWidth: value > 0 ? '1px' : 0
      }"/>
    </div>
    <div class="slider two" v-else :class="{ disabled: disabled }">
      <input
        type="range"
        :value="0"
        @input="$emit('input', parseInt($event.target.value, 10))"
        :min="min"
        :max="max"
        :disabled="disabled"
      />
      <div class="progress" :style="{ width: '0%' }"/>
    </div>

    <div class="infos grid-x align-justify">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 0,
      type: Number,
    },
    min: {
      default: 0,
      type: Number,
    },
    max: {
      default: 100,
      type: Number,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    type: {
      default: 'slider',
      validator: (val) => ['slider', 'progress'].indexOf(val) !== -1,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang="sass">
.app-slider
  .slider
    background-color: var(--color-50)
    width: 100%
    border-radius: 15px
    position: relative
    height: 11px
    &.disabled
      ::-webkit-slider-thumb
        cursor: initial
        background-color: var(--color-70)
      ::-moz-range-thumb
        cursor: initial
        background-color: var(--color-70)
      .progress
        background-color: var(--color-70)
    ::-webkit-slider-thumb
      -webkit-appearance: none
      appearance: none
      width: 20px
      height: 20px
      background-color: var(--color-black)
      border: 2px solid var(--color-cloud)
      border-radius: 10px
      cursor: pointer
    ::-moz-range-thumb
      width: 15px
      height: 15px
      background-color: white
      border: black 1px solid
      border-radius: 10px
      cursor: pointer
    .progress
      position: absolute
      background-color: var(--color-black)
      height: 7px
      z-index: 0
      top: 0
      bottom: 0
      left: 2px
      margin: auto
      border-radius: 15px
    input
      font-size: $global-font-size
      border-radius: $global-border-radius
      transition: all 0.2s ease-in-out
      -webkit-appearance: none
      margin: 0
      height: 100%
      width: 100%
      padding: 0
      display: block
      background-color: transparent
      z-index: 1
      position: absolute
      &:focus
        outline: 0
  .infos
    margin: $global-padding-horizontal 0
    @include details
    p
      margin: 0
  &.progress
    ::-webkit-slider-thumb
      display: none
    ::-moz-range-thumb
      visibility: hidden
    .infos
      margin: ($global-padding-horizontal / 2) 0
</style>
