
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"viewBox":"0 0 33 32","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('rect',{attrs:{"x":".162","width":"32","height":"32","rx":"16","fill":"#1A202C"}}),_c('path',{attrs:{"d":"M16.162 8.716v10.388m0 0l3.078-3.078m-3.078 3.078l-3.077-3.078","stroke":"#fff","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M9.3 17.098v3.818a2 2 0 002 2h9.724a2 2 0 002-2v-3.818","stroke":"#fff","stroke-width":"1.5","stroke-linecap":"round"}})])
          )
        }
      }
    