<template>
  <div class="register-company-embargo">
    <p class="title">Embargo entreprise <SvgInfos /></p>

    <p class="subtitle" v-if="!moreInformationRequired">Vous n'entrez pas dans le cadre de cette nouvelle réglementation, nous n'avons pas besoin d'informations complémentaires. Vous pouvez passer à l'étape suivante.</p>
    <p class="subtitle">Si votre chiffre d’affaire est supérieur à 1M€ ou que votre <br> code NAF fait partie de cette liste</p>

    <app-loader v-if="isLoading"></app-loader>
    <form v-else @submit.prevent="sendData">
      <div class="grid-x grid-margin-x activity-outside-eu align-middle">
        <p class="cell auto">Votre société a-t-elle une activité commerciale hors UE ? *</p>
        <app-radio-custom class="cell shrink align-right" :values=EnumYesNoActivityOutsideEuComputed @value="(value) => activityOutsideEu = value" :value="activityOutsideEuComputed"></app-radio-custom>
      </div>

      <template v-if="(activityOutsideEuComputed && (activityOutsideEuComputed.name === 'true'))">
        <div class="grid-x economic-sanctions align-middle">
          <p class="cell small-8">Votre société, ses succursales, ses filiales, joint-ventures, ses dirigeants ou ses employés sont-ils visés par des mesures de Sanctions Économiques ? *</p>
          <app-radio-custom class="cell auto align-right" :values="EnumYesNo" @value="(value) => economicSanctions = value" :value="economicSanctionsComputed"></app-radio-custom>
        </div>

        <div class="grid-x resident-countries-sanctions align-middle">
          <p class="cell small-8">Votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils physiquement présents dans, ou opèrent-ils depuis, des pays soumis à des sanctions étendues ? * </p>
          <app-radio-custom class="cell auto align-right" :values=EnumYesNo @value="(value) => residentCountriesSanctions = value" :value="residentCountriesSanctionsComputed"></app-radio-custom>
        </div>

        <div class="grid-x involved-sanctions align-middle">
          <p class="cell small-8">Après un examen minutieux et à votre connaissance, votre société, ses succursales, ses filiales ou ses joint-ventures sont-ils impliqués dans des opérations, des investissements, des activités ou toutes autres transactions impliquant ou profitant directement ou indirectement à (i) des pays soumis à des sanctions étendues ou à (ii) toute personne ou entité visée par des Sanctions (« Personnes Sanctionnées ») ? *</p>
          <app-radio-custom class="cell auto align-right" :values=EnumYesNo @value="(value) => involvedSanctions = value" :value="involvedSanctionsComputed"></app-radio-custom>
        </div>

        <div class="errors" v-if="errors && errors.length > 0">
          <SvgWarning width="22" />
          <ul>
            <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
              {{ transformError(error) }}
            </li>
          </ul>
        </div>
      </template>

      <app-button :disabled="!activityOutsideEuComputed || (activityOutsideEuComputed && activityOutsideEuComputed.name === 'true' && (!economicSanctionsComputed || !involvedSanctionsComputed || !residentCountriesSanctionsComputed))" class="next" type="submit">Suivant <SvgArrow /></app-button>
    </form>
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgArrow from '../../../assets/img/icons/arrow.svg?inline';
import SvgInfos from '../../../assets/img/icons/infos.svg?inline';

// Components
import AppRadioCustom from '../../AppRadioCustom.vue';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumYesNo from '../../../services/enums/yesNo.enum';
import EnumLegalAnnualTurnOverRanges from '../../../../../commons/legalAnnualTurnOverRanges.json';
import EnumNafCodes from '../../../../../commons/nafEmbargo.json';

export default {
  name: 'register-company-embargo',
  components: {
    SvgWarning,
    AppRadioCustom,
    SvgArrow,
    SvgInfos,
  },
  inject: ['$getCustomer'],
  data() {
    return {
      activityOutsideEu: null,
      economicSanctions: null,
      residentCountriesSanctions: null,
      involvedSanctions: null,

      moreInformationRequired: false,

      errors: [],
      isLoading: false,

      EnumCustomerType,
      EnumYesNo,
      EnumLegalAnnualTurnOverRanges,
      EnumNafCodes,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    EnumYesNoActivityOutsideEuComputed() {
      return Object.keys(EnumYesNo).map((key) => ({
        ...EnumYesNo[key],
        // eslint-disable-next-line
        disabled: this.moreInformationRequired ? false : true,
      }));
    },
    activityOutsideEuComputed: {
      get() { return this.activityOutsideEu || (this.moreInformationRequired ? this.activityOutsideEu : EnumYesNo.false) || EnumYesNo[this.customer.customer.activityOutsideEu]; },
      set(newValue) { this.activityOutsideEu = newValue; },
    },
    economicSanctionsComputed: {
      get() { return this.economicSanctions || EnumYesNo[this.customer.customer.economicSanctions]; },
      set(newValue) { this.economicSanctions = newValue; },
    },
    residentCountriesSanctionsComputed: {
      get() { return this.residentCountriesSanctions || EnumYesNo[this.customer.customer.residentCountriesSanctions]; },
      set(newValue) { this.residentCountriesSanctions = newValue; },
    },
    involvedSanctionsComputed: {
      get() { return this.involvedSanctions || EnumYesNo[this.customer.customer.involvedSanctions]; },
      set(newValue) { this.involvedSanctions = newValue; },
    },
  },
  watch: {
    customer() {
      this.moreInformationRequired = this.needMoreInformation();
    },
  },
  mounted() {
    if (this.customer && this.customer.customer.legalAnnualTurnOver) this.moreInformationRequired = this.needMoreInformation();
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        activityOutsideEu: false,
        economicSanctions: false,
        residentCountriesSanctions: false,
        involvedSanctions: false,
      };

      if (this.moreInformationRequired) {
        tmpUser.activityOutsideEu = (this.activityOutsideEuComputed.name === 'true');
        tmpUser.economicSanctions = (this.economicSanctionsComputed.name === 'true');
        tmpUser.residentCountriesSanctions = (this.residentCountriesSanctionsComputed.name === 'true');
        tmpUser.involvedSanctions = (this.involvedSanctionsComputed.name === 'true');
      }

      try {
        await ApiCustomer.setCustomer(tmpUser);
        // Mise à jour de l'injection de dépendance customer
        this.$getCustomer(true);
        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
    needMoreInformation() {
      // CA > 1M€ || Code naf inclu dans la liste
      const ca = this.customer.customer.legalAnnualTurnOver?.split('-');
      const doesTurnoverExceed1M = ca.some((number) => Number(number) >= 1000);
      const isNafCodeIncluded = EnumNafCodes.find((code) => code.name === this.customer.customer.legalSector);

      return doesTurnoverExceed1M || isNafCodeIncluded;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case '':
          newErrorMessage = '';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.register-company-embargo
  text-align: center
  p
    &.title, &.subtitle
      margin: 0
    &.title
      @include header1
      margin-bottom: ($global-padding-vertical / 2)
      svg
        margin: 0 0 5px 5px
        width: 20px
    &.subtitle
      @include details
      margin-bottom: $global-padding-horizontal * 3.5
      color: var(--color-grey70)
  .app-input
    margin: 0
  form
    .activity-outside-eu, .economic-sanctions, .resident-countries-sanctions, .involved-sanctions
      @include header4
      text-align: left
    .economic-sanctions, .resident-countries-sanctions, .involved-sanctions
      margin-top: $global-padding-vertical * 3
  .errors
    display: flex
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
