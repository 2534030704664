<template>
  <div class="operations-list" v-if="dataSorted">
    <div class="wrapper" :class="`year-${year[0]}`" v-for="(year, index) in dataSorted" :key="`operations-list-year-${year}-${index}`">
      <template v-for="(month) in Object.entries(year[1]).reverse()">
        <p class="month" :key="`operations-list-year-${year}-${index}-month-${month[0]}-${index}`">{{ $dayjs(new Date(0,+month[0],0)).format('MMMM') | capitalize }} {{ year[0] }}</p>
        <div class="record grid-x grid-margin-x" v-for="(record, index) in year[1][month[0]]" :key="`operations-list-year-${year}-${index}-month-${month}-${index}-record-${index}`"
          :class="[{ 'active': selectedOperation.objectId === record.objectId }, { 'pending': record.status === EnumOperationsStatus.AUTHORIZED.name }]"
          @click="$emit('click', record)">
          <div class="cell shrink icon">
            <div class="circle"  >
              <component v-if="record.instant === true" :is="EnumOperationType['BANKTRANSFER_INSTANT'].icon"></component>
              <component v-else-if="record.metadata && record.metadata.cardPayment &&  record.metadata.cardPayment.mcc.code === 6011" :is="EnumOperationType['CARDTRANSACTION_WITHDRAW'].icon"></component>
              <component v-else :is="EnumOperationType[record.operationType.toUpperCase()].icon"></component>
            </div>
          </div>
          <div class="cell auto debtor-creditor">
            <p v-if="record.ibanFullname" class="label">VIR {{ record.ibanFullname.toUpperCase() }}</p>
            <p v-else-if="record.operationType.toUpperCase() == 'FEES'" class="label">Kalveen</p>
            <p v-else-if="record.operationType.toUpperCase() == 'CREDITNOTE'" class="label">Kalveen</p>
            <p v-else-if="record.metadata && record.metadata.cardPayment && record.metadata.cardPayment.mcc.code === 6011" class="label">RETRAIT {{ record.label.toUpperCase() }}</p>
            <p v-else-if="record.label" class="label">{{ record.label.toUpperCase() }}</p>
            <p v-else class="label">--</p>
            <p class="created-date">{{ record.date.creation | formatDate }}</p>
          </div>
          <div class="cell auto type" v-if="record.instant === true">{{ EnumOperationType['BANKTRANSFER_INSTANT'].label }}</div>
          <div class="cell auto type" v-else>{{ EnumOperationType[record.operationType.toUpperCase()].label }}</div>
          <div class="cell auto amount" :class="[{ 'credit': record.direction === 'CREDIT' }, { 'cancel': record.status === 'CANCELED' }]">
            {{ record.status === EnumOperationsStatus.AUTHORIZED.name ? `${EnumOperationsStatus.AUTHORIZED.label}: ` : null }} {{ EnumOperationsDirection[record.direction] }} {{ record.amount && record.amount.amount | convertCentsToEuros | formatPrice }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EnumOperationType from '../../services/enums/operations/type.enum';
import EnumOperationsDirection from '../../services/enums/operations/direction.enum';
import EnumOperationsStatus from '../../services/enums/operations/status.enum';

export default {
  name: 'operations-list',
  props: {
    data: {
      type: Array,
      required: true,
    },
    selectedOperation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataSorted: [],
      EnumOperationType,
      EnumOperationsDirection,
      EnumOperationsStatus,
    };
  },
  watch: {
    data() {
      this.getDataSorted();
    },
  },
  mounted() {
    this.getDataSorted();
  },
  methods: {
    getDataSorted() {
      this.dataSorted = this.sortOperationsByMonth();
    },
    /**
     * Retourne la liste des transactions groupées par mois de chaque année
     * et triées du mois le plus récent au plus éloigné
     */
    sortOperationsByMonth() {
      let group = {};
      this.data.forEach((operation) => {
        const yearAlreadyExists = Object.keys(group).find((key) => Number(key) === (Number(this.$dayjs(operation.date.creation).get('year'))));
        if (yearAlreadyExists) {
          const monthAlreadyExists = Object.keys(group[yearAlreadyExists]).find((key) => Number(key) === (Number(this.$dayjs(operation.date.creation).get('month')) + 1));

          if (monthAlreadyExists) {
            group[yearAlreadyExists][monthAlreadyExists].push(operation);
          } else {
            group[yearAlreadyExists] = {
              [Number(this.$dayjs(operation.date.creation).get('month')) + 1]: [operation],
              ...group[yearAlreadyExists],
            };
          }
        } else {
          group = {
            ...group,
            [this.$dayjs(operation.date.creation).get('year')]: {
              [Number((this.$dayjs(operation.date.creation).get('month')) + 1)]: [operation],
            },
          };
        }
      });
      return Object.entries(group).reverse();
    },
  },
};
</script>

<style lang="sass">
.operations-list
  .wrapper
    padding: $global-padding-horizontal
    .month
      @include header4
      color: var(--color-grey70)
    .record
      @include clickable-row
      &.pending
        .cell, p
         color: var(--color-100) !important
        .icon .circle
          opacity: 0.5
          background-color: var(--color-100)
      .icon
        svg
          height: 18px
          width: 18px
          path
            fill: var(--color-grey90)
        .circle
          display: flex
          height: 32px
          width: 32px
          justify-content: center
          align-items: center
          border-radius: 100%
          background-color: var(--color-50)
      .debtor-creditor
        p
          margin: 0
        .label
          @include header5
        .created-date
          @include details
          color: var(--color-grey70)
      .type
        @include text
      .amount
        @include header4
        &.credit
          color: var(--color-success)
        &.cancel
          color: var(--color-warning)

</style>
