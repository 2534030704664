<template>
  <div class="transactions">
    <app-flap class="flap-container" :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)" :onScroll="onScrollTransactions">
      <template v-slot:app-flap-main>
        <p>Historique des<br/>transactions</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <div v-else-if="transactions && transactions.length > 0">
          <div
            class="record grid-x grid-margin-x"
            v-for="(transaction, index) in transactions"
            :key="index">
            <div class="cell auto debtor-creditor">
              <p class="label">{{ transaction.merchantName.trim().toUpperCase() }}</p>
              <p class="created-date">{{ transaction.authorizationIssuerTime | formatDate }}</p>
            </div>

            <div class="cell auto amount" :class="[{ 'credit': /-/.test(transaction.paymentAmount) }]">
              {{ /-/.test(transaction.paymentAmount) ? '+' : '-' }} {{ transaction.paymentAmount.replaceAll('-', '') }} €
            </div>
          </div>
          <app-loader v-if="isRefreshing"></app-loader>
        </div>
        <p v-else>Aucune transaction sur cette carte</p>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'transactions',
  props: {
    cardId: {
      type: Number,
      required: false,
    },
    secretCode: {
      type: Array,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      transactions: null,
      metadata: {
        order: '',
        limit: 5,
        offset: 0,
        count: 0,
        search: '',
      },

      isLoading: false,
      isRefreshing: false,
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        // Si nouvelle ouverture, on remet à zéro
        this.metadata = {
          order: '',
          limit: 20,
          offset: 0,
          count: 0,
          search: '',
        };

        this.getTransactions();
      }
    },
  },
  methods: {
    async getTransactions() {
      if (this.transactions) {
        this.isRefreshing = true;
      } else {
        this.isLoading = true;
      }

      try {
        const data = await ApiCard.getCardTransactions(this.cardId, this.metadata.limit, this.metadata.offset, ['S']);

        this.metadata.offset = data.metadata.offset;
        this.metadata.count = data.metadata.count;

        if (this.transactions) {
          this.transactions = [...this.transactions, ...data.data];
        } else {
          this.transactions = [...data.data];
        }
      } catch {
        this.transactions = [];
        this.$notification.show({
          title: 'Les transactions n\'ont pas pu être récupérées',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      } finally {
        this.isLoading = false;
        this.isRefreshing = false;
      }
    },
    onScrollTransactions({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight && !this.isLoading && !this.isRefreshing && (this.transactions.length < this.metadata.count)) {
        this.getTransactions();
      }
    },
  },
};
</script>

<style lang="sass">
.transactions
  text-align: center
  .flap-container
    display: flex
    flex-direction: column
    .content
      flex: 1
      padding: 60px 0px 32px 0px
      .record
        padding: 16px 24px
        border-bottom-width: 1px
        border-bottom-style: solid
        border-color: var(--color-50)
        &:last-of-type
          border: 0
        .debtor-creditor
          p
            margin: 0
            text-align: left
          .label
            @include header5
          .created-date
            @include details
            color: var(--color-grey70)
        .type
          @include text
        .amount
          @include header4
          text-align: right
          &.credit
            color: var(--color-success)
</style>
