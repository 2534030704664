<template>
  <section class="checks-list">
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h2>Chèques</h2>
      </div>
      <div class="cell shrink container-buttons">
        <app-button size="large" @click="$emit('click:new', 'new')">Déposer un chèque<SvgPlus /></app-button>
      </div>
    </div>
    <app-loader v-if="isLoading"></app-loader>
    <p class="no-data" v-else-if="checks && checks.length <= 0">
      <SvgNoChecksIndividual v-if="$theme === EnumCustomerType.INDIVIDUAL.toLowerCase()"/>
      <SvgNoChecksBusiness v-else-if="$theme === EnumCustomerType.BUSINESS.toLowerCase()"/>
      <span>Aucun chèque sur votre compte</span>
    </p>
    <div class="table" v-if="checks && checks.length > 0">
      <div v-for="check in checks" class="row grid-x" :key="check.payinId" @click="$emit('click:check', check)" :class="{ active: selectedCheck && selectedCheck.payinId === check.payinId }">
        <div class="cell auto name">{{ check.userFirstname }} {{ check.userLastname }}</div>
        <div class="cell auto">{{ cleanAdditionalData(check.additionalData) }}</div>
        <div class="cell auto">{{ check.createdDate | formatDate }}</div>
        <div class="cell shrink amount">{{ check.amount ? '+' : '' }} {{ +check.amount | formatPrice }}</div>
        <div class="cell auto status" :class="getCheckStatus(check)"><span>{{ CheckStatusLabel[getCheckStatus(check)] }}</span><SvgArrowRight /></div>
      </div>

      <app-button class="see-more" size="small" :disabled="!canSeeMore" :isLoading="isLoadingMore" kind="secondary" @click="getMorePayins">Voir plus <SvgArrowDown /></app-button>
    </div>
  </section>
</template>

<script>
import payinApi from '@/services/api/payin';

import CheckStatusLabel from '@/services/enums/checks/check-status-label.enum';
import EnumNotificationType from '@/services/enums/notification.enum';
import EnumCustomerType from '@/services/enums/customer/type.enum';

import SvgPlus from '@/assets/img/icons/plus.svg?inline';
import SvgArrowRight from '@/assets/img/icons/arrow-right.svg?inline';
import SvgNoChecksIndividual from '@/assets/img/individual/no-checks.svg?inline';
import SvgNoChecksBusiness from '@/assets/img/business/no-checks.svg?inline';
import SvgArrowDown from '../../assets/img/icons/down.svg?inline';

export default {
  components: {
    SvgPlus,
    SvgArrowRight,
    SvgNoChecksIndividual,
    SvgNoChecksBusiness,
    SvgArrowDown,
  },
  props: {
    getCheckStatus: {
      type: Function,
      required: true,
    },
    selectedCheck: {
      type: Object,
      required: false,
    },
  },
  emits: ['click:check', 'click:new'],
  data() {
    return {
      checks: null,
      metadata: {
        pageCount: 10,
        pageNumber: 1,
        count: 0,
      },
      isLoading: false,
      isLoadingMore: false,
      CheckStatusLabel,

      EnumCustomerType,
    };
  },
  computed: {
    canSeeMore() {
      return this.checks.length < this.metadata.count;
    },
  },
  mounted() {
    this.getPayins();
  },
  methods: {
    async getPayins() {
      this.isLoading = true;
      try {
        const response = await payinApi.getPayins({ paymentMethodId: 26, pageNumber: this.metadata.pageNumber, pageCount: this.metadata.pageCount });
        this.checks = response.data;
        this.metadata.pageNumber = response.metadata.offset;
        this.metadata.pageCount = response.metadata.limit;
        this.metadata.count = response.metadata.count;
      } catch (error) {
        this.checks = [];
        this.$notification.show({ title: 'Erreur', text: 'Une erreur est survenue en récupérant vos Chèques. Veuillez rééssayer plus tard', type: EnumNotificationType.ECHEC });
      }
      this.isLoading = false;
    },
    async getMorePayins() {
      this.isLoadingMore = true;
      try {
        const response = await payinApi.getPayins({ paymentMethodId: 26, pageNumber: this.metadata.pageNumber + 1, pageCount: this.metadata.pageCount });
        this.checks.push(...response.data);
        this.metadata.pageNumber = response.metadata.offset;
        this.metadata.pageCount = response.metadata.limit;
      } catch (e) {
        this.$notification.show({ title: 'Erreur', text: 'Une erreur est survenue en récupérant vos Chèques. Veuillez rééssayer plus tard', type: EnumNotificationType.ECHEC });
      }
      this.isLoadingMore = false;
    },
    cleanAdditionalData(additionalData) {
      let value = 'XXXX XXXX XXXX XXXX';

      if (additionalData && (typeof additionalData === 'object' && additionalData.length > 0)) {
        const json = JSON.parse(additionalData[0].replace(/'/g, '"'));
        value = `${json.cheque.cmc7.a} - ${json.cheque.cmc7.b} - ${json.cheque.cmc7.c}`;
      }

      return value;
    },
  },
};
</script>

<style lang="sass">
.checks-list
  .no-data
    @include no-data
  .header
    margin-bottom: 48px
    h2
      @include header2
  .table
    .row
      @include clickable-row
      padding: 20px ($global-padding-vertical * 2)
      .cell
        @include text
        vertical-align: middle
      .name, .status
        @include header5
        line-height: 20px
      .name
        text-transform: uppercase
      .amount
        @include header4
      .status
        color: var(--color-grey70)
        &.CASHED,&.VALIDATED
          color: var(--color-success)
        &.REFUSED,&.FAIL,&.SHIPPING_FAILED
          color: var(--color-warning)
        svg
          margin-left: $global-padding-vertical * 2
          vertical-align: middle
  .see-more
    @include see-more
</style>
