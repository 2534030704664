<template>
  <div class="app-errors" v-if="errors && errors.length > 0">
    <SvgWarning width="22" />
    <ul>
      <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
import SvgWarning from '@/assets/img/icons/warning.svg?inline';

export default {
  components: {
    SvgWarning,
  },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="sass">
.app-errors
  display: flex
  color: var(--color-warning)
  margin: 1rem 0 2rem 0
  ul
    padding-left: $global-padding-horizontal
    list-style: none
    color: var(--color-warning)
</style>
