const statusCode = Object.freeze({
  UNLOCK: {
    key: 0,
    name: 'UNLOCK',
    label: 'Débloquée',
  },
  LOCK: {
    key: 1,
    name: 'LOCK',
    label: 'Bloquée',
  },
  LOST: {
    key: 2,
    name: 'LOST',
    label: 'Perdue',
  },
  STOLEN: {
    key: 3,
    name: 'STOLEN',
    label: 'Volée',
  },
  DESTROYED: {
    key: 4,
    name: 'DESTROYED',
    label: 'Détruite',
  },
});

export default statusCode;
