<template>
  <div class="app-datepicker">
    <p class="placeholder" :class="{ 'placeholder-at-top': isFocused || input }">{{ placeholder }}</p>

    <datepicker
      :class="{ 'disabled': isDisabled }"
      format="dd / MM / yyyy"
      :value="input"
      @selected="onSelect"
      :clear-button="hasResetButton"
      :disabled="isDisabled"
      :calendar-button="true"
      :disabled-dates="disabledDates"
      :language="fr"
      :use-utc="isUtc"
    ></datepicker>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { fr } from 'vuejs-datepicker/dist/locale';
// import SvgCalendar from '../../assets/img/icons/calendar.svg?inline';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: [Date],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password'].indexOf(val) !== -1,
    },
    hasResetButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabledDates: {
      type: Object,
      required: false,
    },
    isUtc: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isFocused: false,
      fr,
    };
  },
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  &.disabled
    .vdp-datepicker__calendar-button
      cursor: pointer
      pointer-events: none

  .placeholder
    @include text
    position: absolute
    padding: 0 4px
    left: $global-padding-vertical * 2 - 4px
    color: var(--color-black)
    background-color: var(--color-30)
    transition: all 0.2s ease-in-out
    z-index: 100
    &.placeholder-at-top
      font-size: 11px
      color: var(--color-grey70)
      transform: translateY(-$global-padding-vertical - 5px)

  input
    @include text
    width: 100%
    padding: $global-padding-vertical ($global-padding-vertical * 2)
    font-size: $global-font-size
    color: var(--color-black)
    border-radius: $global-border-radius * 5
    border: 1px solid var(--color-grey70)
    background: var(--color-30)
    transition: all 0.2s ease-in-out
    &:hover
      border-color: darken(white, 10%)
    &:focus
      outline: 0
      border-color: black

  .vdp-datepicker__calendar
    width: initial
    border-radius: $global-border-radius * 2
    .cell
      &.selected
        background: var(--color-30) !important
      &:hover, &.selected:hover
        border: 0 !important
        background: var(--color-70) !important
    header
      .next, .prev
  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 0
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    right: $global-padding-vertical * 2
  .vdp-datepicker__calendar-button
    i span
      content: url('../../assets/img/icons/calendar.svg');
</style>
