const CheckStatusLabel = Object.freeze({
  SHIPPING: 'À ENVOYER',
  PENDING: 'TRAITEMENT',
  SHIPPING_FAILED: 'ERREUR RÉCEPTION',
  FAIL: 'ERREUR',
  REFUSED: 'REFUSÉ',
  VALIDATED: 'TRAITÉ',
  CASHED: 'ENCAISSÉ',
});

export default CheckStatusLabel;
