<template>
  <app-flap class="edit-issuer-contact" :isOpen="isOpen" @click:is-open="$emit('click:is-open', $event)" parent="contact">
    <template v-slot:app-flap-main>Contact émetteur</template>
    <template v-slot:app-flap-content>
      <form class="grid-x align-middle align-justify">
        <div class="cell small-12"><app-input label="Numéro de voie *" v-model="issuer.streetNumber" name="streetNumber" required type="number"></app-input></div>
        <div class="cell small-12"><app-input label="Libellé de la voie *" v-model="issuer.route" name="route" required></app-input></div>
        <div class="cell small-5"><app-input label="Code postal *" v-model="issuer.postalCode" name="postalCode" required></app-input></div>
        <div class="cell small-5"><app-input label="Ville *" v-model="issuer.locality" name="locality" required></app-input></div>
        <div class="cell small-12"><app-input label="Numéro de téléphone *" v-model="issuer.phone" name="phone" type="phone" required></app-input></div>
        <div class="cell small-12"><app-input label="Email *" v-model="issuer.email" name="email" type="email" required ></app-input></div>
        <app-errors :errors="errors" />
        <app-button class="validate-btn" @click="submit">Valider</app-button>
      </form>
    </template>
  </app-flap>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    streetNumber: {
      type: String,
      required: false,
    },
    route: {
      type: String,
      required: false,
    },
    postalCode: {
      type: String,
      required: false,
    },
    locality: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
    email: {
      type: String,
      required: false,
    },
  },
  emits: ['click:is-open', 'submit'],
  data() {
    return {
      issuer: {
        streetNumber: '',
        route: '',
        postalCode: '',
        locality: '',
        phone: '',
        email: '',
      },
      errors: [],
    };
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.issuer = {
          streetNumber: this.streetNumber,
          route: this.route,
          locality: this.locality,
          postalCode: this.postalCode,
          phone: this.phone,
          email: this.email,
        };
      }
    },
  },
  methods: {
    submit() {
      if (this.checkValidity()) {
        this.$emit('submit', this.issuer);
      }
    },
    checkValidity() {
      this.errors = [];
      const fields = [
        { name: 'streetNumber', label: 'Numéro de voie' },
        { name: 'route', label: 'Libéllé de la voie' },
        { name: 'postalCode', label: 'Code postal' },
        { name: 'locality', label: 'Ville' },
        { name: 'phone', label: 'Téléphone' },
        { name: 'email', label: 'Email' },
      ];
      fields.forEach((field) => {
        const input = document.getElementsByName(field.name)[0];
        if (input.validationMessage) {
          this.errors.push(`${field.label}: ${input.validationMessage}`);
        }
      });
      if (this.errors.length) {
        return false;
      }
      return true;
    },
  },

};
</script>

<style lang="sass">
.edit-issuer-contact
  .validate-btn
    width: 100%
    justify-content: center
  .app-input
    margin-bottom: 40px
    .label
      background-color: var(--color-cloud)
</style>
