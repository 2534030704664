const period = Object.freeze({
  3: {
    name: 3,
    label: '3 mois',
    active: false,
    disabled: false,
  },
  6: {
    name: 6,
    label: '6 mois',
    active: false,
    disabled: false,
  },
  12: {
    name: 12,
    label: '12 mois',
    active: false,
    disabled: false,
  },
});

export default period;
