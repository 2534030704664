import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient la liste des bénéficiaires d'un utilisateur
 *
 * @param {number} pageCount
 * @param {number} pageNumber
 * @param {string} sortBy
 * @param {string} sortOrder
 * @param {string} search
 * @param {string} type
 * @returns
 */
const getBeneficiaries = async ({
  pageCount, pageNumber, sortBy, sortOrder, search, type,
}) => {
  try {
    const response = await axios.get(`${API_URL}/beneficiaries`, {
      params: {
        ...(pageCount && { pageCount }),
        ...(pageNumber && { pageNumber }),
        ...(sortBy && { sortBy }),
        ...(sortOrder && { sortOrder }),
        ...(search && { search }),
        ...(type && { type }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la création d'un bénéficiaire
 *
 * @param {string} name
 * @param {string} iban
 * @param {string} bic
 * @param {string} nickName
 * @param {string} secretCode
 * @returns
 */
const createBeneficiary = async (name, iban, bic, nickName = '', sepaCreditorIdentifier, uniqueMandateReference, secretCode) => {
  try {
    const response = await axios.post(`${API_URL}/beneficiaries`, {
      ...(name && { name }),
      ...(iban && { iban }),
      ...(bic && { bic }),
      nickName,
      ...(sepaCreditorIdentifier && { sepaCreditorIdentifier }),
      ...(uniqueMandateReference && { uniqueMandateReference }),
      secretCode: secretCode.toString().replaceAll(',', ''),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la modification d'un bénéficiaire
 *
 * @param {string} beneficiaryId
 * @param {string} name
 * @param {string} iban
 * @param {string} bic
 * @param {string} nickName
 * @returns
 */
const setBeneficiary = async (beneficiaryId, name, iban, bic, nickName = '', sepaCreditorIdentifier, uniqueMandateReference) => {
  try {
    const response = await axios.put(`${API_URL}/beneficiaries/${beneficiaryId}`, {
      ...(name && { name }),
      ...(iban && { iban }),
      ...(bic && { bic }),
      nickName,
      ...(sepaCreditorIdentifier && { sepaCreditorIdentifier }),
      ...(uniqueMandateReference && { uniqueMandateReference }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la suppression d'un bénéficiaire
 *
 * @param {*} beneficiaryId
 * @returns
 */
const deleteBeneficiary = async (beneficiaryId) => {
  try {
    const response = await axios.delete(`${API_URL}/beneficiaries/${beneficiaryId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getBeneficiaries = getBeneficiaries;
api.createBeneficiary = createBeneficiary;
api.setBeneficiary = setBeneficiary;
api.deleteBeneficiary = deleteBeneficiary;

export default api;
