<template>
  <div class="register-register">
    <p>Bienvenue chez Kalveen !</p>
    <p>Commençons par connaître qui vous êtes afin de vous proposer une <br> expérience sur-mesure</p>

    <app-two-blocks>
      <template v-slot:main>
        <div class="individual">
          <app-button class="intro" @click="() => emitType(EnumCustomerType.INDIVIDUAL)">
            <div class="grid-x align-middle">
              <div class="icon cell shrink">
                <div class="circle">
                  <SvgIndividual />
                </div>
              </div>
              <p class="cell auto">Particulier</p>
              <div class="cell shrink arrow">
                <SvgArrowRight />
              </div>
            </div>
          </app-button>
        </div>
        <div class="business">
          <app-button class="intro" @click="() => emitType(EnumCustomerType.BUSINESS)">
            <div class="grid-x align-middle">
              <div class="icon cell shrink">
                <div class="circle">
                  <SvgBusiness />
                </div>
              </div>
              <p class="cell auto">Professionnel</p>
              <div class="cell shrink arrow">
                <SvgArrowRight />
              </div>
            </div>
          </app-button>
        </div>
      </template>
      <template v-slot:animation>
        <img :src="PngCards" alt="">
      </template>
    </app-two-blocks>

    <div class="background"></div>
  </div>
</template>

<script>
// Svg / Png
import PngCards from '../../../assets/img/cards.png';
import SvgIndividual from '../../../assets/img/icons/parameters.svg?inline';
import SvgBusiness from '../../../assets/img/icons/pro.svg?inline';
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';

export default {
  name: 'register-register',
  components: {
    SvgIndividual,
    SvgBusiness,
    SvgArrowRight,
  },
  data() {
    return {
      PngCards,

      EnumCustomerType,
    };
  },
  methods: {
    emitType(type) {
      this.$emit('click', type);
    },
  },
};
</script>

<style lang="sass">
.register-register
  text-align: center
  p:not(.label)
    position: relative
    margin: 0
    @include header3
    color: var(--color-grey70)
    z-index: 5
    &:first-child:not(.label)
      @include header1
  > div
    .content
      .business
        margin-top: ($global-padding-vertical * 2)
      .app-button.intro
        @include header3
        width: 100%
        border-radius: ($global-border-radius * 2)
        padding: $global-padding-horizontal ($global-padding-vertical * 2)
        background-color: var(--color-cloud)
        > div
          width: 100%
          div.arrow
            display: flex
            svg
              path
                stroke: var(--color-grey70)
          p
            text-align: left
          .icon
            margin-right: $global-padding-horizontal
            svg
              margin: 0
              width: 22px
              circle
                fill: var(--color-cloud)
                stroke: transparent
              path
                stroke: transparent
                fill: var(--color-cloud)
              rect, path.rect
                fill: var(--color-black)
            .circle
              display: flex
              height: 38px
              width: 38px
              justify-content: center
              align-items: center
              border-radius: 100%
              background-color: var(--color-black)
        &.active, &:hover
          background-color: var(--color-50)
    .illustration
      img
        position: relative
        width: 100%
        z-index: 1
  .background
    &::after
      position: fixed
      content: ''
      display: block
      bottom: 0
      right: 0
      border-style: solid
      border-width: 0px 0 85vh 50vw
      border-color: transparent transparent var(--color-50) transparent
</style>
