<template>
  <div class="profil-company-edit-contact">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Adresse de l'entreprise</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x streetnumber align-middle">
            <app-input
              class="cell auto"
              label="Numéro *"
              type="text"
              v-model="streetNumberComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x route align-middle">
            <app-input
              class="cell auto"
              label="Adresse *"
              type="text"
              v-model="routeComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x postalcode align-middle">
            <app-input
              class="cell auto"
              label="Code postal *"
              type="text"
              v-model="postalCodeComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x locality align-middle">
            <app-input
              class="cell auto"
              label="Ville *"
              type="text"
              v-model="localityComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x locality align-middle">
            <app-select
              class="cell auto"
              v-model="countryComputed"
              label="Pays *"
              required
              :options="EnumCountries">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x phone align-middle">
            <app-input
              class="cell auto"
              label="Numéro de téléphone *"
              type="phone"
              v-model="phoneComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !phoneComputed || !streetNumberComputed || !routeComputed || !postalCodeComputed || !localityComputed || !countryComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Components

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';
import EnumCountries from '../../../../../commons/countries.json';

// Api
import ApiCustomer from '../../../services/api/customer';

export default {
  name: 'profil-company-edit-contact',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      errors: [],
      isLoading: false,

      EnumCountries: this.$utils.sortAlphabetically(EnumCountries),
    };
  },
  computed: {
    phoneComputed: {
      get() { return this.data.customer?.phone?.replace('+33', ''); },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            phone: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    streetNumberComputed: {
      get() { return this.data.customer.address?.streetNumber || null; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            address: {
              ...this.data.customer.address,
              streetNumber: newValue,
            },
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    routeComputed: {
      get() { return this.data.customer.address?.route || null; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            address: {
              ...this.data.customer.address,
              route: newValue,
            },
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    postalCodeComputed: {
      get() { return this.data.customer.address?.postalCode || null; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            address: {
              ...this.data.customer.address,
              postalCode: newValue,
            },
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    localityComputed: {
      get() { return this.data.customer.address?.locality || null; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            address: {
              ...this.data.customer.address,
              locality: newValue,
            },
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    countryComputed: {
      get() { return this.data.customer.address?.administrativeAreaLevel1 || null; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            address: {
              ...this.data.customer.address,
              administrativeAreaLevel1: newValue,
            },
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        phone: `+33${this.phoneComputed}`,
        address: {
          streetNumber: String(this.streetNumberComputed),
          route: this.routeComputed,
          postalCode: this.postalCodeComputed,
          locality: this.localityComputed,
          administrativeAreaLevel1: this.countryComputed,
        },
      };

      try {
        /* eslint-disable */
        await ApiCustomer.setCustomer(tmpUser);
        /* eslint-enable */
        this.$getCustomer(true);
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'phone must match /^\\+[1-9]\\d{10,14}$/ regular expression':
          newErrorMessage = 'Le numéro de téléphone n\'est pas valide. Ex: +33 0222222222';
          break;
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-company-edit-contact
  .app-input
    margin: 0
  .app-input, .app-select
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3

  .app-flap
    .main
      p:first-child
        @include header4
        margin-bottom: 0
        color: var(--color-100)
      p:last-child
        margin-top: 0
        @include header2
    .content
      .streetnumber, .route, .postalcode, .locality, .phone
        svg
          margin-left: $global-padding-vertical
          path
            &:first-child
              stroke-width: 1
              stroke: var(--color-grey70)
            &:last-child
              fill: var(--color-cloud)
              stroke: var(--color-grey70)
</style>
