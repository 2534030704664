<template>
  <div class="profil">
    <app-navigation
      v-if="$route.meta.hasNavigation"
      :type="$route.meta.navigationType"
      :routes="navigation"
    ></app-navigation>
    <router-view></router-view>
  </div>
</template>

<script>
import EnumNavigationProfil from '../services/enums/navigation/profil.enum';
import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'profil',
  data() {
    return {
      EnumNavigationProfil,
      EnumCustomerType,
      navigation: [],
    };
  },
  mounted() {
    this.navigation = this.cleanNavigation();
  },
  methods: {
    cleanNavigation() {
      return Object.values(EnumNavigationProfil).filter((item) => {
        // Garde les entrées en fonction du rôle de l'utilisateur
        if (item.userRole) return this.$theme === item.userRole.toLowerCase();
        return item;
      }).map((item) => {
        // Renommage du label de la tab profil pour un utilisateur BUSINESS
        const tmpItem = { ...item };
        if (tmpItem.name === 'personal-information' && this.$theme === EnumCustomerType.BUSINESS.toLowerCase()) tmpItem.label = 'Paramètres du profil';
        return tmpItem;
      });
    },
  },
};
</script>

<style lang="sass">
.profil
  nav.app-navigation.links a
    padding-top: 0
</style>
