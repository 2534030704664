import Vue from 'vue';
import Router from 'vue-router';
import auth from './services/auth';
// import ApiCustomer from './services/api/customer';

// Components
import Dashboard from './views/Dashboard.vue';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import ForgotPassword from './views/ForgotPassword.vue';
// import DesignSystem from './views/DesignSystem.vue';
import ResetPassword from './views/ResetPassword.vue';
import Operations from './views/Operations.vue';
import PayoutsScreen from './views/Payouts.vue';
import Cards from './views/Cards.vue';
import Checks from './views/Checks.vue';
import Accounting from './views/Accounting.vue';
import Documents from './views/Documents.vue';
import Profil from './views/Profil.vue';
import Support from './views/Support.vue';

// Profil
import ProfilHome from './components/Profil/Profil/Profil.vue';
import Proofs from './components/Profil/Proofs/Proofs.vue';
import Security from './components/Profil/Security/Security.vue';
import Limits from './components/Profil/Limits.vue';
import Offer from './components/Profil/Offer.vue';
import Company from './components/Profil/Company/Company.vue';

import Home from './components/Home.vue';
import CardPhysical from './components/Cards/Physical/Physical.vue';
import CardVirtual from './components/Cards/Virtual/Virtual.vue';

// Documents
import Rib from './components/Documents/Rib.vue';
import Statement from './components/Documents/Statement.vue';

// Payouts
import Payouts from './components/Payouts/Payouts.vue';
import Beneficiaries from './components/Beneficiaries/Beneficiaries.vue';
import Creditors from './components/Creditors/Creditors.vue';

// Offres
import Offers from './views/Offers.vue';

// Ubble
import Ubble from './views/Ubble.vue';

// Enums
import EnumNavigationType from './services/enums/navigation/type.enum';
import EnumCustomerType from './services/enums/customer/type.enum';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'not-found',
      // component: NotFound,
    },
    {
      path: '/',
      component: Dashboard,
      redirect: { name: 'home' },
      children: [
        // Chemin inconnu, redirection automatque vers l'écran de première connexion
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            hasMenu: true,
            isAuthRequired: true,
          },
        },
        {
          path: 'profil',
          name: 'profil',
          component: Profil,
          redirect: { name: 'personal-information' },
          meta: {
            hasMenu: true,
            isAuthRequired: true,
          },
          children: [
            {
              path: '',
              name: 'personal-information',
              component: ProfilHome,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'justificatifs',
              name: 'proofs',
              component: Proofs,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'securite',
              name: 'security',
              component: Security,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'limitations',
              name: 'limits',
              component: Limits,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'offre',
              name: 'offer',
              component: Offer,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'entreprise',
              name: 'company',
              component: Company,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
                userRole: EnumCustomerType.BUSINESS,
              },
            },
          ],
        },
        {
          path: 'transactions',
          name: 'transactions',
          component: Operations,
          meta: {
            hasMenu: true,
            isAuthRequired: true,
          },
        },
        {
          path: 'virements',
          component: PayoutsScreen,
          redirect: { name: 'payouts' },
          meta: {
            isAuthRequired: true,
          },
          children: [
            {
              path: '',
              name: 'payouts',
              component: Payouts,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'beneficiaires',
              name: 'payouts-beneficiaries',
              component: Beneficiaries,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'prelevements-sepa',
              name: 'payouts-creditors',
              component: Creditors,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
          ],
        },
        {
          path: 'cartes',
          name: 'cards',
          component: Cards,
          redirect: { name: 'cards-card-physical' },
          meta: {
            isAuthRequired: true,
          },
          children: [
            {
              path: '',
              redirect: { name: 'cards-card-physical' },
            },
            {
              path: 'physiques',
              name: 'cards-card-physical',
              component: CardPhysical,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'virtuelles',
              name: 'cards-card-virtual',
              component: CardVirtual,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
          ],
        },
        {
          path: 'cheques',
          name: 'checks',
          component: Checks,
          meta: {
            hasMenu: true,
            hasNavigation: false,
            isAuthRequired: true,
          },
        },
        {
          path: 'comptabilite',
          name: 'accounting',
          component: Accounting,
          meta: {
            hasMenu: true,
            hasNavigation: false,
            isAuthRequired: true,
          },
        },
        {
          path: 'documents',
          name: 'documents',
          component: Documents,
          redirect: { name: 'documents-rib' },
          meta: {
            isAuthRequired: true,
          },
          children: [
            {
              path: '',
              redirect: { name: 'rib' },
            },
            {
              path: 'rib',
              name: 'documents-rib',
              component: Rib,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
            {
              path: 'releves',
              name: 'documents-bank-statement',
              component: Statement,
              meta: {
                hasMenu: true,
                hasNavigation: true,
                navigationType: EnumNavigationType.LINKS,
                isAuthRequired: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/connexion',
      name: 'login',
      meta: {
        hasFooter: true,
        hasNavigation: true,
        navigationType: EnumNavigationType.DEFAULT,
        isAuthRequired: false,
      },
      component: Login,
    },
    {
      path: '/inscription',
      name: 'register',
      component: Register,
      meta: {
        hasFooter: true,
        hasNavigation: true,
        navigationType: EnumNavigationType.DEFAULT,
        isAuthRequired: false,
      },
    },
    {
      path: '/mot-de-passe-perdu',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        hasFooter: true,
        hasNavigation: true,
        navigationType: EnumNavigationType.DEFAULT,
        isAuthRequired: false,
      },
    },
    {
      path: '/reinitialisation-de-mot-de-passe',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        hasFooter: true,
        hasNavigation: true,
        navigationType: EnumNavigationType.DEFAULT,
        isAuthRequired: false,
      },
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        hasFooter: false,
        hasNavigation: false,
        isAuthRequired: true,
        hasMenu: true,
      },
    },
    {
      path: '/offres',
      name: 'offers',
      component: Offers,
      meta: {
        hasFooter: true,
        hasNavigation: true,
        isAuthRequired: false,
        hasMenu: false,
      },
    },
    {
      path: '/ubble',
      name: 'ubble',
      component: Ubble,
      meta: {
        hasFooter: false,
        hasNavigation: false,
        isAuthRequired: false,
        hasMenu: false,
      },
    },
    // {
    //   path: '/design-system',
    //   name: 'design-system',
    //   component: DesignSystem,
    //   meta: {
    //     hasNavigation: true,
    //     navigationType: EnumNavigationType.LINKS,
    //     isAuthRequired: false,
    //   },
    // },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (!auth.isLoggedIn() && to.meta.isAuthRequired && to.name !== ('login' || 'register' || 'forgot-password' || 'reset-password' || 'offers' || 'design-system')) {
    next({ name: 'login' });
  } else next();
});

export default router;
