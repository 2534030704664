<template>
  <div class="profil-limits">
    <p>Limitations</p>
    <p>Vous pouvez consulter l’état de votre compte</p>

    <app-progress :data="limits"/>

    <div class="status" v-if="status" :class="status.toLowerCase()">
      <template v-if="status === 'PENDING'">
        <p>Votre compte est limité.</p>
        <p>Nous avons besoin d’informations complémentaires pour valider votre compte.</p>

        <app-spinner v-if="((customer.customer.type === EnumCustomerType.BUSINESS) && !isSelfEmployedPerson) ? !childrenComputed || childrenComputed.length <= 0 : false" :small="true"></app-spinner>
        <div class="records" v-else>
          <p class="pending" v-if="customer.customer.readyForReviewStatus === EnumCustomerReadyForReviewStatus.PENDING">Votre demande d'analyse est en cours de traitement.</p>
          <app-button size="small" @click="() => $router.push({ name: 'profil' })"
            v-for="(child, index) in ((customer.customer.type === EnumCustomerType.BUSINESS) && !isSelfEmployedPerson ? childrenComputed : [customer])"
            :key="`limits-profil-${index}`"
            :disabled="isProfilDisabled(child)">
            <div class="grid-x align-middle">
              <p v-if="isProfilDisabled(child)" class="cell shrink svg-valid">
                <SvgValid />
              </p>
              <p class="cell auto">
                <span>Compléter les informations du profil</span>
                <span>{{ child.customer.lastname }} {{ child.customer.firstname }}</span>
              </p>
              <div class="cell shrink arrow">
                <SvgArrowRight />
              </div>
            </div>
          </app-button>
          <app-button
            size="small"
            @click="() => $router.push({ name: 'documents' })"
            :disabled="isRIBDisabled">
            <div class="grid-x align-middle">
              <p v-if="isRIBDisabled" class="cell shrink svg-valid">
                <SvgValid />
              </p>
              <p class="cell auto">
                <span>Réaliser un virement sur votre compte</span>
                <span>Consulter mon RIB: versement entre 10€ et 3000€</span>
              </p>
              <div class="cell shrink arrow">
                <SvgArrowRight />
              </div>
            </div>
          </app-button>
          <app-button size="small" @click="() => $router.push({ name: 'proofs' })"
            v-for="(child, index) in ((customer.customer.type === EnumCustomerType.BUSINESS) && !isSelfEmployedPerson ? childrenComputed : [customer])"
            :key="`limits-proofs-${index}`"
            :disabled="isProofsDisabled(child)">
            <div class="grid-x align-middle">
              <p v-if="isProofsDisabled(child)" class="cell shrink svg-valid">
                <SvgValid />
              </p>
              <p class="cell auto">
                <span>Ajouter des pièces justificatives</span>
                <span>{{ child.customer.lastname }} {{ child.customer.firstname }}{{ (childrenComputed.length - 1) > index ? ',' : null }}</span>
              </p>
              <div class="cell shrink arrow">
                <SvgArrowRight />
              </div>
            </div>
          </app-button>
        </div>

        <app-button size="small" kind="primary" @click="() => sendReadyForReview()" :disabled="requestAnalysisDisabled">
          <app-spinner v-if="isLoading" :small="true"></app-spinner>
          <span>Demander une analyse</span>
        </app-button>
      </template>
      <template v-if="status === 'ANALYSIS'">
        <p>Votre compte est limité.</p>
        <p>Les documents sont actuellement en analyse par nos services. Cette analyse peut prendre 1 à 3 jours.</p>
      </template>
      <template v-if="status === 'VALIDATED'">
        <p>Votre compte est validé !</p>
        <p>Vous avez désormais accès à toutes les fonctionnalités de votre compte Kalveen.</p>
      </template>
    </div>
  </div>
</template>

<script>
// Utils
import utils from '../../services/utils/utils';

// Api
import ApiCustomer from '../../services/api/customer';

// Components
import AppProgress from '../../basics/components/AppProgress.vue';

// Enum
import EnumLimits from '../../services/enums/limits.enum';
import EnumCustomerType from '../../services/enums/customer/type.enum';
import EnumNotificationType from '../../services/enums/notification.enum';
import EnumCustomerReadyForReviewStatus from '../../services/enums/customer/readyForReviewStatus.enum';
import EnumKycDocumentsValid from '../../services/enums/customer/kycDocumentsValid.enum';

// Svg
import SvgArrowRight from '../../assets/img/icons/arrow-right.svg?inline';
import SvgValid from '../../assets/img/icons/valid.svg?inline';

export default {
  name: 'profil-limits',
  inject: ['$getCustomer', '$isAccountNotValidated', '$isSelfEmployedPerson'],
  components: {
    AppProgress,
    SvgArrowRight,
    SvgValid,
  },
  data() {
    return {
      EnumLimits,
      EnumCustomerType,
      EnumCustomerReadyForReviewStatus,
      // Les customers INDIVIDUAL ou BUSINESS auto-entrepreneur n'auront jamais de children, le tableau sera toujours vide pour eux
      children: [],
      status: null,
      isRequestAnalysisEnabled: true,
      isLoading: false,
      refreshInterval: null,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    isAccountNotValidated() {
      return this.$isAccountNotValidated();
    },
    childrenComputed() {
      return this.children;
    },
    limits() {
      const tmpLimits = utils.transformEnumToArray(EnumLimits);
      if (this.customer) return this.setStatus(tmpLimits);
      return tmpLimits;
    },
    isRIBDisabled() {
      return !this.isAccountNotValidated || this.customer.customer.initialPayin;
    },
  },
  watch: {
    customer(customer) {
      if (customer.customer.type === EnumCustomerType.BUSINESS) this.getCustomerChildren();

      // Si l'utilisateur est en demande d'analyse on rafraichi le contenu toutes les 10 secondes
      if (!this.refreshInterval && ((this.customer.treezorUser.kycLevel && this.customer.treezorUser.kycReview) !== (0 || 2))) {
        this.refreshInfos();
      } else {
        clearInterval(this.refreshInterval);
      }
    },
    isRIBDisabled(value) {
      this.setIsRequestAnalysisEnabled(value);
    },
  },
  mounted() {
    if (this.customer && (this.customer.customer.type === EnumCustomerType.BUSINESS)) this.getCustomerChildren();
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    setStatus(limits) {
      const tmpLimits = [...limits];
      const pending = tmpLimits.find((limit) => limit.label === EnumLimits.PENDING.label);
      const analysis = tmpLimits.find((limit) => limit.label === EnumLimits.ANALYSIS.label);
      const validated = tmpLimits.find((limit) => limit.label === EnumLimits.VALIDATED.label);

      if (this.customer.treezorUser.kycLevel === 0 && this.customer.treezorUser.kycReview === 0) {
        pending.status = 'active';
        analysis.status = 'pending';
        validated.status = 'pending';
        this.status = 'PENDING';
      } else if (this.customer.treezorUser.kycLevel === 2 && this.customer.treezorUser.kycReview === 2) {
        pending.status = 'valid';
        analysis.status = 'valid';
        validated.status = 'valid';
        this.status = 'VALIDATED';
      } else {
        pending.status = 'active';
        analysis.status = 'active';
        validated.status = 'pending';
        this.status = 'ANALYSIS';
      }
      return [pending, analysis, validated];
    },
    async getCustomerChildren(parentType) {
      try {
        this.children = await ApiCustomer.getCustomerChildren(parentType);
      } catch (error) {
        throw error;
      }
    },
    async sendReadyForReview() {
      this.isLoading = true;
      try {
        await ApiCustomer.setReadyForReviewStatus(EnumCustomerReadyForReviewStatus.PENDING);
        this.$getCustomer(true);
        this.refreshInfos();
      } catch (error) {
        this.$notification.show({
          title: 'L\'envoi d\'une demande d\'analyse a échouée.',
          text: 'Nous vous invitons à faire un nouvel essai.',
          type: EnumNotificationType.ECHEC,
        });
      } finally {
        this.isLoading = false;
      }
    },
    isProfilDisabled(child) {
      const test = (child.customer.civility
        && child.customer.firstname
        && child.customer.lastname
        && child.customer.birthdate
        && child.customer.placeOfBirth
        && child.customer.placeBirthCountry
        && child.customer.nationality
        && child.customer.email
        && child.customer.phone
        && child.customer.address
        && child.customer.incomeRange
        && child.customer.personalAssets
        && child.customer.occupation
      ) || !this.isAccountNotValidated;
      this.setIsRequestAnalysisEnabled(test);
      return test;
    },
    isProofsDisabled(child) {
      const test = !this.isAccountNotValidated
        || child.customer.kycDocumentsValid === EnumKycDocumentsValid.VALIDATED;
      this.setIsRequestAnalysisEnabled(test);
      return test;
    },
    setIsRequestAnalysisEnabled(value) {
      // Si la propriété isRequestAnalysisEnabled devient fausse on la garde ainsi et on ne l'a met plus à jour
      if (!this.isRequestAnalysisEnabled) return;
      this.isRequestAnalysisEnabled = value;
    },
    requestAnalysisDisabled() {
      return this.customer.customer.readyForReviewStatus === EnumCustomerReadyForReviewStatus.PENDING || this.customer.customer.readyForReviewStatus === EnumCustomerReadyForReviewStatus.VALIDATED || !this.isRequestAnalysisEnabled;
    },
    /**
     * Rafraichit les informations de la page toutes les 10 secondes
     */
    refreshInfos() {
      this.refreshInterval = setInterval(() => {
        this.$getCustomer(true);
      }, 10000);
    },
  },
};
</script>

<style lang="sass">
.profil-limits
  max-width: 557px
  p
    margin: 0
    @include text-bold
    color: var(--color-grey70)
    &:first-child
      @include header2
    &:nth-child(2)
      @include text
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)
  .app-progress
    margin-top: $global-padding-vertical * 3.3
    > div:last-child
      svg
        width: 22px

  .status
    margin-top: ($global-padding-vertical * 3.3)
    > .app-spinner
      margin: ($global-padding-vertical * 3.3) auto auto auto
    p
      @include text
      color: var(--color-grey70)
      &:first-child
        @include header3
      &.pending
        @include header4
        margin-top: ($global-padding-horizontal / 2)
        margin: 0
    &.pending
    &.analysis
    &.validated
      p:first-child
        color: var(--color-success)

  .records
    margin: ($global-padding-vertical * 3.3) 0
    > .app-button
      width: 100%
      text-align: left
      border-radius: ($global-border-radius * 2)
      background-color: var(--color-cloud)
      &[disabled]
        opacity: initial
        background-color: rgba(59, 182, 28, 0.1)
        .svg-valid svg
          margin-right: $global-padding-horizontal
          width: 22px
          circle, path
            stroke: var(--color-success)
      > div
        width: inherit
      p
        margin: 0
        span
          display: block
          &:first-child
            @include header4
          &:last-child
            @include text
            color: var(--color-grey70)
      ~ .app-button
        margin-top: ($global-padding-horizontal / 2)
      div.arrow
        display: flex
        svg
          path
            stroke: var(--color-grey70)
      &.active, &:hover
        background-color: var(--color-50)

</style>
