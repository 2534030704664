const identity = Object.freeze({
  description: '1 pièce au choix',
  IDENTITY_CARD: {
    document: 'DOCUMENT_TYPE_9',
    description: 'CNI en couleur et cours de validité (R / V)',
  },
  PASSPORT_UE: {
    document: 'DOCUMENT_TYPE_17',
    description: 'Passeport UE en couleur et cours de validité (R / V)',
  },
  PASSPORT_OUE: {
    document: 'DOCUMENT_TYPE_17',
    description: 'Passeport hors UE en couleur et cours de validité (R / V) Fournir obligatoirement le titre de séjour ou le visa valable',
  },
  GREEN_CARD: {
    document: 'DOCUMENT_TYPE_15',
    description: 'Titre de séjour en couleur et cours de validité (R / V)',
  },
});

export default identity;
