import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

/**
 * Obtient la formule de l'utilisateur
 *
 * @returns
 */
const getSubscription = async () => {
  try {
    const response = await axios.get(`${API_URL}/subscriptions`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Demande la modification de la formule de l'utilisateur
 *
 * @param {*} packageId
 * @returns
 */
const setSubscription = async (packageId) => {
  try {
    const response = await axios.post(`${API_URL}/subscriptions`, {
      ...(packageId && { packageId }),
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getSubscription = getSubscription;
api.setSubscription = setSubscription;

export default api;
