<template>
  <div class="card-physical-cancel">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Faire opposition</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <p>Carte perdue ? Volée ? Êtes-vous sûr de vouloir faire opposition ?</p>
          <p>Cela entraînera le blocage définitif de votre carte. Une nouvelle carte sera automatiquement commandée et vous serez facturé 10€.</p>

          <app-button class="submit" type="submit">Faire opposition</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Enums
import EnumLockStatus from '../../../services/enums/cards/lockStatus';
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'card-physical-cancel',
  props: {
    data: {
      type: Object,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      errors: [],
      isLoading: false,
    };
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      try {
        await ApiCard.setCardLockUnlock(this.data.cardId, EnumLockStatus.STOLEN.key);

        this.$notification.show({
          title: 'La carte a bien été mise en opposition.',
          type: EnumNotificationType.SUCCESS,
        });

        this.$emit('click');
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de l\'opposition',
          text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
          type: EnumNotificationType.ECHEC,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.card-physical-cancel
  text-align: center
  .app-flap
    .content
      p:first-child
        @include header3
        margin-bottom: $global-padding-vertical * 3
      p:last-child
        @include text
  form
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
</style>
