<template>
  <div class="app-checkbox">
      <label>
        <input type="checkbox"
          :checked="Array.isArray(input) && input.includes(value)"
          @change="onChange()"/>
        <span class="checkmark"></span>
        <slot></slot>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Array, Number],
    value: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange() {
      let response = null;
      response = Array.isArray(this.input) ? this.input : [];

      if (response.includes(this.value)) {
        response.splice(response.indexOf(this.value), 1);
      } else {
        response.push(this.value);
      }
      this.$emit('input', response);
    },
  },
};
</script>

<style lang="sass">
.app-checkbox
  display: inline-block
  label
    display: inline-block
    position: relative
    margin: 0 5px
    cursor: pointer
    user-select: none

    input
      position: absolute
      margin: 0
      height: 0px
      width: 0px
      opacity: 0
      cursor: pointer

      &:checked ~ .checkmark
        background-color: black
        border-color: transparent

      &:checked ~ .checkmark:after
        opacity: 1

    .checkmark
      display: inline-block
      margin-right: 5px
      margin-top: -5px
      height: 16px
      width: 16px
      border: 1px solid var(--color-black)
      background-color: transparent
      border-radius: 4px
      transform: translateY(5px)
      transition: background ease-in-out 0.2s
      &:after
        content: ""
        position: absolute
        opacity: 0
        left: 3px
        top: 3px
        width: 6px
        height: 3px
        border: solid white
        border-width: 0 2px 2px 0
        transform: rotate(-45deg) scaleX(-1)
        transition: opacity ease-in-out 0.2s

</style>
