import SvgTransfer from '../../../assets/img/icons/transfer.svg?inline';
import SvgTransferInstant from '../../../assets/img/icons/transfer-instant.svg?inline';
import SvgCard from '../../../assets/img/icons/card.svg?inline';
import SvgCheck from '../../../assets/img/icons/check.svg?inline';
import SvgKvn from '../../../assets/img/logo-kvn-img.svg?inline';
import SvgEuro from '../../../assets/img/icons/euro-atm.svg?inline';

const operations = Object.freeze({
  BANKDIRECTDEBIT: {
    label: 'Prélèvement',
    icon: SvgTransfer,
  },
  FEES: {
    label: 'Frais de gestion Kalveen',
    icon: SvgKvn,
  },
  CREDITNOTE: {
    label: 'Remboursement - Frais de gestion',
    icon: SvgKvn,
  },
  BANKTRANSFER: {
    label: 'Virement',
    icon: SvgTransfer,
  },
  BANKTRANSFER_INSTANT: {
    label: 'Virement instantané',
    icon: SvgTransferInstant,
  },
  CARDTRANSACTION: {
    label: 'Carte de paiement',
    icon: SvgCard,
  },
  CARDTRANSACTION_WITHDRAW: {
    label: 'Retrait',
    icon: SvgEuro,
  },
  CHECK: {
    label: 'Chèque',
    icon: SvgCheck,
  },
  CARDTOPUP: {
    label: 'Recharge',
    icon: SvgCard,
  },
  BANKDIRECTDEBIT_REFUND: {
    label: 'Prélèvement - Remboursement',
    icon: SvgTransfer,
  },
  BANKTRANSFER_REFUND: {
    label: 'Virement - Remboursement',
    icon: SvgTransfer,
  },
  CARDTRANSACTION_REFUND: {
    label: 'Carte de paiement - Remboursement',
    icon: SvgCard,
  },
  CHECK_REFUND: {
    label: 'Chèque - Remboursement',
    icon: SvgCheck,
  },
  CARDTOPUP_REFUND: {
    label: 'Recharge - Remboursement',
    icon: SvgCard,
  },
  WALLETTRANSFER: {
    label: 'Virement Interne Kalveen',
    icon: SvgTransfer,
  },
});

export default operations;
