<template>
  <div class="card-physical-activation">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Activation de <br> votre carte</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <p class="description">Pour activer votre carte, veuillez rentrer le code à 9 chiffres qui se trouve au dos de votre carte :</p>

          <app-input
            label="Code *"
            type="text"
            v-model="code"
            ></app-input>
          <p class="info" v-if="code && code.length > 0">Une fois votre carte activée il vous faudra modifier son code pin pour pouvoir commencer à l'utiliser.</p>

          <app-button :disabled="!code" class="submit" type="submit">Activer ma carte <SvgLockOpen /></app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Api
import ApiCard from '../../../services/api/card';

// Svg
import SvgLockOpen from '../../../assets/img/icons/lock-open.svg?inline';

// Enums
import EnumNotificationType from '../../../services/enums/notification.enum';

export default {
  name: 'card-physical-activation',
  components: {
    SvgLockOpen,
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      code: null,

      errors: [],
      isLoading: false,
    };
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      try {
        await ApiCard.setCardActivation(this.data.cardId, this.code);
        this.$notification.show({
          title: 'L\'activation est un succès !',
          text: 'Il ne vous reste plus qu\'à modifier votre code pin afin de pouvoir commencer à utiliser votre carte.',
          type: EnumNotificationType.SUCCESS,
        });
        this.$emit('activated');
      } catch (error) {
        if (`${error?.response?.data?.statusCode}` === '403') {
          this.$notification.show({
            title: 'Code erroné',
            text: 'Le code secret que vous avez renseigné ne correspond pas à celui enregistré.',
            type: EnumNotificationType.ECHEC,
          });
        } else {
          if (error.response.data.message && error.response.data.message.length > 0) {
            if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
            else this.errors = [...this.errors, ...error.response.data.message];
          }
          this.$notification.show({
            title: 'Echec de l\'opposition',
            text: 'Nous vous invitons à faire un nouvel essai. Si le problème persiste veuillez contacter le support à cette adresse support@kalveen.com.',
            type: EnumNotificationType.ECHEC,
          });
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.card-physical-activation
  text-align: center
  .app-flap
    .content
      p
        &.description
          @include header4
          color: var(--color-grey70)
          margin-bottom: $global-padding-vertical * 3
        &.info
          @include header5
          text-align: left
          font-weight: 300
      svg path
        fill: var(--color-cloud)
  form
    .app-input
      line-height: initial
      p.label
        margin: revert
        background-color: var(--color-cloud)
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
</style>
