<template>
  <div class="home">
    <p class="title">Bienvenue chez Kalveen !</p>

    <div class="info grid-x">
      <div class="cell shrink">
        <SvgWarning />
      </div>
      <div class="cell auto">
        <p class="title">Accès limité</p>
        <p class="text">Votre compte Kalveen est actuellement limité, pour finaliser la création de votre compte, nous avons besoin encore de quelques informations.
Par exemple ajouter des pièces-justificatives ou encore réaliser un virement minimum de 10€. Pour accéder à la liste des actions restantes afin de valider votre compte vous pouvez cliquer sur le bouton ci-après.</p>
        <app-button class="" size="large" kind="primary" @click="redirectTo('limits')">
          <p>Suivre le statut de mon compte</p>
          <SvgArrow />
        </app-button>
      </div>
    </div>

    <div class="grid-x grid-margin-x">
      <app-button class="transactions cell small-6" size="large" kind="primary" @click="redirectTo('transactions')">
        <div class="icon cell shrink">
          <SvgHome />
        </div>
        <div class="cell auto">
          <p class="title">Compte</p>
          <p class="text">Pour retrouver l’historique de tous les mouvements sur votre compte</p>
        </div>
      </app-button>
      <app-button class="transfers cell small-6" size="large" kind="primary" @click="redirectTo('transfers')">
        <div class="icon cell shrink">
          <SvgTransfer />
        </div>
        <div class="cell auto">
          <p class="title">Virements</p>
          <p class="text">Pour suivre, effectuer des virements simples ou des imports et créer vos bénéficiaires</p>
        </div>
      </app-button>
      <app-button class="parameters cell small-6 grid-x" size="large" kind="primary" @click="redirectTo('profil')">
        <div class="icon cell shrink">
          <SvgParameters />
        </div>
        <div class="cell auto">
          <p class="title">Paramètres</p>
          <p class="text">Pour ajouter ou modifier des informations sur votre profil, gérer la sécurité de votre compte.</p>
        </div>
      </app-button>
      <app-button class="checks cell small-6" size="large" kind="primary" @click="redirectTo('checks')">
        <div class="icon cell shrink">
          <SvgCheck />
        </div>
        <div class="cell auto">
          <p class="title">Chèques</p>
          <p class="text">Pour déposer vos chèques en ligne et suivre vos demandes.</p>
        </div>
      </app-button>
      <app-button class="cards cell small-6" size="large" kind="primary" @click="redirectTo('cards')">
        <div class="icon cell shrink">
          <SvgCard />
        </div>
        <div class="cell auto">
          <p class="title">Cartes</p>
          <p class="text">Pour activer et suivre vos cartes, gérer les plafonds et autres paramètres.</p>
        </div>
      </app-button>
      <app-button class="documents cell small-6" size="large" kind="primary" @click="redirectTo('documents')">
        <div class="icon cell shrink">
          <SvgDocument />
        </div>
        <div class="cell auto">
          <p class="title">Documents</p>
          <p class="text">Pour consultrer et télécharger votre IBAN et vos relevés bancaires</p>
        </div>
      </app-button>
    </div>
  </div>
</template>

<script>
import SvgParameters from '../assets/img/icons/parameters.svg?inline';
import SvgTransfer from '../assets/img/icons/transfer.svg?inline';
import SvgCard from '../assets/img/icons/card.svg?inline';
import SvgCheck from '../assets/img/icons/check.svg?inline';
import SvgDocument from '../assets/img/icons/document.svg?inline';
import SvgHome from '../assets/img/icons/home.svg?inline';
import SvgArrow from '../assets/img/icons/arrow.svg?inline';
import SvgWarning from '../assets/img/icons/warning.svg?inline';

import EnumCustomerType from '../services/enums/customer/type.enum';

export default {
  name: 'home',
  beforeRouteEnter(to, from, next) {
    if (from.name === 'register') {
      next();
    } else {
      next({ name: 'transactions' });
    }
  },
  components: {
    SvgTransfer,
    SvgParameters,
    SvgCard,
    SvgCheck,
    SvgDocument,
    SvgHome,
    SvgArrow,
    SvgWarning,
  },
  data() {
    return {
      EnumCustomerType,
    };
  },
  mounted() {
  },
  methods: {
    redirectTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="sass">
body
  &[data-theme="business"]
    .home
      .info::after
        background-image: url('../assets/img/business/background-register.svg')

.home
  padding: 0
  .title
    @include header2
    margin-top: 0

  .info
    position: relative
    padding: ($global-padding-vertical * 2) $global-padding-horizontal
    border-radius: $global-border-radius
    background-color: var(--color-70)
    &::after
      content: ''
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background-repeat: no-repeat
      background-position: center
      z-index: 0
      background-image: url('../assets/img/individual/background-register.svg')
      opacity: 0.2
    > div
      z-index: 1
    svg
      &.svg-warning
        margin-right: $global-padding-horizontal
        width: 20px
        path
          fill: var(--color-black)
        circle
          stroke: var(--color-black)
      &.svg-arrow
        margin-left: 10px
        width: 16px
        path
          stroke: var(--color-cloud)
    .title
      @include header4
      margin: 0
    .text
      @include details
    .app-button
      margin-left: 0
      font-family: 'nexaBold'
      font-size: 10px
      border-radius: $global-border-radius * 5
      background-color: var(--color-black)

  .app-button
    margin: 0 auto
    margin-top: ($global-padding-vertical * 2)
    border-radius: $global-padding-horizontal
    padding: $global-padding-horizontal
    background-color: transparent
    p
      text-align: left
      &.title
        @include header4
      &.text
        @include details
        color: var(--color-grey70)
    p, svg
      margin: 0
    &:hover
      background-color: var(--color-50)
  .icon
    display: flex
    margin-right: $global-padding-horizontal
    height: 38px
    width: 38px
    justify-content: center
    align-items: center
    align-self: flex-start
    border-radius: 100%
    background-color: var(--color-70)
    svg
      width: 22px
      height: 22px
      path, circle
        fill: var(--color-black)
        stroke: transparent
</style>
