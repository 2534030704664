<template>
  <div class="register-secret-code">
    <app-two-blocks>
      <template v-slot:main>
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
          <p class="title">Définissez votre code secret</p>
          <p class="subtitle">Pour effectuer certaines opérations sur votre compte, nous vous demanderons votre code secret constitué de 4 chiffres.</p>

          <p class="label">Code secret *</p>
          <app-input-pin ref="register-secretcode-app-input-pin" @input="(event) => onChangeSecretCode(event)" :isVisible="false"></app-input-pin>
          <p class="label">Répétez le code secret *</p>
          <app-input-pin ref="register-secretcode-confirm-app-input-pin" @input="(event) => onChangeSecretCodeConfirm(event)" :isVisible="false"></app-input-pin>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button :disabled="(!secretCode || secretCode.length < 4) || (!secretCodeConfirm || secretCodeConfirm.length < 4)" @click="manageClick">Valider mes identifiants</app-button>
        </template>
      </template>
      <template v-slot:animation>
        <app-lottie
          :dataAppearance="PinAppeareance"
          :dataLoop="PinLoop"
        ></app-lottie>
      </template>
    </app-two-blocks>
  </div>
</template>

<script>
// Components
import AppInputPin from '../../AppInputPin.vue';
import AppLottie from '../../AppLottie.vue';

// Animations
import PinAppeareance from '../../../assets/animations/individual/pin-appearance.json';
import PinLoop from '../../../assets/animations/individual/pin-loop.json';

// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';

export default {
  name: 'register-secretcode',
  components: {
    AppInputPin,
    AppLottie,

    SvgWarning,
  },
  props: {
    secretCode: {
      type: Array,
      required: true,
    },
    secretCodeConfirm: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      PinAppeareance,
      PinLoop,
    };
  },
  mounted() {
    this.setAppFooterPosition();
  },
  methods: {
    onChangeSecretCode(event) {
      this.$emit('onChangeSecretCode', event);
    },
    onChangeSecretCodeConfirm(event) {
      this.$emit('onChangeSecretCodeConfirm', event);
    },
    manageClick() {
      this.$emit('click');
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'SecretCode and SecretCodeConfirm must be the same':
          newErrorMessage = 'Les deux codes secret doivent être identiques.';
          break;
        case 'Email already in use':
          newErrorMessage = 'L\'adresse email saisie précédemment est déjà utilisée. Nous vous invitons à refaire une demande d\'inscription avec une autre adresse.';
          break;
        default:
          newErrorMessage = 'Une erreur est survenue.';
          break;
      }
      return newErrorMessage;
    },
    setAppFooterPosition() {
      const component = document.querySelector('.app-footer');
      component.style.removeProperty('margin');
      component.style.left = 'initial';
      component.style.right = 'initial';
      component.style.transform = 'initial';
    },
  },
};
</script>

<style lang="sass">
// Surcharge personnalisée des couleurs
body
  &[data-theme="business"]
    .register-secret-code
      .illustration::after
        background-image: url('../../../assets/img/business/background-register.svg')

.register-secret-code
  text-align: left
  p
    margin: 0
    @include text
    &.title
      @include header1
    &.subtitle
      @include header3
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)
  > p:first-child
    @include header1
  > p:last-child
    @include header4
  p:nth-child(2)
    margin-bottom: $global-padding-horizontal * 2
  p.label
    @include text-bold
    color: var(--color-grey70)
    margin: $global-padding-horizontal 0
  .app-input
    .label
      background-color: var(--color-30)
  .app-button
    align-self: flex-start
    margin-top: $global-padding-vertical * 6

  .illustration
    &::after
      background-image: url('../../../assets/img/individual/background-register.svg')
  .errors
    display: flex
    svg
      width: 38px
    ul
      padding-left: $global-padding-horizontal
      list-style: none
      color: var(--color-warning)
</style>
