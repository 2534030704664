<template>
  <div class="cards-show">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p>Voir les informations <br> de la carte</p>
      </template>
      <template v-slot:app-flap-content>
        <img class="verso" :src="`data:image/png;base64,${data.file}`" alt="">
        <div class="timer"><SvgTimer /><AppCountdown @onEnd="timerFinished" /></div>
        <app-button class="submit" @click="$emit('click')">Cacher mes informations</app-button>
      </template>
    </app-flap>
  </div>
</template>

<script>
import SvgTimer from '../../../assets/img/icons/timer.svg?inline';

import AppCountdown from '../../AppCountdown.vue';

export default {
  name: 'cards-show',
  components: {
    SvgTimer,
    AppCountdown,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    timerFinished() {
      this.$emit('is-open', false);
    },
  },
};
</script>

<style lang="sass">
.cards-show
  img.verso
    display: block
    margin: 0 auto
    width: 272px
    border-radius: $global-border-radius * 2
    background-color: var(--color-30)
  .timer
    @include text-bold
    display: flex
    margin: $global-padding-horizontal auto auto auto
    padding: ($global-padding-horizontal / 2)
    width: 95px
    border: 1px solid var(--color-grey70)
    border-radius: $global-border-radius * 5
    .app-countdown
      margin: auto
  .app-button
    margin-top: ($global-padding-horizontal * 3)
    width: 100%
    justify-content: center;
</style>
