<template>
  <div class="profil-profil" v-if="customer">
    <p>{{ titleComputed }}</p>
    <p>{{ subTitleComputed }}</p>

    <template>
      <div class="record" v-for="(child, index) in dataRecordComputed" :key="`profil-record-child-${index}`">
        <app-dropdown ref="profil-profil-dropdown">
          <template v-slot:main>
            <div class="grid-x align-middle">
              <div class="cell auto">
                <p v-if="child.customer">{{ child.customer.firstname ? `${child.customer.firstname} ${child.customer.lastname}` : 'En attente' }}</p>
                <p v-if="child.customer">{{ getParentType(child) }}</p>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="personal-information">
              <app-button
                @click="() => { isTheFlapOpenPersonalInformation = true; personalInformation = child }"
                :class="{ 'active': isTheFlapOpenPersonalInformation && (personalInformationComputed.customer && (personalInformationComputed.customer.customerId === child.customer.customerId)) }"
              >
                <div class="grid-x align-middle">
                  <div class="icon cell shrink">
                    <div class="circle">
                      <SvgId />
                    </div>
                  </div>
                  <p class="cell auto">Informations personnelles</p>
                  <div class="cell shrink arrow">
                    <SvgArrowRight />
                  </div>
                </div>
              </app-button>
            </div>
            <div class="birthdate" v-if="child.customer.customerId">
              <app-button
                @click="() => { isTheFlapOpenBirthdate = true; birthdate = child }"
                :class="{ 'active': isTheFlapOpenBirthdate && (birthdate.customer && (birthdateComputed.customer.customerId === child.customer.customerId)) }"
              >
                <div class="grid-x align-middle">
                  <div class="icon cell shrink">
                    <div class="circle">
                      <SvgDate />
                    </div>
                  </div>
                  <p class="cell auto">Date de naissance</p>
                  <div class="cell shrink arrow">
                    <SvgArrowRight />
                  </div>
                </div>
              </app-button>
            </div>
            <div class="estate" v-if="child.customer.customerId">
              <app-button
                @click="() => { isTheFlapOpenEstate = true; estate = child }"
                :class="{ 'active': isTheFlapOpenEstate && (estate.customer && (estateComputed.customer.customerId === child.customer.customerId)) }"
              >
                <div class="grid-x align-middle">
                  <div class="icon cell shrink">
                    <div class="circle">
                      <SvgEstate />
                    </div>
                  </div>
                  <p class="cell auto">Patrimoine</p>
                  <div class="cell shrink arrow">
                    <SvgArrowRight />
                  </div>
                </div>
              </app-button>
            </div>
            <div class="contact" v-if="child.customer.customerId && !isSelfEmployedPerson">
              <app-button
                @click="() => { isTheFlapOpenContact = true; contact = child }"
                :class="{ 'active': isTheFlapOpenContact && (contact.customer && (contact.customer.customerId === child.customer.customerId)) }"
              >
                <div class="grid-x align-middle">
                  <div class="icon cell shrink">
                    <div class="circle">
                      <SvgContact />
                    </div>
                  </div>
                  <p class="cell auto">Contact</p>
                  <div class="cell shrink arrow">
                    <SvgArrowRight />
                  </div>
                </div>
              </app-button>
            </div>
            <div class="fiscal-residence" v-if="child.customer.customerId">
              <app-button
                @click="() => { isTheFlapOpenFiscalResidence = true; fiscalResidence = child }"
                :class="{ 'active': isTheFlapOpenFiscalResidence && (fiscalResidence.customer && (fiscalResidence.customer.customerId === child.customer.customerId)) }"
              >
                <div class="grid-x align-middle">
                  <div class="icon cell shrink">
                    <div class="circle">
                      <SvgFiscal />
                    </div>
                  </div>
                  <p class="cell auto">Résidence fiscale</p>
                  <div class="cell shrink arrow">
                    <SvgArrowRight />
                  </div>
                </div>
              </app-button>
            </div>
          </template>
        </app-dropdown>
      </div>
    </template>

    <EditPersonalInformation ref="profil-profil-edit-personal-information"
      :data="personalInformationComputed"
      :customer="customer"
      @onChange="(newData) => personalInformationComputed = newData"
      :isOpen="isTheFlapOpenPersonalInformation"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenPersonalInformation = value"
    />
    <EditBirthdate ref="profil-profil-edit-birthdate"
      :data="birthdateComputed"
      :customer="customer"
      @onChange="(newData) => birthdateComputed = newData"
      :isOpen="isTheFlapOpenBirthdate"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenBirthdate = value"
    />
    <EditEstate ref="profil-profil-edit-estate"
      :data="estateComputed"
      :customer="customer"
      @onChange="(newData) => estateComputed = newData"
      :isOpen="isTheFlapOpenEstate"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenEstate = value"
    />
    <EditContact ref="profil-profil-edit-contact"
      :data="contactComputed"
      :customer="customer"
      @onChange="(newData) => contactComputed = newData"
      :isOpen="isTheFlapOpenContact"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenContact = value"
    />
    <EditFiscalResidence ref="profil-profil-edit-fiscal-residence"
      :data="fiscalResidenceComputed"
      :customer="customer"
      @onChange="(newData) => fiscalResidenceComputed = newData"
      :isOpen="isTheFlapOpenFiscalResidence"
      :isDisabled="areFieldsDisabledComputed"
      @is-open="(value) => isTheFlapOpenFiscalResidence = value"
    />
  </div>
</template>

<script>
// Api
import ApiCustomer from '../../../services/api/customer';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';
import EnumCivility from '../../../services/enums/customer/civility.enum';

// Components
import AppDropdown from '../../../basics/components/AppDropdown.vue';
import EditPersonalInformation from './EditPersonalInformation.vue';
import EditBirthdate from './EditBirthdate.vue';
import EditEstate from './EditEstate.vue';
import EditContact from './EditContact.vue';
import EditFiscalResidence from './EditFiscalResidence.vue';

// Svg
import SvgArrowRight from '../../../assets/img/icons/arrow-right.svg?inline';
import SvgId from '../../../assets/img/icons/id.svg?inline';
import SvgDate from '../../../assets/img/icons/date.svg?inline';
import SvgEstate from '../../../assets/img/icons/estate.svg?inline';
import SvgContact from '../../../assets/img/icons/contact.svg?inline';
import SvgFiscal from '../../../assets/img/icons/fiscal.svg?inline';

export default {
  name: 'profil-profil',
  inject: ['$getCustomer', '$isAccountNotValidated', '$isSelfEmployedPerson'],
  components: {
    AppDropdown,

    SvgArrowRight,
    SvgId,
    SvgDate,
    SvgEstate,
    SvgContact,
    SvgFiscal,

    EditPersonalInformation,
    EditBirthdate,
    EditEstate,
    EditContact,
    EditFiscalResidence,
  },
  data() {
    return {
      children: [],
      isLoading: false,

      personalInformation: {},
      birthdate: {},
      estate: {},
      contact: {},
      fiscalResidence: {},

      isTheFlapOpenPersonalInformation: false,
      isTheFlapOpenBirthdate: false,
      isTheFlapOpenEstate: false,
      isTheFlapOpenContact: false,
      isTheFlapOpenFiscalResidence: false,

      EnumCustomerType,
      EnumParentType,
      EnumCivility,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    childrenComputed() {
      // Affiche le gérant en premier
      const tmp = [...this.children];
      let tmpSorted;
      if (this.children && this.children.length > 0) {
        // eslint-disable-next-line
        tmpSorted = tmp.sort((x, y) => (x.customer.parentType === EnumParentType.LEADER.name ? -1 : y.customer.parentType === EnumParentType.LEADER.name ? 1 : 0));
      }
      return tmpSorted;
    },
    isAccountNotValidated() {
      return this.$isAccountNotValidated();
    },
    isSelfEmployedPerson() {
      return this.$isSelfEmployedPerson();
    },
    areFieldsDisabledComputed() {
      return !this.isAccountNotValidated;
    },
    titleComputed() {
      return this.customer.customer.type === EnumCustomerType.BUSINESS
        ? 'Paramètres du profil'
        : 'Profil';
    },
    subTitleComputed() {
      return this.customer.customer.type === EnumCustomerType.BUSINESS
        ? 'Les informations des personnes liées à votre entreprise'
        : 'Vos informations personnelles.';
    },
    shareHolderNumber() {
      return Number(this.customer.customer.shareholderNumber);
    },
    dataRecordComputed() {
      if (this.customer.customer.type === EnumCustomerType.BUSINESS) {
        if (this.childrenComputed) {
          return [...this.childrenComputed, ...this.getEmptyCustomer(this.shareHolderNumber)];
        }
      }
      return [this.customer];
    },
    personalInformationComputed: {
      get() { return this.personalInformation; },
      set(data) { this.personalInformation = data; },
    },
    birthdateComputed: {
      get() { return this.birthdate; },
      set(data) { this.birthdate = data; },
    },
    estateComputed: {
      get() { return this.estate; },
      set(data) { this.estate = data; },
    },
    contactComputed: {
      get() {
        let tmp = {};
        if (this.contact.customer) {
          const tmpAddress = !this.contact.customer.address ? {
            address: {
              locality: null,
              postalCode: null,
              route: null,
              streetNumber: null,
            },
          } : { ...this.contact.customer.address };
          tmp = {
            ...this.contact,
            customer: {
              ...this.contact.customer,
              address: { ...tmpAddress },
            },
          };
        }
        return tmp;
      },
      set(data) { this.contact = data; },
    },
    fiscalResidenceComputed: {
      get() { return this.fiscalResidence; },
      set(data) { this.fiscalResidence = data; },
    },
  },
  watch: {
    customer() {
      this.getCustomerChildren();
    },
    isTheFlapOpenPersonalInformation(newValue) {
      if (newValue) this.toogleisFlapOpen(true, false, false, false, false);
    },
    isTheFlapOpenBirthdate(newValue) {
      if (newValue) this.toogleisFlapOpen(false, true, false, false, false);
    },
    isTheFlapOpenEstate(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, true, false, false);
    },
    isTheFlapOpenContact(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, true, false);
    },
    isTheFlapOpenFiscalResidence(newValue) {
      if (newValue) this.toogleisFlapOpen(false, false, false, false, true);
    },
  },
  mounted() {
    this.getCustomerChildren();

    setTimeout(() => {
      this.overwriteDropdown();
    }, 300);
  },
  methods: {
    async getCustomerChildren(parentType) {
      this.isLoading = true;
      try {
        this.children = await ApiCustomer.getCustomerChildren(parentType);
      } catch (error) {
        throw error;
      }
      this.isLoading = false;
    },
    toogleisFlapOpen(isTheFlapOpenPersonalInformation, isTheFlapOpenBirthdate, isTheFlapOpenEstate, isTheFlapOpenContact, isTheFlapOpenFiscalResidence) {
      this.isTheFlapOpenPersonalInformation = isTheFlapOpenPersonalInformation;
      this.isTheFlapOpenBirthdate = isTheFlapOpenBirthdate;
      this.isTheFlapOpenEstate = isTheFlapOpenEstate;
      this.isTheFlapOpenContact = isTheFlapOpenContact;
      this.isTheFlapOpenFiscalResidence = isTheFlapOpenFiscalResidence;
    },
    /**
    * Pour un customer INDIVIDUAL comme il n'y a q'une entrée on ouvre automatiquement le dropdown sur la position ouverte et on désactive le clic
    */
    overwriteDropdown() {
      if (this.$refs['profil-profil-dropdown'] && this.$refs['profil-profil-dropdown'].length > 0) {
        this.$refs['profil-profil-dropdown'][0].isOpen = true;
      }
    },
    getEmptyCustomer(number) {
      const tmpArray = [];
      const tmpEmtyCustomer = { ...this.customer.customer };
      Object.keys(tmpEmtyCustomer).forEach((property) => { tmpEmtyCustomer[property] = null; });

      /** Boucle à partir d'un nombre,
          défini par le nombre d'actionnaires renseigné par le customer business lors de son inscription
          et du nombre de customer déjà présents en bdd
      */
      // this.children.length - 1: -1 pour retirer le leader customer qui sera toujours présent dans children
      for (let index = 0; index < (number - (this.childrenComputed.length - 1)); index += 1) {
        tmpArray.push({
          customer: {
            ...tmpEmtyCustomer,
            address: {
              locality: null,
              postalCode: null,
              route: null,
              streetNumber: null,
            },
          },
          treezorUser: {},
        });
      }
      return tmpArray;
    },
    getParentType(child) {
      let type;
      if ((this.customer.customer.type === EnumCustomerType.INDIVIDUAL) || this.isSelfEmployedPerson) {
        type = '';
      } else {
        type = child.customer.parentType
          ? EnumParentType[child.customer.parentType].label
          : EnumParentType.SHAREHOLDER.label;
      }
      return type;
    },
  },
};
</script>

<style lang="sass">
.profil-profil
  > p
    margin: 0
    @include text-bold
    color: var(--color-grey70)
    &:first-child
      @include header2
      + p
        margin-bottom: ($global-padding-horizontal * 2)
    &:nth-child(2)
      @include text
      margin-top: ($global-padding-horizontal / 2)
      color: var(--color-grey70)

  div.record
    max-width: 382px
    ~ .record
      margin-top: $global-padding-horizontal
    p
      margin: 0
    > .app-dropdown
      padding: 0
      background-color: transparent
      &.content-opened
        .main
          border-radius: ($global-border-radius * 2) ($global-border-radius * 2) 0 0
      &.disabled
        .content .personal-information
          pointer-events: initial
      .main
        border-radius: ($global-border-radius * 2)
        padding: ($global-padding-vertical * 2)
        background-color: var(--color-cloud)
        &:hover
          background-color: var(--color-50)
      .content
        position: relative
        padding: 0
        border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
        .app-button
          @include header3
          width: 100%
          border-radius: 0
          padding: $global-padding-horizontal ($global-padding-vertical * 2)
          background-color: var(--color-cloud)
          &:hover, &.active
            background-color: var(--color-50)
          > div
            width: 100%
          div.arrow
            display: flex
            svg
              path
                stroke: var(--color-grey70)
          p
            text-align: left
          .icon
            margin-right: $global-padding-horizontal
            svg
              margin: 0
              width: 20px
              path
                fill: var(--color-black)
                stroke: transparent
            .circle
              display: flex
              height: 38px
              width: 38px
              justify-content: center
              align-items: center
              border-radius: 100%
              background-color: var(--color-70)
        > div:last-child .app-button
          border-radius: 0 0 ($global-border-radius * 2) ($global-border-radius * 2)
    > .app-button
      background-color: var(--color-cloud)

      &.active, &:hover
        background-color: var(--color-50)
    > .app-dropdown, > .app-button
      width: 100%
      border-radius: ($global-border-radius * 2)
      > div
        width: 100%
      div.arrow
        display: flex
        svg
          path
            stroke: var(--color-grey70)
      p
        @include header3
        ~ p
          @include details
          color: var(--color-grey70)
        text-align: left

  form
    .app-button.submit
      margin-top: ($global-padding-horizontal * 3)
      width: 100%
      justify-content: center
    .errors
      display: flex
      svg
        max-width: 22px
        min-width: 22px
      ul
        padding-left: $global-padding-horizontal
        list-style: none
        color: var(--color-warning)
</style>
