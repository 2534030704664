<template>
  <div class="check-details">
    <app-flap :isOpen="!!selectedCheck" @click:is-open="$emit('click:close')" small>
      <template v-slot:app-flap-main>
        <p>{{ selectedCheck.amount | formatPrice }}</p>
        <p v-if="additionalData.cheque.drawerData.lastName">à {{ additionalData.cheque.drawerData.firstName }} {{ additionalData.cheque.drawerData.lastName }}</p>
      </template>
      <template v-slot:app-flap-content>
        <div class="check-info">
          <div>
            <div>Numéro :</div>
            <div>{{ additionalData ? additionalData.cheque.cmc7.a : '--' }}{{ additionalData ? additionalData.cheque.cmc7.b : '--' }}{{ additionalData ? additionalData.cheque.cmc7.c : '--' }}</div>
          </div>
          <div>
            <div>Clé :</div>
            <div>{{ additionalData ? additionalData.cheque.RLMCKey : '--' }}</div>
          </div>
          <div>
            <div>Date :</div>
            <div>{{ selectedCheck.createdDate | formatDate }}</div>
          </div>
          <div class="status">
            <div>Statut :</div>
            <div :class="getCheckStatus(selectedCheck)">{{ CheckStatusLabel[getCheckStatus(selectedCheck)] }}</div>
          </div>
        </div>
        <div class="process-address">
          <p>Adresse postale</p>
          <p>KALVEEN - SSP<br/>
          25 rue Louis le Grand<br/>
          75002 PARIS
          </p>
        </div>
        <div class="process-info">
          <SvgInfos />
          <p>Une fois reçu, votre chèque sera analysé et sera crédité sous 15 jours sur votre compte après validation.</p>
        </div>
      </template>
    </app-flap>
  </div>
</template>

<script>
import CheckStatus from '@/services/enums/checks/check-status.enum';
import CheckStatusLabel from '@/services/enums/checks/check-status-label.enum';

import SvgInfos from '@/assets/img/icons/infos.svg?inline';

export default {
  props: {
    selectedCheck: {
      type: Object,
      required: false,
    },
    getCheckStatus: {
      type: Function,
      default: () => '',
    },
  },
  components: {
    SvgInfos,
  },
  data() {
    return {
      check: null,
      CheckStatus,
      CheckStatusLabel,
    };
  },
  computed: {
    additionalData() {
      const additionalData = {
        cheque: {
          cmc7: {
            a: 'XXXXXXX',
            b: 'XXXXXXXXXXXX',
            c: 'XXXXXXXXXXXX',
          },
          RLMCKey: 'XX',
          drawerData: {
            firstName: '',
            lastName: '',
          },
        },
      };
      return this.selectedCheck.additionalData && this.selectedCheck.additionalData.length ? JSON.parse(this.selectedCheck.additionalData[0]) : additionalData;
    },
  },
};
</script>

<style lang="sass">
.check-details
  .app-flap
    > .main
      p:first-child
        margin-bottom: 0
      p:not(:first-child)
        @include header3
        color: var(--color-grey70)
        margin-top: 0
    > .content
      padding-right: 0
      padding-left: 0
      .check-info
        > div
          display: flex
          justify-content: space-between
          padding: $global-padding-vertical 32px
          border-bottom: 1px solid var(--color-70)
          > div
              word-wrap: break-word
              &:first-child
                @include header5
                text-transform: uppercase
                color: var(--color-grey70)
              &:last-child
                @include text
                max-width: 70%
                text-align: right
          &.status
            div:last-child
              @include header5
            .CASHED
              color: var(--color-success)
            .REFUSED
              color: var(--color-warning)
          &:last-child
            border-bottom: 0
      .process-address
        margin: 40px 32px 22px
        padding: $global-padding-vertical $global-padding-horizontal
        background-color: var(--color-30)
        border-radius: $global-border-radius
        p
          &:first-child
            @include header3
            margin: 0
          &:last-child
            @include details
            margin: 0
            color: var(--color-grey70)
      .process-info
        display: flex
        justify-content: flex-start
        align-items: flex-start
        margin: 22px 32px
        svg
          min-width: 22px
          margin-right: 1rem
        p
          font-size: 11px
          line-height: 16px
          margin: 0
          color: var(--color-grey70)

</style>
