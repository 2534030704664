<template>
  <div class="state-complete">
    <div class="main">
      <RegisterNavigation :class="$utils.transformPascalCaseToClassName(Object.keys(necessaryComponentsComputed)[stepComputed - 1])"
        :icon="getIconComputed"
        :name="getNameComputed"
        :step="stepComputed"
        :stepLocked="userStepLocked"
        :stepsNumber="stepsNumberComputed"
        @click:previous="() => { stepComputed -= 1; navigationAction = 'previous' }"
        @click:next="() => { stepComputed < userStepLocked ? stepComputed += 1 : null; navigationAction = 'next' }"
      />
    </div>
    <div class="content">
      <template v-for="(component, index) in Object.keys(necessaryComponentsComputed)">
        <component v-if="(index + 1) === stepComputed"
          :ref="`component-${index}`"
          @click="() => { stepComputed += 1; navigationAction = 'next' }"
          :key="`register-state-complete-${index}`"
          :is="necessaryComponentsComputed[component]"
        ></component>
      </template>
    </div>

    <div class="illustration" :class="$theme"></div>
  </div>
</template>

<script>
// Components
import RegisterNavigation from '../Navigation.vue';

import CompanyData from '../Company/Data.vue';
import CompanyNumbers from '../Company/Numbers.vue';
import CompanyEmbargo from '../Company/Embargo.vue';
import CompanyContact from '../Company/Contact.vue';

import ProfilPersonalInformation from '../Profil/PersonalInformation.vue';
import ProfilBirthdate from '../Profil/Birthdate.vue';
import ProfilEstate from '../Profil/Estate.vue';
import ProfilContact from '../Profil/Contact.vue';
import ProfilFiscalResidence from '../Profil/FiscalResidence.vue';

import Contract from '../Contract.vue';

// Enums
import EnumCustomerType from '../../../services/enums/customer/type.enum';

// Svgs
import SvgCompanyData from '../../../assets/img/icons/pro.svg?inline';
import SvgCompanyNumbers from '../../../assets/img/icons/numbers.svg?inline';
import SvgCompanyEmbargo from '../../../assets/img/icons/embargo.svg?inline';
import SvgProfilPersonalInformation from '../../../assets/img/icons/id.svg?inline';
import SvgProfilBirthdate from '../../../assets/img/icons/date.svg?inline';
import SvgProfilEstate from '../../../assets/img/icons/estate.svg?inline';
import SvgProfilContact from '../../../assets/img/icons/contact.svg?inline';
import SvgProfilFiscalResidence from '../../../assets/img/icons/home.svg?inline';
import SvgContract from '../../../assets/img/icons/sign.svg?inline';

const individualComponents = {
  ProfilPersonalInformation,
  ProfilBirthdate,
  ProfilEstate,
  ProfilContact,
  ProfilFiscalResidence,
  Contract,
};
const businessComponents = {
  CompanyData,
  CompanyNumbers,
  CompanyEmbargo,
  CompanyContact,

  ProfilPersonalInformation,
  ProfilBirthdate,
  ProfilEstate,
  ProfilContact,
  ProfilFiscalResidence,
  Contract,
};

export default {
  name: 'state-complete',
  components: {
    RegisterNavigation,

    SvgCompanyData,
    SvgCompanyNumbers,
    SvgCompanyEmbargo,
    SvgProfilPersonalInformation,
    SvgProfilBirthdate,
    SvgProfilEstate,
    SvgProfilContact,
    SvgProfilFiscalResidence,
  },
  inject: ['$getCustomer', '$getLeader'],
  data() {
    return {
      step: 1,
      userStepLocked: 1,
      counterInit: 0,
      navigationAction: null,

      hasAlreadyBeenCalled: false,

      EnumCustomerType,
    };
  },
  computed: {
    customer() {
      return this.$getCustomer();
    },
    leader() {
      return this.$getLeader();
    },
    stepComputed: {
      get() {
        return this.step;
      },
      set(newValue) {
        this.step = newValue;
        if (this.counterInit <= 0 || this.stepComputed > this.counterInit) {
          this.userStepLocked = newValue;
          this.counterInit = newValue;
        }
      },
    },
    stepsNumberComputed() {
      return this.customer?.customer.type === EnumCustomerType.INDIVIDUAL ? Object.keys(individualComponents).length : Object.keys(businessComponents).length;
    },
    necessaryComponentsComputed() {
      return this.customer?.customer.type === EnumCustomerType.INDIVIDUAL ? individualComponents : businessComponents;
    },
    getNameComputed() {
      return this.getName(Object.keys(this.necessaryComponentsComputed)[this.stepComputed - 1]);
    },
    getIconComputed() {
      return this.getIcon(Object.keys(this.necessaryComponentsComputed)[this.stepComputed - 1]);
    },
    customerAndLeaderComputed() {
      /* eslint-disable */
      if ((this.customer?.customer?.type === EnumCustomerType.BUSINESS) && !this.customer?.customer?.isFrenchFiscalResident) {
        if (((this.stepComputed + 2) < this.stepsNumberComputed) && (Object.keys(this.necessaryComponentsComputed)[this.stepComputed + 2].includes('Profil'))) {
          return this.customer && this.leader;
        } else {
          return this.customer || this.leader;
        }
      }
      /* eslint-enable */
      return this.customer;
    },
  },
  watch: {
    customerAndLeaderComputed() {
      const currentComponent = Object.keys(this.$refs).find((key) => this.$refs[key].length > 0);
      if (this.hasAlreadyBeenCalled) return;
      // Si l'écran ne possède pas d'erreurs et que l'action de navigation est d'incrémenter
      // alors ils est possible de passer à l'étape suivante
      if (this.navigationAction === 'next') {
        this.hasAlreadyBeenCalled = true;
      } else if (this.navigationAction !== 'previous' && (this.$refs[currentComponent][0].errors.length <= 0)) {
        if (this.stepComputed < this.userStepLocked) {
          this.stepComputed += 1;
        } else {
          // eslint-disable-next-line
          this.initStep();
        }
      }
    },
  },
  mounted() {
    return this.customer ? this.initStep() : null;
  },
  methods: {
    initStep() {
      const componentsArray = this.$utils.transformEnumToArray(this.necessaryComponentsComputed);

      if (this.customer.customer.type === EnumCustomerType.BUSINESS
        && !this.customer.customer.legalRegistrationNumber) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'CompanyData') + 1;
      } else if (this.customer.customer.type === EnumCustomerType.BUSINESS
        && (!this.customer.customer.legalRegistrationDate || !this.customer.customer.legalShareCapital || !this.customer.customer.legalAnnualTurnOver || !this.customer.customer.legalNetIncomeRange || !this.customer.customer.legalNumberOfEmployeeRange || !this.customer.customer.shareholderNumber)
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'CompanyNumbers') + 1;
      } else if (this.customer.customer.type === EnumCustomerType.BUSINESS
        && (!this.customer.customer.address || !this.customer.customer.address.route || !this.customer.customer.phone || !this.customer.customer.address.streetNumber || !this.customer.customer.address.postalCode || !this.customer.customer.address.locality || !this.customer.customer.address.administrativeAreaLevel1)
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'CompanyContact') + 1;
      } else if (this.customer.customer.type === EnumCustomerType.BUSINESS
        && this.customer.customer.activityOutsideEu === null
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'CompanyEmbargo') + 1;
      } else if (((this.customer.customer.type === EnumCustomerType.BUSINESS) && !this.customer.customer.isSelfEmployedPerson)
        ? (!this.leader || !this.leader.customer.lastname || !this.leader.customer.firstname || !this.leader.customer.civility)
        : (!this.customer.customer.lastname || !this.customer.customer.firstname || !this.customer.customer.civility)) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'ProfilPersonalInformation') + 1;
      } else if (((this.customer.customer.type === EnumCustomerType.BUSINESS) && !this.customer.customer.isSelfEmployedPerson)
        ? (this.leader.customer.lastname && this.leader.customer.firstname && this.leader.customer.civility && !this.leader.customer.birthdate)
        : (this.customer.customer.lastname && this.customer.customer.firstname && this.customer.customer.civility && !this.customer.customer.birthdate)
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'ProfilBirthdate') + 1;
      } else if (((this.customer.customer.type === EnumCustomerType.BUSINESS) && !this.customer.customer.isSelfEmployedPerson)
        ? (this.leader.customer.birthdate && this.leader.customer.placeOfBirth && this.leader.customer.placeBirthCountry && this.leader.customer.nationality && !this.leader.customer.occupation)
        : (this.customer.customer.birthdate && this.customer.customer.placeOfBirth && this.customer.customer.placeBirthCountry && this.customer.customer.nationality && !this.customer.customer.occupation)
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'ProfilEstate') + 1;
      } else if (((this.customer.customer.type === EnumCustomerType.BUSINESS) && !this.customer.customer.isSelfEmployedPerson)
        ? (this.leader.customer.occupation && this.leader.customer.incomeRange && this.leader.customer.personalAssets && !this.leader.customer.phone)
        : (this.customer.customer.occupation && this.customer.customer.incomeRange && this.customer.customer.personalAssets && !this.customer.customer.phone)
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'ProfilContact') + 1;
      } else if (((this.customer.customer.type === EnumCustomerType.BUSINESS) && !this.customer.customer.isSelfEmployedPerson)
        ? (this.leader.customer.phone && this.leader.customer.address.streetNumber && this.leader.customer.address.route && this.leader.customer.address.postalCode && this.leader.customer.address.locality && (this.leader.customer.isFrenchFiscalResident === null))
        : (this.customer.customer.phone && this.customer.customer.address.streetNumber && this.customer.customer.address.route && this.customer.customer.address.postalCode && this.customer.customer.address.locality && (this.customer.customer.isFrenchFiscalResident === null))
      ) {
        this.stepComputed = componentsArray.findIndex((el) => el.key === 'ProfilFiscalResidence') + 1;
      } else {
        this.stepComputed = componentsArray.length;
      }
    },
    getName(value) {
      let name;
      switch (value) {
        case 'CompanyData':
          name = 'Informations entreprise';
          break;
        case 'CompanyNumbers':
          name = 'Chiffres de votre entreprise';
          break;
        case 'CompanyEmbargo':
          name = 'Embargo entreprise';
          break;
        case 'CompanyContact':
          name = 'Adresse de l\'entreprise';
          break;
        case 'ProfilPersonalInformation':
          name = 'Informations personnelles';
          break;
        case 'ProfilBirthdate':
          name = 'Date de naissance';
          break;
        case 'ProfilEstate':
          name = 'Patrimoine';
          break;
        case 'ProfilContact':
          name = 'Adresse de contact';
          break;
        case 'ProfilFiscalResidence':
          name = 'Résidence fiscale';
          break;
        default:
          name = 'Signature de votre contrat';
          break;
      }
      return name;
    },
    getIcon(value) {
      let icon;
      switch (value) {
        case 'CompanyData':
          icon = SvgCompanyData;
          break;
        case 'CompanyNumbers':
          icon = SvgCompanyNumbers;
          break;
        case 'CompanyEmbargo':
          icon = SvgCompanyEmbargo;
          break;
        case 'ProfilPersonalInformation':
          icon = SvgProfilPersonalInformation;
          break;
        case 'ProfilBirthdate':
          icon = SvgProfilBirthdate;
          break;
        case 'ProfilEstate':
          icon = SvgProfilEstate;
          break;
        case 'ProfilContact':
          icon = SvgProfilContact;
          break;
        case 'ProfilFiscalResidence':
          icon = SvgProfilFiscalResidence;
          break;
        default:
          icon = SvgContract;
          break;
      }
      return icon;
    },
  },
};
</script>

<style lang="sass">
.state-complete
  margin-top: $global-padding-horizontal * 4
  .content
    position: relative
    margin: auto
    max-width: 600px
    z-index: 5
    .app-button.next
      margin: ($global-padding-horizontal * 10) auto
      svg path
        stroke: white
  form
    padding-bottom: $global-padding-vertical
  .illustration
    position: fixed
    top: 0
    width: 30%
    right: 0
    height: 100%
    background-repeat: no-repeat
    background-size: cover
    background-image: url(../../../assets/img/individual/background-register-2.svg)
    z-index: 1
    &.business
      background-image: url(../../../assets/img/business/background-register-2.svg)

</style>
