import EnumCustomerType from '../customer/type.enum';

const profil = Object.freeze({
  COMPANY: {
    label: 'Profil',
    name: 'company',
    userRole: EnumCustomerType.BUSINESS,
  },
  PROFIL: {
    label: 'Profil',
    name: 'personal-information',
  },
  PROOFS: {
    label: 'Justificatifs',
    name: 'proofs',
  },
  SECURITY: {
    label: 'Sécurité',
    name: 'security',
  },
  LIMITS: {
    label: 'Limitations',
    name: 'limits',
  },
  OFFER: {
    label: 'Offre',
    name: 'offer',
  },
});

export default profil;
