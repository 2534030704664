import AppEvent from './utils/AppEvent';
import AppMessage from './components/AppMessage.vue';
import AppModal from './components/AppModal.vue';
import AppCheckbox from './components/AppCheckbox.vue';
import AppRadio from './components/AppRadio.vue';
import AppSwitch from './components/AppSwitch.vue';
import AppButton from './components/AppButton.vue';
import AppCard from './components/AppCard.vue';
import AppNotifications from './components/AppNotifications.vue';
import AppSelect from './components/AppSelect.vue';
import AppInput from './components/AppInput.vue';
import AppDatepicker from './components/AppDatepicker.vue';
import AppLabel from './components/AppLabel.vue';
import AppSlider from './components/AppSlider.vue';
import AppProgress from './components/AppProgress.vue';
import AppTable from './components/AppTable.vue';
import AppTooltip from './components/AppTooltip.vue';
import AppUpload from './components/AppUpload.vue';
import AppAlert from './components/AppAlert.vue';
import AppLoader from './components/AppLoader.vue';
import AppSpinner from './components/AppSpinner.vue';

const basics = {

  install(Vue) {
    Vue.component('app-message', AppMessage);
    Vue.component('app-modal', AppModal);
    Vue.component('app-checkbox', AppCheckbox);
    Vue.component('app-radio', AppRadio);
    Vue.component('app-switch', AppSwitch);
    Vue.component('app-button', AppButton);
    Vue.component('app-card', AppCard);
    Vue.component('app-notifications', AppNotifications);
    Vue.component('app-select', AppSelect);
    Vue.component('app-input', AppInput);
    Vue.component('app-datepicker', AppDatepicker);
    Vue.component('app-label', AppLabel);
    Vue.component('app-slider', AppSlider);
    Vue.component('app-progress', AppProgress);
    Vue.component('app-table', AppTable);
    Vue.component('app-tooltip', AppTooltip);
    Vue.component('app-upload', AppUpload);
    Vue.component('app-alert', AppAlert);
    Vue.component('app-loader', AppLoader);
    Vue.component('app-spinner', AppSpinner);

    // eslint-disable-next-line
    Vue.prototype.$message = {
      show(params) {
        AppEvent.$emit('app-message-show', params);
      },
    };

    // eslint-disable-next-line
    Vue.prototype.$notification = {
      show(params) {
        AppEvent.$emit('app-notification-show', params);
      },
    };

    // eslint-disable-next-line
    Vue.prototype.$alert = {
      show(params) {
        AppEvent.$emit('app-alert-show', params);
      },
      cancel(alert) {
        AppEvent.$emit('app-alert-cancel', alert);
      },
    };
  },
};

export default basics;
