<template>
  <div class="profil-profil-edit-birthdate">
    <app-flap :isOpen="isOpen" @click:is-open="(value) => $emit('is-open', value)">
      <template v-slot:app-flap-main>
        <p><span>{{ data.customer.lastname }}</span> <span>{{ data.customer.firstname }}</span></p>
        <p>Date de naissance</p>
      </template>
      <template v-slot:app-flap-content>
        <app-loader v-if="isLoading"></app-loader>
        <form v-else @submit.prevent="sendData">
          <div class="grid-x birthdate align-middle">
            <app-datepicker
              class="cell auto"
              v-model="birthdateComputed"
              :isDisabled="isDisabled"
              label="Date de naissance *"
              :disabledDates="datepickerDisabledDates()"
              isUtc
            ></app-datepicker>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x place-of-birth align-middle">
            <app-input
              class="cell auto"
              label="Ville de naissance *"
              type="text"
              v-model="placeOfBirthComputed"
              :isDisabled="isDisabled"
            ></app-input>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x place-birth-country align-middle">
            <app-select
              class="cell auto"
              v-model="placeBirthCountryComputed"
              label="Pays de naissance *"
              :options="EnumCountries"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="grid-x nationality align-middle">
            <app-select
              class="cell auto"
              v-model="nationalityComputed"
              label="Nationalité *"
              :options="EnumNationalities"
              :isDisabled="isDisabled">
            </app-select>
            <SvgLock v-if="isDisabled" class="cell shrink" />
          </div>

          <div class="errors" v-if="errors && errors.length > 0">
            <SvgWarning width="22" />
            <ul>
              <li v-for="(error, index) in errors" :key="`reset-password-error-${index}`">
                {{ transformError(error) }}
              </li>
            </ul>
          </div>

          <app-button class="submit" :disabled="isDisabled || !birthdateComputed || !placeOfBirthComputed || !placeBirthCountryComputed || !nationalityComputed" type="submit">Enregistrer les modifications</app-button>
        </form>
      </template>
    </app-flap>
  </div>
</template>

<script>
// Svg
import SvgWarning from '../../../assets/img/icons/warning.svg?inline';
import SvgLock from '../../../assets/img/icons/lock.svg?inline';

// Components

// Enums
import EnumCountries from '../../../../../commons/countries.json';
import EnumNationalities from '../../../../../commons/nationalities.json';
import EnumCustomerType from '../../../services/enums/customer/type.enum';
import EnumParentType from '../../../services/enums/customer/parentType.enum';
import EnumNotificationType from '../../../services/enums/notification.enum';

// Api
import ApiCustomer from '../../../services/api/customer';

const currentDate = new Date();

export default {
  name: 'profil-profil-edit-birthdate',
  inject: ['$getCustomer'],
  components: {
    SvgWarning,
    SvgLock,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      EnumCountries: this.$utils.sortAlphabetically(EnumCountries),
      EnumNationalities: this.$utils.sortAlphabetically(EnumNationalities),

      errors: [],
      isLoading: false,
    };
  },
  computed: {
    birthdateComputed: {
      get() {
        let value;
        if (this.data.customer.birthdate) {
          value = this.$dayjs(this.data.customer.birthdate).toDate();
        } else {
          value = this.$dayjs().subtract(18, 'year').toDate();
        }
        return value;
      },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            birthdate: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    placeOfBirthComputed: {
      get() { return this.data.customer.placeOfBirth; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            placeOfBirth: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    placeBirthCountryComputed: {
      get() { return this.data.customer.placeBirthCountry; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            placeBirthCountry: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
    nationalityComputed: {
      get() { return this.data.customer.nationality; },
      set(newValue) {
        const tmp = {
          ...this.data,
          customer: {
            ...this.data.customer,
            nationality: newValue,
          },
        };
        this.$emit('onChange', tmp);
      },
    },
  },
  methods: {
    async sendData() {
      this.errors = [];
      this.isLoading = true;

      const tmpUser = {
        birthdate: this.birthdateComputed,
        placeOfBirth: this.placeOfBirthComputed,
        placeBirthCountry: this.placeBirthCountryComputed,
        nationality: this.nationalityComputed,
      };

      try {
        /* eslint-disable */
        // Cas customer INDIVIDUAL
        if (this.customer.customer.type === EnumCustomerType.INDIVIDUAL) {
          await ApiCustomer.setCustomer(tmpUser);
        } else { // Cas customer BUSINESS
          // S'il y a un customerId il faut vérifier à quel type de customer on a à faire,
          // car la route de modification ne sera pas pas identique.
          if (this.data.customer.parentType === EnumParentType.LEADER.name) {
            await ApiCustomer.setLeader(tmpUser);
          } else if (this.data.customer.parentType === EnumParentType.SHAREHOLDER.name) {
            await ApiCustomer.setAssociate(this.data.customer.customerId, tmpUser);
          }
        }
        /* eslint-enable */
        this.$getCustomer(true);
      } catch (error) {
        if (error.response.data.message && error.response.data.message.length > 0) {
          if (typeof error.response.data.message === 'string') this.errors.push((error.response.data.message));
          else this.errors = [...this.errors, ...error.response.data.message];
        }
        this.$notification.show({
          title: 'Echec de la sauvegarde',
          text: 'Nous vous invitons à consulter les erreurs.',
          type: EnumNotificationType.ECHEC,
        });
      }

      this.isLoading = false;
    },
    datepickerDisabledDates() {
      // Objet spécifique au package vuejs-datepicker
      return {
        customPredictor(date) {
          let age = currentDate.getFullYear() - date.getFullYear();
          const month = currentDate.getMonth() - date.getMonth();
          if (month < 0 || (month === 0 && currentDate.getDate() < date.getDate())) {
            age -= 1;
          }
          if (age < 18) {
            return true;
          }
          return false;
        },
      };
    },
    transformError(error) {
      let newErrorMessage;
      switch (error) {
        case 'Contact customer service to edit profile':
          newErrorMessage = 'La modification des ses informations n\'est plus autorisée. Veuillez contacter le service client pour réaliser cette démarche.';
          break;
        case 'Birthdate must correspond to an adult':
          newErrorMessage = 'La date de naissance doit correspondre à une personne majeure.';
          break;
        case '':
          newErrorMessage = '';
          break;
        default:
          break;
      }
      return newErrorMessage;
    },
  },
};
</script>

<style lang="sass">
.profil-profil-edit-birthdate
  .app-input
    margin: 0
  .app-input, .app-select, .app-datepicker
    line-height: initial
    .label
      margin: revert
      background-color: var(--color-cloud)
  .app-datepicker input
    background-color: var(--color-cloud)
  .grid-x
    ~ .grid-x
      margin-top: $global-padding-vertical * 3

  .app-flap
    .main
      p:first-child
        @include header4
        margin-bottom: 0
        color: var(--color-100)
      p:last-child
        margin-top: 0
        @include header2
    .content
      .birthdate, .place-of-birth, .place-birth-country, .nationality
        svg
          margin-left: $global-padding-vertical
          path
            &:first-child
              stroke-width: 1
              stroke: var(--color-grey70)
            &:last-child
              fill: var(--color-cloud)
              stroke: var(--color-grey70)
</style>
