<template>
  <div class="list-entry" :class="{ 'selectable': selectable, 'is-next': isNext, 'active-entry': active }" @click="() => select($vnode.key)">
    <h3>{{ title }}</h3>

    <p v-for="(subtitle,i) in subtitles" :key="i">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'list-entry',
  props: {
    title: {
      type: String,
      required: false,
    },
    subtitles: {
      type: Array,
      required: false,
    },
    selectable: {
      type: Boolean,
      required: false,
    },
    select: {
      type: Function,
      required: false,
    },
    isNext: {
      type: Boolean,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="sass">
.list-entry
  padding: 8px 16px
  h3
    @include header3
    margin: 0
    text-align: left
  p
    @include text
    color: var(--color-grey70)
    text-align: left
.is-next
  border-bottom: 1px solid var(--color-50)
.selectable
  cursor: pointer
  &:hover
    background-color: var(--color-50)
.active-entry
  background-color: var(--color-50)
</style>
